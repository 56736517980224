import moment from 'moment-timezone';
import React, {PureComponent} from 'react';
import {View, Text} from 'react-native';
import PropTypes from 'prop-types';
import EStyleSheet from "react-native-extended-stylesheet";
import API from "../api";

// Todo: rewrite as functional component

export default class Clock extends PureComponent {

  static propTypes = {};

  componentDidMount() {
    this._interval = setInterval(this._refresh, 1000);
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  _refresh = () => {
    this.forceUpdate();
  }

  render() {
    const now = moment();
    return (
      <View style={styles.container}>
        <Text style={styles.text}>{now.format('LT')}</Text>
      </View>
    );
  }
}


const styles = EStyleSheet.create({
  container: {
    marginHorizontal: 10
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#fff'
  }
});
