import React, {useEffect, useState} from 'react';
import {CardElement, useStripe, useElements} from "@stripe/react-stripe-js";
import API from "../../api";
import FormWrapper from "./FormWrapper";
import EStyleSheet from "react-native-extended-stylesheet";


const CardForm =  ({reusable, onSuccess, onClose, onError = () => {}, saveButtonText, paymentIntentParams}) => {
  const [saving, setSaving] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const saveManualCard = async () => {
    setSaving(true);
    onError('');

    const cardElement = elements.getElement('card');

    if (reusable) {
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {

        onError(error.message);
        setSaving(false);
      } else {
        onSuccess(paymentMethod);
      }
    } else {
      let {clientSecret, error: createError} = await API.createStripePaymentIntent(paymentIntentParams);

      if(createError){
        onError(createError.message);
        setSaving(false);
        return;
      }

      const {error, paymentIntent} = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          //billing_details: {}, // todo: add these?
        },
        setup_future_usage: undefined
      });

      if (error) {
        console.error(JSON.stringify(error, null, 2));
        onError(error.message);
        setSaving(false);
      } else onSuccess(paymentIntent);
    }
  }

  useEffect(() => {
    const cardElement = elements.getElement('card');
    cardElement.focus();
  }, [])

  return (
    <FormWrapper
      saveButtonText={saveButtonText}
      onSave={saveManualCard}
      onCancel={onClose}
      disabled={saving}
    >
      <CardElement/>
    </FormWrapper>
  )
}


export default CardForm;

const styles = EStyleSheet.create({
  errorText: {

  }
})
