import React from 'react';
import TextField from './TextField';
import {formatNumber} from "../../../helpers/HelperFunctions";

const NumberField = (props) => {
  const {required} = props;

  const validator = (value) => {
    if(!required && !value) return true;
    return !!value.match(/[\d.-]+$/)
  }

  return (
    <TextField
      {...props}
      validator={validator}
      formatter={formatNumber}
      keyboardType={'numeric'}
    />
  )
}

export default NumberField;
