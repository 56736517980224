import {View} from "react-native";
import {TextM} from "../../components";
import FuzzyField from "../../components/FuzzyField";
import {FormattedCurrency} from "react-native-globalize";
import Card from "./Card";
import React, {useEffect, useState} from "react";
import EStyleSheet from "react-native-extended-stylesheet";
import CheckoutCartItem from "./CheckoutCartItem";
import PromoInput from "./PromoInput";
import Colors from "../../constants/Colors";

/**
 * The <ItemsCard> is always displayed as long as the cart is non-empty (which it should never be if when viewing the
 * Tip Screen)
 *
 * @param props
 * @param {CheckModel} props.check Information on the current order, including items and various prices
 * @param {boolean} props.checkingPrices If true,  price values of the items and totals are fuzzy to denote checking
 * @param {number} props.tipAmount (in dollars) The final amount of the tip, as given from the <TipsCard> element
 * @constructor
 */
const CartItemsCard = ({check, checkingPrices, tipAmount, ...rest}) => {
  const [discounts, setDiscounts] = useState([]);

  // Construct the list of lines for the fees
  check.cart.updateFees();
  const fees = check.getFees();
  const feesLines = fees.map(fee => {
    return (
      <View testID={'itemsCardFeesLine'} style={styles.summaryRow} key={fee.id}>
        <TextM style={styles.summaryTitle}>{fee.name}</TextM>
        <FuzzyField testID={'itemsCardFeesCost'}>
          <FormattedCurrency style={styles.totals} value={(fee.total || 0) / 100}/>
        </FuzzyField>
      </View>
    );
  });

  useEffect(() => {
    setDiscounts(check.getDiscounts());
  }, [check.cart._last_modified]);


  return (
    <Card testID={'itemsCard'} title={'Items'} {...rest}>
      <View testID={'itemsCardReceiptItems'}>
        {check.items.map(item => (
          <CheckoutCartItem
            key={item.id}
            item={item}
            blurPrice={checkingPrices}
          />
        ))}
      </View>
      <PromoInput cart={check.cart}/>
      { /* -------- TOTALS -------- */}
      <View style={{width: "100%", marginVertical: 10, paddingLeft: '10%'}}>
        {/* Discount Lines */}
        {discounts.length !== 0 && discounts.map(discount => (
          <DiscountLine check={check} discount={discount} checkingPrices={checkingPrices}/>
        ))}
        {/* Sub-Total Line */}
        <View testID={'itemsCardSubtotalLine'} style={styles.summaryRow}>
          <TextM style={styles.summaryTitle}>Sub-Total</TextM>
          <FuzzyField testID={'itemsCardSubtotalCost'} fuzzy={checkingPrices}>
            <FormattedCurrency style={styles.totals} value={check.pretax_total / 100}/>
          </FuzzyField>
        </View>
        {/* Fees Lines */}
        {fees.length !== 0 && (feesLines)}
        {/* Tax Line */}
        <View testID={'itemsCardTaxLine'} style={styles.summaryRow}>
          <TextM style={styles.summaryTitle}>Tax</TextM>
          <FuzzyField testID={'itemsCardTaxCost'} fuzzy={checkingPrices}>
            <FormattedCurrency style={styles.totals} value={check.tax_total / 100}/>
          </FuzzyField>
        </View>
        {/* Tip Line */}
        <View testID={'itemsCardTipLine'} style={styles.summaryRow}>
          <TextM style={styles.summaryTitle}>Tip</TextM>
          <FuzzyField testID={'itemsCardTipCost'} fuzzy={checkingPrices}>
            <FormattedCurrency style={styles.totals} value={tipAmount / 100}/>
          </FuzzyField>
        </View>
        {/* Total Line */}
        <View testID={'itemsCardTotalLine'} style={[styles.summaryRow, {marginTop: 5}]}>
          <TextM style={[styles.summaryTitle, styles.bold]}>Total</TextM>
          <FuzzyField testID={'itemsCardTotalCost'} fuzzy={checkingPrices}>
            <FormattedCurrency
              style={[styles.totals, styles.bold]}
              value={(check.total + (tipAmount || 0)) / 100}
            />
          </FuzzyField>
        </View>
      </View>
    </Card>
  );
}

const DiscountLine = ({check, discount, checkingPrices}) => {
  let discountAmount = 0;
  check.items.forEach(item => {
    let d = item.discounts.find(d => d.promotion_id === discount.promotion_id);
    if (d) discountAmount += d.cents_added;
  });
  return (
    <View testID={'itemsCardDiscountLine'} style={styles.summaryRow} key={discount.promotion_id}>
      <TextM style={[styles.summaryTitle, {color: Colors.primary, fontWeight: 'bold'}]} numberOfLines={1} adjustsFontSizeToFit>PROMO: {discount.name}</TextM>
      <FuzzyField testID={'itemsCardDiscountCost'} fuzzy={checkingPrices}>
        <FormattedCurrency style={styles.totals} value={(discountAmount || 0) / 100}/>
      </FuzzyField>
    </View>
  )
}

export default CartItemsCard;

const styles = EStyleSheet.create({
  bold: {
    fontWeight: 'bold'
  },
  summaryRow: {
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
    marginBottom: 5
  },
  summaryTitle: {
    flex: 1,
    fontSize: 14
  },
  totals: {
    fontSize: 14
  },
})
