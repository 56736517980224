import _ from 'lodash';
import React, {Component} from 'react';
import {
  View,
  Text,
  ScrollView,
  Platform,
  TouchableWithoutFeedback,
  Dimensions, Linking
} from 'react-native';
import EStyleSheet from "react-native-extended-stylesheet";
import {showMessage} from "react-native-flash-message";
import {Content, Container, Footer, FooterTab, Body, List, Right} from "native-base";
import StripeT from 'react-native-stripe-terminal';
import API from '../api';
import Alert from "../components/Alert";
import {CardView, TabView, styles as cardStyles} from "../components/CardView";
import Loader from '../components/Loader';
import {prompt} from '../components/Prompt';
import ReaderStatus from "../components/ReaderStatus";
import Colors from "../constants/Colors";
import NfcManager, {Ndef, NfcEvents} from "react-native-nfc-manager";
import IconButton from "../components/IconButton";
import Fontello from "../assets/fonts/Fontello";
import StripeModal from "../components/Stripe/StripeCardModal";
import {NewTabModal} from "../components/ConsumerTab/NewTabButton";
import HelpIcon from "../components/HelpIcon";

/*
- When adding card, need to choose the guest as well
 */

export default class CardChooser extends Component {

  static navigationOptions = (props) => {
    return {
      title: 'Choose Card',
      headerRight: () => (
        <ReaderStatus/>
      )
    }
  };

  constructor(props) {
    super(props);

    let {navigation} = this.props;
    let location = navigation.getParam('location');
    let check = navigation.getParam('check');
    let uniqSeats = check.getUniqSeats();
    const config = API.getConfig();

    this.state = {
      check: check,
      consumer_tabs: location.open_tabs,
      containerHeight: Dimensions.get('window').height - 64,
      group_cards: [],
      guest_cards: location.seated_group ? location.seated_group.getGuestCardIds(uniqSeats) : [],
      loading: true,
      location: location,
      party_tabs: [],
      readerConnected: StripeT.readerConnected,
      selected_card: null,
      smart_ordering_cards: [],
      tabAmount: Math.max(config.default_tab_cents, check.total * config.tab_multiplier),
      showStripe: false,
      availableCaptureMethods: this._getAvailableCaptureMethods()
    };

  }

  componentDidMount() {
    this._mounted = true;
    this.refreshCards();

    this._stripeChangeListener = StripeT.on('ConnectionStatusChange', () => {
      if (!this._mounted) return;
      this.setState({
        readerConnected: StripeT.readerConnected,
        availableCaptureMethods: this._getAvailableCaptureMethods()
      })
    });

    if(Platform.OS === 'android') {
      // Default Android method
      Linking.addEventListener('url', this._handleNFC)
      // Needed once we fire up react-native-nfc-manager
      NfcManager.setEventListener(NfcEvents.DiscoverTag, (tag) => {
        console.log('DiscoverTag', tag.ndefMessage[0]);
        console.log('Decoded: ', Ndef.uri.decodePayload(tag.ndefMessage[0].payload))
      })
      NfcManager.registerTagEvent();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    this._stripeChangeListener.remove();
    Linking.removeEventListener('url', this._handleNFC);

  }

  _getAvailableCaptureMethods = () => {
    const methods = [];
    if(API.config.allow_manual_card_entry) methods.push('manual');
    if(StripeT.readerConnected) methods.push('reader');

    return methods;
  }

  _handleNFC = async ({url}) => {
    const path = url.replace('bbot://', '');
    const parts = path.split('/');
    const action = parts[0];
    if (action.toUpperCase() === 'JOIN-TAB') {
      const code = parts[1];
      const tab = await API.getTabInfo(code);
      console.log('scanned tag: ', tab.tab_id);
      if(tab.message){
        Alert.alert('Tab Not Found', tab.message);
      } else {
        const tabModel = API._tabs[tab.tab_id];
        if (tabModel) {
          this.setState({
            selected_card: tabModel
          }, this._goToTipScreen)
        } else {
          Alert.alert('Tab Not Found', "Can't find tab, please try refreshing.")
        }
      }
    }
  }

  async refreshCards() {
    this.has_cards = false;

    // If on a given handheldPoll, we notice a tab we care about is updated, we should re-call getLocationDetails
    const result = await API.getCards(this.state.location);

    if(!this._mounted) return;

    if (result.error) {
      this.setState({
        loading: false
      });

      // todo do something on error
      console.log("Error: ", result.error);
      return;
    }

    this.setState({
      loading: false,
      group_cards: result.group_cards,
      // party_tabs: result.party_tabs,
      smart_ordering_cards: result.smart_ordering_cards
    });
  }

  render() {
    const {loading, showTabNameModal, location} = this.state;
    let possibleSeats = this.state.check.getUniqSeats();
    const config = API.getConfig();

    return (
      <Container testID={'cardChooserScreen'} onLayout={this._onContentLayout} style={{maxHeight: this.state.containerHeight}}>
        <Loader shown={loading}/>
        <Content padder>
          <View style={{maxWidth: 650, width: '100%', alignSelf: 'center' }}>
            {this._getGroupCards()}
            {config.allow_pay_with_consumer_tab && this._getConsumerTabs()}
            {this._getTabs()}
            {this._getSOCards()}
            {this._getNoCardsMsg()}
          </View>
        </Content>
        <Footer style={{backgroundColor: 'black'}}>
          <FooterTab style={{backgroundColor: 'black'}}>
             <IconButton
               label={"Add Card"}
               icon={'credit-card'}
               iconType={"FontAwesome5"}
               onPress={this.showStripePopup}
               disabled={!this.state.availableCaptureMethods.length}
             />
            <IconButton
              label={"Prompt Guest"}
              icon={<Fontello name={'user-card'} style={{color: 'white', fontSize: 24}}/>}
              disabled={!this.state.availableCaptureMethods.length}
              onPress={this.promptCustomer}
            />
          </FooterTab>
        </Footer>

        <StripeModal
          visible={this.state.showStripe}
          reusable={true}
          onSuccess={this._onStripeSuccess}
          onClose={() => { this.setState({showStripe: false})}}
          paymentIntentParams={{}} // only required when reusable=false
        />

        { showTabNameModal && (
          <NewTabModal
            location={location}
            onClose={()=>this.setState({showTabNameModal: false})}
            onSuccess={(name) => this.openTab(this.state.selected_card, name)}
          />
        )}

      </Container>
    )
  }

  /**
   * This is needed since react-native-web ScrollViews are broken and don't respect their parent
   * elements flex height. Hopefully fixed in a future release at which point this could be removed. Flex-basis: 0
   * sometimes helps but not here.
   * @private
   */
  _onContentLayout = () => {
    // added the setTimeout because in react-native-web, this function is called before the height is finished re-calculating
    setTimeout(() => {
      const height = Dimensions.get('window').height - 64;
      if (height !== this.state.containerHeight) {
        this.setState({
          containerHeight: height
        });
      }
    });
  }

  _getNoCardsMsg() {
    if (!this.state.loading && !this.has_cards)
      return (
        <View style={{alignItems: 'center', padding: 20}}>
          <Text style={{fontWeight: 'bold', fontSize: 16}}>No Payment Methods Available</Text>
        </View>
      )
  }

  _getGroupCards() {
    let {group_cards} = this.state;
    let filtered_cards = group_cards.filter(card => {
      return this.state.guest_cards.includes(card.id) && !this.state.consumer_tabs.find(t => t.default_card_id === card.id)
    });
    if (filtered_cards.length) this.has_cards = true;

    const {allow_manual_card_entry} = API.config;

    return (
      <View>
        {
          !!filtered_cards.length && <Text style={styles.title}>Credit Cards</Text>
        }
        {
          filtered_cards.map(
            card => (
              <CardView
                testID={'cardChooserCardView'}
                key={card.id}
                card={card}
                onPress={ this._selectCard }
              />
            )
          )
        }
      </View>);
  }

  _getSOCards() {
    let {smart_ordering_cards, consumer_tabs} = this.state;

    let cards_to_display = smart_ordering_cards.filter(card => !consumer_tabs.find(t => t.brand === card.brand && t.last4 === card.last4 && t.expiry === card.expiry ))

    if (cards_to_display.length) this.has_cards = true;

    return (
      <View>
        {
          !!cards_to_display.length && (
            <View>
              <Text style={styles.title}>Cards used on Website</Text>
              <Text style={styles.note}>
                YOU MUST CONFIRM THE LAST 4 DIGITS OF THE CARD WITH GUEST BEFORE USING ONE OF THESE OPTIONS.
              </Text>
            </View>
          )
        }
        {
          cards_to_display.map(
            card => (
              <CardView
                key={card.id}
                card={card}
                onPress={ this._selectCard }
              />
            )
          )
        }
      </View>
    );
  }

  _getTabs() {
    // filter tabs by seated_group_id
    let {party_tabs} = this.state;
    let filtered_tabs = party_tabs;
    //let filtered_tabs = party_tabs.filter(tab => this.state.guest_cards.includes(tab.id)); // && tab.available_cents > 0);
    if (filtered_tabs.length > 0) this.has_cards = true;
    else return null;

    return (
      <View>
        <Text style={styles.title}>Party Tabs</Text>
        {
          filtered_tabs.map(
            tab => (
              <TabView
                key={tab.id}
                tab={tab}
                onPress={ this._selectCard }
              />
            )
          )
        }
      </View>
    );
  }

  /**
   * TODO: Don't show for split check orders
   * @returns {JSX.Element|null}
   * @private
   */
  _getConsumerTabs() {
    // filter tabs by location_id
    let {consumer_tabs} = this.state;
    if(consumer_tabs.length) this.has_cards = true;
    else return null;

    return (
      <View>
        <Text style={styles.title}>Cloud Tabs</Text>
        {
          consumer_tabs.map(
            tab => (
              <TabView
                key={tab.id}
                tab={tab}
                onPress={this._selectCard}
              />
            )
          )
        }
      </View>
    )
  }

  /**
   * Sets the currently selected Card / Tab
   * @param card
   * @private
   */
  _selectCard = (card) => {
    this.setState({
      selected_card: card
    }, () => this._goToTipScreen());
  }

  /**
   * We'll prompt the customer on the next screen to present their card for an instant transaction
   */
  promptCustomer = () => {
    this.setState({
      selected_card: null
    }, () => {
      this._goToTipScreen(true);
    })
  }

  _onStripeSuccess = async (paymentMethod) => {

    this.setState({
      showStripe: false,
      loading: false
    });

    let {location} = this.state;
    let {seated_group} = location;
    let { guest_cards, check, group_cards} = this.state;
    let seats = check.getUniqSeats();

    let result = await API.saveReusableCard(seated_group, paymentMethod.id, seats);
    if(result.error){
      // todo: handle error
      showMessage({
        floating: true,
        position: 'bottom',
        type: 'danger',
        duration: 5000,
        message: result.error
      })
    } else {
      const {card} = result;

      let tabsAllowed = API.config.allow_pay_with_consumer_tab && this.state.location.allow_consumer_tabs;

      if (tabsAllowed && ['visa', 'mastercard'].includes(card.brand)) {
        /** FOR VISA AND MASTERCARD, WE WANT TO START A TAB **/
        let defaultVal = "Guest" + (seats.length > 1 ? "s" : "") + " " + seats.join(", ");
        this.setState({showTabNameModal: true, selected_card: card});
      } else {
        /** FOR EVERYTHING ELSE, WE JUST SAVE THE CARD */
        this.setState({
          selected_card: card,
          group_cards: group_cards.concat(card),
          guest_cards: guest_cards.concat(card.id)
        })
        this._goToTipScreen();
      }
    }
  }



  openTab = async (card, tabName) => {
    this.setState({
      showTabNameModal: false
    })

    let {location} = this.state;
    let {seated_group} = location;
    let {consumer_tabs, guest_cards, check} = this.state;
    let seats = check.getUniqSeats();

    let card_id = card.id;

    // todo: pass seat # if only 1 uniqSeat, otherwise prompt for Seat# with Tab Name.
    // todo: Pass param letting us know this is a keep-open tab
    let result = await API.openTab(seated_group.id, tabName, location.id, card_id);
    let {tab, joinURL} = result;
    tab._joinURL = joinURL;
    consumer_tabs.push(tab);
    seated_group.addCardToGuests(seats, tab.id);

    this.setState({
      consumer_tabs,
      guest_cards: guest_cards.concat(tab.id),
      selected_card: tab
    });

    this._goToTipScreen();
  }

  showStripePopup = () => {
    this.setState({
      showStripe: true
    })
  }

  _goToTipScreen = (force=false) => {
    let {state, navigate} = this.props.navigation;

    if (this.state.selected_card || !this.state.check.total || force) {
      navigate('CheckoutScreen', {
        check: this.state.check,
        card: this.state.selected_card,
        refreshFn: this.props.navigation.getParam('refreshFn'), // passed along from SplitOrderScreen
        go_back_key: state.key,
        location: this.state.location
      });
    }
  }

}

// todo: have CardView extend this?
const NewCardButton = (props) => {
  let {onPress, text, disabled} = props;
  return (
    <TouchableWithoutFeedback onPress={() => {
      if (disabled || !onPress) return;
      onPress();
    }}>
      <View style={[cardStyles.cardContainer, disabled ? styles.disabled : null, {marginTop: 20}]}>
        <Text style={{flex: 1, textAlign: 'center'}}>{text}</Text>
      </View>
    </TouchableWithoutFeedback>
  )
};
NewCardButton.defaultProps = {
  text: "NEW CARD"
};

const HButton = (props) => {
  let {onPress, text, disabled, disabledMsg, testID} = props;
  return (
    <TouchableWithoutFeedback
      testID={testID}
      onPress={() => {
        if (disabled && disabledMsg) {
          Alert.alert("Notice", disabledMsg);
        } else if(onPress){
          onPress()
        }
      }}>
      <View style={[styles.hButton, styles.centered, disabled ? styles.disabled : null]}>
        <Text style={[{textAlign: 'center'}, disabled ? {color: '#777'}:null]}>{text}</Text>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = EStyleSheet.create({
  centered: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  disabled: {
    backgroundColor: Colors.gray,
    borderColor: Colors.gray
  },
  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 10
  },
  note: {
    fontSize: '0.9rem',
    marginBottom: 10,
    color: Colors.darkGray
  },
  hButton: {
    flex: 1,
    backgroundColor: Colors.primaryLight,
    borderRadius: 5,
    padding: 10,
    margin: 10,
    borderWidth: 1,
    borderColor: Colors.primary,
    height: 50,
  },
  continueBtn: {
    flex: 1,
    padding: 15,
    borderRadius: 5,
    borderWidth: 1,
    marginHorizontal: 10,
    height: 50
  },
  continueBtnText: {
    color: Colors.dark
  }
});
