import {Button, Icon, Spinner, Text} from "native-base";
import React, {Component} from "react";
import {TouchableOpacity, StyleSheet, View, Image} from "react-native";
import {showMessage} from "react-native-flash-message";
import {FormattedCurrency} from "react-native-globalize";
import Alert from "../Alert";
import OrderList from "../Order/OrderList";
import {prompt} from "../Prompt";
import API from "../../api";
import {formatCardExpirationDate, formatDate, fromNow} from "../../helpers/HelperFunctions";
import {Colors, Typography} from "../../styles";
import {show as showQRCode} from "../Modals/QRCodeModal";
import NfcManager, {NfcTech, Ndef} from 'react-native-nfc-manager';
import NavigationService from "../../navigation/NavigationService";


class ConsumerTab extends Component {

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      isClosedTab: props.tab?.end_date.isSameOrBefore(),
      isFutureTab: props.tab?.start_date.isAfter(),
      loading: false,
      name: props.tab?.tab_name || `**** ${props.tab?.card_last_4}`,
      showDetails: false,
      showOrders: true,
      subtotal_cents: 0,
      tax_cents: 0,
      tip_cents: 0,
      total_cents: 0,
      isLoadingQR: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.tab?.tab_name && prevProps.tab.tab_name !== this.state.name) {
      this.setState({
        name: prevProps.tab.tab_name
      })
    }

    if (!this.state.isClosedTab && this.props.tab?.end_date.isSameOrBefore()) {
      this.setState({
        isClosedTab: true
      })
    }
  }

  render() {
    let {expanded, isClosedTab, loading, name, isLoadingQR} = this.state;
    let {tab, location} = this.props;
    let cardExpirationDate = formatCardExpirationDate(tab.card_exp_month, tab.card_exp_year);
    let isDefaultTabName = name === `**** ${tab.card_last_4}`;

    return (
      <View style={[styles.tabContainer, (isClosedTab && !expanded ? styles.closedTab : null)]}>
        {/* Overlay for loading state */}
        {
          loading && (
            <View style={[styles.overlay]}>
              <Spinner color='blue'/>
            </View>
          )}

        <TouchableOpacity onPress={() => this.setState({expanded: !expanded})}>
          <View style={[styles.headerContainer, {paddingHorizontal: 15, paddingTop: 20}]}>
            <View style={[styles.headerLeft]}>
              <View style={{alignItems: "flex-start", flex: 1, flexDirection: "row", flexWrap: 'wrap'}}>
                <Text style={[Typography.header2, {marginBottom: 10, marginRight: 10}]}>{name}</Text>
                {
                  expanded &&
                  <View style={{marginBottom: 10, marginRight: 10}}>
                    <Icon name={'edit'} onPress={this._handleRenameTab} type={"MaterialIcons"} style={{fontSize: 20}}/>
                  </View>
                }
                {
                  isClosedTab &&
                  <View style={[styles.closedBadge, {marginBottom: 10}]}>
                    <Text style={{color: "#000", fontWeight: "bold"}}>Closed</Text>
                  </View>
                }
              </View>
              {
                isDefaultTabName ? (
                  <View style={{flexDirection: "row", alignItems: "center"}}>
                    <Image style={styles.cardIcon} source={tab.icon}/>
                    <Text style={Typography.header3}>{cardExpirationDate}</Text>
                  </View>
                ) : (
                  <>
                    <View style={{flexDirection: "row", alignItems: "center"}}>
                      <Image style={styles.cardIcon} source={tab.icon}/>
                      <Text style={Typography.header3}>**** {tab.card_last_4}</Text>
                    </View>
                    <View>
                      <Text style={[Typography.header3, {marginLeft: 34}]}>{cardExpirationDate}</Text>
                    </View>
                  </>
                )
              }
            </View>
            <View style={styles.headerRight}>
              {
                !isClosedTab && !!tab.last_modified &&
                <View style={styles.headerRightContent}>
                  <Text style={[Typography.italic, {textAlign: "right"}]}>Updated </Text>
                  <Text style={[Typography.italic, {textAlign: "right"}]}>{fromNow(tab.last_modified)}</Text>
                </View>
              }
              {
                !!tab.start_date &&
                <View style={styles.headerRightContent}>
                  <Text style={[Typography.italic, {textAlign: "right"}]}>Started </Text>
                  <Text style={[Typography.italic, {textAlign: "right"}]}>{fromNow(tab.start_date)}</Text>
                </View>
              }
              {
                !!tab?.end_date &&
                <View style={styles.headerRightContent}>
                  <Text style={[Typography.italic, {textAlign: "right"}]}>{isClosedTab ? 'Closed ' : 'Ends '}</Text>
                  <Text style={[Typography.italic, {textAlign: "right"}]}>
                    {isClosedTab ? formatDate(tab.end_date) : fromNow(tab.end_date)}
                  </Text>
                </View>
              }
            </View>
          </View>

          {/* Tab Body */}
          <View style={[styles.tabBody]}>
            <View style={[styles.headerContainer, {marginBottom: 10}]}>
              <View style={styles.headerLeft}>
                <Icon type={'AntDesign'} name={expanded ? 'caretdown' : 'caretright'} style={{fontSize: 20}}/>
              </View>
              <View style={[styles.headerRight, {justifyContent: "center"}]}>
                <Text style={[Typography.sizeSmall, Typography.bold, {textAlign: "right"}]}>
                  Total: <FormattedCurrency value={tab.used_cents / 100}/>
                  {
                    tab.available_cents > 0 && (
                      <Text> / <FormattedCurrency value={tab.available_cents / 100}/> </Text>
                    )}
                </Text>
              </View>
            </View>
            {expanded && tab?.orders && <OrderList orders={tab.orders}/>}
          </View>
        </TouchableOpacity>


        <View style={styles.actionList}>
          {!isClosedTab ? (
            <>
              {/*  THIS IS COMMENTED OUT FOR NOW, IN DEV
              <Button
                iconRight={isLoadingQR}
                disabled={isLoadingQR}
                style={{marginRight: 10, paddingHorizontal: 5}}
                onPress={this._writeNFC}
              >
                <Text style={[Typography.bold, {color: Colors.white}]}>WRITE NFC</Text>
                { isLoadingQR ? <Spinner size={'small'} color={Colors.primary}/>: null}
              </Button>*/}

              <Button
                onPress={this._handleCloseTab}
                disabled={isClosedTab}
                style={styles.tabButton}
                transparent
              >
                <Text style={[Typography.bold]}>Close</Text>
              </Button>
              <Button
                disabled={isLoadingQR}
                style={styles.tabButton}
                onPress={this._getQR}
                success
              >
                <Text style={[Typography.bold, {color: 'white'}]}>SHARE</Text>
                {isLoadingQR ? <Spinner size={'small'} color={Colors.white} style={{marginRight: 10}}/> : <Icon type={"Entypo"} name={"share"} style={{color: 'white', marginLeft: 0}}/>}
              </Button>
              {API.config.allow_pay_with_consumer_tab && (
                <Button
                  style={styles.tabButton}
                  onPress={() => {
                    NavigationService.navigate("OrderCreator", {
                      location: this.props.location,
                      tab_id: tab.id
                    })
                  }}
                >
                  <Text style={[Typography.bold, {color: Colors.white}]}>NEW ORDER</Text>
                  {isLoadingQR ? <Spinner size={'small'} color={Colors.primary}/> : null}
                </Button>
              )}
            </>
          ) : (
            <Text style={{color: Colors.gray1}}>
              Tab Closed
            </Text>
          )}
        </View>

      </View>
    );
  }

  _calcTotals = (tab) => {
    let {orders} = tab;
    var subtotal_cents = 0,
      tax_cents = 0,
      total_cents = 0,
      tip_cents = 0;

    orders.forEach((o) => {
      subtotal_cents += o.subtotal_cents;
      tax_cents += o.tax_cents;
      tip_cents += o.tip_cents;
      total_cents += o.total_cents;
    })

    this.setState({
      subtotal_cents: subtotal_cents,
      tax_cents: tax_cents,
      tip_cents: tip_cents,
      total_cents: total_cents,
    })
  }

  _handleCloseTab = async () => {
    let total = Math.round((this.props.tab?.used_cents + Number.EPSILON) * 100) / 10000;

    Alert.alert(
      `Are you sure you want to close the tab ${this.props.tab?.name} and with amount $${total.toFixed(2)}?`,
      null,
      [
        {
          text: 'Cancel',
          style: 'cancel'
        },
        {
          text: 'Continue',
          onPress: async () => {
            this.setState({
              loading: true
            });

            let response = await API.closeTabs([this.props.tab.id], this.props.location.id);

            if (response) {
              this.setState({
                loading: false,
                expanded: false,
                isClosedTab: true,
              });
            } else {
              // Alert: Unknown error occured, couldn't close tab
              this.setState({
                loading: false
              })
            }
          }
        }
      ]
    )
  }

  _handleRenameTab = () => {
    prompt({
      title: 'Rename Tab:',
      onSubmit: async value => {
        this.setState({
          loading: true
        });

        let response = await API.renameTab(this.props.tab, value);

        if (response.success) {
          this.setState({
            loading: false,
            name: response.tab_name,
          });
        } else {
          showMessage({
            message: response.error?.error,
            type: "danger",
            position: "top"
          });
          this.setState({
            loading: false
          });
        }
      },
      defaultValue: this.state.name,
      placeholder: this.state.name
    })
  }

  /**
   *
   * @returns {Promise<void>}
   * @private
   */
  _getQR = async () => {
    let {tab, location} = this.props;
    this.setState({isLoadingQR: true});
    let result = await API.getTabURL(tab.id, location.id);
    if(result?.url) {
      showQRCode('JOIN ' + tab.tab_name || 'TAB', result.url, {});
      this.setState({isLoadingQR: false});
    } else {
      Alert.alert("Error fetching QR");
    }
  }

  /**
   *
   * @returns {Promise<void>}
   * @private
   */
  _writeNFC = async () => {
    await NfcManager.start();
    this.setState({loading: true})
    let {tab, location} = this.props;
    let result = await API.getTabURL(tab.id, location.id, true);
    try {

      await NfcManager.requestTechnology(NfcTech.Ndef, {
        alertMessage: "Touch Tag to Device..."
      });
      console.log('touch');
      const bytes = Ndef.encodeMessage([Ndef.absoluteUriRecord('bbot://', result.url.replace('bbot://', ''))])
      if (bytes) {
        console.log('trying to write...');
        await NfcManager.ndefHandler.writeNdefMessage(bytes);
        this.setState({loading: false})
        Alert.alert('Success')
      }
      NfcManager.cancelTechnologyRequest();
    } catch (ex) {
      console.warn(ex);
      NfcManager.cancelTechnologyRequest();
    }

  }
}

export default ConsumerTab;

const styles = StyleSheet.create({
  tabContainer: {
    borderWidth: 1,
    backgroundColor: "#FFF",
    borderColor: Colors.gray1,
    margin: 10,
    shadowOffset: {width: 0, height: 3},
    shadowColor: 'black',
    shadowOpacity: 0.5,
    shadowRadius: 4,
  },
  overlay: {
    position: "absolute",
    backgroundColor: Colors.gray1,
    opacity: 0.7,
    width: "100%",
    height: "100%",
    zIndex: 100,
    alignItems: "center",
    justifyContent: "center",
  },
  closedTab: {
    opacity: 0.5,
  },
  closedBadge: {
    borderWidth: 2,
    borderColor: "#000",
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 10,
    paddingVertical: 2,
  },
  headerContainer: {
    flexDirection: "row",
  },
  headerLeft: {
    flex: 3,
  },
  headerRight: {
    flex: 2,
    alignItems: "flex-end",
  },
  headerRightContent: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
  tabBody: {
    paddingVertical: 5,
    paddingHorizontal: 15
  },
  tabDetails: {
    paddingHorizontal: 20,
    marginBottom: 10,
  },
  tabButton: {
    marginHorizontal: 5
  },
  cardIcon: {
    width: 24,
    height: 24,
    aspectRatio: 1,
    marginRight: 10
  },
  actionList: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 15
  }
});
