import React from 'react';
import {
  Dimensions,
  Platform,
  StatusBar,
  StyleSheet,
  View,
  BackHandler,
  ActivityIndicator,
  LogBox,
  Linking
} from 'react-native';
import Alert from "./components/Alert";
import { RUNNING_TESTS } from "./constants/Config";
import AppNavigator from './navigation/AppNavigator';
import { GlobalizeProvider, loadCldr, localeIsLoaded } from "react-native-globalize";
import EStyleSheet from 'react-native-extended-stylesheet';
import NavigationService from './navigation/NavigationService';
import FlashMessage, { showMessage } from "react-native-flash-message";
import API from "./api";
import PinPadModal from "./components/PinPad/PinPadModal";
import * as SplashScreen from "expo-splash-screen";
import { StyleProvider } from "native-base";
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import getTheme from './native-base-theme/components';
import material from "./native-base-theme/variables/material";
import NotificationBanner from "./components/NotificationBanner";
import Prompt from "./components/Prompt";
import ActionSheet from "./components/ActionSheet";
import * as Analytics from "expo-firebase-analytics";
import {
  AntDesign,
  Entypo,
  Feather,
  FontAwesome,
  FontAwesome5,
  Foundation,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  Octicons,
} from "@expo/vector-icons";
import Colors from "./constants/Colors";
import KeepAwake from "./services/KeepAwake";
import Sentry from "./services/Sentry";
import ErrorScreen from "./screens/ErrorScreen";
import QRCodeModal from "./components/Modals/QRCodeModal";
import NotificationModal from "./components/TerminalNotifications/NotificationModal";
import StripeProvider from './components/StripeElements/StripeProvider';


// Needed to run tests successfully because yellow warning prompts block tests from detecting UI elements. Usually unset, only set to true when devConstants.runningTests is set to true.
if(LogBox) {
  LogBox.ignoreAllLogs(RUNNING_TESTS);
  LogBox.ignoreLogs(["Warning: React does not recognize the `dataComponent`"])
}
else console.disableYellowBox = RUNNING_TESTS;

//SafeAreaView.setStatusBarHeight(0);

// We must preload all locales we plan on using:
loadCldr( require('react-native-globalize/locale-data/en') )
loadCldr( require('react-native-globalize/locale-data/en-GB') )
loadCldr( require('react-native-globalize/locale-data/en-CA') )
loadCldr( require('react-native-globalize/locale-data/fr') )
loadCldr( require('react-native-globalize/locale-data/zh') )


export default class App extends React.Component {
  state = {
    isLoadingComplete: false,
    updating: false,
    progress: 0,
    isSplashReady: false,
    isAppReady: false,
    customer: {}
  };

  constructor(props) {
    super(props);

    EStyleSheet.build({
      $outline: 0   // used for debugging layout issues
    });

    if (props.exp && props.exp.errorRecovery) {
      let message = props.exp.errorRecovery.message;
      Alert.alert(
        'Unexpected Error',
        `Bbot CC has encountered an unexpected error. We've notified our developers and will do our best to fix the error promptly.
      
Details:
========
${message}
`,
        [{text: 'OK'}]
      );
    }
  }


  componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', this.onBackPress);

    API.initSentry();

    this._loadResourcesAsync();
    this._listeners = [
      API.on('customer_change', this._setCustomer),
    ]

    if(Platform.OS === 'android') {
      this._batteryStateListener = KeepAwake();
    }
  }

  /**
   * App is exiting
   */
  componentWillUnmount() {
    BackHandler.removeEventListener('hardwareBackPress', this.onBackPress);
    API.off('customer_change', this._setCustomer);
    if(this._batteryStateListener) this._batteryStateListener.remove();
  }

  onBackPress = () => {
    return true;
  }

  onLayout = (e) => {
    const {width, height} = Dimensions.get('window');
    let orientation = width > height ? 'landscape' : 'portrait';
    API.setOrientation(orientation);
  }

  _reloadApp = () => {
    if(Platform.OS === 'web')
      window.location.reload();
  }

  render() {
    if (!this.state.isLoadingComplete && !this.props.skipLoadingScreen) {
      return (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
          <ActivityIndicator size={'large'} color={Colors.primary}/>
        </View>
      );
    } else {
      const {dispatch, state} = this.props;
      const currency = this.state.customer.currency ? this.state.customer.currency.toUpperCase() : 'USD';
      let locale = this.state.customer.locale ? this.state.customer.locale.replace('_','-') : 'en';
      if(locale === 'en-US') locale = 'en';

      return (
        <Sentry.ErrorBoundary fallback={<ErrorScreen/>} showDialog={Platform.OS === 'web'}>
          <StyleProvider style={getTheme(material)} fsClass={'fs-unmask'}>
            <GlobalizeProvider locale={locale} currency={currency}>
              { Platform.OS === 'android' && <StatusBar barStyle="light-content" hidden={true}/> }
              <View testID="welcome" style={[styles.container, StyleSheet.absoluteFill]} onLayout={this.onLayout} onTouchStart={API.touched}>
                <NotificationBanner isGlobal={true}/>
                <StripeProvider>
                  <AppNavigator
                    ref={navigatorRef => {
                      NavigationService.setTopLevelNavigator(navigatorRef)
                    }}
                    dispatch={dispatch}
                    state={state}
                    onNavigationStateChange={this._onNavigationStateChange}
                  />
                </StripeProvider>
                <ActionSheet />
                <FlashMessage position="bottom" style={{zIndex: 10000}}/>
                <PinPadModal
                  onClose={() => {}}
                  onSuccess={() => {}}
                  isGlobal={true}
                />
                <QRCodeModal/>
                {/* ==== For showing KDS Messages ==== */}
                <NotificationModal/>
              </View>
              <Prompt visible={false}/>
            </GlobalizeProvider>
          </StyleProvider>
        </Sentry.ErrorBoundary>
      );
    }
  }

  _setCustomer = (customer) => {
    let locale = customer.locale ? customer.locale.replace('_','-') : 'en';
    if(locale === 'en-US') locale = 'en';

    this.setState({
      customer: customer
    })
  }

  _loadResourcesAsync = async () => {

    try {
      await Promise.all([
        Asset.loadAsync([
          require('./assets/images/bbot_logo.png'),
          require('./assets/images/cart.png'),
          require('./assets/images/readers/chipper2x.png'),
          require('./assets/images/contactless-payment-symbol.png'),
          require('./assets/images/ChipReader.png'),
          require('./assets/images/list-loading.gif'),
          require('./assets/images/num_guests.png'),
          require('./assets/images/smartphone.png'),
          require('./assets/images/thank-you.png'),
          require('./assets/fonts/SpaceMono-Regular.ttf'),
          require('./assets/sounds/alarm_old.mp3'),
          require('./assets/sounds/notification_old.mp3'),
          require('./assets/sounds/new_message.mp3')
        ]),
        Font.loadAsync({
          'fontello': require('./assets/fonts/fontello/font/fontello.ttf'),
          'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
          'Roboto': require('native-base/Fonts/Roboto.ttf'),
          'Roboto_medium': require('native-base/Fonts/Roboto_medium.ttf'),
          ...MaterialCommunityIcons.font,
          ...MaterialIcons.font,
          ...Feather.font,
          ...Entypo.font,
          ...AntDesign.font,
          ...Ionicons.font,
          ...FontAwesome.font,
          ...FontAwesome5.font,
          ...Foundation.font,
          ...Octicons.font
        }),
      ]);
    } catch(err){
      await API.sendCaughtError(err, null, "Error loading assets");
    }

    await this._handleFinishLoading();
  };

  _handleLoadingError = error => {
    // In this case, you might want to report the error to your error
    // reporting service, for example Sentry
    console.warn(error);
  };

  _handleFinishLoading = async () => {

    this.setState({isLoadingComplete: true});

    API.on('connected', (connected, msg) => {
      if (!connected) {
        showMessage({
          message: msg,
          autoHide: false,
          backgroundColor: 'red'
        })
      } else if (connected) {
        showMessage({
          message: "You are back online",
          duration: 3000,
          backgroundColor: '#16ff01'
        })
      }
    });

    if(Platform.OS !== 'web')
      await SplashScreen.hideAsync();
  };

  _onNavigationStateChange = (prevState, currentState, action) => {

    const currentRouteName = getRouteName(currentState);
    const prevRouteName = getRouteName(prevState);

    // check because hot-reloading during dev breaks shit
    if(API) API.currentScreen = currentRouteName;

    if (currentRouteName !== prevRouteName) {
      API.trigger('screen', currentRouteName);
      // the line below uses the @react-native-firebase/analytics tracker
      // change the tracker here to use other Mobile analytics SDK.
      try {
        Analytics.setCurrentScreen(currentRouteName, currentRouteName);
      } catch(err){
      }
    }
  }

}

function getRouteName(navigationState) {
  if (!navigationState) {
    return null;
  }
  const route = navigationState.routes[navigationState.index];
  // dive into nested navigators
  if (route.routes) {
    return getRouteName(route);
  }
  return route.routeName;
}


const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    height: '100%',
    backgroundColor: '#ffffff'
  }
});
