import _ from 'lodash';
import uuid from 'uuid';
import {CancelToken} from 'axios';

import EventModel from "./EventModel";
import CartItem from './CartItem';
import Fee from './Fee';
import ItemPart from "./ItemPart";
import CheckModel from "./CheckModel";

const source = CancelToken.source();

/**
 * The CartPayload is the result of calling a Cart's .getPayLoad() method. This is the expected form of the Cart Payload
 * @typedef {Object} CartPayload
 * @property {Map<string, Object>} items - Maps item ids to the full JSON repr of the CartItem (result of `toJSON`)
 * @property {CartCheck[]} checks - JSON repr of each check in this cart
 * @property {string} checkout_id
 * @property {?string} customer_id
 * @property {{key: string, value: *}[]} extra_checkout_info - List of checkout info field keys and field value pairs
 * @property delivery_info
 * @property extra_delivery_info
 * @property {FeeData[]} fees
 * @property {string} fulfillment_method
 * @property {string[]} lineItemIdsToRemove
 * @property {string} location_id
 * @property {PriceCheckData[]} pricechecks
 * @property {string[]} promo_codes
 * @property prompts_to_guests
 * @property {number} subtotal_amount - (In cents)
 * @property {number} tax_amount - (In cents) The integer amount of tax applied in total to this cart
 * @property {number} tip_amount - (In cents) The integer amount of tip applied in total to this cart
 */

/**
 * Information on how much money to charge to a specific Card
 * @typedef {Object} DesiredCharge
 * @property {number} amount_cents
 * @property {string} cardId
 * @property {string} type
 */

/**
 * Information on the prices and price-changes associated with a single item for server-side price checking.
 * @typedef {Object} PriceCheckData
 * @property {Array<unknown>} discounts - A list of the discounts applied on this item TODO - Array of what?
 * @property {number} lineitem_pretax_cents - (In cents) The pretax (unsure if prediscount) cost of this item
 * @property {number} lineitem_tax_cents - (In cents) The cost of tax on this item
 */

/**
 * A Cart is a collection of CheckModels. It manages splitting checks and different interactions between CheckModels.
 */
export default class Cart extends EventModel {

  _priceCheckInProgress = false;
  _needPriceCheck = true;
  customer_id = null;
  lineItemIdsToRemove = null;
  prompts_to_guest = null;
  available_time_blocks = [];

  constructor(obj){
    super();
    /** @type {string} UUID of this checkout*/
    this.checkout_id = uuid.v4();
    this.customer_id = obj.customer_id;
    /** @type {string} Location UUID */
    this.location_id = obj.locationId;
    /** @type {'patron_choice' | 'server_delivery' | string} TODO - Enumerate the possible values for `fulfillment_method` */
    this.fulfillment_method = obj.fulfillment_method || 'server_delivery';
    /** @type {DesiredCharge[]} */
    this.desired_charges = obj.desired_charges || [];
    /** @type {number} (In cents) the sum of pretax_cents for all items in all checks */
    this.subtotal_amount = obj.subtotal_amount || 0;
    /** @type {number} (In cents) the total cost of taxes from all of the checks */
    this.tax_amount = obj.tax_amount || 0;
    /** @type {number} (In cents) the total cost of tips from all of the checks */
    this.tip_amount = obj.tip_amount || 0;
    /** @type {{key: string, value: *}[]} List of extra checkout info field keys and field value pairs */
    this.extra_checkout_info = [];
    /** @type {Object} TODO add more detail on the expected form of delivery_info */
    this.delivery_info = obj.delivery_info || {};
    /** @type {*[]} TODO add more detail on the expected form */
    this.extra_delivery_info = obj.extra_delivery_info || [];
    /** @type {CartItem[]} */
    this.items = obj.items || [];
    /** @type {CheckModel[]} The list of split checks within this since Cart */
    this.checks = obj.checks || [
      new CheckModel({
        label: 'Check 1',
        seat: 1
      }, this)
    ];

    this.user_id = this.api.currUser?.user.id

    //this.promo_codes = obj.promo_codes || [];
    /** @type {FeeData[]} */
    this.fees = obj.fees || [];
    /** @type {string[]} A list of all the promo codes that have been applied to this cart */
    this.valid_promo_codes = [];
    /** @type {Dictionary<string, PriceCheckData>} Maps item uuids to its price data, for server-side price checking */
    this.pricechecks = {};

    if(this.fulfillment_method === 'patron_choice'){
      this.fulfillment_method = 'patron_pickup'; // start with this option
    }

    this.items.forEach((item, i) => {
      if(!(item instanceof CartItem))
        this.items[i] = new CartItem(this, item);
    });

    // todo: this should use this.update(obj);
  }

  _field_map = {
    items: items => items.map(item => new CartItem(this, item)),
    fees: fees => this._updateFees(fees),
    pricechecks: pricechecks => {
      _.forIn(pricechecks, (value, id) => {
        /** @type {CartItem} **/
        let item = this.items.find(i => i.id === id);
        item.update(value);
      });
      return pricechecks;
    }
  }

  _do_not_serialize = ['valid_promo_codes'];
  _force_serialize = ['subtotal_amount', 'promo_codes'];

  /**
   * @returns {Location} The location model for the location this cart is for
   */
  get location(){
    return this.api._locations[this.location_id];
  }

  // Backwards compatibility
  get locationId(){
    return this.location_id;
  }

  /**
   * @returns {string[]} The list of currently applied promo codes in the cart.
   */
  get promo_codes() {
    return this.valid_promo_codes;
  }

  // pretax, post discount:
  get subtotal_amount() {
    return _.sumBy(Object.values(this.items), 'frontend_post_discount_cents') + _.sumBy(this.fees, 'pretax_cents');
  }
  set subtotal_amount(val){}

  updateTotals(){
    this.tax_amount = this.items.reduce((total, item) => total += item.qty * item.displayed_tax_cents, 0);
    this.tip_amount = this.checks.reduce((total, check) => total += check.tip_cents, 0);
  }

  getPretaxTotal(){
    this.updateTotals();
    return this.subtotal_amount;
  }

  getTotal(){
    this.updateTotals();
    return this.subtotal_amount + this.tax_amount;
  }

  async addItem(item){
    if(!(item  instanceof  CartItem)){
      item = new CartItem(this, item);
    } else {
      item.cart = this;
    }
    this.items.push(item);

    this._needPriceCheck = true;
    this.updateTotals();

    return item;
  }

  /**
   * Modifies this Cart's state after checking the price. Uses the api to get the cart price, and updates this Cart's
   * attributes to reflect the new Cart Price with the provided promo code. Does not save the given promo_code, only the
   * prices after it was applied.
   *
   * @param {string} [promo_code] The promo code to apply to the cart when checking the price
   * @returns {Promise<boolean>} Resolves to true upon completion
   */
  getCartPrice = async (promo_code) => {

    if(this.location.require_get_cart_price && this._needPriceCheck){

      if(this._priceCheckInProgress) source.cancel();

      let result = await this.api.getCartPrice(this, promo_code, source.token);
      if(result.error){
        // Try Again! // Todo try again after half second
      } else {
        this.update(result);
      }

      this._needPriceCheck = false;
      this._priceCheckInProgress = false;
    }
    return true;
  }

  /**
   * Removes the given item (or the item with the given UUID) from this cart. Updates the totals of this cart.
   *
   * @param {string | CartItem} item - Either the item's UUID, or the actual item itself
   */
  removeItem(item){
    let itemToRemove = item;
    if(typeof item === "string"){
      itemToRemove = this.items.find(i => i.id === item);
    }
    this.items = _.without(this.items, itemToRemove);
    this.checks.forEach(check => check.removeItem(itemToRemove?.id));
    this._needPriceCheck = true;

    this.updateTotals();
  }

  addCharge(chargeObject){
    this.desired_charges.push(chargeObject);
  }

  /**
   * Converts this Cart model into a JSON repr with attributes the server requires when submitting an order.
   *
   * @returns {CartPayload}
   */
  getPayload(){
    this.updateTotals();

    let payload = this.toJSON();
    /** @type {Dictionary<Object>} Maps item id to item JSON repr */
    payload.items = _.keyBy(this.items.map(i => i.toJSON()), 'id');
    /** @type {CartCheck[]} A list of this cart's checks, summarized as a JSON. See CartCheck for the form*/
    payload.checks = this.checks.map(check => check.getCartCheck());
    return payload;
  }

  /**
   * Applies the promo code, checks the cart's price, and updates the charge for all of the checks within this cart
   *
   * @param {string} code - The promotional code string
   */
  addPromoCode = async (code) => {
    // Update's this cart's price
    this._needPriceCheck = true;
    this.trigger('updating', true);
    await this.getCartPrice(code);

    this.checks.forEach(check => {
      if(check.charge) {
        // If price goes up, invalidate
        // If price goes down, show by how much?
        //console.log("Existing Charge needs adjusting: ", check.charge);
        check.charge.orig_cents = check.charge.amount_cents;
        check.charge.amount_cents = check.tip_total;
      }
    });
    this.trigger('updating', false);
  }

  /**
   * Removes the given promo code from the cart and updates the Cart Price
   * @param {string} code - The promo code to remove from this cart
   */
  removePromoCode = async (code) => {
    this.valid_promo_codes = _.without(this.valid_promo_codes, code);
    this._needPriceCheck = true;

    await this.getCartPrice();
    // TODO remove promo code from items and itemParts
  }

  submit(){
    // Verify desired_charges total >= cart total
    // send to server
  }

  _updateFees = (fees) => {
    fees = fees.map(fee => new Fee(this, fee));

    this.updateFees(fees);
    return fees;
  }

  updateFees = () => {
    this.checks.forEach(check => check.fees = []);

    this.fees.forEach(fee => {
      let feeItems = fee.line_item_ids;
      let checksToActOn = [];
      this.checks.forEach(check => {
        let checkFeeItems = check.items.filter(i=>feeItems.includes(i.itemId));
        if(checkFeeItems.length){
          checksToActOn.push(check);
        }
      });
      let taxes = ItemPart.distributeByWeights(fee.tax_cents, checksToActOn.length);
      let pretax = ItemPart.distributeByWeights(fee.pretax_cents, checksToActOn.length);

      checksToActOn.forEach((check, i) => {
        check.fees.push({
          id: fee.id,
          tax_cents: taxes[i],
          pretax_cents: pretax[i],
          total: taxes[i] + pretax[i]
        })
      });
    });
  }

  removeEmptyChecks = () => {
    this.checks = this.checks.filter(check=>check.items.length);
  }

}
