const tintColor = '#2f95dc';

// BBOT Palette:
const primary = '#05b3e9';
const primaryLight = '#A5E3F6';
const secondary = '#20409a';
const ternary = '#9665aa';
const quaternary = '#48A9A6';
const special = '#11711a';


const lightGray = '#e5e7e9';
const gray = '#bcbec0';
const darkGray = '#7f8183';
const light = '#FFF';
//const light = '#000';

const success = '#5CB85C';

const dark = '#000';
//const dark = '#FFF';
const accent = '#ed1651';
const error = '#ff0000';
const warning = '#F0AD4E';
const danger = '#D9534F'


export default {
  tintColor,
  primary,
  primaryLight,
  secondary,
  ternary,
  quaternary,
  gray,
  light,
  dark,
  accent,
  darkGray,
  lightGray,
  error,
  warning,
  success,
  special,
  pickupNoFeedback: '#e3652a',
  statusIcon: '#1D388F',
  tabIconDefault: light,
  tabIconSelected: primary,
  tabBar: '#fefefe',
  errorBackground: error,
  errorText: light,
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  background: '#fff',
  highlight: '#17b5e9',
  buttonSelected: '#c7c7c7',
  isPolling: success,
  linkColor: primary,
  disabled: darkGray,
  robotButton: '#ff6a0e',
  unreadNotification: primary
};
