import React, {useEffect, useState} from 'react';
import API from "../../api";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

/**
 * Loads the Stripe Provider for Stripe ReactJS
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const StripeProvider = ({children}) => {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    if (API.stripeKey) {
      setStripe(loadStripe(API.stripeKey));
    } else {
      const listener = API.on('stripePublishableKey', (key) => {
        setStripe(loadStripe(key));
      })
      return () => {
        listener.remove();
      }
    }
  }, [])

  return <Elements stripe={stripe}>{children}</Elements>
}

export default StripeProvider;
