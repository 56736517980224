import React from 'react';
import TextField from './TextField';
import {validateEmail} from "../../../helpers/HelperFunctions";

const EmailField = (props) => {
  const {required} = props;

  const validator = (email) => {
    if(!required && !email) return true;
    return validateEmail(email);
  }

  return (
    <TextField
      {...props}
       validator={validator}
       keyboardType={'email-address'}
       placeholder={'example@domain.com'}
    />
  )
}

export default EmailField;
