import {Platform} from "react-native";
import ReactNativeForegroundService from "@supersami/rn-foreground-service";

export default class PollerService {

  static API;

  loopDelay = 1000;

  constructor(task) {

    if (Platform.OS === 'android') {
      ReactNativeForegroundService.register();

      if(!ReactNativeForegroundService.is_task_running('poller')) {
        ReactNativeForegroundService.remove_task('poller');
      }

      ReactNativeForegroundService.add_task(task, {
        delay: this.loopDelay,
        onLoop: true,
        taskId: 'poller',
        onError: this._handleTaskError
      });

      ReactNativeForegroundService.start({
        id: 1000,
        title: "Terminal Service",
        message: "Terminal is polling",
        vibration: false,
        visibility: 'public',
        importance: 'max',
        button: false
      });

    } else if(Platform.OS === 'web') {
      if(window._pollInterval) {
        clearInterval(window._pollInterval);
      }
      window._pollInterval = setInterval(task, this.loopDelay);
    }
  }

  _handleTaskError = (e) => {
    PollerService.API.sendCaughtError(e);
  }

  stop = () => {
    if(Platform.OS === 'android') ReactNativeForegroundService.stop();
  }

}
