import React from "react";
import _ from 'lodash';
import {Text, TouchableOpacity, View, Dimensions} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import Colors from "../constants/Colors";
import API from "../api";
import StatusIcon from "./StatusIcon";
import PropTypes from 'prop-types';
import {Icon} from 'native-base';

export default class StationButton extends React.Component {

  static propTypes = {
    columns: PropTypes.number
  }
  static defaultProps = {
    columns: 4
  }

  state = {
    filteredOrders: []
  }

  constructor(props){
    super(props);
    let {station, showClosed, showSnoozed} = this.props;
    this.state = {
      filteredOrders: station.getFilteredOrders(showClosed, showSnoozed),
      filteredStatuses: station.getFilteredStatuses(showClosed, showSnoozed)
    };
  }

  componentDidMount() {
    this._mounted = true;
    this.props.station.on('orders', this._update);
  }
  componentWillUnmount() {
    this._mounted = false;
    this.props.station.off('orders', this._update);
  }

  _update = () => {
    let {station, showClosed, showSnoozed} = this.props;
    if(!this._mounted) return;
    this.setState({
      filteredOrders: station.getFilteredOrders(showClosed, showSnoozed),
      filteredStatuses: station.getFilteredStatuses(showClosed, showSnoozed)
    })
    this.forceUpdate();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.showSnoozed !== prevProps.showSnoozed || this.props.showClosed != prevProps.showClosed)
      this._update();
  }


  render() {
    const {filteredOrders, filteredStatuses} = this.state;
    let {station, columns} = this.props;

    let windowWidth = Dimensions.get('window').width;

    let containerStyle = {
      width: windowWidth / columns,
      height: windowWidth / columns
    };
    let buttonStyle = {};

    if(station.statuses['wait_for_runner']){
      buttonStyle.backgroundColor = Colors.primaryLight;
    }
    let claimed = 0;
    let total = 0;
    filteredOrders.forEach(order => {
      order.items.forEach(item => {
        total++;
        if(item.claimed_by){
          claimed++;
        }
      })
    });

    return (
      <TouchableOpacity
        style={[styles.container, containerStyle]}
        title={"button " + station.id}
        onPress={ this._onPress }
        //onLongPress={()=>{onLongPress(station)}}
      >
        <View style={[styles.button, buttonStyle]}>
          <View style={styles.topRow}>
            <Text style={styles.locationName}
                  adjustsFontSizeToFit={true}
                  minimumFontScale={0.5}
                  numberOfLines={1}
                  ellipsizeMode={'middle'}>{station.station_name}</Text>
          </View>
          <View style={styles.items}>
            {
              _.map(filteredStatuses, (count, status) => <ItemStatus key={status} status={status} count={count}/>)
            }
            {
              claimed > 0 && <ItemStatus status={'Claimed'} count={claimed+'/'+total}/>
            }
            {
              !filteredOrders.length && <Text style={{color: Colors.gray}}>No Orders</Text>
            }
          </View>
          <View style={styles.bottomRow}>
            <View style={{flex:1}}>
              <StationIcon
                icon={<Icon type={"MaterialCommunityIcons"}
                  name={station.is_polling ? 'lan-connect' : 'lan-disconnect'}
                  style={[styles.whiteText, {fontSize: 18, color: station.is_polling ? Colors.primary:'white'}]}
                />}
              />
            </View>
            <StationIcon
              icon={<Icon type={"MaterialCommunityIcons"}
                name={ station.accepting_orders ? 'play-circle' : 'stop-circle'}
                style={[styles.whiteText, {fontSize: 18, color: station.accepting_orders ? Colors.isPolling:'white'}]}/>}
            />
          </View>
        </View>
      </TouchableOpacity>
    )
  }

  _onPress = () => {
    this.props.onPress(this.props.station);
  }

}

const ItemStatus = ({status,count}) => {
  let name = API.status_pretty_names[status] || status;

  return (
    <View key={status} style={styles.status}>
      <StatusIcon size={'small'} status={status} style={styles.statusIcon}/>
      <Text style={styles.statusCount}>{count}</Text>
      <Text adjustsFontSizeToFit numberOfLines={1} style={styles.statusText}>{name}</Text>
    </View>
  )
}

const StationIcon = ({text, icon}) => {
  return (
    <View style={{flexDirection: 'row', alignItems: 'center', marginRight: 5, marginLeft: 5}}>
      {icon}
      <Text>{text}</Text>
    </View>
  )
}

const styles = EStyleSheet.create({
  container: {
  },
  button: {
    borderRadius: 10,
    overflow: 'hidden',
    backgroundColor: '#f5f5f5',
    elevation: 5,
    marginLeft: 5,
    marginTop: 10,
    flex: 1
    /// height: Dimensions.get('window').width / (CONFIG.NUM_COLUMNS+1), // approximate a square
  },
  needsAttention: {
    borderColor: '#b9f3ff',
    backgroundColor: '#e5fff8',
    borderWidth: 2
  },
  topRow: {
    flexDirection: 'row',
    height: '20%',
    padding: 3,
    backgroundColor: '#4c4c4c',
  },
  locationName: {
    marginLeft: '5%',
    color: '#FFF',
    flex: 1
  },
  bottomRow: {
    height: '20%',
    padding: 3,
    flexDirection: 'row',
    backgroundColor: '#4c4c4c'
  },
  items: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  status: {
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  statusIcon: {
    width: '10%',
    height: '90%',
    marginLeft: '5%',
    marginRight: '5%',
  },
  statusCount: {
    marginRight: '5%'
  },
  statusText: {
    flex: 1
  },
  itemInvisible: {
    backgroundColor: 'transparent',
    flex: 1,
    margin: 5,
    aspectRatio: 1
  },
  white: {
    tintColor: '#fff',
    opacity: 1
  },
  whiteText: {
    color: '#fff'
  }
});
