import React from "react";
import {Text, TouchableWithoutFeedback, View, StyleSheet} from "react-native";
import KDSItemModifiers from "./KDSItemModifiers";
import {Colors} from "../../../styles";

export default class KDSOrderItem extends React.PureComponent {

  state = {
    made: false
  }

  constructor(props) {
    super(props);
    this.state = {
      made: props.items[0].made
    }
  }

  componentDidMount() { this._mounted = true}
  componentWillUnmount() {this._mounted = false}

  render() {
    let {items} = this.props;
    let item = items[0];
    let quantity = items.length;
    const refunded = item.status === 'refunded';
    const textDecoration = refunded ? 'line-through':'none';
    const textColor = (this.state.made || refunded) ? '#b5b5b5':'#000';

    const textStyle = {color: textColor, textDecorationLine: textDecoration };

    return (
      <TouchableWithoutFeedback onPress={this._onPress}>
        <View style={styles.row} onLayout={this._onLayout}>
          <Text style={[styles.quantity, textStyle]}>{quantity}</Text>
          <View style={{flex: 1}}>
            <Text style={[styles.itemName, textStyle]} ellipsizeMode={'tail'}>{item.itemName}</Text>
            {item.mods ? <KDSItemModifiers mods={item.mods} color={textColor}/> : null}
            {!!item.special_instructions && <Text style={{marginLeft: 8, color: Colors.secondary, fontSize: 13}}>{item.special_instructions}</Text>}
          </View>
        </View>
      </TouchableWithoutFeedback>
    )
  }

  _onLayout = (event) => {
    if(this._mounted) this.yOffset = event.nativeEvent.layout.y;
  }

  _onPress = () => {
    let {items, onPress} = this.props;
    let item = items[0];
    if(item.order.is_snoozed) return;
    item.made = !item.made;
    this.setState({
      made: item.made
    });
    onPress(item);
  }

}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    marginHorizontal: 10, marginVertical: 5
  },
  quantity: {
    width: 25
  },
  itemName: {
    fontWeight: 'bold', fontSize: 16
  },

})
