import React from 'react';
import {View, Text, Dimensions, TouchableWithoutFeedback} from 'react-native';
import Alert from './Alert';
import EStyleSheet from 'react-native-extended-stylesheet';
import Colors from '../constants/Colors';
import PropTypes from 'prop-types';
import {Icon} from "native-base";

const margin = 3;
const height = 54; //'14.28%'; // should calculate this

export default class ItemButton extends React.PureComponent {
  static propTypes = {
    selected: PropTypes.bool,
    columns: PropTypes.number,
    disabled: PropTypes.bool,
    modifier_groups: PropTypes.array,
  }

  static defaultProps = {
    columns: 4,
  }

  render() {
    let { modifier, cartModifier, testID } = this.props;
    let showCustomize = modifier?.modifier_groups.length > 0;
    let customizeText = modifier?.hasRequiredModifiers() ? "Required" : "Optional";
    if (cartModifier?.mods && cartModifier?.selected) {
      customizeText = cartModifier.getChildModsString()?.buttonText || customizeText
    }

    const {width} = Dimensions.get('window');
    let {text, buttonStyle, selected, columns, disabled} = this.props;

    let viewStyles = [styles.button];
    let textStyle = [styles.text];
    buttonStyle = buttonStyle || {};

    if (selected) {
      viewStyles.push(styles.selected);
    }
    if (disabled === true) {
      viewStyles.push(styles.disabled);
      textStyle.push(styles.disabledText);
    }


    let customizeButtonStyle = [];
    if (cartModifier && !cartModifier.isValid()) {
      customizeButtonStyle = [styles.errorButton]
    }

    let customizeTextStyle = [];
    if (cartModifier && !cartModifier.isValid()) {
      customizeTextStyle = [styles.errorText]
    }

    let style = {};

    if (!columns) columns = 4;

    let containerWidth = (width / columns) - (margin * 2);
    // style.height = height;

    viewStyles.push(style);

    if (buttonStyle) viewStyles.push(buttonStyle);
    let textNode = text ?
      <Text adjustsFontSizeToFit numberOfLines={2} ellipsizeMode={'middle'} style={textStyle}>{text}</Text> : null;

    return (
      <View style={[ styles.buttonContainer, { width: containerWidth } ]}>
        <TouchableWithoutFeedback
          testID={testID}
          accessible={true}
          accessibilityLabel={text}
          onPress={this._onPress}
        >
          <View style={[ viewStyles, showCustomize && styles.leftButtonSplit ]}>
            {this.props.children || textNode}
          </View>
        </TouchableWithoutFeedback>

        { showCustomize &&
          <TouchableWithoutFeedback
            accessible={true}
            accessibilityLabel={text}
            onPress={this.props.customizeModifier}
          >
            <View style={[ styles.rightButtonSplit, customizeButtonStyle ]}>
              <Icon type={"MaterialIcons"} style={[{ fontSize: 20 }, customizeTextStyle]} name={"edit"} color="#DDD"/>
              <Text numberOfLines={1} ellipsizeMode={'tail'} style={[{ fontSize: 10 }, customizeTextStyle]}>{ customizeText }</Text>
            </View>
          </TouchableWithoutFeedback>
        }
      </View>
    )
  }

  _onPress = () => {
    let {item, onPress, disabled, disabledMsg} = this.props;
    if(disabled){
      if(disabledMsg) Alert.alert('', disabledMsg);
      return;
    }
    onPress(item);
  }
}

const styles = EStyleSheet.create({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginLeft: margin,
    marginBottom: margin,
  },
  button: {
    backgroundColor: '#dddddd',
    borderRadius: 2,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    paddingLeft: 3,
    paddingRight: 3,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    marginBottom: 1,
    height: height
  },
  leftButtonSplit: {
    borderBottomLeftRadius: 4,
    borderTopLeftRadius: 4,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    flexGrow: 0.8,
  },
  rightButtonSplit: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
    backgroundColor: "#EEE",
    borderColor: "#DDD",
    borderWidth: 2,
    paddingHorizontal: 3,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 1,
    flexGrow: 0.2,
    flexShrink: 1,
    flexBasis: 0,
    height: height
  },
  selected: {
    borderWidth: 2,
    borderColor: Colors.highlight,
    backgroundColor: Colors.buttonSelected
  },
  text: {
    textAlign: 'center',
    paddingHorizontal: 3,
  },
  errorButton: {
    borderColor: Colors.error
  },
  errorText: {
    color: Colors.error
  },
  disabled: {
    backgroundColor: Colors.gray
  },
  disabledText: {
    color: Colors.darkGray
  }
});
