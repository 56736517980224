import * as Geolocation from 'expo-location';
import * as uuid from "uuid";
import _ from 'lodash';
import AsyncStorage from "@react-native-community/async-storage";
import axios, {CancelToken} from 'axios';
import moment from "moment";
import Timer from 'react-native-background-timer';
import {Audio} from 'expo-av';
import {Platform, Vibration} from "react-native";
import Sentry from '../services/Sentry';

import Alert from "../components/Alert"
//import CacheManager from "./CacheManager";
import Model from '../models/Model';
import NavigationService from '../navigation/NavigationService';
import {
  Location, MenuData, User, SavedCard, PartyTab, Station, TerminalNotification,
  Order, Customer, HandheldConfig, SeatedGroup, HandheldDevice, UserInfo,
} from '../models';
import {
  Coordinate,
  sortAlphaNum,
  validateEmail,
  getCsrfCookieName,
  getCircularReplacer
} from "../helpers/HelperFunctions";
import {API_URL, FULLSTORY_ENABLED, getSentryEnv, SENTRY_ENV, setSentryEnv} from "../constants/Config";
import {PING_RESULT, URLS, Sounds} from '../constants/Constants';
import {show as showBanner, hide as hideBanner} from '../components/NotificationBanner';

import DB, {resetDB} from '../services/PouchDB';

import PollerService from "../services/PollerService";
import Constants from "expo-constants";
import SentryFullStory from "@sentry/fullstory";
import * as Print from 'expo-print';

import manifest from "../app.json";

axios.defaults.baseURL = API_URL;

axios.defaults.xsrfCookieName = getCsrfCookieName();
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

axios.interceptors.response.use(
  response => {
    /*if(response.data.errorCode){
      // Todo: Automatically display error code popup (will need to remove individual error handling)
    }*/
    return response
  },
  error => {
    if (error.response) {
      const {status} = error.response;
      if (status === 401) {
        /** 401 Unauthorized **/
        API.logout();
        NavigationService.navigate('Login');
      } else if (status === 400) {
        /** TODO: add this and remove all individual error messages. Caution: Modals may interfere with message zIndex
         /*showMessage({
           floating: true, position: 'top', type: 'error',
           message: error.response.data.errorCode
         })*/
      }
    } else if (error.request){
      // error.request

    } else {
      // error.message
    }

    API.trigger('request_failure');
    return Promise.reject(error);
  }
);


/* ---------------- Enums for Terminal (To Prevent Stringly-Typed Code) ---------------- */

/**
 * The different orientations that a device can take. Used in updating and reading the Orientation from the API
 * @enum {string}
 */
const Orientation = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait'
}

export {Orientation};

/* ------------------------------------------------------------------------------------- */


// Cached instance variables:
let events = {};

function getErrorMessage(error) {
  if (error.response) {
    if (error.response.status === 404) return "Server Error: 404";
    if (error.response.data) return error.response.data;
    return "Server Error: " + error.response.status;
  } else if (error.request) {
    return "No response from server";
  } else {
    return error.message;
  }
}

const intervalFn = Platform.select({
  android: Timer.setInterval.bind(Timer),
  default: setInterval
})
const clearIntervalFn = Platform.select({
  android: Timer.clearInterval.bind(Timer),
  default: clearInterval
})
const timeoutFn = Platform.select({
  android: Timer.setTimeout.bind(Timer),
  default: setTimeout
})
const clearTimeoutFn = Platform.select({
  android: Timer.clearTimeout.bind(Timer),
  default: clearTimeout
})

/**
 * I'm wondering if these should all be State variables on a Top Level Component.
 * Might help preserve them between hot-reloads.
 */

class API {

  static version = manifest.expo.version;
  static env = SENTRY_ENV;

  static initialize() {
    PollerService.API = API; // We do this to avoid cyclical imports
    API._pollerService = new PollerService(API.taskPoll);

    if (Platform.OS === 'web') {
      const WebSetup = require('../helpers/WebSetup').default;
      new WebSetup(API);
    }

    API.resetAudio();
  }

  //static ws = new WebSocket('ws://'+API_URL+'/chat/');

  /** @type {Sound} */
  static audioPlayer = null;
  static audioPlayer2 = null;
  /** @type {Orientation} */
  static orientation = Orientation.LANDSCAPE;

  static currUser = null;
  static customer_id = null;
  static is_admin = false;
  /** @type {Customer} */
  static main_customer = null;

  /** @type {MenuData} */
  static menuData = null;
  static menuLoaded = false;
  /** @type {HandheldConfig} */
  static config = null;

  static currentScreen = '';

  /** @type {HandheldDevice} */
  static handheldDevice = new HandheldDevice({});
  /** @type {?string} The JWT Token from the Server */
  static authToken = null;
  /** @type {?string} Stripe Publishable Key */
  static stripeKey = null;
  /** @type {boolean} We assume we are connected by default */
  static isConnected = true;

  /** @type {boolean} True if we've polled for the first time already */
  static hasPolled = false;
  //static poller = null;               // the reference to the current setTimeout
  static pollingEnabled = false;        // Whether or not polling is currently enabled
  static _polling = false;              // True if we are currently polling. Think this is the same as `pollingEnabled`
  static _pollInProgress = false;       // true if a poll is in progress
  static _pollCompleteTime = null;      // when the last poll finished (client time - used to determine when we should poll next)
  static _lastPollError = null;
  static _pollErrorCount = 0;           // Total number of poll errors since we last logged to Sentry
  static _pollInterval = 20;

  static hideClosedOrdersBefore = null;

  /** @type {moment.Moment} The last time that the user has interacted with the application */
  static lastTouch = moment();
  static userHasInteracted = false;     // tracks whether the user has interacted with the application yet (required for web audio)
  static audioInitialized = false;      // On iOS, we initialize audio with a landing page by playing each clip once. Stupid, I know.

  static notifications = true;          // todo: load this from prefs?

  static _customers = {};
  static _orders = {};
  static _locations = {};
  static _selectedLocations = [];
  static _stations = {};
  static _seated_groups = {};
  static _smart_orders = [];
  static _notices = {};
  static _noticePages = 0;
  static _tabs = {};
  static _bartenders = {};

  static _customers_hash = null;
  static _cfg_hash = null;
  static _last_poll = null;         // Last time a poll was completed in server time. Used to only fetch new data.
  static _menus_hash = null;
  static _station_hash = null;
  static _location_hash = null;
  static _seated_group_hash = null;

  static _errorCount = 0;           // Number of poll errors since the last successful poll

  static _inactionTimer = null;
  static _inactionEvents = {};

  static touched() {
    API.userHasInteracted = true;
    API.lastTouch = moment();
    API.trigger('touch');
  }

  static onInaction = (seconds, fn) => {

    if (!API._inactionTimer) {
      API._inactionTimer = intervalFn(API._handleInaction, 1000);
    }
    let key = _.random(1000, 9999, false);
    let wrapper = {
      remove: () => {
        try {
          delete API._inactionEvents[seconds][key];
          // if there are no more events for this time, remove the key:
          if (!Object.keys(API._inactionEvents[seconds]).length) delete API._inactionEvents[seconds];
          // if there are no more events, clear the interval timer:
          if (!Object.keys(API._inactionEvents).length) clearIntervalFn(API._inactionTimer);
        } catch (err) {
        }
      }
    };
    if (!API._inactionEvents[seconds]) API._inactionEvents[seconds] = {};
    API._inactionEvents[seconds][key] = fn;

    return wrapper;
  }

  static _handleInaction = () => {
    let secs = moment().diff(API.lastTouch, 'seconds');
    if (API._inactionEvents[secs]) {
      try {
        Object.keys(API._inactionEvents[secs]).forEach(key => {
          try {
            API._inactionEvents[secs][key](secs);
          } catch (err) {
            delete API._inactionEvents[secs][key]
          }
        });
      } catch (err) {
        Sentry.captureMessage("Error trying to cleanup missing inAction event. " + JSON.stringify(API._inactionEvents));
      }
    }
  }

  /**
   * Adds an event listener to API. Whenever an event with the name `event` is triggered, all event listeners will be
   * called in the order that they were added.
   * @param {string} event - The name of the event to listen for
   * @param {function|DebouncedFunc} fn - The callback function to call
   * @param {string} [name] - An internal name for this listener
   * @returns {{remove: function}} The function for removing the function from the list of event listeners
   */
  static on(event, fn, name) {
    if (name) fn._name = name;
    if (!events[event]) events[event] = [fn];
    else events[event].push(fn);

    return {
      remove: () => {
        events[event] = _.without(events[event], fn);
      }
    };
  };

  /**
   * Listen to an event once
   * @param {string} event  - The event to listen to
   * @param {function} fn - The function to call
   * @param {string} [name] - An internal name for this listener
   */
  static once(event, fn, name = '') {
    let listener = API.on(event, (...args) => {
      fn.apply(null, args);
      listener.remove();
    }, name);
    return listener;
  }

  /**
   * Removes the callback function `fn` from the list of event listeners for the event with given name `event`
   * @param {string} event - The name of the event
   * @param {function} fn - The callback function to remove
   */
  static off(event, fn) {
    _.pull(events[event], fn);
  };

  /**
   * This function is used to trigger events on the API, they can be listened to using API.on('trigger_name')
   *
   * @param event {string} Used by system
   * @param args Extra arguments
   */
  static trigger(event, ...args) {
    if (events[event]) {
      events[event].forEach(fn => {
        if (typeof fn === 'function') {
          try {
            fn.apply(null, args);
          } catch (err) {
            console.error("Event listener failed to run: ", event, err);
            Sentry.captureException(err);
            API.off(event, fn);
          }
        } else {
          _.pull(events[event], fn);
        }
      });
    }
  };

  /**
   * Updates the orientation of the application.
   * @param {Orientation} orientation - Whether the application is now portrait or landscape.
   */
  static setOrientation(orientation) {
    API.orientation = orientation;
    API.trigger('orientation', orientation);
  }

  static getURL(API) {
    return axios.defaults.baseURL + URLS[API];
  };

  static initSentry() {
    if (__DEV__) return;

    Sentry.getCurrentHub()?.endSession();

    let sentryEnv = getSentryEnv();
    const version = API.version;
    Sentry.init({
      dsn: 'https://22b375fb4c9d44a2bd484e9b914d4161@o390327.ingest.sentry.io/5233068',
      environment: sentryEnv,
      release: (Platform.OS === 'web' ? version : null),
      integrations: FULLSTORY_ENABLED ? [new SentryFullStory('bbot-inc')] : null,
      ignoreErrors: ['Network Error']
    });
    Sentry.setTag('version', version);
    Sentry.setTag('target_env', sentryEnv.split('-')[0]);
  }

  static async setServer(apiURL) {
    if(apiURL !== axios.defaults.baseURL) {
      axios.defaults.baseURL = apiURL;
      setSentryEnv(apiURL);
      API.initSentry();
      await API.logout();
    }
  }

  /**
   * Checks to see if we are currently logged in, and if so, set up the instance variables.
   * @returns {Promise<boolean>}
   */
  static async isLoggedIn() {
    try {

      const fields = await API.multiGet(["jwt_token"]);
      if (fields.jwt_token) {
        await API.setJWT(fields.jwt_token);

        let response = await axios.get(URLS.AUTH);
        let {data} = response;

        if (data.customer_id) {
          await API.selectCustomer(data.customer_id);
        }
        if (data.device_id)
          API.handheldDevice.id = data.device_id;

        if (data.config_id)
          API.handheldDevice.config_id = data.config_id;

        if (data.stripe_key) {
          AsyncStorage.setItem('stripe_key', data.stripe_key);
          API.stripeKey = data.stripe_key;
          API.trigger('stripePublishableKey', data.stripe_key);
        }


        return true;
      } else {
        API.logout();
        return false;
      }
    } catch (error) {
      console.log("Error checking login state: ", error);
      return false;
    }
  };

  /**
   *
   * @param {string} email
   * @param {string} password
   * @returns {Promise<{success: boolean, error: string}|{success: boolean, allowed_customers: any}>}
   */
  static async login(email, password) {
    email = email.toLowerCase().trim();

    try {
      let response = await axios.post(URLS.LOGIN, {
        email: email,
        password: password
      });

      let {data} = response;

      if (!data.jwt_token)
        return {
          success: false,
          error: data.error
        };

      await API.setJWT(data.jwt_token, true);
      await AsyncStorage.multiSet([
        ['email', email],
        ['is_admin', data.is_admin.toString()],
        ['stripe_key', data.stripe_key]
      ])

      if (data.allowed_customers.length === 1) {
        await API.setCustomer(data.allowed_customers[0]);
      }

      return {
        success: true,
        allowed_customers: data.allowed_customers
      }

    } catch (error) {
      console.log('axios login error: ', JSON.stringify(error));
      return {
        success: false,
        error: getErrorMessage(error)
      }
    }
  }

  /**
   * Resolves to the list of allowed customers that this user is allowed to view
   * @returns {Promise<any|{success: false}>}
   */
  static async getAllowedCustomers() {
    try {
      let response = await axios.get(URLS.ALLOWED_CUSTOMERS);
      let {data} = response;
      return data;
    } catch (err) {
      return {
        success: false
      }
    }
  }

  /**
   * Sends an API call to the Server to attempt to select the customer with the provided `customer_id` string
   * @param {string} customer_id - The UUID of the customer to select
   * @returns {Promise<{success: boolean, error?: (string|*)}>} An error message is given is success is false
   */
  static async selectCustomer(customer_id) {
    try {
      let response = await axios.post(URLS.SELECT_CUSTOMER, {
        customer_id: customer_id
      });
      let {data} = response;
      if (data.jwt) {
        await API.setJWT(data.jwt);
        let main_customer = new Customer(data.customer);

        hideBanner();

        if (API.customer_id && API.customer_id !== customer_id) {
          await API.clearCache();
          API.clearHashes();
        }

        if (API._polling) {
          API.menuLoaded = false;
          API.stopPolling();
        }

        await API.setCustomer(main_customer);
      }
      return {success: true};
    } catch (error) {
      return {success: false, error: getErrorMessage(error)}
    }
  }

  /**
   * Sets the locally stored JWT token
   * @param {string} jwt
   * @param {boolean} [doWrite=false]
   * @returns {Promise<void>}
   */
  static async setJWT(jwt, doWrite = false) {
    API.authToken = "JWT " + jwt;
    await AsyncStorage.setItem('jwt_token', jwt);
    axios.defaults.headers.common["Authorization"] = 'JWT ' + jwt;
  }

  /**
   * @param {Customer} customer - Assigns the currently viewed Customer
   * @returns {Promise<boolean>} Resolves to True once the method completes
   */
  static async setCustomer(customer) {
    API.customer_id = customer.customer_id;
    API.main_customer = customer;
    let tz = API.main_customer?.timezone;
    if (tz) {
      moment.tz.setDefault(tz);
    }
    API.trigger('customer_change', customer);
    await AsyncStorage.setItem('customer_id', customer.customer_id);
    return true;
  }

  /**
   * Saves a document to PouchDB containing data
   * @param key - the document key
   * @param data - the data to save
   * @param hash - a 2nd parameter that will be passed to the callback function
   * @param encode - whether or not to JSON encode the data
   * @param callback - data and hash will be passed to the callback function when the cache is re-loaded
   * @returns {Promise<*>}
   */
  static async saveData(key, data, hash, encode, callback) {
    let doc;
    data = encode ? JSON.stringify(data) : data;
    try {
      doc = await DB().get(key);
    } catch (err) {
      doc = {_id: key};
    }
    try {
      Object.assign(doc, {data, hash, decode: encode, callback});
      await DB().put(doc, {force: true});
      return true;
    } catch (err2) {
      //API.sendCaughtError(err2);
      return false;
    }
  }

  /**
   * Loads cached values into memory
   * @returns {Promise<boolean>}
   */
  static async loadFromCache() {
    try {
      let docs = await DB().allDocs({
        include_docs: true
      });

      // Load cached data from indexeddb:
      if (docs.total_rows) {
        docs.rows.forEach(row => {
          const doc = row.doc;
          let data = doc.decode ? JSON.parse(doc.data) : doc.data;
          if (API[doc.callback]) {
            API[doc.callback](data, doc.hash);
          }
        })
      }


      const fields = await API.multiGet(['user', 'customer_id', 'stripe_key', 'is_admin', 'last_cleared']);
      API.is_admin = fields.is_admin === 'true' || fields.is_admin === true;
      API.customer_id = fields.customer_id;
      API.currUser = fields.user ? new User(JSON.parse(fields.user)) : null;
      API.stripeKey = fields.stripe_key;
      API.hideClosedOrdersBefore = fields.last_cleared ? moment(fields.last_cleared) : null;

      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  static multiGet = async (fields) => {
    try {
      const results = await AsyncStorage.multiGet(fields);
      return _.reduce(results, (obj, row) => {
        obj[row[0]] = row[1];
        return obj;
      }, {});
    } catch (error) {
      return {
        error
      }
    }
  };

  /**
   * Validates the given pin number and returns the logged in user
   * @param pin
   * @param changeUser
   * @param useAdminPin
   * @returns {Promise<object>}
   */
  static async checkPin(pin, changeUser = true, useAdminPin=false) {
    let customer_id = await AsyncStorage.getItem('customer_id');
    let email = await AsyncStorage.getItem('email');

    try {
      let response = await axios.post(URLS.CHECKPIN, {
        is_admin: useAdminPin,
        customer_id: customer_id,
        email: email,
        pin_number: pin
      });
      if (response.data.user) {
        let user = new User(response.data);
        if (changeUser) {
          AsyncStorage.setItem('user', JSON.stringify(response.data));
          API.currUser = user;
        }
        return {
          user: user,
        };
      }

      return {
        error: response.data.Warning
      };

    } catch (error) {
      return {
        error: error.message
      }
    }
  }

  static async getAccount() {
    return await AsyncStorage.getItem('email');
  }

  static getUser() {
    return API.currUser;
  }

  static get customer() {
    return API._customers[API.customer_id];
  }

  static clearUser() {
    API.currUser = null;
    AsyncStorage.removeItem('user');
  }

  /**
   * Registers a new device or Updates an existing device
   *
   * @param {Object} fieldsToUpdate
   * @returns {Promise<void>}
   */
  static async updateDevice(fieldsToUpdate) {

    let deviceData = API.handheldDevice.toJSON();
    Object.assign(deviceData, fieldsToUpdate);

    try {
      let response = await axios.post(URLS.UPDATE_DEVICE, deviceData);
      let {data} = response;

      API.handheldDevice.update(data.device);

      if (data.config) {
        API.config = new HandheldConfig(data.config);
        AsyncStorage.setItem('config', JSON.stringify(data.config));
      }
      if (data.jwt) {
        await API.setJWT(data.jwt, true);
      }

      return data;

    } catch (error) {
      return {
        success: false,
        error: getErrorMessage(error)
      }
    }

  }

  /**
   * Checks to see if a Handheld Device exists, and if so, returns it
   * @returns {Promise<any|{error: string, device: HandheldDevice}>}
   */
  static async getDeviceInfo() {
    try {
      let response = await axios.post(URLS.DEVICE_INFO, {
        unique_id: API.handheldDevice.unique_id,
        apk_version: API.handheldDevice.apk_version,
        bundle_version: API.handheldDevice.bundle_version,
        release_channel: API.handheldDevice.release_channel,
        device_name: API.handheldDevice.device_name,
        api_level: API.handheldDevice.api_level,
        platform: Platform.OS
      });

      let data = response.data;
      if (data.device) {
        if (data.jwt) {
          await API.setJWT(data.jwt, true);
        }
        API.handheldDevice.update(data.device);

        if (API.handheldDevice.reader_serial) {
          await AsyncStorage.setItem("reader", API.handheldDevice.reader_serial);
        }

        if (API.handheldDevice.config_id && API.config?.id !== API.handheldDevice.config_id) {
          // we have the same customer id and the server is telling us to update the config_id:
          await API.setConfig(API.handheldDevice.config_id);
        }
        data.device = API.handheldDevice;
      }

      return data;

    } catch (err) {
      return {
        device: API.handheldDevice,
        error: getErrorMessage(err)
      }
    }
  }

  static async checkDeviceName(name) {
    try {
      let response = await axios.post(URLS.IS_DEVICE_NAME_VALID, {
        unique_id: API.handheldDevice.unique_id,
        name: name
      });

      return response.data
    } catch (err) {
      return {
        error: getErrorMessage(err),
        valid: false
      }
    }
  }

  /**
   * Gets all available handheld configs
   * format: [ {id: xxx, config_name: 'name'} ]
   * @returns {Promise<*>}
   */
  static async getAvailableConfigs() {

    try {
      let response = await axios.post(URLS.GET_CONFIGS);

      return response.data;

    } catch (err) {
      return {
        success: false,
        error: getErrorMessage(err)
      }
    }

  }

  static getConfig() {
    return API.config;
  }

  static getConfigId() {
    return API.config ? API.config.id : null;
  }

  /**
   *
   * @param config_id {int} The config id
   * @returns {Promise<*>}
   */
  static async setConfig(config_id) {

    try {
      let response = await axios.post(URLS.SET_CONFIG, {
        handheldConfigId: config_id
      });
      let {data} = response;

      if (data.warning) {
        return {
          success: false,
          error: data.warning
        }
      }

      API.stripeKey = data.stripe_key;
      let handheldConfig = data.config;

      await AsyncStorage.multiSet([
        ['jwt_token', data.jwt_token],
        ['stripe_key', data.stripe_key],
        ['config', JSON.stringify(handheldConfig)]
      ]);

      if (API.config && API.config.id !== config_id) {
        API.clearHashes();
        await API.clearCache(false);
      }

      API.config = new HandheldConfig(handheldConfig);
      API.handheldDevice.config_id = API.config.id;

      await API.setJWT(response.data.jwt_token);

      API.trigger('config_updated');
      return {
        success: true
      }

    } catch (err) {
      return {
        success: false,
        error: err.message
      }
    }
  }

  static async saveConfig(configObj, hash) {
    if (!API.config) API.config = new HandheldConfig(configObj);
    else API.config.update(configObj);
    API._cfg_hash = hash;

    API.trigger('config_updated', API.config);
    return true;
  }

  static getCustomerId() {
    return API.customer_id;
  }

  static async getStripeConnectionToken(){
    try {
      const res = await axios.get(URLS.STRIPE.AUTH);
      return res.data.secret;
    } catch (err) {

    }
  }

  static async createStripePaymentIntent({amount, currency, statement_descriptor, charges, on_behalf_of}){
    try {
      const res = await axios.post(URLS.STRIPE.CREATE_INTENT, {
        amount,
        currency,
        statement_descriptor,
        payment_method_types: ['card_present', 'card'], // ,'interac_present' for canada
        capture_method: 'manual',
      })
      return {
        clientSecret: res.data.clientSecret
      };
    } catch(err){
      return {
        error: getErrorMessage(err)
      }
    }
  }

  static async getReader() {
    const str = await AsyncStorage.getItem("reader");
    let res = null;
    try {
      res = JSON.parse(str);
    } catch(err){
      if(!res && str){
        res = {
          serial_number: str,
          device_type: 'CHIPPER_2X'
        };
      }
    }
    return res;
  }

  static async setConnectedReader(reader) {
    API.reader = reader;
    await AsyncStorage.setItem("reader", JSON.stringify(reader));
    return true;
  };

  /**
   * Gets the stations for the current customer
   * @returns {Array}
   */
  static getStations() {
    return _.sortBy(Object.values(API._stations), (station) => {
      let customer = API._customers[station.customer_id];
      return customer ? customer.customer_name + station.station_name : station.station_name;
    });
  }

  static getLocations() {
    return _.orderBy(Object.values(API._locations), ['has_orders', 'shortId'], [
      'desc',
      (a, b) => sortAlphaNum(a, b)
    ]);
  }

  static getSeatedGroups() {
    return Object.values(API._seated_groups);
  }

  /**
   * Runs once a second, and runs a poll only if necessary
   * @returns {Promise<void>}
   */
  static async taskPoll() {
    if(!API._pollCompleteTime || !API.pollingEnabled || API._pollInProgress) return;
    const timeSinceLastPoll = moment().diff(API._pollCompleteTime, 'seconds');

    if(timeSinceLastPoll >= API._pollInterval) {
      await API.handheldPoll();
    }
  }

  static startPolling() {
    API._polling = true;
    API.pollingEnabled = true;
    API.handheldPoll(true);

    if(Platform.OS === 'android'){
      // API._pollVerification = setInterval(() => {}, 5 * 60 * 1000);
    }
  }

  static stopPolling() {
    API.hasPolled = false;
    API.menuLoaded = false;
    API._pollInProgress = false;
    API._polling = false;
    API.pollingEnabled = false;
  }

  /**
   *
   * @returns {Promise<Coordinate|null>}
   */
  static async getCurrentPosition() {
    try {
      let location = await Geolocation.getCurrentPositionAsync({
        enableHighAccuracy: true, timeout: 2000, maximumAge: 10000
      });

      return new Coordinate(location);
    } catch (error) {
      return null;
    }

  }

  // For future use if we want to be able to cancel a promise
  static cancellable = CancelToken.source();

  static async getPositionAndDistance() {
    try {
      let position = null;
      let distance = null;
      if (Platform.OS !== 'web') {
        position = await API.getCurrentPosition();
      }

      if (position) {
        if (API._lastPos && position.distanceTo)
          distance = position.distanceTo(API._lastPos.point);
        API._lastPos = position;
        return {
          lat: position.latitude,
          lng: position.longitude,
          alt: position.altitude,
          dist: distance
        }
      }

      return null;

    } catch (err) {
      console.log("Couldn't retrieve position");
      return null;
    }
  }

  static async handheldPoll(initialPoll = false) {
    try {
      if (!API.customer_id) {
        API.trigger('poll_complete', false);
        return;
      }

      if (initialPoll) API.trigger('poll_status', "Initializing...");
      API._pollInProgress = true;
      API._polling = true;
      API.trigger('poll');

      let payload = {
        lp: API._last_poll,
        cfgh: API._cfg_hash,
        mh: API._menus_hash,
        sgh: API._seated_group_hash,
        sh: API._station_hash,
        lh: API._location_hash,
        ch: API._customers_hash
      };

      /* - Don't do GPS polling for now, since we don't use it
      let distAndPos = API.getPositionAndDistance();
      if ((distAndPos && distAndPos.dist > 3) || (!API._lastPos && distAndPos)) {
        payload = Object.assign(payload, distAndPos);
        API.trigger('gps', distAndPos);
      }
      */

      const ENDPOINT = API._last_poll ? URLS.POLL_NORMAL : URLS.POLL_BIG;

      let result = await axios.get(ENDPOINT, {
        params: payload,
        headers: {'POLL-PERIOD-SECONDS': API._pollInterval || API.config?.poll_interval || 20}
      });

      if (initialPoll) API.trigger('poll_status', "Received Data...");

      if (!API.isConnected) {
        API.trigger('connected', true);
        API.isConnected = true;
      }

      API._errorCount = 0;

      let data = result.data;

      if (!data) {
        Sentry.captureException(new Error('Data missing from poll result: ' + JSON.stringify(result)));
      }

      if (data.session_expired) {
        API.logout();
        NavigationService.navigate('Login');
        return;
      }

      if (data.no_config) {
        NavigationService.navigate('ConfigChooser');
        API.stopPolling();
        return;
      }

      try {
        // if the key exists, we either show or hide the banner. This occasionally throws errors for some dumb reason:
        if (data && 'message_to_bartender' in data) {
          if (data.message_to_bartender) {
            showBanner({
              message: data.message_to_bartender
            });
          } else hideBanner();
        }
      } catch (err) {
        API.sendCaughtError(err, null, "Couldn't show message to bartender");
      }

      API.trigger('poll_status', "Loading Customer Data...");

      if (data.customers) {
        API.updateCustomers(data.customers, data.customers_hash);
        await API.saveData('customers', Object.values(API._customers), data.customers_hash, true, 'updateCustomers');
      }

      if (data.bartenders) {
        API.updateBartenders(data.bartenders);
        await API.saveData('bartenders', Object.values(API._bartenders), null, true, 'updateBartenders');
      }

      if (initialPoll) API.trigger('poll_status', "Loading Menu Data...");

      if (data.menuData) {
        await API.updateMenuData(data.menuData, data.menus_hash);
        await API.saveData('menuData', data.menuData, data.menus_hash, false, 'updateMenuData');
      }

      if (initialPoll) API.trigger('poll_status', "Loading Profile...");

      if (data.config) {
        await API.saveConfig(data.config, data.cfg_hash);
        await API.saveData('config', data.config, data.cfg_hash, false, 'saveConfig');
      }

      if (initialPoll) API.trigger('poll_status', "Loading Groups...");

      if (data.seated_groups) {
        API.updateSeatedGroups(data.seated_groups, data.seated_group_hash);
        await API.saveData('seated_groups', Object.values(API._seated_groups), data.seated_group_hash, true, 'updateSeatedGroups');
      }

      if (initialPoll) API.trigger('poll_status', "Loading Locations...");

      if (data.locations) {
        API.updateLocations(data.locations, data.location_hash);
        await API.saveData('locations', Object.values(API._locations), data.location_hash, true, 'updateLocations');
      }

      if (initialPoll) API.trigger('poll_status', "Loading Stations...");

      if (data.stations) {
        await API.updateStations(data.stations, data.station_hash);
        await API.saveData('stations', Object.values(API._stations), data.station_hash, true, 'updateStations');
      }

      if (initialPoll) API.trigger('poll_status', "Loading Orders...");

      // TODO: Should trigger orders to update at least once a minute, since we need to update the UI when snooze_till elapses
      if (data.orders) {
        API.updateOrders(data.orders, data.last_poll);
        await API.saveOrders(data.last_poll);
      } else {
        // Check to see if any orders need to be un-snoozed:
        _.forEach(API._orders, order => {
          let diff = order.user_desired_time && order.snooze_till.diff(moment(), 'seconds');
          let interval = API.config.poll_interval;

          if (!order.time_closed && diff && diff <= 0 && diff >= -interval) {
            // If the order isn't closed, has a desired_time, and the desired_time is since the last poll,
            // need to trigger the orders event
            API.trigger('orders', []);
            return false;
          }
        })
      }

      if (initialPoll) API.trigger('poll_status', "Loading Notices...");

      if (data.notices) {
        API.updateNotices(data.notices);
        await API.saveNotices();
      }

      if (initialPoll) API.trigger('poll_status', "Loading Tabs...");

      if (data.tabs) {
        API.updateTabs(data.tabs);
        await API.saveTabs();

      }

      if (initialPoll) API.trigger('poll_status', "Loading Smart Orders...");

      API._smart_orders = data.smart_orders || [];
      API.trigger('smart_orders'); // we have no way of knowing really if smart orders have changed (maybe should cache last poll?)

      API._last_poll = data.last_poll;
      await API.saveData('last_poll', data.last_poll, null, false, 'updateLastPoll');

      if (!API.hasPolled) {
        API.hasPolled = true;
        // if no orders, need to still trigger an event to update KDS UI:
        if (!data.orders)
          API.trigger('orders', []);
      }

      API.cleanup();
      if (initialPoll) API.trigger('poll_status', "Cleanup complete...");

      API.trigger('poll_complete', true);
      API._pollInterval = result?.headers['correct-poll-seconds']; // If null, will default to config setting, then to 20 seconds
    } catch (error) {
      API._pollErrorCount++;

      // Only send poller errors at most once every 30 minutes:
      if (API._lastPollError && API._lastPollError.isBefore(moment().subtract(30, 'minute'))) {
        API.sendCaughtError(error, null, `HandheldPoll error: ${API._pollErrorCount} in 30 minutes`).then();
        API._lastPollError = moment();
        API._pollErrorCount = 0;
      }

      API.trigger('poll_complete', false);

      API._pollInterval = error?.response?.headers?.['correct-poll-seconds'];
      //const errorMsg = getErrorMessage(error);
      // if (error.status >= 500)
      API._errorCount++;
      // If we can't hit the server 3 times in a row, show 'disconnected' warning
      if (API._errorCount === 2) {
        let canConnect = await API.checkConnection();
        let msg = canConnect ? "Re-connecting to Bbot servers..." : "Internet connection lost. Check Wifi";
        API.trigger('connected', false, msg);
        API.isConnected = false;
      }
    }

    if (API._pollInterval) {
      API._pollInterval = parseInt(API._pollInterval);
      if(isNaN(API._pollInterval)) API._pollInterval = null;
    } else {
      API._pollInterval = API.config?.poll_interval || 20;
      if(API._errorCount === 2) API._pollInterval = 30; // 30 seconds
      else if(API._errorCount > 2) API._pollInterval = 60; // One Minute
    }
    API._pollInProgress = false;
    API._pollCompleteTime = moment();
  }

  static async updateMenuData(menu_data, hash) {
    let lastModified = {};
    menu_data.menus.forEach((menu) => {
      lastModified[menu.menuId] = menu.last_modified
    });

    API.menuData = new MenuData(menu_data); // todo: create update function?
    API._menus_hash = hash;
    API.menuLoaded = true;
    API.trigger('menu');
  }

  static updateSeatedGroups(seated_groups, hash) {
    let seated_group_ids = seated_groups.map(seated_group => {
      if (API._seated_groups[seated_group.id]) API._seated_groups[seated_group.id].update(seated_group);
      else API._seated_groups[seated_group.id] = new SeatedGroup(seated_group);
      return seated_group.id;
    });
    API._seated_groups = _.pick(API._seated_groups, seated_group_ids);
    API._seated_group_hash = hash;
    API.trigger('seated_groups');
  }

  static updateLocations(locations, hash) {
    let location_ids = locations.map(location => {
      if (API._locations[location.id]) API._locations[location.id].update(location);
      else API._locations[location.id] = new Location(location);
      return location.id;
    });
    API._locations = _.pick(API._locations, location_ids);
    API._location_hash = hash;
    API.trigger('locations', API.getLocations());
  }

  static saveLocations(hash){
    return API.saveData('locations', Object.values(API._locations), hash, true, 'updateLocations');
  }

  static updateOrders(orders, last_poll) {
    let cleanupCount = 0;
    for (let i = 0; i < orders.length; i++) {
      let order = orders[i];
      //API.hideClosedOrdersBefore
      // ignore cached orders older the 48 hours old. If there's no user_desired_time, the desired time is the order time.
      if (moment(order.user_desired_time || order.time).isBefore(moment().subtract(48, 'hours')) ||
        (API.hideClosedOrdersBefore && order.time_closed && moment(order.time_closed).isBefore(API.hideClosedOrdersBefore))
      ) {
        cleanupCount++;
        continue;
      }

      if (API._orders[order.orderId]) API._orders[order.orderId].update(order);
      else API._orders[order.orderId] = new Order(order);

      orders[i] = API._orders[order.orderId];

      let location = API._locations[order.location_id];
      if (location) location._last_modified = moment();
    }

    // sends array of updated orderId's:
    API.trigger('orders', orders.map(o => o.orderId));

    if (cleanupCount > 0) {
      console.log(`skipped ${cleanupCount} old orders`);
      API.saveOrders(last_poll);
    }
    API.playOrderNotifications(orders);
  }

  static async saveOrders(last_poll) {
    await API.saveData('orders', Object.values(API._orders), last_poll, true, 'updateOrders');
  }

  static updateTabs(tabs, hash) {
    // iterate through tabs
    // manage API._tabs dict
    tabs.forEach(tab => {
      if (API._tabs[tab.id]) API._tabs[tab.id].update(tab);
      else API._tabs[tab.id] = new PartyTab(tab);
    });

    // Cleanup old tabs
    for (let tab_id in API._tabs) {
      let tab = API._tabs[tab_id];
      if (tab.end_date && tab.end_date.isBefore(moment().subtract(1, 'days'))) delete API._tabs[tab_id];
    }

    API.trigger('tabs', tabs);
  }

  static async saveTabs() {
    return API.saveData('tabs', Object.values(API._tabs), null, true, 'updateTabs');
  }

  static async updateNotices(noticeResult = {}) {
    if(Array.isArray(noticeResult)) return;
    const {notices, pages} = noticeResult;
    let newNotice = false;
    let locations = JSON.parse(await AsyncStorage.getItem('selected_locations'));

    API._noticePages = pages;
    notices.forEach(notification => {
      if (API._notices[notification.id]) {
        if (notification.status === 'dismissed') delete API._notices[notification.id];
        else API._notices[notification.id].update(notification);
      } else {
        if (!notification.time_read && notification.status === 'active'){
          if(locations && notification.location_id && locations.includes(notification.location_id)) newNotice = true;
          else newNotice = true;
        }
        API._notices[notification.id] = new TerminalNotification(notification);
      }
      if(notification.location_id){
        let location = API._locations[notification.location_id];
        if(location) location.trigger('notice');
      }
    });

    let expired = moment().subtract(48, 'hour');
    for (let id in API._notices) {
      let n = API._notices[id];
      if (n.last_modified?.isBefore(expired)) delete API._notices[id];
    }

    if (newNotice && API.userHasInteracted && API.config.receive_service_requests){
      try {
        API.noticeSound.playAsync()
        if(Platform.OS === 'android') Vibration.vibrate([0,100,100,100])
      } catch(err){ console.log(err); }
    }

    API.trigger('notices')
  }

  static async saveNotices() {
    return API.saveData('notices', {
      notices: Object.values(API._notices),
      pages: API._noticePages
    }, null, true, 'updateNotices');
  }

  // todo: update KDSView to use this?
  static newKDSOrderCount = () => {
    if (!API.config || !API.config.kds_stations.length) return 0;

    let kds_station_orders = API.config.kds_stations.reduce((orders, station) => {
      if (station) return orders.concat(station.orders);
      return orders;
    }, []);

    return kds_station_orders.filter(order => order.kds_open && order.status === 'waiting').length;
  }

  static updateCustomers(customers, hash) {

    let customer_ids = customers.map(customer => {
      if (API._customers[customer.customer_id]) API._customers[customer.customer_id].update(customer);
      else API._customers[customer.customer_id] = new Customer(customer);
      return customer.customer_id;
    });
    API._customers = _.pick(API._customers, customer_ids);
    API._customers_hash = hash;
  }

  static updateBartenders(bartenders) {

    let bartenderIds = bartenders.map(bartender => {
      if (API._bartenders[bartender.id]) API._bartenders[bartender.id].update(bartender);
      else API._bartenders[bartender.id] = new UserInfo(bartender);
      return bartender.id;
    });
    API._bartenders = _.pick(API._bartenders, bartenderIds);
  }

  static async updateStations(stations, hash) {
    let station_ids = stations.map(station => {
      if (API._stations[station.id]) API._stations[station.id].update(station);
      else API._stations[station.id] = new Station(station);
      return station.id;
    });
    API._stations = _.pick(API._stations, station_ids);
    API._station_hash = hash;
    API.trigger('stations', API.getStations());
  }

  static updateLastPoll(last_poll) {
    API._last_poll = last_poll;
    API._pollCompleteTime = moment(last_poll);
  }

  static resetAudio = async () => {
    try {
      await Audio.setAudioModeAsync({
//        allowsRecordingIOS: true,
        //      playsInSilentModeIOS: true,
        //    interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
        staysActiveInBackground: true,
        //  interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
//        shouldDuckAndroid: true,
        //      playThroughEarpieceAndroid: false,
      });

      if (API.audioPlayer) await API.audioPlayer.unloadAsync();
      else API.audioPlayer = new Audio.Sound();

      if (API.audioPlayer2) await API.audioPlayer2.unloadAsync();
      else API.audioPlayer2 = new Audio.Sound();

      API.noticeSound = (await Audio.Sound.createAsync(Sounds.newMessage)).sound;

      API.audioPlayer.setOnPlaybackStatusUpdate(API.playBackStatusUpdate);
      API.audioPlayer2.setOnPlaybackStatusUpdate(API.playBackStatusUpdate2);

      await API.audioPlayer.loadAsync(Sounds.notification);
      await API.audioPlayer2.loadAsync(Sounds.alarm);

    } catch (err) {
      console.log('error loading notification sound', err);
    }
    return true;
  }

  static playOrderNotifications = async (orders) => {
    let {config} = API;
    if (config.show_stations_overview || config.show_kds_view) {

      if (!API.audioPlayer || !API.audioPlayer._loaded) {
        await API.resetAudio();
      }

      const {kds_station_ids} = config;
      // If we show the KDS View, we
      if (config.show_kds_view && kds_station_ids?.length) {
        orders = orders.filter(order => kds_station_ids.includes(order.bartending_station_id))
      }

      let playNotification = false;
      let count = 0;
      orders.forEach(order => {
        if (!order) return; // probably an order from the Search screen which isn't stored in our local cache
        order.items.forEach(i => {
          if (config.statuses_to_chirp_for.includes(i.status) && !i.notified) {
            playNotification = true;
            i.notified = true;
            count++;
          }
        })
      });

      if (playNotification && API.notifications) {
        if (!config.allow_disabling_chirp) {
          //  let volume = await SystemSetting.getVolume();
          //   if (volume < config.force_volume_level)
          //     await SystemSetting.setVolume(config.force_volume_level);
          //if (API.audioPlayer.volume < config.force_volume_level)
          // API.audioPlayer.volume = config.force_volume_level;
        }

        API.playNotificationSound();

        if (Platform.OS === 'web') API.showOrderNotification(count);

        Vibration.vibrate([200, 300, 200, 300, 200, 300]);
      }
    }
  }

  /**
   * Shows notifications in the Web browser
   * @param count
   */
  static showOrderNotification = (count) => {
    try {
      let plural = count > 1 ? 's' : '';
      let msg = `You have ${count} new order${plural}`;
      if (!("Notification" in window) || !document.hidden) return;
      else if (Notification.permission === "granted") {
        new Notification(msg, {tag: 'newOrders'});
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') new Notification(msg, {tag: 'newOrders'});
        })
      }
    } catch (err) {
      // couldn't show notification, no biggie, shouldn't be running tab in the background anyways
    }
  }

  static playNotificationSound = async () => {
    if (Platform.OS === 'web') {
      if (!API.userHasInteracted) return;
      if (API.audioPlayer) {
        // For iOS, we need to re-use the same audioPlayer instance for every playback.
        try {
          await API.audioPlayer.playFromPositionAsync(0);
        } catch (err) {
          console.log("Couldn't play notification: ", err);
          API.resetAudio();
        }
      } else {
        API.resetAudio();
        console.log('playing notification sound failed since audio player not ready');
      }
    }
    if (Platform.OS === 'android') {
      // This seems more reliable on Android then the approach below
      const player = new Audio.Sound();
      try {
        player.setOnPlaybackStatusUpdate(async (status) => {
          if (status.didJustFinish) {
            await player.unloadAsync();
          }
        });
        await player.loadAsync(Sounds.notification);
        await player.playAsync();
      } catch (err) {
        API.sendCaughtError(err)
      }
    }

  }

  // iOS Safari requires all sounds played to be played via an Audio player instance which was first played by
  // user interaction
  static playAlarmSound = async () => {
    await API.audioPlayer2.playFromPositionAsync(0);
  }

  static playBackStatusUpdate = (status) => {
    if (status.didJustFinish) {
      // API.resetAudio();
    }
  }
  static playBackStatusUpdate2 = (status) => {
    if (status.didJustFinish) {
      //API.audioPlayer2.unloadAsync();
    }
  }

  static checkConnection = async () => {

    try {
      let response = await axios.get(URLS.PING);
      return response.data.trim() === PING_RESULT;
    } catch (error) {
      console.log(JSON.stringify(error, null, 4));
      return false;
    }
  }


  /**
   * remove old orders
   */
  static cleanup() {
    let expiry = moment().subtract(2, 'days')

    // Cleanup Orders
    API._orders = _.pickBy(API._orders, (order, key) => {
      if (API.hideClosedOrdersBefore && order.time_closed && order.time_closed.isBefore(API.hideClosedOrdersBefore)) return false;

      return order.last_modified.isAfter(expiry) || order.user_desired_time?.isAfter(expiry) || !order.time_closed
    });

    // Cleanup Tabs
    API._tabs = _.pickBy(API._tabs, (tab) => {
      return tab.end_date.isAfter(expiry)
    })

  }

  /**
   * This function updates tabs from the server since getting the tab details (used_cents, limit_cents, available_cents)
   *
   * @param location - The Location Model(should use pk)
   * @returns {Promise<*>}
   */

  static async getLocationDetails(location) {
    try {
      let result = await axios.get(URLS.LOCATIONDETAIL, {
        params: {
          location_id: location.id,
          hash: location.hash,
          last_poll: location._last_poll?.toISOString()
        }
      });
      location._last_poll = moment();

      location.detailsUpdate(result.data);

      let {tabs} = result.data;
      tabs.forEach(tab => {
        if (API._tabs[tab.id])
          API._tabs[tab.id].update(tab);
        else {
          API._tabs[tab.id] = new PartyTab(tab);
        }
      })

      // todo: this should return info on whether or not anything changed so we know if we need to update
      return {
        location: location
      };

    } catch (error) {
      return {
        location: location,
        error: getErrorMessage(error)
      };
    }
  }

  /**
   * Get an Array of Location ShortIDs
   * @returns {string[]}
   */

  static getLocationCodes() {
    return Object.values(API._locations).map(l => l.shortId);
  }

  /** TODO: REMOVE [DEPRECATED]
   * Load past orders for the given location_id
   * @param location_id
   * @returns {Promise<{orders: *}|{orders: [], error: string}>}
   */
  static async moreOrders(location_id) {
    // for compatibility:
    let locationModel = _.find(API._locations, l => l.id === location_id);
    try {
      let result = await axios.post(URLS.MORE_ORDERS, {
        location_id: location_id,
        location: locationModel.shortId
      });
      return {
        orders: result.data.map(order => new Order(order))
      }
    } catch (error) {
      return {
        orders: [],
        error: getErrorMessage(error)
      }
    }
  }

  /** Clears the Seated Group for a location
   *
   * @param location {Location} The Location Model
   * @returns {Promise<*>}
   */

  static async clearLocation(location) {
    try {
      let response = await axios.post(URLS.CLEAR_LOCATION, {
        location_id: location.id
      });
      let data = response.data;
      if (data.success) {
        location.seated_group_id = null;
        location.time_guests_seated = moment(data.time_guests_seated);

        API.trigger('seated_groups');
      }
      return data;

    } catch (err) {
      return {
        success: false,
        error: getErrorMessage(err)
      }
    }
  }

  /**
   * Clears seated groups for all locations in the zone
   * @param zone
   * @returns {Promise<void>}
   */
  static async clearZoneGuests(zone) {
    try {
      let response = await axios.post(URLS.CLEAR_ZONE, {
        zone
      });
      let {data} = response;
      if (data.success) {
        let time_cleared = moment(data.time_guests_seated);
        Object.values(API._locations).forEach(location => {
          if (location.zone_for_reports === zone) {
            if (location.seated_group_id) {
              delete API._seated_groups[location.seated_group_id];
              location.seated_group_id = null;
              location.time_guests_seated = moment(time_cleared);
            }
          }
        })
      }

      return data;
    } catch (err) {
      if (err.response) {
        return err.response.data;
      } else {
        return {
          error: getErrorMessage(err)
        }
      }
    }
  }

  /**
   * @returns {?MenuData} Returns the menu data if it exists, or null otherwise.
   */
  static getMenu() {
    return API.menuData || null
  }

  static get status_sequences() {
    return API.menuData ? API.menuData.status_sequences : {};
  }

  static get status_pretty_names() {
    return API.menuData ? API.menuData.status_pretty_names : {};
  }

  static getProperties() {
    return API.menuData ? API.menuData.app_properties : {};
  }


  static async doMenuUpdate(fullUpdate) {
    API._menus_hash = null;

    if (fullUpdate) {
      API.clearHashes();

      await resetDB();
    }

    return API.handheldPoll();
  }

  static async stockUpdate(items, in_stock) {
    if (!Array.isArray(items)) items = [items];

    try {
      let menuItemIds = _.uniq(items.map(i => i.menuItemId));

      let response = await axios.post(URLS.STOCKUPDATE, {
        menuItemIds: menuItemIds,
        in_stock: in_stock
      });

      let {data} = response;

      if (!data.success) return {error: "Unable to change item status"};

      if ("in_stock" in data) {
        let in_stock = data.in_stock;

        items.forEach(item => item.update({
          in_stock: in_stock
        }));

        API.trigger('menu');
        // trigger menu update?
        return {
          menuItemIds,
          in_stock
        }
      } else {
        return {
          error: 'An error occurred'
        }
      }

    } catch (error) {
      let message = getErrorMessage(error);
      return {
        error: message
      }
    }
  }

  /**
   * Search for Orders
   * @returns {Promise<object>}
   */
  static async searchOrders(params) {
    let allOptions = {
      order_number: '',
      hours_in_past: 0,
      phone_number: 0,
      last4: '',
      exp_month: '',
      exp_year: '',
      page: 1,
      from: null,
      to: null,
      checkout_info: null
    }

    try {
      let result = await axios.post(URLS.SEARCHORDERS, params);

      result.data.orders = result.data.orders.map(order => {
        return new Order(order);
      }).sort((a, b) => (a.user_desired_time || a.time).isAfter(b.user_desired_time || b.time));

      return result.data;

    } catch (error) {
      return {
        orders: [],
        orderFees: [],
        numPages: 0,
        error: getErrorMessage(error)
      }
    }
  }

  static async getCartPrice(cart, promo_code, cancelToken) {
    let fm = cart.location.fulfillment_method;
    try {
      let response = await axios.post(URLS.GET_CART_PRICE, {
        cart: _.keyBy(cart.items, 'id'),
        promo_codes: _.compact([promo_code]),
        locationId: cart.location_id,
        fulfillment_method: fm
      }, {
        cancelToken: cancelToken
      });

      let {data} = response;
      return data;

    } catch (error) {
      return {
        error: getErrorMessage(error)
      }
    }

  }

  static async checkDeliverable(customerId, addressObj) {
    try {
      const response = await axios.post(URLS.CHECK_DELIVERABLE, {
        customerId,
        addressObj
      });
      return response.data;
    } catch(err){
      return {
        error: true,
        message: getErrorMessage(err)
      }
    }
  }

  static async submitOrder(payload) {
    try {
      let response = await axios.post(URLS.PAIDORDER, payload);
      return response.data;
    } catch (error) {
      let message = getErrorMessage(error);
      API.sendCaughtError(error, {}, JSON.stringify(payload, getCircularReplacer()))
      return {
        message: message,
        error: error
      };
    }

  }

  static async setItemsStatus(items) {

    let configId = API.getConfigId();
    try {
      let response = await axios.post(URLS.SETSTATUS, {
        handheldConfigId: configId,
        items: items,
        allow_all_status_transitions: true
      });

      return {
        success: true,
        changedOrderStatuses: response.data.changedOrderStatuses
      };

    } catch (error) {
      let msg = getErrorMessage(error);
      return {
        success: false,
        error: msg
      };
    }
  }

  static setLastClosedOrder(order) {
    // if there's already a timeout, clear it:
    clearTimeout(API._lastClosedOrderTimeout)

    API._lastClosedOrder = order;

    // After 1 minute, clear
    if(order) {
      API._lastClosedOrderTimeout = setTimeout(() => {
        API.setLastClosedOrder(null);
      }, 60 * 1000);
    }

    API.trigger('lastClosedOrder', API._lastClosedOrder);
  }

  static async addOrderRefund(order, cents_to_add = 0, tip_cents_to_add = 0, fraction_multiplier = 1.0, tip_fraction_multiplier = 1.0, reason = '', items_to_refund = null) {
    let token = await AsyncStorage.getItem('refundToken') || uuid.v4();
    await AsyncStorage.setItem('refundToken', token);

    try {
      let response = await axios.post(URLS.ORDER_REFUND, {
        order_id: order.orderId,
        idempotency_token: token,
        cents_to_add,
        tip_cents_to_add,
        fraction_multiplier,
        tip_fraction_multiplier,
        reason,
        items_to_refund
      });

      let {data} = response;
      if (data.success) {
        await AsyncStorage.removeItem('refundToken');
        //todo: return updated prices so we can do a local update, it's more lightweight
        API.handheldPoll(); // trigger immediate poll so we get the updated order
      }
      return data;

    } catch (err) {
      if (err?.response?.data) {
        return err.response.data;
      }
      return {
        error: getErrorMessage(err)
      }
    }
  }

  static async refundOrderItemsAndFees(orderitem_ids_to_refund = null, orderfee_ids_to_refund = null) {
    try {
      const response = await axios.post(URLS.REFUND_ORDER_ITEMS_AND_OR_FEES, {
        orderitem_ids_to_refund,
        orderfee_ids_to_refund
      });

      // Endpoint returns 200 so trigger immediate update of order model
      API.updateOrders(response.data.orders);
      return response.data;
    } catch (err) {
      API.sendCaughtError(err);
      return err;
    }
  }

  static async modifyOrder(order, fieldsToEdit) {
    let {driver_delivery_jobs, orderId} = order;
    let driver_delivery_job_id = null;

    if (_.isEmpty(fieldsToEdit)) return;

    if ('user_desired_time' in fieldsToEdit && driver_delivery_jobs.length) {
      driver_delivery_job_id = driver_delivery_jobs[0].id;
    }

    try {
      let response = await axios.post(URLS.MODIFY_ORDER, {
        orderId,
        fieldsToEdit,
        ...driver_delivery_job_id && {driver_delivery_job_id}
      });
      let {data} = response;

      if (data.success) {
        order.update(data.orderData);
      }

      API.trigger('orders', [orderId]);

      return {
        success: true,
        order
      };
    } catch (error) {
      return {
        success: false,
        error: getErrorMessage(error)
      }
    }
  }

  static async editTip(tab, order_ids, new_tip_cents) {

  }

  static async claimItems(items, unclaim = false) {

    try {
      let response = await axios.post(URLS.CLAIM, {
        device_id: API.handheldDevice.id,
        orderitem_ids: items.map(i => i.orderitemid),
        act_on_other_devices_items: false, // Config.allow_override && override
        action: unclaim ? 'unclaim' : 'claim'
      });

      let data = response.data;
      data.success = true;

      data.devices.forEach(device => {
        if (device.name && device.name !== API.handheldDevice.name) {
          data.warning = "An item has already been claimed";
        }
        device.orderitem_ids.forEach(orderitem_id => {
          let item = items.find(i => i.orderitemid === orderitem_id);
          item.claimed_by = device.name;
        })
      });

      return data;

    } catch (error) {
      return {
        success: false,
        error: getErrorMessage(error)
      }
    }
  }

  static async seatGroup(location_id, num_guests) {
    try {
      let response = await axios.post(URLS.SEAT_GROUP, {
        location_id: location_id,
        num_guests: num_guests
      });

      if (response.data) {
        let sg = new SeatedGroup(response.data.seated_group);
        API._seated_groups[sg.id] = sg;
        API._locations[location_id].seated_group_id = sg.id;

        API.trigger('seated_groups');
        return {
          success: true,
          seated_group: sg
        }
      }
    } catch (error) {
      return {
        success: false,
        error: getErrorMessage(error)
      }
    }
  }

  static async editGroup(seated_group) {
    try {
      let response = await axios.post(URLS.EDIT_GROUP, {
        seated_group_id: seated_group.id,
        guests: seated_group.guests
      });

      if (response.data) {
        seated_group.update(response.data.seated_group);
        API.trigger('seated_groups');
        return response.data;
      } else {
        return {
          success: false,
          error: response
        }
      }
    } catch (error) {
      return {
        success: false,
        error: getErrorMessage(error)
      }
    }
  }

  static async moveGroup(seated_group, old_location, new_location) {
    try {
      let response = await axios.post(URLS.MOVE_GROUP, {
        seated_group_id: seated_group.id,
        location_id: new_location.id
      });

      if (response.data) {
        old_location.seated_group_id = null;
        new_location.seated_group_id = seated_group.id;

        API.trigger('seated_groups');
        return response.data;
      } else {
        return {
          success: false,
          error: response
        }
      }
    } catch (error) {
      return {
        success: false,
        error: getErrorMessage(error)
      }
    }
  }

  static async addCardToGroup(seated_group, token, seats = null) {
    try {
      let response = await axios.post(URLS.ADD_CARD_TO_GROUP, {
        seated_group_id: seated_group.id,
        stripe_token: token
      });

      let {data} = response;
      if (data.error) {
        return data;
      }
      let card = null;
      if (response.data.card) {
        card = new SavedCard(response.data.card);
      } else if (data.id) {
        card = new SavedCard(response.data); //backwards compatibility
      } else {
        return {
          error: data
        }
      }

      if (seated_group) {
        seated_group.cards.push(card);
        seated_group.addCardToGuests(seats, card.id);
        API.trigger('seated_groups');
      }

      return {
        card: card
      };

    } catch (err) {
      return {
        success: false,
        error: getErrorMessage(err)
      }
    }
  }

  static async saveReusableCard(seated_group, stripe_payment_method_id, seats = null) {
    try {
      let response = await axios.post(URLS.CARD.SAVE, {
        seated_group_id: seated_group.id,
        stripe_payment_method_id
      });
      const {card} = response.data;
      const savedCard = new SavedCard(card);
      if (seated_group) {
        seated_group.cards.push(savedCard);
        if(seats) seated_group.addCardToGuests(seats, card.id);
        API.trigger('seated_groups');
      }

      return {
        card: savedCard
      }

    } catch (err) {
      return getErrorMessage(err)
    }
  }

  static async getCards(location) {
    let {seated_group} = location;

    try {
      let response = await axios.post(URLS.GET_CARDS, {
        location_id: location.id,
        seated_group_id: seated_group.id
      });
      let {data} = response;

      if (!data.error) {
        data.group_cards = data.group_cards ? data.group_cards.map(card => new SavedCard(card)) : [];
        data.smart_ordering_cards = data.smart_ordering_cards ? data.smart_ordering_cards.map(card => new SavedCard(card)) : [];
        data.group_tabs = data.group_tabs ? data.group_tabs.map(tab => new PartyTab(tab)) : [];
      }

      return data;
    } catch (err) {
      return {
        error: getErrorMessage(err)
      }
    }
  }

  static async openTab(seated_group_id, tab_name, location_id, card_id) {
    try {
      let response = await axios.post(URLS.TAB.CREATE, {
        seated_group_id,
        card_id,
        tab_name,
        location_id,
        user_id: API.is_admin ? null : API.currUser.user.id,
      });
      const {data} = response;

      if (data.tab) {
        const tab = new PartyTab(response.data.tab);
        tab._local = true; // created locally, don't bother with fancy load animation
        if(card_id) {
          // If this is a reusable tab, add it to the local tabs cache:
          API._tabs[tab.id] = tab;
          API.trigger('tabs', [tab]);
        }

        return {
          tab,
          joinURL: data.joinURL
        }
      } else {
        return {
          tab: null,
          error: "Server returned: " + response.data
        }
      }
    } catch (error) {
      return {
        tab: null,
        error: getErrorMessage(error)
      }
    }
  }

  static async fundTabFromPaymentIntent(tab, payment_intent_id, amount_cents, close_now) {
    try {
      let response = await axios.post(URLS.TAB.FUND, {
        tab_id: tab.id,
        payment_intent_id,
        amount_cents,
        close_now
      });

      const {data} = response;
      if(data.end_date){
        tab.update({
          end_date: data.end_date
        })
      } else {
        API._tabs[tab.id] = tab;
      }

      return data;
    } catch(err){
      return {
        error: getErrorMessage(err)
      }
    }
  }

  /** Adds fund to the consumer tab **/
  static async expandConsumerTab(tab, amount_cents, location_id) {
    if (tab.available_cents >= amount_cents) {
      // Tab already contains enough funds, no need to expand it:
      return true;
    }

    try {
      let response = await axios.post(URLS.TAB.EXPAND, {
        tab_id: tab.id,
        card_id: null,
        location_id: location_id,
        total_auth_cents: tab.used_cents + amount_cents,
        old_total_auth_cents: tab.limit_cents,
        tab_owner_secret: tab.tab_owner_secret
      });
      let {data} = response;
      let obj = data.tab;
      if (obj) tab.update(obj);
      return data;
    } catch (err) {
      return null;
    }
  }

  static async getTabURL(tab_id, location_id, staff_only=false, single_use=false) {
    try {
      let response = await axios.post(URLS.TAB.URL, {
        tab_id,
        location_id,
        staff_only,
        single_use
      });
      return response.data;

    } catch (err) {

    }
  }

  static async getTabInfo(code){
    try {
      const response = await axios.get(URLS.TAB.INFO, {
        params: { code }
      })
      const {data} = response;
      return data.tab;
    } catch(err){
      return {
        message: err.message
      }
    }
  }

  static async sendReceipt(order, email, phone) {
    phone = phone.replace(/[^0-9]+/g, '');
    if (phone && (phone.length !== 10 && phone.length !== 11)) {
      return {
        error: "Phone number does not appear to be valid"
      }
    }
    if (email && !validateEmail(email)) {
      return {
        error: "E-mail address does not appear to be valid"
      }
    }
    try {
      let response = await axios.post(URLS.SEND_RECEIPT, {
        orderId: order.orderId,
        email,
        phone
      });

      let {data} = response;
      return data;
    } catch (error) {
      // This endpoint returns a 400 errorCode if the e-mail has already been sent:
      let data = error?.response?.data;
      if (data) return data;
      else return {
        error: getErrorMessage(error)
      }
    }
  }

  static async printTicket(order, print_full_receipt = false, include_whole_day = false, station = null) {

    try {
      let response = await axios.post(URLS.PRINT_TICKET, {
        orderId: order.orderId,
        print_full_receipt: print_full_receipt,
        include_whole_day: include_whole_day,
        station: station
      });

      // response is empty object
      return {
        success: true
      }
    } catch (error) {

    }
  }

  static async printTicketLocal(order) {
    try {
      let response = await axios.get(URLS.PRINT_TICKET_PDF, {
        params: {orderId: order.orderId}
      });
      let {data} = response;
      if (data.pdf_b64) {
        API.printPdf(data.pdf_b64);
      }
      return data;
    } catch (err) {
      return {
        errorCode: getErrorMessage(err)
      }
    }
  }

  static async printReceiptLocal(order) {
    try {
      let response = await axios.get(URLS.PRINT_RECEIPT_PDF, {
        params: {orderId: order.orderId}
      })
      // height_points, width_points, pdf_b64
      let {data} = response;
      if (data.pdf_b64) {
        API.printPdf(data.pdf_b64);
      } else {
        Alert.alert("Error", data.errorCode);
      }
      return data;
    } catch (err) {
      return {
        errorCode: getErrorMessage(err)
      }
    }
  }

  static async printPdf(b64_data) {
    if (Platform.OS === 'web')
      API.printPdfWeb(b64_data);
    else {
      await Print.printAsync({
        uri: 'data:application/pdf;base64,' + b64_data
      })
    }
  }

  static printPdfWeb(b64_data) {

    function b64toBlob(b64Data, contentType) {
      let byteCharacters = atob(b64Data)
      let byteArrays = []
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        let slice = byteCharacters.slice(offset, offset + 512),
          byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }
        byteArrays.push(new Uint8Array(byteNumbers))
      }
      return new Blob(byteArrays, {type: contentType})
    }

    let iframe = document.getElementById('printIframe');
    if (!iframe) {
      iframe = document.createElement("iframe");
      iframe.setAttribute('id', 'printIframe');
      iframe.style.display = 'none';

      document.body.appendChild(iframe);
    }
    try {
      let pdfObjectUrl = URL.createObjectURL(b64toBlob(b64_data, 'application/pdf'))
      iframe.setAttribute('src', pdfObjectUrl);
    } catch (err) {
      console.log(err);
    }
    // Then to print
    setTimeout(() => {
      iframe.contentWindow.print()
    }, 100)
  }

  /**
   *
   * @param order
   * @param time - minutes to add to snooze_till. If negative, un-snoozes.
   */
  static async snoozeOrder(order, time) {
    let orderIds = [order.orderId];
    try {
      let params = {
        orderIds: orderIds,
        reset_status: false
      }
      if (!time) params.minutes_from_now = -1;
      else if (time < 0) params.minutes_before_desired_time = Math.abs(time);
      else params.minutes_from_now = time;

      let response = await axios.post(URLS.SNOOZE_ORDER, params);
      let {data} = response;

      if (data.success) {
        //order.setStatus('waiting');
        order.update({
          snooze_till: data.snooze_till
        });

      }

      API.trigger('orders', orderIds);
      // response.warning or response.success
      return {
        order: order,
        snooze_till: order.snooze_till
      };

    } catch (error) {

    }
  }


  static async textPatronAgain(order) {
    try {
      let response = await axios.post(URLS.TEXT_PATRON_AGAIN, {
        orderIds: [order.orderId]
      });
      let {data} = response;
      return {
        success: !data.warning,
        warning: data.warning
      }
    } catch (error) {

    }
  }

  /**
   *
   * @param time_range
   * @param start
   * @param end
   * @returns {Promise<{date_headings: [], orders: [], menu_visits: []}>}
   */
  static async tipReport(time_range, start, end) {
    let data = {
      orders: [],
      menu_visits: [],
      date_headings: []
    };

    try {
      /*
        accepts these GET params:
        {
          'time_range':'today' | 'yesterday' | '7_days' | 'custom',
          'custom_start_date': iso format date
          'custom_end_date': iso format date
        }
      */

      let response = await axios.get(URLS.TIP_REPORT, {
        params: {
          time_range: time_range,
          custom_start_date: start,
          custom_end_date: end
        }
      });
      data.orders = response.data.orders;
      data.menu_visits = response.data.menu_visits;
      data.date_headings = response.data.date_headings;
      data.bartenders = response.data.bartenders;

    } catch (err) {
      data.error = getErrorMessage(err);
    }

    return data;

  }

  static async closeTabs(tab_ids, location_id) {
    try {
      let response = await axios.post(URLS.TAB.CLOSE, {
        tab_ids: tab_ids,
        location_id: location_id
      });

      const {data} = response;
      if (!data.success) return false;

      let {end_date} = data;
      tab_ids.forEach((tab_id) => {
        API._tabs[tab_id].update({end_date: end_date, last_modified: end_date})
      })

      API.saveTabs();
      API.trigger("tabs", _.pick(API._tabs, tab_ids));

      return data;
    } catch (error) {
      return {
        success: false,
        error: getErrorMessage(error)
      }
    }
  }

  static async renameTab(tab, new_name) {
    try {
      let response = await axios.post(URLS.TAB.RENAME, {
        id: tab.id,
        new_name
      });

      const {data} = response;

      if (data?.success) {
        tab.update({
          tab_name: data.tab_name
        });
        API.trigger('tabs', [tab]);
        API.saveTabs();
        return response.data;
      }
    } catch (error) {
      return {
        success: false,
        error: getErrorMessage(error)
      }
    }
  }

  /**
   *
   * @param station {Station} - The Station Model
   * @param fulfillment_method {String} - The fulfillment method we want to change
   * @param state {boolean} - true if allowed, false if not
   * @param user_id
   * @returns {Promise<{success: boolean, station: *}|{success: boolean, error: string}>}
   */
  static async toggleFulfillmentMethod(station, fulfillment_method, state, user_id) {

    try {
      let response = await axios.post(URLS.TOGGLE_FULFILLMENT, {
        station: station.id,
        fulfillment_method,
        state,
        user_id
      });
      let {data} = response;
      if (data.success) {
        station.update({
          selected_fulfillment_methods: data.fulfillment_methods
        })
      }
      return {
        success: true,
        station: station
      };
    } catch (err) {
      return {
        success: false,
        error: getErrorMessage(err)
      }
    }

  }


  static async getLocationOrderAllowed() {
    try {
      let response = await axios.get(URLS.GET_ALLOWED_LOCATIONS);
      let {data} = response;
      return data;
    } catch (error) {
      let msg = getErrorMessage(error);
      return {
        error: msg
      }
    }
  }

  static async setLocationOrderAllowed(station, locations, allowed) {
    try {
      let response = await axios.post(URLS.SET_ALLOWED_LOCATIONS, {
        station_id: station.id,
        location_ids: locations.map(l => l.id),
        order_allowed: allowed ? 'on' : 'off'
      });
      let {data} = response;
      if (data.success) {
        // Todo: this should be internal to Location model:
        locations.forEach(location => {
          location.update({
            order_allowed: allowed ? 'on' : 'off'
          });
        })
      }
      return data;
    } catch (error) {

    }
  }
  static async setOrderAllowed(station, value) {
    try {
      // If 'value' is an INT, it's pause_duration_minutes, else it's 'allowed' status
      const pause_duration_minutes = isNaN(value) ? null : parseInt(value);
      const allowed = isNaN(value) ? value : station.order_allowed;
      const response = await axios.post(URLS.SET_ORDER_ALLOWED, {
        station: station.id,
        allowed,
        pause_duration_minutes
      });

      let { data } = response;
      if (data.success) {
        station.update({
          order_allowed: data.allowed
        });
      }
      return {
        success: true,
        station: station
      }

    } catch (error) {

    }
  }

  static async setServiceConditions(station, selectedIds) {
    let result = {
      success: false
    }
    try {
      let response = await axios.post(URLS.SET_SERVICE_CONDITIONS, {
        station_ids: [station.id],
        service_condition_ids: selectedIds
      });
      let {data} = response;
      if (data.success) {
        station.update({
          service_condition_ids: selectedIds
        });
        return {
          success: true,
          service_condition_ids: selectedIds
        }
      }
    } catch (error) {
      result.success = false;
      result.error = getErrorMessage(error);
    }
  }

  static async closeOpenOrders(hours_ago) {
    try {
      let response = await axios.post(URLS.CLOSE_OPEN_ORDERS, {
        hours_ago
      });
      let {data} = response;
      return data;
    } catch (err) {
      if (err.response) {
        return err.response.data;
      } else {
        return {
          success: false,
          error: getErrorMessage(err)
        }
      }
    }
  }

  static async clearClosedOrders() {
    AsyncStorage.setItem('last_cleared', moment().toISOString());
    API.hideClosedOrdersBefore = moment();

    API._orders = _.pickBy(API._orders, (order, orderId) => {
      return !order.time_closed
    });
  }

  static async kdsImage() {
    try {
      let response = await axios.get(URLS.KDS.CUTE_PIC);
      let {data} = response;
      return data;
    } catch (error) {
      return {
        link: null,
        fact: null
      }
    }
  }

  /**
   * Fetches the courier capabilities dict which tells the UI which buttons to show for a given delivery job
   * eg:
   * {
   *   [courier_code]: {
   *     create_job: {Bool},
   *     update_job: {Bool},
   *     delete_job: {Bool},
   *     edit_job: {Bool},
   *     map_driver: {Bool}
   *   }
   * }
   *
   * @returns {Promise<void>}
   */
  static async getCourierCapabilities() {

    if (API._courierCapabilities && API._courierCapabilities?._cacheTime.isAfter(moment().subtract(1, "hour")))
      return API._courierCapabilities;
    try {
      let response = await axios.get(URLS.DRIVER.GET_COURIER_CAPABILITIES);
      let {data} = response;
      // Cache since this likely won't change often
      API._courierCapabilities = data;
      API._courierCapabilities._cacheTime = moment();
      return data;
    } catch (err) {

      return {}
    }
  }

  static async cancelDriver(order, job) {
    try {
      let response = await axios.post(URLS.DRIVER.CANCEL, {
        driver_delivery_job_id: job.id
      });
      let {data} = response;
      let errMsg;
      if (data.job_cancellation_result === 'success') {
        job.job_status = 'cancelled';
        order.update({
          driver_delivery_jobs: [...order.driver_delivery_jobs],
          last_modified: data.last_modified
        })
      }
      // 'too_late', 'server_error', 'not_implemented':
      return {
        success: data.job_cancellation_result === 'success',
        status: data.job_cancellation_result,
        job_id: data.job_id
      }
      // todo need to remove the driver_job from the order and trigger update
    } catch (err) {
      if (err?.response?.data) return err.response.data;
      return {
        error: getErrorMessage(err)
      }
    }
  }

  static async changeDriverTime(order, job, new_time) {
    try {
      let response = await axios.post(URLS.DRIVER.CHANGE_TIME, {
        driver_delivery_job_id: job.id,
        pickup_time: new_time.toISOString()
      });
      let {data} = response;
      switch (data.job_update_result) {
        case 'success':
          job.requested_pickup_time = moment(data.pickup_time);
          order.update({
            last_modified: data.last_modified
          })
          break;
        case 'server_error':
          break;
        case 'not_implemented':
          break;
      }

      return {
        success: data.job_update_result === 'success',
        status: data.job_update_result
      }

      // todo need to update the order driver_job
    } catch (err) {
      if (err?.response?.data) return err.response.data;
      return {
        success: false,
        error: 'A server error occurred: ' + getErrorMessage(err)
      }
    }
  }

  static async getMoreNotifications(page) {
    try {
      const res = await axios.get(URLS.NOTIFICATIONS.GET, {
        params: {page}
      });
    } catch (err) {

    }
  }

  static async setNotificationState(notification, newStatus) {
    try {
      const res = await axios.post(URLS.NOTIFICATIONS.SET, {
        id: notification.id,
        status: newStatus
      });
      const {data} = res;
      if (data.success) {
        if (data.notification.status === 'dismissed') {
          delete API._notices[notification.id];
        } else {
          API._notices[notification.id].update(data.notification);
        }
      }
      await API.saveNotices();
      const loc = API._locations[notification.location_id];
      if(loc) loc.trigger('notice');
      API.trigger('notices');
      return data;
    } catch (err) {
    }

  }

  static clearHashes() {
    API._last_poll = API._location_hash = API._customers_hash = API._seated_group_hash = API._station_hash =
      API._menus_hash = null;
  }

  /**
   * @param full - Whether or not to clear all caches
   */
  static async clearCache(full = true) {
    if (full) {
      API.customer_id = null;
      API.menuData = null;
      API.config = null;
      API.currUser = null;
      API.handheldDevice = new HandheldDevice({});
      API.hasPolled = false;

      AsyncStorage.multiRemove(['customer_id', 'menu_data', 'config', 'CutePic', 'last_cleared', 'selected_locations']);
    }

    try {
      await resetDB();
    } catch (err) {
    }

    API._courierCapabilities = null;
    API._locations = {};
    API._orders = {};
    API._notices = {};
    API._stations = {};
    API._seated_groups = {};
    API._smart_orders = [];
    API._customers = {};

    API.trigger('reset');
  }


  /**
   * Sends the caught error to the server along with other debug info which is logged to the err-{env}-cc slack channel
   * @param error
   * @param errorInfo
   * @param details {string} extra details
   * @returns {Promise<void>}
   */
  static async sendCaughtError(error, errorInfo = {}, details) {
    if (__DEV__) {
      console.error(error);
      return;
    }
    try {
      let text = error.toString();
      text = text.substring(0, text.indexOf('This error is located at:'));

      let email = await API.getAccount();

      // Todo: we shouldn't need the user/extra info here since they are set globally. Remove and test.
      Sentry.captureException(error, {
        user: {email},
        extra: {
          details: details,
          customer: API.main_customer?.customer_name,
          device: API.handheldDevice?.name,
          config: API.config?.config_name
        }
      });

      /*await axios.post(URLS.ERROR, {
        device_id: API.handheldDevice.id,
        message: text,
        trace: error,
        stack: errorInfo.componentStack,
        details
      });*/

    } catch (err) {

    }
  }

  /**
   *
   * @param message
   * @returns {Promise<string>}
   */
  static async digestMessage(message) {
    try {
      const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
      const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
      const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
      return hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
    } catch (err) {
      return "";
    }
  }

  static async retrieveAsyncStorageData(key) {
    try {
      const value = await AsyncStorage.getItem(key);
      const parsedValue = JSON.parse(value);
      if (value !== null && typeof parsedValue === "object") {
        return parsedValue;
      } else if (value !== null) {
        return value;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  static async storeAsyncStorageData(key, value) {
    let parsedValue = value;
    if (typeof value === "object") {
      parsedValue = JSON.stringify(value)
    }
    try {
      await AsyncStorage.setItem(
        key,
        parsedValue
      );
    } catch (error) {
      // Error saving data
    }
  };

  /**
   * @param pin
   * @returns {Promise<void>}
   */
  static async unlockScreen(pin) {
    if (Platform.OS === 'web') {
      let hex = await API.digestMessage(pin);
    }
    await AsyncStorage.setItem('locked', "")
  }

  /**
   *
   * @param view {string} - The view to return to on unlock
   * @returns {Promise<void>}
   */
  static async lockScreen(view) {
    await AsyncStorage.setItem('locked', view);
  }

  static async forceDelivery(stationId, locationId) {
    try {
      let response = await axios.post(URLS.ROBOT.FORCE_DELIVERY, {
        locationId: locationId,
        station: stationId,
      });
      return response.data.displayString
    } catch (error) {
      let message = getErrorMessage(error);
      return {
        error: message
      }
    }
  }

  static async stowBasket(stationId, basket_stowed) {
    try {
      let response = await axios.post(URLS.ROBOT.STOW, {
        station: stationId,
        basket_stowed: basket_stowed
      });
      const {data} = response;
      if (data.success) {
        API._stations[stationId].update({
          basket_stowed: data.basket_stowed
        });
      }
      return data;
    } catch (error) {
      let message = getErrorMessage(error);
      return {
        success: false,
        error: message
      }
    }
  }


  static async recallRobot(stationId) {
    try {
      let response = await axios.post(URLS.ROBOT.RECALL, {
        station: stationId,
      });
      return response.data;
    } catch (error) {
      let message = getErrorMessage(error);
      return {
        error: message
      }
    }
  }

  static async recoverRobot(station) {
    try {
      let response = await axios.post(URLS.ROBOT.RECOVER, {
        station: station,
      });
      let {data} = response;
      return data;
    } catch (error) {
      let message = getErrorMessage(error);
      return {
        error: message
      }
    }
  }


  static async logout() {
    API._errorCount = 0;
    clearIntervalFn(API._inactionTimer);

    hideBanner();

    API.clearHashes();
    API.clearCache();

    API.is_admin = false;

    API.stopPolling();

    axios.defaults.headers.common["Authorization"] = null;

    let fieldsToRemove = ['jwt_token', 'customer_id', 'user', 'config', 'config_id', 'menu_data', 'menu_modified', 'is_admin', 'helper_prompts', 'last_cleared'];
    //let rememberEmail = await AsyncStorage.getItem('rememberEmail');
    //console.log('logging out. Remember email?', rememberEmail);
    //if(rememberEmail !== 'true') fieldsToRemove.push('email');

    try {
      await AsyncStorage.multiRemove(fieldsToRemove);
    } catch (error) {
      throw "Error logging out";
    }

    return true;
  }

}

API.initialize();


/**
 * This was an attempt to fix issues related to hot reloads losing API state. Needs work
 */
if (__DEV__ && false) {
  setTimeout(() => {
    setInterval(async () => {
      if (!API.menuData && !API._polling) {
        // Fix shit here
        await API.loadFromCache();
        if (API.config) {
          await API.setConfig(API.config.id);
        }
      } else {

      }
    }, 1000);
  }, 10000);
}


/** This function checks for bundle updates every 15 minutes, but only if there are no open orders and there's been
 * no activity for the past 5 minutes. [BUNDLE UPDATES NOT CURRENTLY WORKING SINCE WE MOVED AWAY FROM MANAGED EXPO BUILD]
 * /
 if(Platform.OS === 'android') {
  // Check right away:
  //HelperFunctions.checkForBundleUpdates(status => {}, true);

  // todo: Change setInterval to Timer.setInterval for Android
  // Continue checking every 15 minutes:
  Timer.setInterval(async () => {
    let hasOpenOrders = false;
    if (API.config?.kds_stations.length) {
      hasOpenOrders = API.config.kds_stations.reduce((orders, station) => orders.concat(station.orders), []).filter(o => o.kds_open).length > 0;
    } else {
      hasOpenOrders = Object.values(API._orders).filter(o => o.kds_open).length > 0;
    }
    let noActivity = moment().diff(API.lastTouch, 'minutes') > 5;

    if (!hasOpenOrders && noActivity) {
      // HelperFunctions.checkForBundleUpdates(status => {}, true);
    }

  }, 15 * 60 * 1000);
}
 */


// Eliminates require cycle when importing API in Models;
Model.api = API;
//AppUpdate.API = API;

export default API;
