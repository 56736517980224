import _ from 'lodash';
import React, {Component} from "react";
import {ActivityIndicator, FlatList, StyleSheet, Text, View} from "react-native";
import API from "../../api";
import {Colors, Typography} from "../../styles";
import ConsumerTab from "./ConsumerTab"


class ConsumerTabList extends Component {

  static defaultProps = {
    search: ''
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedTabs: [],
      tabs: this._getTabsList(), // Sort by start date
      loading: false
    }
  }

  componentDidMount() {
    this._tabsListener = API.on('tabs', (updatedTabs) => {
      const newTabs = this._getTabsList();
      if (updatedTabs[0]?._local || newTabs.length === this.state.tabs.length) {
        this.setState({
          tabs: newTabs
        })
      } else {
        this.setState({loading: true});
        setTimeout(() => this.setState({
          loading: false,
          tabs: newTabs
        }), 2000)
      }
    })
  }

  componentWillUnmount() {
    this._tabsListener?.remove();
  }


  sortTabs = (tabList) => {
    return tabList.sort((a, b) => b.start_date.diff(a.start_date));
  }

  render() {
    const {tabs, loading} = this.state;
    const {search} = this.props;
    const searchTabs = tabs.filter(tab => !!tab.tab_name && tab.tab_name?.includes(search) || tab.card_last_4?.includes(search))
    return (
      <>
        {
          loading &&
          <View style={[styles.incomingTabAlert]}>
            <ActivityIndicator size={"small"} color={Colors.primary}/>
          </View>
        }
        <FlatList
          data={searchTabs}
          renderItem={this._renderTab}
          keyExtractor={item => item.id}
          ListEmptyComponent={this.renderEmptyMessage}
        />
      </>
    );
  }

  _getTabsList = () => {
    const {location} = this.props;
    if (!location) return Object.values(API._tabs);

    let openTabs = this.sortTabs(location.open_tabs);
    let closedTabs = this.sortTabs(location.closed_tabs);
    let futureTabs = this.sortTabs(location.future_tabs);
    return openTabs.concat(closedTabs, futureTabs);
  }

  _pullToRefresh = () => {
    this.setState({
      loading: true,
      tabs: this._getTabsList()
    });
  }

  reSortTabs = () => {
    let {tabs} = this.props;
    this.setState({
      tabs: this.sortTabs(tabs)
    })
  }

  renderEmptyMessage = () => {
    return (
      <View style={{padding: 20, flex: 1}}>
        <Text style={[Typography.header2, {textAlign: 'center', marginBottom: 20}]}>Empty</Text>
        <Text style={{textAlign: 'center'}}>No tabs found.</Text>
      </View>
    );
  }

  _renderTab = ({item}) => {
    let {location} = this.props;
    return (
      <ConsumerTab
        location={location}
        tab={item}
      />
    );
  }
}

export default ConsumerTabList;

const styles = StyleSheet.create({
  incomingTabAlert: {
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: Colors.gray1,
    borderStyle: "dashed",
    backgroundColor: Colors.white,
    margin: 10,
    height: 40,
  },
});

