import React from 'react';
import {
  Platform,
  StyleSheet,
  View,
  ActivityIndicator,
  TextInput,
  Dimensions,
} from 'react-native';
import {Switch, Text} from "native-base"
import * as Animatable from 'react-native-animatable';
import API from '../../api';
import PinButton from './PinButton';
import PinDigit from './PinDigit';
import {TextL} from "../Text";
import PropTypes from 'prop-types';
import HelpIcon from "../HelpIcon";
import {Colors} from "../../styles";
import AsyncStorage from "@react-native-community/async-storage";

const PINLENGTH = 4;

export default class PinPad extends React.Component {

  static navigationOptions = {
    header: null
  }

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    changeUser: PropTypes.bool,
    style: PropTypes.object,
  }

  static defaultProps = {
    changeUser: false,
    style: {}
  }

  _input = null;

  constructor(props) {
    super(props);

    this._isTouchDevice = Platform.OS !== 'web' || ("ontouchstart" in document.documentElement);

    this.state = {
      currPin: [],
      processing: false,
      useAdminPin: true,
    };
  }

  async componentDidMount() {
    let useAdminPin = JSON.parse(await AsyncStorage.getItem('useAdminPin'));
    this.setState({useAdminPin});
  }

  toggleUseAdminPin = (val) => {
    this.setState({useAdminPin: val})
    AsyncStorage.setItem('useAdminPin', JSON.stringify(val));
  }

  render() {
    const {useAdminPin} = this.state;
    const {style} = this.props;
    let loading = this.state.processing ?
      <View style={styles.loading}><ActivityIndicator size="large" color="white"/></View> : null;

    return (
        <View style={[styles.container, style]}>
          <View style={[styles.pinPad]}>
            {loading}
            <TextL style={{marginBottom: 10}}>Enter Pin</TextL>

            <Animatable.View ref={ref => this.pinScreen = ref} style={styles.displayArea}>
              {this._getPinScreen()}
            </Animatable.View>

            {this._getKeypad()}

            {API.is_admin && (
              <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 10, justifyContent: 'center'}}>
                <Switch value={useAdminPin} onValueChange={this.toggleUseAdminPin}/>
                <Text style={{marginHorizontal: 10}}>Admin Pin</Text>
                <HelpIcon color={Colors.primary} title={'Admin Pin'}
                          message={'Logs you in as an admin instead of an employee'}/>
              </View>
            )}

          </View>
          {!this._isTouchDevice && (
            <TextInput
              nativeID={"pinPadInput"}
              ref={me => this._input = me}
              autoFocus={true}
              onKeyPress={this._onKeyPress}
              onBlur={this._reFocusInput}
              style={styles.hiddenInput}
            />
          )}
        </View>

    );
  }

  _reFocusInput = () => {
    if (this._input) this._input.focus();
  }

  shakeDisplay() {
    this.pinScreen.shake(800);
  }

  _getPinScreen() {
    let screen = [];
    for (let i = 0; i < PINLENGTH; i++) {
      screen.push(<PinDigit key={i} position={i} value={this.state.currPin[i]}/>);
    }
    return screen;
  }

  _getKeypad() {
    return (
      <View style={styles.keyPad}>
        <View style={styles.buttonRow}>
          <PinButton value={1} onPress={this._buttonPressed}/>
          <PinButton value={2} onPress={this._buttonPressed}/>
          <PinButton value={3} onPress={this._buttonPressed}/>
        </View>
        <View style={styles.buttonRow}>
          <PinButton value={4} onPress={this._buttonPressed}/>
          <PinButton value={5} onPress={this._buttonPressed}/>
          <PinButton value={6} onPress={this._buttonPressed}/>
        </View>
        <View style={styles.buttonRow}>
          <PinButton value={7} onPress={this._buttonPressed}/>
          <PinButton value={8} onPress={this._buttonPressed}/>
          <PinButton value={9} onPress={this._buttonPressed}/>
        </View>
        <View style={styles.buttonRow}>
          <PinButton value="" onPress={this._buttonPressed}/>
          <PinButton value={0} onPress={this._buttonPressed}/>
          <PinButton value="Del" onPress={this._buttonPressed}/>
        </View>
      </View>
    )
  }

  _buttonPressed = (value) => {

    if (value === 'Del') {
      this.state.currPin.pop();
    } else {
      if (this.state.currPin.length < 4) {
        this.state.currPin.push(value);
      }
    }

    if (this.state.currPin.length === PINLENGTH) {
      this._verifyPin(this.state.currPin.join(''));
    }

    this.setState({
      currPin: this.state.currPin
    });

  }

  async _verifyPin(pin) {
    let {changeUser, onSuccess} = this.props;
    const {useAdminPin} = this.state;

    this.setState({
      processing: true
    });

    const isAdmin = API.is_admin ? useAdminPin : false;
    let result = await API.checkPin(pin, changeUser, isAdmin);
    this.setState({
      currPin: [],
      processing: false
    });
    if (!result.user) {
      this.shakeDisplay();
    } else {
      onSuccess(result.user);
    }
  }

  _onKeyPress = ({nativeEvent}) => {
    let {key} = nativeEvent;
    if (key === 'Backspace') this._buttonPressed('Del');
    if (key in [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]) {
      this._buttonPressed(parseInt(key));
    }
    if (this._input) {
      this._input.clear();
      this._input.focus();
    }
  }

  clear = () => {
    this.setState({
      currPin: []
    })
  }

  focus = () => {
    if (Platform.OS === 'web') {
      setTimeout(() => {
        let el = document.getElementById('pinPadInput');
        if (el) el.focus()
      });
    }
  }

}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

  },
  pinPad: {
    padding: 30,
    minHeight: 500,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white'
  },
  displayArea: {
    width: '100%',
    minHeight: 50,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  keyPad: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  loading: {
    zIndex: 100,
    backgroundColor: 'rgba(0,0,0,0.2)',
    position: 'absolute',
    flex: 1,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  hiddenInput: {
    position: 'absolute',
    top: -1001
  }

});
