import React from 'react';
import { Text, View, Dimensions, TouchableWithoutFeedback, Platform } from 'react-native';
import {Icon} from 'native-base';
import API from '../api';
import EStyleSheet from "react-native-extended-stylesheet";
import KDSOrdersView from "./KDSOrdersView";
import moment from "moment";
import Colors from "../constants/Colors";
import Clock from "../components/Clock";
import DraggableScrollView from "../components/DraggableScrollView";
import {Loader} from "../components";
import _ from 'lodash';
import FlashingOverlay from "../components/FlashingOverlay";
import KDSFilteredOrdersView from "../components/KDSFilteredOrdersView";
import BackgroundTimer from 'react-native-background-timer';
import NotificationToggle from "../components/TerminalNotifications/NotificationToggle";
import Fontello from "../assets/fonts/Fontello";
import RecallButton from "../components/KDS/RecallButton";

const ONE_MINUTE = 60 * 1000;
const THREE_MINUTES = 60 * 3;

const setIntervalFn = Platform.OS === 'android' ? BackgroundTimer.setInterval.bind(BackgroundTimer) : setInterval;
const clearIntervalFn = Platform.OS === 'android' ? BackgroundTimer.clearInterval.bind(BackgroundTimer) : clearInterval;


export default class KDSView extends React.Component {

  static navigationOptions = {}

  state = {
    loading: true,
    newCurrentOrders: 0,
    newFutureOrders: 0,
    show_future_orders: false,
    isFlashing: false,
    kds_idle_screen: API.handheldDevice.preferences.kds_idle_screen,
    tickerEnabled: true
  }

  componentDidMount() {
    this._mounted = true;
    API.on('orders', this._updateCounts, 'KDSView');
    API.on('menu', this._updateCounts);
    API.on('reset', this._setLoading);
    API.handheldDevice.on('update', this._setIdleScreen);
    if(API.hasPolled)
      this._updateCounts();

    this._inactionListener = API.onInaction(THREE_MINUTES, this.focusMe);
    this._flashPoller = setIntervalFn(this._shouldIFlash, ONE_MINUTE);
    API.on('touch', this._shouldIFlash);
  }

  componentWillUnmount() {
    this._mounted = false;
    API.off('orders', this._updateCounts);
    API.off('touch', this._shouldIFlash);
    API.off('menu', this._updateCounts);
    API.off('reset', this._setLoading);
    API.handheldDevice.off('update', this._setIdleScreen)
    this._inactionListener.remove();
    if(this._flashPoller) {
      clearIntervalFn(this._flashPoller);
      this._flashPoller = null;
    }
  }

  _setLoading = () => this.setState({loading: true});

  focusMe = () => {
    if(!this._mounted) return;
    let {navigation} = this.props;
    // Todo: Only focus KDSView if there's something to see (new current or future orders)
    if(API.handheldDevice.preferences.kds_autofocus && !navigation.isFocused() && this.state.newCurrentOrders) {
      navigation.navigate("KDSViewStack");
      this._folderTabs.setActive(1);
    }
  }

  render() {
    const config = API.getConfig();
    if(!API || !config) return null;

    const futureTabColor = '#ffffff';
    let {kds_idle_screen, show_future_orders, newCurrentOrders, newFutureOrders} = this.state;

    return (
      <View style={{flex: 1}}>
        <Loader shown={this.state.loading}/>
        <FlashingOverlay shown={this.state.isFlashing}/>
        <FolderTabs ref={me => this._folderTabs = me}>
          <View style={{ marginRight: 15, marginLeft: 5, justifyContent: 'center', alignItems: 'center'}}>
            <Fontello name={'bbot-logo'} style={{color: 'white', fontSize: 28}}/>
          </View>
          <FolderTab heading={"Current Orders"} count={newCurrentOrders}>
            <KDSOrdersView
              filterFn={ order => order.kds_open }
              sortField={[order=>order.user_desired_time||order.time]}
              sortDir={["asc"]}
              showCutePicsWhenEmpty={kds_idle_screen}
            />
          </FolderTab>
          { show_future_orders && (
            <FolderTab heading={"Future Orders"} count={newFutureOrders} activeColor={futureTabColor}>
              <KDSOrdersView
                filterFn={ order => order.kds_future }
                sortField={['user_desired_time', 'time']}
                sortDir={["asc"]}
                style={{backgroundColor: futureTabColor}}
                emptyMsg={'No snoozed orders'}
              />
            </FolderTab>
          )}
          <FolderTab heading={"Past Orders"}>
            <KDSOrdersView
              filterFn={ order => order.kds_closed  }
              sortField={"last_modified"}
              sortDir={'desc'}
              emptyMsg={'Past orders will appear here'}
            />
          </FolderTab>
          <FolderTab heading={"Filtered Orders"}>
            <KDSFilteredOrdersView/>
          </FolderTab>
          <TabRight>
            <RecallButton api={API}/>
            <TouchableWithoutFeedback onPress={this._lock}>
              <Icon style={{color: 'white', marginRight: 10}} name={"lock"} type={"FontAwesome"}/>
            </TouchableWithoutFeedback>
            <NotificationToggle/>
            <Clock/>
          </TabRight>
        </FolderTabs>
      </View>
    )
  }

  _updateCounts = async () => {
    let kds_station_orders = this._getKDSOrders();
    let newCurrentOrders = 0;
    let newFutureOrders = 0;
    let show_future_orders = API.menuData?.allow_order_ahead;
    const now = moment();

    kds_station_orders.forEach(order => {
      if(order.kds_future) show_future_orders = true;

      if(order.kds_open && order.status === 'waiting') newCurrentOrders++;
      else if(!order.time_closed && order.snooze_till.isAfter(now)) newFutureOrders++;
    })

    if(Platform.OS === 'web'){
      if(newCurrentOrders > 0)
        document.title = "("+newCurrentOrders+") Bbot Terminal";
      else document.title = "Bbot Terminal";
    }


    this.setState({
      loading: !API.menuLoaded,
      newCurrentOrders: newCurrentOrders,
      newFutureOrders: newFutureOrders,
      show_future_orders
    });
  }

  _getKDSOrders = () => {
    let config = API.getConfig();
    if(!config) return [];

    return config.kds_stations.reduce((orders, station) => {
      if(station) return orders.concat(station.orders);
      return orders;
    }, []);
  }

  _shouldIFlash = () => {
    let kdsOrders = this._getKDSOrders();

    let openOrders = kdsOrders.filter(order => order.kds_open);
    let waitingOrders = kdsOrders.filter(order => order.kds_open && order.status === 'waiting');

    let allOrdersWaiting = openOrders.length === waitingOrders.length;
    let oldestWaitingOrder = _.minBy(waitingOrders, o => o.time);

    if(!oldestWaitingOrder) {
      if(this.state.isFlashing)
        this.setState({isFlashing: false});
      return;
    }

    if (
      ( // All orders are in a waiting state,
        // and the oldest waiting order is over 30 seconds old,
        // and the last interaction with the tablet was over 30 seconds ago:
        allOrdersWaiting &&
        oldestWaitingOrder.time < moment().subtract(30, 'second') &&
        API.lastTouch < moment().subtract(30, 'second')
      ) ||
      (
        // There are some orders that are not waiting, but the oldest 'waiting' order is over 5 minutes old,
        // and no one has touched the tablet in 5 minutes:
        !allOrdersWaiting && oldestWaitingOrder.time < moment().subtract(5, 'minutes') &&
        API.lastTouch < moment().subtract(5, 'minutes')
      )
    ){
      // We should flash:
      if(!this.state.isFlashing){
        if(!this.props.navigation.isFocused()){
          this.focusMe();
        }
        this.setState({
          isFlashing: true
        })
      }
    } else if(this.state.isFlashing){
      this.setState({
        isFlashing: false
      })
    }
  }

  _setIdleScreen = async () => {
    if(this._mounted)
      this.setState({
        kds_idle_screen: API.handheldDevice.preferences.kds_idle_screen
      })
  }

  _lock = () => {
    let {navigation} = this.props;
    navigation.navigate("Pin", {
      forwardTo: 'KDSView'
    });
  }

}

/**
 * Todo: Move this to component.
 */
class FolderTabs extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      activeTab: React.Children.toArray(props.children)[1],
      viewWidth: Dimensions.get('window').width
    }

    this.tabPositions = [];
  }

  _tabs(children = this.props.children){
    return children.filter(child => child?.type?.displayName === 'FolderTab'); //React.Children.map(children, child => child);
  }

  get tabLeft(){
    const {children} = this.props;
    return children[0];
  }
  get tabRight(){
    const {children} = this.props;
    return children.filter(child => child?.type?.displayName === 'TabRight');
  }

  render () {
    const child = React.Children.only(this.state.activeTab.props.children);
    const childView = React.cloneElement(
      child,
      {ref: el => this.tabShown = el}
    );

    return (
      <View style={{flex:1}} onLayout={this._onLayout}>
        { this._tabBar() }
        <View style={{flex: 1}}>
          { childView }
        </View>
      </View>
    )
  }

  _onLayout = ({nativeEvent}) => {
    this.setState({
      viewWidth: nativeEvent.layout.width
    });
  }

  _tabsScroller = () => {
    return (
      <DraggableScrollView horizontal scrollRef={sv => this._scrollview = sv}>
        {
          this._tabs().map((tab, index, tabs) => {
            if (tab?.type?.displayName !== 'FolderTab') return tab;
            let isActive = this.state.activeTab.props.heading === tab.props.heading;
            let showRightBorder = false;
            if (!isActive && index < tabs.length - 1) {
              let next = tabs[index + 1];
              let nextActive = next.key === this.state.activeTab.key;
              if (next && !nextActive) {
                showRightBorder = true;
              }
            }
            return (
              <FolderTab
                key={tab.props.heading}
                heading={tab.props.heading}
                count={tab.props.count}
                activeColor={tab.props.activeColor}
                active={isActive}
                showRightBorder={showRightBorder}
                onLayout={({nativeEvent}) => {
                  this.tabPositions[index] = nativeEvent.layout.x;
                }}
                onPress={() => {
                  this._scrollview.scrollTo({x: this.tabPositions[index]-75})
                  this._setActive(tab)
                }}
              />
            )
          })
        }
      </DraggableScrollView>
    )
  }

  _setActive = (tab) => {
    this.setState({
      loading: true,
      activeTab: tab
    }, () => {
      if(this.tabShown.refresh)
        this.tabShown.refresh(true)
    });
  }

  setActive = (tabIndex) => {
    let {children} = this.props;
    let tab = React.Children.toArray(children)[tabIndex];

    this._setActive(tab);
  }

  /**
   * Formats the TabBar depending on Screen Width
   * @returns {*}
   * @private
   */
  _tabBar = () => {
    let {viewWidth} = this.state;
    const numTabs = this._tabs().length;
    const wrapWidth = numTabs <= 3 ? 800 : 1000;
    if (viewWidth <= wrapWidth) {
      return (
        <View style={[styles.tabBar, {height: 80}]}>
          <View style={{flex: 1}}>
            <View style={{flexDirection: 'row', paddingVertical: 5, justifyContent: 'space-between', alignItems: 'center', flex: 1}}>
              {this.tabLeft}
              {this.tabRight}
            </View>
            <View style={{backgroundColor: Colors.primary}}>
              {this._tabsScroller()}
            </View>
          </View>
        </View>
      )
    } else {
      return (
        <View style={[styles.tabBar, {flexDirection: 'row'}]}>
          {this.tabLeft}
          <View style={{overflow: 'hidden'}}>
            { this._tabsScroller() }
          </View>
          {this.tabRight}
        </View>
      )
    }
  }

}
// Right hand side of the tab bar
const TabRight = (props) => {
  return (
    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginLeft: 5, flex: 1}}>
      { props.children }
    </View>
  )
}
TabRight.displayName = 'TabRight';

const FolderTab = (props) => {
  let { active, heading, count, showRightBorder, onLayout} = props;
  let style = active ? styles.activeTab : null;
  let extraStyle = (active && props.activeColor) ? {backgroundColor: props.activeColor} : null;

  return (
    <TouchableWithoutFeedback onPress={props.onPress}>
      <View style={{position: 'relative', bottom: 0}} onLayout={onLayout}>
        <View style={[styles.tab, style, props.style, extraStyle]}>
          <Text numberOfLines={1} style={active ? styles.activeTabText : styles.tabText}>{heading}</Text>
          { count > 0 && <View style={styles.tabCount}><Text style={styles.tabCountText}>{count}</Text></View> }
        </View>
        {showRightBorder && <View style={styles.tabRightBorder}/>}
        {active && <View style={[styles.tabFlairLeft, extraStyle]}><View style={styles.tabFlairLeftInner}/></View>}
        {active && <View style={[styles.tabFlairRight, extraStyle]}><View style={styles.tabFlairRightInner}/></View>}
      </View>
    </TouchableWithoutFeedback>
  )
}
FolderTab.displayName = 'FolderTab';


const styles = EStyleSheet.create({
  tabBar: {
    paddingLeft: 10,
    backgroundColor: Colors.primary,
  },
  tab: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    minWidth: 150,
    minHeight: 40,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  },
  tabCount: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'red',
    borderRadius: 20,
    height: 20,
    width: 20,
    marginLeft: 20
  },
  tabCountText: {
    fontSize: 13,
    color: 'white'
  },
  tabText: {
    color: 'white'
  },
  activeTabText: {
    color: 'black'
  },
  tabFlairLeft: {
    position: 'absolute',
    width: 8,
    height: 8,
    left: -8,
    bottom: 0,
    backgroundColor: 'white'
  },
  tabFlairLeftInner: {
    width: 8,
    height: 8,
    borderBottomRightRadius: 8,
    backgroundColor: Colors.primary
  },
  tabFlairRight: {
    zIndex: 100,
    position: 'absolute',
    width: 8,
    height: 8,
    right: -8,
    bottom: 0,
    backgroundColor: 'white'
  },
  tabFlairRightInner: {
    zIndex: 101,
    width: 8,
    height: 8,
    borderBottomLeftRadius: 8,
    backgroundColor: Colors.primary
  },
  tabRightBorder: {
    position: 'absolute',
    height: '50%',
    top: '25%',
    right: 0,
    borderRightWidth: 1,
    borderRightColor: 'rgba(255,255,255,0.62)'
  },
  activeTab: {
    position: 'relative',
    zIndex: 0,
    backgroundColor: '#fff',
    //marginBottom: -1,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottomWidth: 1,
    borderColor: 'white'
  },
  stationNames: {
    color: 'white',
    fontWeight: 'bold'
  }
})
