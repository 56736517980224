import Colors from "../../constants/Colors";
import {Text, TouchableWithoutFeedback, View} from "react-native";
import EStyleSheet from 'react-native-extended-stylesheet';
import {Icon} from 'native-base';
import React from "react";

/**
 * SplitItem View
 * @param props
 * @returns {*}
 * @constructor
 */

export const SplitItem = (props) => {
  let {selected, itemPart, onRemoveItem, testID} = props;
  let {seats, label, fraction} = itemPart;
  let modifierString = itemPart.item.getModifierString();
  let unfulfillable = !itemPart.item.is_fulfillable;
  let seatTxt = '';
  if (seats.length) seatTxt = "[Seat #" + seats.join(", #") + "]";
  let style = selected ? {backgroundColor: Colors.primaryLight} : null;
  return (
    <TouchableWithoutFeedback testID={testID} onPress={() => props.onPress()}>
      <View style={[styles.container, style]}>
        <View style={[styles.checkbox]}>
          { selected && <Icon type={"Entypo"} name="check" fontSize={10}/> }
        </View>
        <View style={{flex: 1}}>
          <Text style={{color: unfulfillable ? 'gray':'black'}}>{label}</Text>
          { unfulfillable ? <Text style={{color: unfulfillable ? 'red':'black'}}>Item no longer available.</Text> : null }
          { modifierString ? <Text style={{fontSize:12}}>{modifierString}</Text>:null }
          { seatTxt ? <Text style={styles.seatText}>{seatTxt}</Text> : null }
        </View>
        <View>
          { unfulfillable && <TouchableWithoutFeedback onPress={()=>onRemoveItem(itemPart.item)}><Icon type={"AntDesign"} name={"close"}/></TouchableWithoutFeedback> }
          { !unfulfillable && <Text>{itemPart.getFraction()}</Text> }
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row', padding: 7, alignItems: 'center'
  },
  checkbox: {
    borderColor: 'grey',
    borderWidth: 1,
    width: 15,
    height: 15,
    marginRight: 10,
    alignItems: 'center',
    justifyContent: 'center'
  },
  seatText: {
    fontSize: 12, fontStyle: 'italic', color: '#767676'
  }
});
