import React from 'react';
import { View, StyleSheet, Platform} from "react-native";
import {Container, Content, Header, Button, Text} from "native-base";
import RNRestart from 'react-native-restart';

const ErrorScreen = ({error, componentStack, resetError}) => {
  return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        {Platform.OS === 'android' && (
          <View style={{marginBottom: 15}}>
            <Text>An error has occurred and our support team has been notified.</Text>
          </View>
        )}
        <View style={{alignItems: 'center', justifyContent: 'center'}}>
          <Button danger onPress={() => {
            if(Platform.OS === 'web') window.location.reload();
            else RNRestart.Restart();
          }}>
            <Text style={{color: 'white'}}>Reload App</Text>
          </Button>
        </View>
      </View>
  )
}

export default ErrorScreen;

const styles = StyleSheet.create({
  screen: {

  }
})
