import React from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  View,
  FlatList,
  Dimensions,
  SectionList, TouchableOpacity, TouchableWithoutFeedback,
} from 'react-native';
import _ from 'lodash';
import LocationButton from '../components/LocationButton';
import API from '../api';
import Loader from '../components/Loader';
import ReaderStatus from "../components/ReaderStatus";
import HeaderIconButton from "../components/HeaderIconButton";
import {Location} from "../models";
import {Icon, Input, Item, Button, Text} from "native-base";
import {escapeRegExp, sortAlphaNum} from "../helpers/HelperFunctions";
import Colors from "../constants/Colors";
import Alert from "../components/Alert";
import {SectionGrid} from "react-native-super-grid";
import LocationChooserModal from "../components/LocationChooserModal";
import {LocationFilterModal} from "../components";
import AsyncStorage from "@react-native-community/async-storage";
import NotificationToggle from "../components/TerminalNotifications/NotificationToggle";

const {width} = Dimensions.get('window');
const targetBtnSize = 137;

export default class LocationsScreen extends React.Component {

    static navigationOptions = ({navigation}) => {
        return {
            title: "Locations",
            headerRight: () => (
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <NotificationToggle
                      showLabel={true}
                    />
                    <HeaderIconButton
                      testID={'locationsFilterModalButton'}
                      name={'Filter'}
                      icon={'filter'}
                      onPress={() => {
                        navigation.getParam('showLocationsModal')();
                      }}
                    />
                    <HeaderIconButton
                        testID={'locationsUserButton'}
                        name={API?.currUser ? API.currUser.getName() : 'User'}
                        icon={'user'}
                        type={'FontAwesome'}
                        accessibilityLabel={'userBtn'}
                        onPress={() => {
                            API.clearUser();
                            navigation.navigate("Pin", {
                                forwardTo: 'LocationsScreen'
                            });
                        }}/>
                    <ReaderStatus/>
                </View>
            )
        }
    }

    constructor(props) {
        super(props);

        props.navigation.addListener('willFocus', this._setFocus);
        props.navigation.addListener('willBlur', this._setBlur);
        props.navigation.setParams({'showLocationsModal': this._showLocationsModal});

        this.state = {
            search: '',
            status: 'loading',
            zones: [],
            filteredZones: [],
            showModal: false,
            updated: false,
            orientation: 'portrait',
            columns: Math.floor(width / targetBtnSize) || 1,
            stickyHeaderIndices: [],
            locationsModal: false,
            selectedLocations: Object.keys(API._locations)
        }
    }

    _setFocus = (param) => {
        this._focused = true;
    }
    _setBlur = () => {
        this._focused = false;
    }

    componentDidMount() {
        this._mounted = true;

        this._debouncedRefresh = _.debounce(this._refresh, 50); // only run this once if it's called multiple times in a row.

        API.on('locations', this._locationsUpdate);
        // API.on('orders', this._debouncedRefresh);
        API.on('seated_groups', this._debouncedRefresh);
        API.on('smart_orders', this._debouncedRefresh);

        this._loadSelectedLocations();

        if (API.hasPolled)
            this._locationsUpdate(API.getLocations());
    }

    componentWillUnmount() {
        this._mounted = false;

        API.off('locations', this._locationsUpdate);
        //   API.off('orders', this._debouncedRefresh);
        API.off('seated_groups', this._debouncedRefresh);
        API.off('smart_orders', this._debouncedRefresh);
    }

    _getZoneData = () => {
        let zone_data = [];
        _.forEach(Location.LocationsByZone(), (locations, zone) => {
            zone_data.push({
                title: zone,
                data: _.orderBy(locations, ['has_orders', 'has_guests', 'shortId'], ['desc', 'desc', (a, b) => sortAlphaNum(a, b)])
            });
        });
        zone_data = _.orderBy(zone_data, 'title');
        return {
            zones: zone_data,
            filteredZones: this._filteredLocations(this.state.search, zone_data)
        }
    }

    _showLocationsModal = () => {
      this.setState({
        locationsModal: true
      })
    }

    _filteredLocations = (search, zones) => {
        if(!zones) zones = this.state.zones;
        const {selectedLocations} = this.state;
        let filteredLocations = [];

        search = escapeRegExp(search);
        let regEx = new RegExp(`${search}`, 'gi');
        zones.forEach(zone => {
            let items = zone.data.filter(i => selectedLocations.includes(i.id) && (i.locationName+'|'+i.shortId).search(regEx) !== -1);
            if(items.length) filteredLocations.push({title: zone.title, data: items})
        })
        if(!filteredLocations.length) {
          let title = search ? `No Results for '${search}'` : 'No Locations Selected'
            return [
                { title, data: [], hideActions: true}
            ]
        }
        return filteredLocations;
    }

    _locationsUpdate = (locations) => {
        let zoneData = this._getZoneData();
        if (this._mounted) {
            this.setState({
                status: locations.length ? 'good' : 'noResults',
                ...zoneData
            });
        } else {
          console.log('trying to update unmounted component (LocationsScreen)')
        }
    }

    _refresh = () => {
        if (!this._mounted) return;
        let zoneData = this._getZoneData();
        this.setState({
            updated: !this.state.updated,
            ...zoneData
        })
    }

    render() {
        // Todo: if(API.currentScreen !== 'LocationsScreen') return null;
        let {status, updated, filteredZones, search, selectedLocations} = this.state;
        const totalLocations = Object.keys(API._locations).length;

        return (
            <View style={[styles.container]} testID={'locationsScreen'}>
                <View style={{flex: 1}} onLayout={this._onLayout}>
                    <View>
                        <Item style={{paddingLeft: 10}}>
                            <Icon name={"md-search"}/>
                            <Input
                              value={search}
                              placeholder={"Search Locations"}
                              onChangeText={this._filter}
                              testID={'locationsSearchInput'}
                            />
                            {selectedLocations.length !== totalLocations && <Text note>{selectedLocations.length}/{totalLocations}</Text> }
                            <Button
                              transparent
                              icon
                              onPress={() => this.setState({search: '', filteredZones: this._filteredLocations()})}
                              disabled={!search}
                              testID={'locationsClearSearchButton'}>
                                <Icon name={'close'}/>
                            </Button>
                        </Item>
                    </View>
                    {status === 'loading' && <Loader/>}
                    {
                        status === 'noResults' &&
                        <Text style={{margin: 10, fontWeight: 'bold'}}>No Locations Available</Text>
                    }
                    <SectionGrid
                        testID={'locationsGridListing'}
                        itemDimension={150}
                        spacing={10}
                        sections={filteredZones}
                        renderItem={this.renderItem}
                        renderSectionHeader={this._renderZoneHeader}
                        stickySectionHeadersEnabled
                        extraData={this.state.search}
                        style={{flex: 1}}
                    />
                </View>
              {this.state.locationsModal && (
                <LocationFilterModal
                  headerTitle={"Select your tables"}
                  onCancel={() => this.setState({locationsModal: false})}
                  onSave={this._saveSelectedLocations}
                  locations={this.state.selectedLocations}
                />
              )}
            </View>
        );
    }

    _onLayout = () => {
        let {width, height} = Dimensions.get('window');
        const numColumns = Math.floor(width / targetBtnSize) || 1;
        this.setState({
            columns: numColumns,
            orientation: (width > height ? 'landscape' : 'portrait')
        });
    }

    _renderZoneHeader = ({section: {title, hideActions}}) => (
        <View testID={`locationsZoneHeader-${_.capitalize(title)}`} style={styles.zoneHeader}>
            <Text style={styles.zoneText}>{_.capitalize(title)}</Text>
            {!hideActions && (
                <Button
                    transparent
                    testID={`locationsZoneHeaderClearAllGuests-${_.capitalize(title)}`}
                    disabled={this.state['zone_' + title + '_clearing']}
                    onPress={() => this._clearZoneLocations(title)} style={{margin: 0, padding: 0, height: 20}}>
                    <Text style={{color: Colors.primary}}>Clear All Guests</Text>
                </Button>
            )}
        </View>
    )

    renderItem = ({item, index}) => {
      return (
        <LocationButton
          location={item}
          onPress={this.onLocationPress}
          onLongPress={this.onLocationLongPress} // during dev, if device clock doesn't match system clock, this can fire early
        />
      );
    }

    onLocationPress = (item) => {
        this.props.navigation.navigate("LocationOverview", {location: item});
    }

    onLocationLongPress = (item) => {
        let {navigate} = this.props.navigation;

        // For now, do nothing
        navigate('GuestManager', {location: item});
    }

    _filter = (text) => {
        this.setState({
            search: text,
            filteredZones: this._filteredLocations(text)
        })
    }

    _clearZoneLocations = async (zone_for_reports) => {
        let key = 'zone_' + zone_for_reports + '_clearing';
        this.setState({
            [key]: true
        })
        // Confirm:
        Alert.alert(
            "Confirm",
            "Are you sure you want to clear all seated groups from this zone?",
            [
                {
                    text: "Cancel", style: "cancel", onPress: () => {
                        this.setState({[key]: false})
                    }
                },
                {
                    text: "Continue", onPress: async () => {
                        let response = await API.clearZoneGuests(zone_for_reports);
                        this.setState({[key]: false})
                    }
                }
            ]
        )
    }

    _saveSelectedLocations = (locations) => {
      API._selectedLocations = locations;
      AsyncStorage.setItem('selected_locations', JSON.stringify(locations))
      this.setState({
        selectedLocations: locations,
        locationsModal: false
      }, this._refresh);
    }

    _loadSelectedLocations = async () => {
      const selectedLocations = await AsyncStorage.getItem('selected_locations');
      if(selectedLocations) {
        this.setState({selectedLocations: JSON.parse(selectedLocations)}, this._refresh)
        API._selectedLocations = selectedLocations;
      }
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    row: {
        flex: 1,
        alignItems: 'center'
    },
    loadingContainer: {
        flex: 1,
        justifyContent: 'center'
    },
    zoneHeader: {
        flexDirection: 'row',
        padding: 10,
        backgroundColor: 'white',
        borderTopWidth: 1,
        borderTopColor: '#ccc',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    zoneText: {
        fontSize: 16,
        fontWeight: 'bold'
    }
});
