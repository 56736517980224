/**
 * DateTimePickerField.js
 *
 * A simple Date/Time Picker Component which displays the selected date and time in a touchable field.
 *
 * @author Gilles St-Cyr
 * @date 2020-01-09
 */
import React, {Component} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {TouchableWithoutFeedback, View, Platform, Pressable} from "react-native";
import {Icon, Text} from "native-base";
import DateTimePicker from "@react-native-community/datetimepicker";
import {offsetInput, offsetOutput} from "../helpers/DateTimePickerHelper";
import {Colors} from "../styles";

export default class DateTimePickerField extends Component {

  static defaultPickerOptions = {
    is24Hour: false,
    mode: 'datetime'
  }

  static propTypes = {
    value: PropTypes.any,
    format: PropTypes.string,
    icon: PropTypes.string,
    pickerOptions: PropTypes.object
  }

  static defaultProps = {
    value: null,
    icon: 'calendar',
    pickerOptions: {}
  }

  state = {
    showPicker: false,
    formattedVal: null,
  }

  static defaultFormats = {
    date: 'MMM D',
    time: 'h:mmA',
    datetime: 'MMM D, h:mmA'
  }

  render() {
    let {pickerOptions, format, icon, value} = this.props;
    let options = {...DateTimePickerField.defaultPickerOptions, ...pickerOptions};
    if(!format) format = DateTimePickerField.defaultFormats[options.mode];

    let date = value ? moment(value) : moment();
    const style = value ? {} : {color: Colors.darkGray}

    return (
      <View style={{flex: 1, alignItems: 'flex-start', width: '100%', justifyContent: 'center' }}>
        <TouchableWithoutFeedback onPress={this.showDatePicker}>
          <View style={{
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Text style={[{marginRight: 10, flex: 1}, style]}>{ value ? date.format(format) : 'Select Date/Time'}</Text>
            { !!value && (
              <Pressable onPress={this._clearValue}>
                <Icon name={'clear'} type={'MaterialIcons'} style={{marginTop: 0}}/>
              </Pressable>
            )}
            <Icon name={icon} style={{marginTop: 0}}/>
          </View>
        </TouchableWithoutFeedback>
        {this.state.showPicker && (
          <DateTimePicker
            onChange={this.handleDateChange}
            value={offsetInput(date.toDate())}
            { ...options }
          />
        )}
      </View>
    )
  }

  showDatePicker = () => {
    this.setState({ showPicker: true } );
  }

  _clearValue = () => {
    const {onChange} = this.props;
    onChange(null);
  }

  handleDateChange = (event, date) => {
    const {onChange} = this.props;

    this.setState({
      showPicker: false
    });

    if(event.type === 'dismissed') return;

    if(date) {
      onChange(moment(offsetOutput(date)));
    }
  }
}
