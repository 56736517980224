import React from 'react';
import {View, Text, TouchableWithoutFeedback, Modal} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import Colors from '../constants/Colors';
import PropTypes from 'prop-types';
import {Icon} from 'native-base';

export default class KeypadModal extends React.Component {

  static propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    overwrite: PropTypes.bool // Whether or not to overwrite the current number
  };

  static defaultProps = {
    title: "Enter number",
    visible: false,
    minValue: 0,
    onClose: () => {},
    overwrite: true
  };

  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
      overwrite: true,
      number: [],
      error: null
    }
  }

  componentDidMount() {
    let number = [];
    if (this.props.value) {
      number = this._getNumberArrayFromString()
    }
    this.setState({
      number: number,
      overwrite: this.props.overwrite
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.visible !== prevProps.visible){
      if(this.props.visible){
        this.setState({
          number: this._getNumberArrayFromString(this.props.value.toString())
        })
      } else {
        this.setState({
          overwrite: this.props.overwrite,
          error: null
        })
      }
    }
  }

  _getNumberArrayFromString(string) {
    return Array.from(this.props.value.toString()).map(i => parseInt(i));
  }

  render() {
    let {onCancel, onClose, visible, testID} = this.props;

    return (
      <Modal
        // animationType="slide"
        transparent={true}
        visible={visible}
        onRequestClose={onCancel}
        fsClass="fs-unmask"
      >
        <TouchableWithoutFeedback onPress={onCancel}>
          <View style={styles.modal}>
            <TouchableWithoutFeedback onPress={()=>{}}>
              <View testID={testID} style={styles.keypadContainer}>
                <View style={styles.titleArea}>
                  <Text style={styles.titleText}>{this.props.title || ""}</Text>
                </View>
                <View style={styles.displayArea}>
                  <View style={{width: 10}}/>
                  {this.state.number.map((n, i) => (
                    <Text testID={'keypadModalDigitDisplay'} key={"digit_" + i}
                          style={[styles.displayDigit, this.state.overwrite ? styles.overwrite : null]}>{n}</Text>
                  ))}
                </View>
                <View style={{flexDirection: 'row'}}>
                  <KeyButton testID={'keypadModal1'} text="1" onPress={this._numberPressed}/>
                  <KeyButton testID={'keypadModal2'} text="2" onPress={this._numberPressed}/>
                  <KeyButton testID={'keypadModal3'} text="3" onPress={this._numberPressed}/>
                  <KeyButton testID={'keypadModalDel'} text={<Icon type={"Ionicons"} name={"md-backspace"} size={20}/>} value="Del"
                             onPress={this._numberPressed}/>
                </View>
                <View style={{flexDirection: 'row'}}>
                  <KeyButton testID={'keypadModal4'} text="4" onPress={this._numberPressed}/>
                  <KeyButton testID={'keypadModal5'} text="5" onPress={this._numberPressed}/>
                  <KeyButton testID={'keypadModal6'} text="6" onPress={this._numberPressed}/>
                  <KeyButton testID={'keypadModalClear'} text="Clear" onPress={this._numberPressed}/>
                </View>
                <View style={{flexDirection: 'row'}}>
                  <KeyButton testID={'keypadModal7'} text="7" onPress={this._numberPressed}/>
                  <KeyButton testID={'keypadModal8'} text="8" onPress={this._numberPressed}/>
                  <KeyButton testID={'keypadModal9'} text="9" onPress={this._numberPressed}/>
                  <KeyButton text=""/>
                </View>
                <View style={{flexDirection: 'row'}}>
                  {
                    onCancel ?
                      <KeyButton testID={'keypadModalCancel'} text={<Icon type={"Ionicons"} name={"arrow-back"} size={20}/>} onPress={onCancel}/>
                      : <KeyButton text=""/>
                  }
                  <KeyButton testID={'keypadModal0'} text="0" onPress={this._numberPressed}/>
                  <KeyButton text=""/>
                  <KeyButton testID={'keypadModalGo'} text="Go" onPress={this._goPressed}/>
                </View>
                {!!this.state.error &&
                <View testID={'keypadModalErrorContainer'} style={styles.errorContainer}><Text style={styles.errorMsg}>{this.state.error}</Text></View>}
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }

  _numberPressed = (pressed) => {
    let {number} = this.state;
    let error = null;

    switch (pressed) {
      case "Del":
        if (this.state.overwrite) number = [];
        else number.pop();
        break;
      case "Clear":
        number.length = 0;
        break;
      default:
        if (this.state.overwrite) number = [];
        let newNumber = parseInt(number.concat(pressed).join(''));
        if (this._validate(newNumber))
          number.push(pressed);
        else {
          error = "Number must be between " + (this.props.minValue || 0) + " and " + this.props.maxValue;
        }
    }
    this.setState({
      number,
      error,
      overwrite: false
    })
  }

  _getNumber() {
    return this.state.number.length ? parseInt(this.state.number.join('')) : 0;
  }

  _validate(number, checkMin) {
    const {maxValue, minValue} = this.props;

    if (maxValue && number > maxValue) return false;
    if (checkMin && number < minValue) return false;
    return true;

  }

  _goPressed = () => {
    let number = this._getNumber();

    if (!this._validate(number, true)) {
      this.setState({
        overwrite: true,
        error: "Number must be between " + (this.props.minValue || 0) + " and " + this.props.maxValue
      });
    } else {
      this.props.onClose(number);
    }
  }
}


class KeyButton extends React.PureComponent {

  static propTypes = {
    onPress: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
  }

  render() {
    let {onPress, text, value, testID} = this.props;
    if (!text) return (<View style={styles.KeyButtonBox}/>);
    return (
      <TouchableWithoutFeedback
        testID={testID}
        onPress={() => {
          if(onPress) onPress(value || text)
        }}
        accessible={true}
        accessibilityLabel={'kb_' + text}>
        <View style={[styles.KeyButtonBox, styles.KeyButton]}>
          <Text style={{fontSize: 20}}>{text}</Text>
        </View>
      </TouchableWithoutFeedback>
    )
  }
};

const styles = EStyleSheet.create({
  modal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "rgba(0,0,0,0.5)"
  },
  keypadContainer: {
    backgroundColor: '#fff',
    padding: 30,
    paddingTop: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleArea: {
    alignSelf: 'stretch'
  },
  titleText: {
    fontSize: 18
  },
  displayDigit: {
    fontSize: 20,
    fontWeight: 'bold',
    padding: 5
  },
  overwrite: {
    backgroundColor: '#44daff',
    color: 'white',
  },
  errorContainer: {
    marginTop: 15
  },
  errorMsg: {
    color: Colors.errorBackground
  },
  displayArea: {
    height: 50,
    alignSelf: 'stretch',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#222',
    borderTopWidth: 1,
    borderTopColor: '#222',
  },
  KeyButtonBox: {
    width: 75,
    height: 75,
    margin: 8
  },
  KeyButton: {
    backgroundColor: '#ccc',
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 4,
    borderWidth: 0.5,
    borderColor: Colors.gray
  },
  KeyButtonText: {
    color: Colors.highlight
  }
});
