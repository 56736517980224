import React from 'react';
import {TouchableWithoutFeedback, View} from "react-native";
import {Icon, Text} from "native-base";
import Colors from "../constants/Colors";
import EStyleSheet from "react-native-extended-stylesheet";

const HeaderIconButton = ({name, icon, onPress, type, disabled, accessibilityLabel, testID='headerIcon'}) => {
  let color = disabled ? Colors.darkGray : Colors.light;
  if(disabled) {
    onPress = () => {};
  }

  return (
    <TouchableWithoutFeedback testID={testID} onPress={onPress} accessibile={true} accessibilityLabel={accessibilityLabel||name}>
      <View style={styles.container}>
        <Icon
          name={icon}
          type={type}
          style={[styles.icon, {color}]}
        />
        <Text numberOfLines={1} style={[styles.label, {color}]}>{name}</Text>
      </View>
    </TouchableWithoutFeedback>
  )
}

export default HeaderIconButton;

const styles = EStyleSheet.create({
  container: { marginHorizontal : 10, alignItems: 'center' },
  icon: { fontSize: 28, height: 30 },
  label: { fontSize: 12 }
})
