import React from 'react';
import {Image} from 'react-native';
import {StatusIcons} from "../constants/Constants";
import Colors from "../constants/Colors";

const StatusIcon = ({status, size='large', color=Colors.statusIcon, style={}}) => {
  let dimension = size === 'large' ? 32 : 16;
  const defaultStyle = { width: dimension, height: dimension }
  let source = StatusIcons[size][status];

  return (
    <Image
      resizeMode={'contain'}
      style={[defaultStyle, style]}
      source={source}
      fadeDuration={0}
      tintColor={color}
    />
  )
}

export default StatusIcon;
