export default class SavedCard {

  constructor(obj) {
    this.id = obj.id;
    this.type = obj.type;
    this.last4 = obj.last4;
    this.brand = obj.brand;
    this.exp_month = obj.exp_month;
    this.exp_year = obj.exp_year;
    this.name_on_card = obj.name_on_card || "";

    this.default_tip = 0;
  }

  get name() {
    return this.name_on_card;
  }

  get expiry() {
    return this.exp_month + ' / ' + this.exp_year;
  }

  get icon() {
    let key = this.brand?.toLowerCase();
    return IconMap.hasOwnProperty(key) ? IconMap[key] : IconMap['default'];
  }

  getCharge(amount_cents){
    return {
      id: this.id,
      card_id: this.id,
      type: this.type,
      amount_cents: amount_cents
    }
  }
}

export const IconMap = {
  "visa": require('../assets/images/cards/visa.png'),
  "visa (debit)": require('../assets/images/cards/visa.png'),
  "mastercard": require('../assets/images/cards/master-card.png'),
  "american express": require("../assets/images/cards/american-express.png"),
  "discover": require("../assets/images/cards/discover.png"),
  "diners club": require("../assets/images/cards/dinners-club.png"),
  "default": require("../assets/images/cards/default.png")
}
