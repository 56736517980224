import moment from 'moment'
import {tz} from 'moment-timezone';
import API from "../api";

/**
 * The Terminal uses "BbotLLC/datetimepicker" (a fork of "@react-native-community/datetimepicker") for selecting
 * date/time values in a `DateTimePicker` component. By default, the library uses the device's local timezone, and the
 * library includes timezone offset options only for iOS and Windows devices.
 *
 * To ensure that the `DateTimePicker` uses the server's timezone uniformly across all devices, use these helpers to:
 * (1) offset the `value` sent to the `DateTimePicker`, and (2) offset the `date` returned from the `DateTimePicker`.
 */

const _getTimezoneHoursOffset = () => {
  const localUTCOffset = moment().local().utcOffset();
  const serverUTCOffset = tz(moment(), API.main_customer.timezone).utcOffset();
  return (serverUTCOffset - localUTCOffset) / 60;
}

export const offsetInput = (date) => {
  const offset = _getTimezoneHoursOffset();
  return moment(date).add(offset, 'hours').toDate();
}

export const offsetOutput = (date) => {
  const offset = _getTimezoneHoursOffset();
  return moment(date).subtract(offset, 'hours').toDate();
}
