import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {View, Animated, Platform} from "react-native";
import Colors from "../constants/Colors";
import {Sounds} from "../constants/Constants"
import API from "../api";
import {Audio} from "expo-av";
import BackgroundTimer from "react-native-background-timer";


const setTimeoutFn = Platform.OS === 'android' ? BackgroundTimer.setTimeout.bind(BackgroundTimer) : setTimeout;
const clearTimeoutFn = Platform.OS === 'android' ? BackgroundTimer.clearTimeout.bind(BackgroundTimer) : clearTimeout;

export default class FlashingOverlay extends Component {

  static propTypes = {
    shown: PropTypes.bool,
    color: PropTypes.string,
  }

  static defaultProps = {
    shown: false,
    color: Colors.primary
  }

  constructor(props) {
    super(props);

    this.state = {
      opacity: new Animated.Value(0.1)
    }
  }

  initAudioPlayer = async () => {
    if(this.player) await this.player.unloadAsync();

    this.player = new Audio.Sound();
    try {
      this.player.setOnPlaybackStatusUpdate(this._audioStatusUpdate);
      await this.player.loadAsync(Sounds.alarm);
    } catch (e) {
      console.log('alarm sound failed to load');
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {shown} = this.props;
    if(prevProps.shown !== shown){
      if (shown) {
        this._getAnimation().start();
        this.playNotification();
      } else {
        this.state.opacity.stopAnimation();
        clearTimeoutFn(this.nextPlay);
        if(this.player && this.player._loaded) {
          this.player.stopAsync();
        }
      }
    }
  }


  componentWillUnmount() {
    clearTimeoutFn(this.nextPlay);
  }

  _getAnimation = () => {
    return Animated.loop(
      Animated.sequence([
        Animated.timing(
          this.state.opacity,
          {
            toValue: 1,
            duration: 250,
            useNativeDriver: Platform.OS !== 'web'
          }
        ),
        Animated.timing(
          this.state.opacity,
          {
            toValue: 0.3,
            duration: 250,
            useNativeDriver: Platform.OS !== 'web'
          }
        ),
        Animated.delay(250)
      ])
    )
  }

  playNotification = async () => {
   // if(!this.player)
     // await this.initAudioPlayer();

    try {
      if(API.userHasInteracted)
        await API.playAlarmSound();
    } catch(err){
      console.log('Error playing alarm sound', err);
    }

    this.nextPlay = setTimeoutFn(this.playNotification, 10000);
    /*if(!this.player.canPlay){
      console.log('player not ready', this.player.state);
      this.player.prepare(this.playNotification);
      return;
    }

    let config = API.getConfig();
    // get volume level first:
    if (!config.allow_disabling_chirp) {
      let volume = await SystemSetting.getVolume();
      if(volume < config.force_volume_level)
        await SystemSetting.setVolume(config.force_volume_level);
      if (this.player.volume < config.force_volume_level)
       this.player.volume = config.force_volume_level;
    }

    console.log('FlashingOverlay, play, ',this.player.state);
    if(this.player.canPlay)
      this.player.play()*/
  }

  _audioStatusUpdate = status => {
    if(status.didJustFinish){
      this.initAudioPlayer();
    }
  }

  render() {
    let {shown, color} = this.props;
    if (!shown) {
      return null;
    }
    return (
      <Animated.View
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          opacity: this.state.opacity,
          backgroundColor: color,
        }}
        pointerEvents={'none'}
      />
    );
  }
}
