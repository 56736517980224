import React from "react";
import {Text, View} from "react-native";
import OrderHelper from "../../../helpers/OrderHelper";

export default class KDSItemModifiers extends React.PureComponent {

  constructor(props) {
    super(props);
    let mods = this.props.mods || [];

    this.state = {
      sortedMods: OrderHelper.sortModifiers(mods)
    }
  }

  render() {
    let {mods, color} = this.props;
    if (!mods) return null;

    return (
      <View style={{marginLeft: 8}}>
        {
          this.state.sortedMods.map((mod) => (
            <View key={mod.id}>
              <Text style={{fontSize: 13, color: color}}>{mod.modifier?.name_for_bartender || mod.name}</Text>
              <KDSItemModifiers mods={mod.mods} color={color}/>
            </View>
          ))
        }
      </View>
    )
  }
}
