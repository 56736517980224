import Model from './Model';
import UserInfo from "./UserInfo";
import moment from 'moment';

export default class User extends Model {

  admin_note = '';

  access_token = '';
  customer = null;
  level = null;   // [Manager, Owner, Server, Bartender]
  pin_number = null;
  subscriptions = [];
  has_mfa_enabled = false;
  user = null;

  _field_map = {
    user: user => new UserInfo(user)
  }

  constructor(obj) {
    super();
    this.update(obj);
  }

  isManager() {
    return ["Owner", "Manager", "Admin"].includes(this.level);
  }

  canRefund() {
    // refund_requires_manager_pin
    return ["Owner", "Manager", "Admin"].includes(this.level);
  }

  getName() {
    return [this.user.first_name, this.user.last_name].join(' ');
  }

}


