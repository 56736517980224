import _ from 'lodash';
import {FlatList, Platform, TouchableWithoutFeedback, View, Modal} from "react-native";
import {Button, Icon, ListItem, Text} from "native-base";
import React from "react";
import EStyleSheet from "react-native-extended-stylesheet";
import NotificationView from "./NotificationView";
import API from "../../api";
import Colors from "../../constants/Colors";

/**
 * TODO: Filter notifications based on filtered locations on Locations screen
 * - Clear all
 */

export default class NotificationModal extends React.Component {
  static instance = null;
  static show = (tallHeader) => NotificationModal.instance.show(tallHeader);
  static hide = () => NotificationModal.instance.hide();
  static toggle = () => NotificationModal.instance.toggle();

  state = {
    mode: 'full',
    visible: false,
    notices: [],
    page: 1
  }

  constructor(props) {
    super(props);
    if (!NotificationModal.instance || !NotificationModal.instance._mounted) NotificationModal.instance = this;
  }

  componentDidMount() {
    this._mounted = true;
    this._listener = API.on('notices', this._update);
  }
  componentWillUnmount() {
    this._mounted = false;
    this._listener.remove();
  }

  toggle = () => {
    if(!this._mounted) return
    const {visible} = this.state;
    this.setState({
      visible: !visible
    })
  }

  show = (tallHeader) => {
    if(!this._mounted) return
    this.setState({
      visible: true,
      tallHeader
    })
  }

  hide = () => {
    if(!this._mounted) return
    this.setState({
      visible: false
    })
  }

  onLayout = ({nativeEvent: {layout: {width, height}}}) => {
    this.setState({
      mode: width <= 640 ? 'full' : 'popup'
    });
  }

  _update = () => {
    if(!this._mounted) return
    const filteredNotices = _.orderBy(Object.values(API._notices).filter(n => n.status !== 'dismissed'), ['time_created'],['desc']);
    this.setState({
      notices: filteredNotices
    });
  }

  render() {
    const {mode, visible, notices, tallHeader} = this.state;
    return (
      <Modal visible={visible} transparent={true} onRequestClose={this.hide}>
        <TouchableWithoutFeedback onPress={this.hide}>
          <View style={styles.layout} onLayout={this.onLayout}>
            <View style={[styles.modal, styles[mode], mode === 'full' ? {} : { top: tallHeader ? 64 : 40 }]}>
              <View style={styles.header}>
                <Text style={{color: 'white'}}>Messages</Text>
                <Button transparent icon light onPress={this.hide}>
                  <Icon name={'close'} style={{color: 'white'}}/>
                </Button>
              </View>
              <FlatList
                data={notices}
                renderItem={this._renderNotification}
                ListEmptyComponent={this._noMessages}
                keyExtractor={item => 'notice-'+item.id}
              />
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    )
  }

  _renderNotification = ({item}) => {
    const {mode} = this.state;
    return (
      <NotificationView
        notification={item}
        mode={mode}
      />
    )
  }

  _noMessages = () => {
    return (
      <ListItem first last>
        <Text>No Messages</Text>
      </ListItem>
    )
  }
}

const styles = EStyleSheet.create({
  layout: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  modal: {
    backgroundColor: 'white'
  },
  header: {
    backgroundColor: Colors.secondary,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 10
  },
  full: {
    width: '90%',
    height: '90%',
    ... Platform.OS === 'web' ? { boxShadow: '2px 2px 0 rgba(0,0,0,0.3)' } : {elevation: 1}
  },
  popup: {
    position: 'absolute',
    width: 640,
    borderWidth: 2,
    right: 2,
    zIndex: 101,
    maxHeight: '80%'
  }
})
