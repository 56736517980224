import React, {Component} from 'react';
import {View, Text, TouchableWithoutFeedback, ScrollView, Modal} from 'react-native';
import EStyleSheet from "react-native-extended-stylesheet";
import PropTypes from 'prop-types';
import Colors from "../constants/Colors";

let globalInstance = null;

export default class ActionSheet extends Component {

  static propTypes = {}

  static show = (options, onSuccess) => {
    if(globalInstance){
      globalInstance.show(options, onSuccess);
    } else {
      // Error
    }
  }

  static hide = () => {
    if(globalInstance) globalInstance.hide();
  }

  state = {
    visible: false,
    config: {},
    onSuccess: ()=>{},
    onCancel: ()=>{}
  }

  constructor(props) {
    super(props);
    globalInstance = this;
  }

  render() {
    let {config, visible} = this.state;
    let {title='', options=[]} = config;


    return (
      <Modal visible={visible} onRequestClose={this.hide} transparent>
        <TouchableWithoutFeedback onPress={this.hide}>
          <View style={styles.background}>
            <TouchableWithoutFeedback>
              <View style={styles.modal}>
                <View style={styles.title}>
                  <Text style={styles.titleText}>{title}</Text>
                </View>
                <ScrollView persistentScrollbar={true}>
                  {options.map((option, index) => (
                    <TouchableWithoutFeedback onPress={()=>{this.onChoice(option, index);}} key={'choice_'+index}>
                      <View style={[styles.choice, option.style]}>
                        <Text style={[styles.choiceText]}>
                          {option.text}
                        </Text>
                      </View>
                    </TouchableWithoutFeedback>
                  ))}
                </ScrollView>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }

  show = (options, onSuccess) => {
    this.setState({
      config: options,
      onSuccess,
      visible: true,
    })
  }

  hide = () => {
    let {onCancel} = this.state;
    this.setState({
      visible: false
    });
    onCancel();
  }

  onChoice = (choice, index) => {
    let {onSuccess} = this.state;
    this.setState({ visible: false});
    onSuccess(index, choice);
  }
}

const styles = EStyleSheet.create({
  background: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  modal : {
    backgroundColor: 'white',
    width: '100%',
    maxHeight: '80%',
    minHeight: 100
  },
  title: {
    backgroundColor: Colors.lightGray,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: Colors.gray,
    padding: 15
  },
  titleText: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: 18
  },
  choice: {
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
    padding: 15,
    justifyContent: 'center'
  },
  choiceText: {
    fontSize: 16,
    justifyContent: 'center'
  }
})
