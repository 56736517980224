import React, {useEffect, useRef, useState} from 'react';
import {Icon, Input, Item, Label, Spinner, Text, View} from "native-base";
import Required from "./Required";
import {GooglePlacesAutocomplete} from "react-native-google-places-autocomplete";
import {Platform, LogBox} from "react-native";
import axios from 'axios';
import {styles} from "./styles";
import TextField from "./TextField";
import API from "../../../api";
import {Colors} from "../../../styles";

if (LogBox) {
  LogBox.ignoreLogs([
    'VirtualizedLists should never be nested',
    'React does not recognize the `dataComponent` prop on a DOM element'
  ]);// TODO: Remove when fixed
}

// todo: put these in env file
// todo: lock down the web url to only accept
const googleAPIKey = Platform.OS === 'android' ? "AIzaSyCAuo85yCr_R0rwrMyB5CqPtqFyogchO1I" : ""

const AddressField = ({
                        field,
                        fieldValue,
                        setFieldValue,
                        valid,
                        setValid,
                        highlightError,
                        label,
                        required,
                        cart,
                        ...rest
                      }) => {

  const ref = useRef(null);
  const [touched, setTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [addressDetails, setAddressDetails] = useState({});


  useEffect(() => {
    ref.current?.setAddressText(fieldValue?.formatted_address || "")
  }, []);

  // unfortunately, we can't pass a style object to GooglePlacesAutocomplete, so we need to do it this way:
  const style = (highlightError && !valid) ? {
    backgroundColor: '#ffeaea',
    borderWidth: 2,
    borderColor: '#C00'
  } : {}

  const getAddressPart = (place, type, use_short_name = true) => {
    const part = place.address_components.find((part) => part.types.includes(type))
    return part ? (use_short_name ? part.short_name : part.long_name) : ''
  }

  const extractBbotAddressFields = (place) => {
    const googleAddress = {}
    // Store the number and name separately for validation
    googleAddress.streetNumber = getAddressPart(place, 'street_number')
    googleAddress.streetName = getAddressPart(place, 'route')

    // Build the fields the endpoint expects to receive
    googleAddress.street = googleAddress.streetNumber + ' ' + googleAddress.streetName
    googleAddress.line1 = googleAddress.street
    googleAddress.city =
      getAddressPart(place, 'locality', false) ||
      getAddressPart(place, 'sublocality_level_1', false) ||
      getAddressPart(place, 'postal_town', false)
    googleAddress.state = getAddressPart(place, 'administrative_area_level_1')
    googleAddress.zip = getAddressPart(place, 'postal_code')
    googleAddress.country_code = getAddressPart(place, 'country')
    // Serializing and deserializing the google place object on saves causes the functions to become properties.
    // On a fresh save they are functions, but on reload save, they are properties.
    if (typeof place.geometry.location.lat === 'function') {
      googleAddress.latitude = place.geometry.location.lat()
      googleAddress.longitude = place.geometry.location.lng()
    } else {
      googleAddress.latitude = place.geometry.location.lat
      googleAddress.longitude = place.geometry.location.lng
    }
    googleAddress.formatted_address = place.formatted_address
    return googleAddress
  }

  return (
    <>
      <Item stackedLabel {...rest} accessible={false} {...{[status]: true}}>
        <Label style={[styles.fieldLabel, !valid && touched && styles.invalidFieldLabel]}>
          {label}
          <Required show={required}/>
        </Label>
        <GooglePlacesAutocomplete
          ref={ref}
          placeholder={"Start typing..."}
          fetchDetails={true}
          onPress={(data, details) => {
            const addressObj = extractBbotAddressFields(details);
            setFieldValue({
              ...fieldValue,
              ...addressObj,
              place_id: data.place_id
            })
            setValid(true);
            // Check if address deliverable if driver_delivery
            if (cart && cart.fulfillment_method === 'driver_delivery') {
              setLoading(true);
              setStatus('');
              API.checkDeliverable(cart.customer_id, addressObj)
                .then(response => {
                  setStatus(response.can_deliver ? 'success' : 'error');
                  if(response.can_deliver && !response.message && response.distance){
                    response.message = `Distance: ${response.distance}, Driving Time: ${response.duration}`
                  }
                  setAddressDetails(response);
                  setValid(response.can_deliver);
                }).finally(() => {
                setLoading(false);
              })
            }
          }}
          requestUrl={{
            useOnPlatform: 'web',
            url: API.getURL('GOOGLE_MAPS'),
            headers: {
              Authorization: axios.defaults.headers.common["Authorization"]
            }
          }}
          query={{
            key: googleAPIKey,
            language: 'en'
          }}
          GooglePlacesDetailsQuery={{
            fields: 'formatted_address,address_component,geometry'
          }}
          styles={{
            container: {
              width: '100%',
            },
            textInput: {
              ...style
            }
          }}
          debounce={50}
          textInputProps={{
            autoComplete: 'ignore-this-field',
            autoCompleteType: 'off',
            onBlur: () => {
              setTouched(true);
            },
            onKeyPress: (prop) => {
              // we want to clear line1 when you type, since we only accept choices from the Picker
              setFieldValue({...fieldValue, line1: ""})
              setValid(false);
              setStatus(null);
              setAddressDetails({});
            }
          }}
          renderRightButton={() => {
            if (!(loading || status)) return null;
            let child;
            if (loading) child = <Spinner style={{height: 'auto'}} size={'small'}/>
            else child = status ? (
              <Icon
                name={status === 'success' ? 'checkmark-circle' : 'close-circle'}
                style={{color: status === 'success' ? Colors.success : Colors.error}}
              />) : null
            return (
              <View style={{position: 'absolute', right: 8, top: 0, bottom: 3, justifyContent: 'center'}}>
                {child}
              </View>
            )
          }}
          enablePoweredByContainer={false}
        />
        { !!addressDetails.message && <Text style={{fontSize: 14, color: addressDetails.can_deliver ? '#0a6e00' :'#ff6363'}}>{addressDetails.message}</Text>}
      </Item>
      <TextField
        label={"Apt/Unit"}
        valid={true}
        setValid={() => {
        }}
        setFieldValue={(text) => {
          setFieldValue({
            ...fieldValue,
            line2: text
          })
        }}
        fieldValue={fieldValue?.line2 || ''}
      />
      <TextField
        label={"Additional Delivery Instructions"}
        valid={true}
        setValid={() => {
        }}
        setFieldValue={(text) => {
          setFieldValue({
            ...fieldValue,
            delivery_instructions: text
          })
        }}
        fieldValue={fieldValue?.delivery_instructions || ''}
      />
    </>
  )
}

export default AddressField;
