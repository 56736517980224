import React from 'react';
import PropTypes from 'prop-types';
import API from '../api';
import {View, Text, TouchableHighlight, Dimensions, Image} from 'react-native';
import Colors from '../constants/Colors';
import {StatusIcons} from "../constants/Constants";
import StatusIcon from "./StatusIcon";
import _ from 'lodash';
import {FormattedCurrency} from 'react-native-globalize';
import EStyleSheet from 'react-native-extended-stylesheet';
import {CartItem, OrderItem} from "../models";
import OrderItemModifiers from "./OrderItemModifiers";
import CartItemModifiers from "./CartItemModifiers";

// todo: This Should Really be OrderItem|CartItem

export default class MenuItem extends React.Component {

  static propTypes = {
    item: PropTypes.object.isRequired,
    onPress: PropTypes.func,
    selected: PropTypes.bool,
    compact: PropTypes.bool,
    showTaxes: PropTypes.bool,
    current: PropTypes.object
  };
  static defaultProps = {
    compact: false,
    onPress: () => {
    }
  };

  render() {
    let {item, selected, showTaxes, compact} = this.props;

    if (_.isEmpty(item)) {
      return this._noItem();
    }

    let priceEach = (showTaxes ? item.getTotal() : item.getPretaxTotal()) / 100;
    let priceTotal = priceEach * item.qty;
    let lastChange;
    let fulfillable = true;
    let textStyle = {
      //     color: selected ? Colors[item.status] : Colors.dark
    };

    if (item.constructor.name === "OrderItem") {
      lastChange = item.time_modified.fromNow();
    }
    if (item.constructor.name === "CartItem") {
      if (!item.location.fulfillable_items.includes(item.menuItemId)) {
        textStyle.color = 'red';
        fulfillable = false;
      }
    }


    let bodyStyle = {
      backgroundColor: selected ? (item.status === 'refunded' ? '#fffcb6' : Colors.primaryLight) : null
    }; // styles['status_' + item.status] - can maybe look at status index within status_sequence, and have a color scale.


    if (item.status === 'refunded') {
      textStyle.textDecorationLine = 'line-through';
      textStyle.textDecorationStyle = 'solid';
    }

    return (
      <TouchableHighlight
        testID={'menuItem-' + item.getName()}
        accessible={true}
        accessibilityLabel={'oi_' + item.getName()}
        title={"button " + item.id}
        onPress={() => {
          this.props.onPress && this.props.onPress(item)
        }}
        underlayColor='#bbbbbb'
      >
        <View style={[styles.body, bodyStyle]}>
          {this._getImage()}
          <View style={{flex: 1}}>
            <View style={styles.titleRow}>
              {item.status === 'refunded' ? <Text testID={'menuItemRefundedMessage'} style={{marginRight: 5}}>[Refunded]</Text> : null}
              <Text style={[styles.itemTitle, selected ? styles.itemSelectedText : null, textStyle]}>
                {item.getName()}
              </Text>
              <View style={{alignItems: 'flex-end', width: '10%'}}>
                <Text testID={'menuItemQuantity'} style={[styles.amount]}>{item.qty}</Text>
              </View>
              <View testID={'menuItemPriceEach'} style={[styles.amount, {width: '18%',}]}>
                <FormattedCurrency value={priceEach || 0} />
              </View>
              <View testID={'menuItemPriceTotal'} style={[styles.amount, {width: '18%'}]}>
                <FormattedCurrency value={priceTotal || 0} />
              </View>
            </View>
            <View style={styles.details}>
              <View style={styles.detailRow}>
                {!fulfillable && <Text style={{fontStyle: 'italic'}}>No longer available. Remove from cart to continue.</Text>}
                {!compact && MenuItem.getModifiers(item, this.props.current)}
                {!compact && MenuItem.getSpecialInstructions(item)}
                {this._getSeats()}
                {this._getClaimedBy(item)}
              </View>
              <View style={styles.otherInfo}>
                <Text style={styles.timestamp}>{lastChange}</Text>
              </View>
            </View>
          </View>
        </View>

      </TouchableHighlight>
    );
  }

  _getClaimedBy(item) {
    let isMe = item.claimed_by === API.handheldDevice.name;
    let device = isMe ? "this device" : item.claimed_by;
    return item.claimed_by ?
      <Text style={[{fontStyle: 'italic'}, isMe ? {color: Colors.highlight} : null]}>Claimed by {device}</Text>
      : null;
  }

  _getImage() {
    let {item} = this.props;
    if (item.status) {
      return <StatusIcon status={item.status} style={{height: 24, width: 24, marginRight: 5}}/>
    }
  }

  _noItem() {
    return (
      <View style={styles.body}>
        <Text>Loading...</Text>
      </View>
    )
  }

  /**
   *
   * @param {OrderItem | CartItem | unknown} item
   * @param current
   * @param {ItemPart} itemPart
   * @returns {JSX.Element|(unknown[]|JSX.Element)[]}
   */
  static getModifiers(item, current, itemPart) {
    if (item instanceof OrderItem) {
      return <OrderItemModifiers item={item}/>
    } else if (item instanceof CartItem) {
      return <CartItemModifiers current={current} item={item} itemPart={itemPart} />
    }

    console.log(" === If we see this, don't delete below this line === ");
    let modifiers = item.getModifiers();
    if (modifiers) {
      let modifiersWithPrice = modifiers.filter(i => i.pretax_cents > 0);
      let freeModifiers = modifiers.filter(i => i.pretax_cents === 0);
      return [
        modifiersWithPrice.map((modifier, index) => {
          return (
            <Text key={index}>{modifier.name} {MenuItem.getModifierPrice(modifier)}</Text>
          )
        }),
        freeModifiers.length ? (<Text key={item.id + '_fm'}>{freeModifiers.map(m => (m.name)).join(', ')}</Text>) : null
      ];
    }
  }

  static getModifierPrice(modifier) {
    if (modifier.pretax_cents) {
      return (
        <Text>
          [+<FormattedCurrency value={modifier.pretax_cents / 100}/>]
        </Text>
      )
    }
  }

  /**
   *
   * @param item
   * @returns {?JSX.Element} The <Text> with the special instructions, or nothing if there are no special instructions
   */
  static getSpecialInstructions(item) {
    if (item.special_instructions) {
      return (
        <Text style={styles.special_instructions}>
          {item.special_instructions}
        </Text>
      )
    }
  }

  _getSeats() {
    let { item } = this.props;
    let location = item.location;
    if (!location) return null;

    if (location.getNumGuests() > 1) {
      if (item.seat_numbers.length) {
        return <Text style={styles.seats}>[Seat #{item.seat_numbers.join(', #')}]</Text>
      }
      else if ( item.errors?.includes("seats") ) {
        return <Text style={[ styles.seats , { color: Colors.error }]}>[ Missing Seat # ]</Text>
      }
    }
  }
}

export const styles = EStyleSheet.create({
  body: {
    flexDirection: 'row',
    padding: 10,
    backgroundColor: '#ffffff',
    borderBottomWidth: 1,
    borderBottomColor: '#c8c8c8',
    minHeight: 40,
    marginTop: 1
  },
  titleRow: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  itemTitle: {
    flex: 1,
    fontSize: 14,
    fontWeight: 'bold'
  },
  itemSelected: {
    backgroundColor: Colors.primaryLight
  },
  itemSelectedText: {},
  amount: {
    alignItems: 'flex-end'
  },
  details: {
    flexDirection: 'row',
    flex: 1
  },
  detailRow: {
    flex: 1,
    marginLeft: 5
  },
  otherInfo: {
    flexDirection: 'row'
  },
  special_instructions: {
    color: Colors.secondary
  },
  timestamp: {
    color: '#787878',
    fontSize: 12

  },
  status_waiting: {
    backgroundColor: Colors.waiting
  },
  status_making: {
    // kitchen has taken the order
    backgroundColor: Colors.making
  },
  status_humandelivering: {
    // ready for pickup
    backgroundColor: Colors.humandelivering
  },
  status_humandelivered: {
    //
    //backgroundColor: Colors.humandelivered
  },
  seats: {
    fontSize: 12,
    fontStyle: 'italic',
    color: '#767676'
  }
});
