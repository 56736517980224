import React, {Component} from 'react';
import {TouchableWithoutFeedback, View, Platform, Modal, ScrollView, Pressable} from 'react-native';
import PropTypes from 'prop-types';
import EStyleSheet from "react-native-extended-stylesheet";
import PinPad from "./PinPad";

let defaultInstance = null;

export default class PinPadModal extends Component {

  static propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    isGlobal: PropTypes.bool
  }

  static defaultProps = {
    visible: false,
    onClose: () => {},
    onSuccess: () => {}
  }

  static show = (onSuccess, onClose) => {
    if(defaultInstance){
      defaultInstance.show(onSuccess, onClose);
    }
  }

  state = {
    visible: false
  }

  componentDidMount() {
    let {isGlobal} = this.props;
    if(isGlobal){
      defaultInstance = this;
    }
  }
  componentWillUnmount() {
    defaultInstance = null;
  }

  render() {
    let {visible, isGlobal} = this.props;
    if(isGlobal) visible = this.state.visible;
    return (
      <Modal
        testID={"pin-pad-modal"}
        ref={modal => this._modal = modal}
        hardwareAccelerated={true}
        visible={visible}
        onRequestClose={this._onClose}
        transparent={true}
        onShow={() => {
          if(isGlobal && Platform.OS === 'web'){
            this._modal.parentNode.parentNode.style.zIndex = '11000';
          }
        }}
        animationType={'none'}>
        <TouchableWithoutFeedback onPress={this._onClose}>
          <View style={PinPadModal.style.background} nativeID="pinPad">
            <Pressable onPress={()=>{}}>
              <ScrollView contentContainerStyle={{flexGrow: 1, justifyContent: 'center'}}>
                <PinPad ref={me => this._pinPad = me} onSuccess={this._onPinSuccess} style={{borderRadius: 30}}/>
              </ScrollView>
            </Pressable>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }

  show = (cb, onClose) => {
    this._callback = cb;
    this._closeCallback = onClose;
    this.setState({
      visible: true
    }, () => {
      this._pinPad.focus();
    });
  }

  _onPinSuccess = (user) => {
    let {onClose, onSuccess, isGlobal} = this.props;

    if(isGlobal && this._callback){
      this._callback(user);
      this._onClose();
    } else {
      onClose(true);
      onSuccess(user);
    }
  }

  _onClose = () => {
    let {onClose, isGlobal} = this.props;
    if(this._pinPad)
      this._pinPad.clear();
    if(isGlobal){
      this.setState({
        visible: false
      })
      if(this._closeCallback) this._closeCallback();
    } else {
      onClose();
    }
  }

  static get style() {
    return EStyleSheet.create({
      background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)'
      }
    })
  }
}


