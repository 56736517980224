import React, {Component} from "react";
import {StyleSheet, TouchableWithoutFeedback, TouchableOpacity, Text, View} from "react-native";
import { Icon } from "native-base";
import {FormattedCurrency} from "react-native-globalize";
import Alert from "../Alert";
import OrderCheckoutInfo from "./OrderCheckoutInfo";
import OrderItem from '../OrderItem/OrderItem';
import { Colors, Typography } from '../../styles';

class Order extends Component {
  static defaultProps = {
    allowSelectItems: true
  }

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      selectedItems: [],
      orderSelectedItems: [],
    }
  }

  _expandOrderView = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  _handleMarkOrderComplete = () => {
    console.log("TODO: Mark Order Complete")
  }

  _handleSelectAllItems = () => {
    this.setState({
      orderSelectedItems: this.props.order.items
    })
  }

  _handleDeselectAllItems = () => {
    this.setState({
      orderSelectedItems: []
    })
  }

  _handleSelectItem = (item) => {
    let orderSelectedItems = this.state.orderSelectedItems.slice();
    let newList = []
    if(orderSelectedItems.includes(item)){
      if(orderSelectedItems.length > 1) {
        orderSelectedItems.splice(orderSelectedItems.indexOf(item), 1)
        newList = orderSelectedItems
      }
      else {
        newList = []
      }
    } else {
      orderSelectedItems.push(item)
      newList = orderSelectedItems.slice()
    }

    this.setState({
      orderSelectedItems: newList
    })
  }

  showSplitOrderToolTip = () => {
    Alert.alert(
      "Split Order",
      `This order belongs to ${this.props.order.party_tab_ids.length} party tabs.`);
  }

  render() {
    let { order, items } = this.props;
    let { expanded } = this.state;
    return (
      <TouchableOpacity onPress={this._expandOrderView} style={[ styles.orderContainer, (this.state.expanded ? styles.selected : {})]}>
        <View style={styles.orderHeader}>
          <View style={styles.orderHeaderLeft}>
            <Text style={Typography.header3}>Order #{order.orderNumberToday} {
              order.party_tab_ids.length > 1 &&
              <TouchableWithoutFeedback onPress={this.showSplitOrderToolTip}>
                <Icon type={"Foundation"} name={"pricetag-multiple"} style={{ fontSize: 16 }} />
              </TouchableWithoutFeedback>
            }
            </Text>
            <Text style={[ Typography.bold, { color: Colors.gray1 } ]}>{ order.customer?.customer_name }</Text>
            <Text>{ order.location_name }</Text>
          </View>
          <View style={styles.orderHeaderRight}>
            { !!order.time &&
              <Text style={[ Typography.sizeSmall, Typography.italic, { color: Colors.gray1, marginBottom: 5, textAlign: "right" } ]}>{ order.time.fromNow()}</Text>
            }
            { !!order.last_modified &&
              <Text style={[ Typography.sizeSmall, Typography.italic, { color: Colors.gray1, marginBottom: 5, textAlign: "right" } ]}>{ order.pretty_status } { order.last_modified.fromNow()}</Text>
            }
          </View>
        </View>

        <View style={[ styles.splitContainer, { paddingVertical: 10, paddingHorizontal: 10} ]}>
          <View style={[ styles.splitLeft, {flexDirection: 'row', alignItems: 'center'} ]}>
            <Text style={ Typography.bold }>{expanded ? "Hide":"Show"} Details</Text>
            <Icon type={"FontAwesome5"} name={ expanded ? 'caret-down' : 'caret-right'} style={{marginLeft: 10}}/>
          </View>
          <View style={[ styles.splitRight ]}>
            { !!order.total_cents && !expanded &&
              <Text style={[Typography.sizeSmall, Typography.bold]}>${(order.total_cents / 100).toFixed(2)}</Text>
            }
          </View>
        </View>

        { expanded && order.checkout_info.length > 0 &&
          <OrderCheckoutInfo
            checkoutInfo={order.checkout_info}
            blockStyle={{borderTopColor: Colors.gray, borderTopWidth: 1, marginVertical: 10, padding: 10}}
            labelStyle={{color: Colors.darkGray, fontStyle: 'italic', flex: 1, marginRight: 5}}
            valueStyle={{flex: 2, flexWrap: 'wrap'}}
          />
        }

        { expanded && !!items &&
          <View style={styles.orderItemList}>
            <View style={{ paddingVertical: 5, borderBottomWidth: 1, borderColor: Colors.gray1 }}>
              <View style={{flexDirection: 'row', flex: 1}}>
                <Text style={[Typography.bold, {flex: 1}]}>NAME</Text>
                <Text style={[Typography.bold, {textAlign: 'right'}]}>QTY</Text>
                <Text style={[Typography.bold, {width: '18%', textAlign: 'right'}]}>EACH</Text>
                <Text style={[Typography.bold, {width: '18%', textAlign: 'right'}]}>TOTAL</Text>
              </View>
            </View>
            { order.grouped_items.map((group) => <OrderItem
              key={group[0].itemId}
              orderItemList={group}
              isSelected={ this.state.orderSelectedItems.includes(group) }
              handleSelectItem={ () => {
                if( this.props.allowSelectItems ){
                  this._handleSelectItem(group)
                }
              }}
            />)}
          </View>
        }

        { expanded && (
          <View style={styles.totals}>
            <Text style={[{ textAlign: "right", color: Colors.gray }, Typography.bold] }>
              Subtotal: <FormattedCurrency value={ order.subtotal_cents / 100 } />
            </Text>
            <Text style={[{ textAlign: "right",  color: Colors.gray }, Typography.bold]}>
              Tax: <FormattedCurrency value={ order.tax_cents / 100 } />
            </Text>
            <Text style={[{ textAlign: "right", marginBottom: 10, color: Colors.gray}, Typography.bold ]}>
              Tip: <FormattedCurrency value={ order.tip_cents / 100 } />
            </Text>
            <Text style={[{ textAlign: "right"}, Typography.bold, Typography.header2 ]}>
              <FormattedCurrency value={ order.total_cents / 100 } />
            </Text>
          </View>
        )}

        {/*
        {this.state.expanded && (
          <View style={styles.actionList}>
            <TouchableOpacity onPress={this._handleMarkOrderComplete}
                              style={[Buttons.link, {borderRightWidth: 1, borderColor: Colors.gray1}]}>
              <Text style={[{color: Colors.gray1}, Typography.underline]}>Mark Order Complete</Text>
            </TouchableOpacity>
            {
              this.state.orderSelectedItems.length != order.items.length ? (
                <TouchableOpacity onPress={this._handleSelectAllItems} style={Buttons.link}>
                  <Text style={{color: Colors.gray1, ...Typography.underline}}>Select All</Text>
                </TouchableOpacity>
              ) : (
                <TouchableOpacity onPress={this._handleDeselectAllItems} style={Buttons.link}>
                  <Text style={{color: Colors.gray1, ...Typography.underline}}>Deselect All</Text>
                </TouchableOpacity>
              )
            }
          </View>
        )}
        */}
      </TouchableOpacity>
    )
  }
}

Order.defaultProps = {
  items: [],
};

export default Order;

const styles = StyleSheet.create({
  orderContainer: {
    backgroundColor: "#FFF",
    borderWidth	: 1,
    borderColor: Colors.gray1,
    marginBottom: 5,
    shadowOffset:{  width: 0,  height: 3 },
    shadowColor: 'black',
    shadowOpacity: 0.5,
    shadowRadius: 4,
  },
  orderHeader: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    paddingBottom: 10,
  },
  orderHeaderLeft: {
    flex: 1,
  },
  orderHeaderRight: {
    flex: 1,
    alignItems: "flex-end"
  },
  splitContainer: {
    flexDirection: "row"
  },
  splitLeft: {
    flex: 1,
  },
  splitRight: {
    flex: 1,
    alignItems: "flex-end"
  },
  orderBody: {
    flex: 1,
    paddingHorizontal: 10,
    paddingBottom: 20
  },
  orderItemList: {
    display: "flex",
    paddingHorizontal: 10
  },
  totals: {
    paddingVertical: 10,
    paddingHorizontal: 10
  },
  selected: {
    shadowOffset:{  width: 0,  height: 7 },
    shadowColor: 'black',
    shadowOpacity: 0.5,
    shadowRadius: 5.46,
  },
  actionList: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderTopWidth: 1,
    borderColor: Colors.gray1
  }
});
