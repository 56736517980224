import * as Colors from './Colors'

export const border = {
  borderWidth: 1,
};

export const borderBottom = {
  borderBottomWidth: 1,
};

