import React, {useState, useEffect} from 'react';
import { View, Text, TouchableWithoutFeedback, Modal} from 'react-native';
import {Container, Header, Content, Body, Title, Form, Item, Input, Label, Switch} from 'native-base';
import EStyleSheet from "react-native-extended-stylesheet";
import Colors from "../constants/Colors";
import {useGlobalize} from "react-native-globalize";
import CurrencyInput from "./CurrencyInput";

export default class EditMenuItemModal extends React.Component {


  render() {
    let {visible, onClose, item} = this.props;
    if (!item) return null;

    return (
      <Modal visible={visible} transparent={true} onRequestClose={onClose}>
        <TouchableWithoutFeedback onPress={onClose}>
          <View style={styles.background}>
            <TouchableWithoutFeedback>
              <View style={styles.modal}>
                <Container>
                  <Header>
                    <Body>
                      <Title>Item Details</Title>
                    </Body>
                  </Header>
                  <Content>
                    <Form>
                      <Item stackedLabel>
                        <Label>Menu Heading</Label>
                        <Input value={item.menu_heading ? item.menu_heading.heading_name : ''} editable={false}/>
                      </Item>
                      <Item stackedLabel>
                        <Label>Item Name</Label>
                        <Input value={item.name_for_customer} editable={false}/>
                      </Item>
                      <Item stackedLabel>
                        <Label>Item Description</Label>
                        <Input value={item.description} editable={false}/>
                      </Item>
                      <Item stackedLabel style={{alignItems: 'flex-start'}}>
                        <Label>Most Loved</Label>
                        <View style={{ paddingVertical: 20}}>
                          {/*<Switch value={item.most_loved} />*/}
                          <Text>{item.most_loved ? 'Yes':'No'}</Text>
                        </View>
                      </Item>
                      <Item stackedLabel>
                        <Label>Price</Label>
                        <CurrencyInput onChange={()=>{}} value={item.pretax_cents/100} editable={false}/>
                      </Item>
                    </Form>
                  </Content>
                </Container>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    )
  }
}



const styles = EStyleSheet.create({
  background: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  modal: {
    borderWidth: 1,
    borderColor: Colors.darkGray,
    width: '80%',
    height: '80%',
    backgroundColor: Colors.light,
  }
});
