import React, {Component} from 'react';
import API from "../api";
import {
  Container,
  Content,
  Header,
  List,
  ListItem,
  Text,
  Title,
  Body,
  Tab,
  Tabs,
  ScrollableTab,
  TabHeading,
  Icon,
  Right,
  Left as NBLeft,
  Switch,
} from "native-base";
import {ActivityIndicator, Platform, StyleSheet} from "react-native";
import Colors from "../constants/Colors";
import LocationChooserModal from "../components/LocationChooserModal";
import {showMessage} from "react-native-flash-message";

const headerHeight = 80; //RNHeader.HEIGHT;

const Left = ({children}) => {
  return <NBLeft style={{marginRight: 25, marginLeft: 5}}>{children}</NBLeft>
}

export default class BotControl extends Component {

  static navigationOptions = () => ({
    title: "Bot Control"
  })

  constructor(props) {
    super(props);

    this.state = {
      config: {},
      messages: {},
      selected_station: null,
      locations: [],
      kds_stations: [],
      manualUpdating: false,
      cancelUpdating: false,
      stowBasketUpdating: false,
      recoverUpdating: false,
      showLocationChooser: false,
    }
  }

  ///call getTabContent() in tab header

  componentDidMount() {
    this._mounted = true;
    let config = API.getConfig();
    let stationsWithRobots = config.kds_stations.filter(station => station.stationControlsRobot);

    this.setState({
      selected_station: stationsWithRobots[0],
      kds_stations: stationsWithRobots
    })
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  static propTypes = {}

  render() {
    let {messages, kds_stations, manualUpdating} = this.state;
    return (
      <Container>
        <Tabs
          onChangeTab={this._setSelectedStation}
          tabBarBackgroundColor={Colors.dark}
          tabBarUnderlineStyle={{backgroundColor: Colors.primary}}
          scrollWithoutAnimation={true}>
          { kds_stations.map((station, index) => (
              <Tab key={'station_' + station.id} heading={station.station_name} tabStyle={{backgroundColor: Colors.dark}} activeTabStyle={{backgroundColor: Colors.dark}} textStyle={{color: Colors.gray}}>
                <List>
                  <ListItem itemDivider>
                    <Text>Missions</Text>
                  </ListItem>
                  <ListItem icon first onPress={this._manualModePressed}>
                    <Left>
                      <Icon type={"MaterialIcons"} name={'location-on'}/>
                    </Left>
                    <Body>
                      <Text>Manual Mode</Text>
                    </Body>
                    <Right style={{flex: 1}}>
                      {manualUpdating ? <ActivityIndicator size={"small"} color={Colors.primary}/> :
                        <Text note>{messages[station.id]?.manual_message}</Text>
                      }
                      <Icon active4 name="chevron-right" type="Entypo"/>
                    </Right>

                  </ListItem>
                  <ListItem icon first onPress={e => this._recallRobot(station.id, index)}>
                    <Left>
                      <Icon type={"MaterialIcons"} name={'home'}/>
                    </Left>
                    <Body>
                      <Text>Cancel Mission</Text>
                    </Body>
                    <Right>
                      {this.state.cancelUpdating ? <ActivityIndicator size={"small"} color={Colors.primary}/> :
                        <Text note>{messages[station.id]?.recall_message}</Text>
                      }
                      <Icon active4 name="chevron-right" type="Entypo"/>
                    </Right>
                  </ListItem>
                  <ListItem itemDivider>
                    <Text>Maintenance and Repair</Text>
                  </ListItem>
                  <ListItem icon first onPress={this._devicePressed}>
                    <Left>
                      <Icon type={"MaterialIcons"} name={'unfold-more'}/>
                    </Left>
                    <Body>
                      <Text>Stow Basket Higher Up</Text>
                    </Body>
                    <Right>
                      {this.state.stowBasketUpdating ? <ActivityIndicator size={"small"} color={Colors.primary}/> :
                        <Switch value={station.basket_stowed}
                                onValueChange={e => this._toggleBasket(station.id, station.basket_stowed, index)}/>
                      }
                    </Right>
                  </ListItem>
                  <ListItem icon first onPress={e => this._recoverRobot(station.id, index)}>
                    <Left>
                      <Icon type={"MaterialIcons"} name={'cancel'}/>
                    </Left>
                    <Body>
                      <Text>Recover From Problem</Text>
                    </Body>
                    <Right>
                      {this.state.recoverUpdating ? <ActivityIndicator size={"small"} color={Colors.primary}/> :
                        <Text note>{messages[station.id]?.recover_message}</Text>
                      }
                      <Icon active4 name="chevron-right" type="Entypo"/>
                    </Right>
                  </ListItem>
                </List>
              </Tab>
            )
          )}
        </Tabs>
        <LocationChooserModal
          visible={this.state.showLocationChooser}
          locations={this.state.locations}
          headerTitle={'Manual Mode'}
          onCancel={() => {
            this.setState({showLocationChooser: false})
          }}
          onSave={this._locationPicked}
        />
      </Container>
    );
  }

  _setSelectedStation = ({i}) => {
    this.setState({
      selected_station: this.state.kds_stations[i]
    })
  }

  _locationPicked = async (location) => {
    // trigger here.
    let response = await API.forceDelivery(this.state.selected_station.id, location.id);
    this._setMessage(this.state.selected_station.id, 'manual_message', response)
    this.setState({showLocationChooser: false})
  }

  _manualModePressed = async () => {
    this.setState({
      manualUpdating: true
    })
    let locations = this.state.selected_station.getRobotLocations();

    this.setState({
      showLocationChooser: true,
      locations,
      manualUpdating: false
    })
  }

  _recoverRobot = async (stationId, index) => {
    this.setState({
      recoverUpdating: true
    })
    let response = await API.recoverRobot(stationId);
    this._setMessage(stationId, 'recover_message', response.success ? "Recovery started" : "Unable to Recover")
    this.setState({
      recoverUpdating: false
    })
  }

  _recallRobot = async (stationId, index) => {
    this.setState({
      cancelUpdating: true
    })
    let response = await API.recallRobot(stationId);
    this._setMessage(stationId, 'recall_message', response.success ? "Recalled!" : "Unable to Recall")
    this.setState({
      cancelUpdating: false
    })
  }

  _setMessage = (stationId, key, message) => {
    const {messages} = this.state;
    if (!messages[stationId]) messages[stationId] = {};
    messages[stationId][key] = message;

    this.setState({messages}, () => {
      setTimeout(() => {
        if (!this._mounted) return;
        messages[stationId][key] = '';
        this.setState({messages})
      }, 5000)
    });
  }


  _toggleBasket = async (stationId, basket_stowed, index) => {
    this.setState({
      stowBasketUpdating: true
    })

    let response = await API.stowBasket(stationId, !basket_stowed);

    if (!response.success) {
      showMessage({
        floating: true, position: 'top', type: 'error',
        message: response.error
      })
    }
    this.setState({
      kds_stations: this.state.kds_stations,
      stowBasketUpdating: false
    });
  }
}
