import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {View, Text} from 'react-native';
import OrderHelper from "../helpers/OrderHelper";

export default class OrderItemModifiers extends PureComponent {

  static propTypes = {}

  constructor(props) {
    super(props);
    this.state = {
      sortedMods: OrderHelper.sortModifiers(props.item.mods)
    }
  }

  render() {
    let {item, indent} = this.props;
    if(!item.mods.length) return null;

    return (
      <View style={{marginLeft: indent ? 10 : 0}}>
        {
          this.state.sortedMods.map(mod => (
            <View key={mod.id}>
              <Text>{mod.modifier?.name_for_bartender || mod.name}</Text>
              { mod.mods?.length > 0 && <OrderItemModifiers item={mod} indent={true}/> }
            </View>
          ))
        }
      </View>
    )
  }
}
