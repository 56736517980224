import {createStackNavigator} from "react-navigation-stack";
import TipsReportScreen from "../screens/TipsReportScreen";
import TabBarIcon from "../components/TabBarIcon";
import {Platform} from "react-native";
import {defaultNavigationOptions} from "./Defaults";
import React from "react";

export const TipReportsStack = createStackNavigator(
  { StationTipsScreen: TipsReportScreen },
  {
    navigationOptions: ({navigation}) => {
      return {
        tabBarLabel: 'Tips',
        tabBarTestID: 'tips',
        tabBarIcon: (props) => (
          <TabBarIcon
            {...props}
            type={"FontAwesome"}
            name={'money'}
          />
        )
      }
    },
    defaultNavigationOptions: defaultNavigationOptions
  }
)
