import React, {PureComponent} from 'react';
import {View, Text, Image} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import PropTypes from 'prop-types';

class GuestView extends PureComponent {

  render() {
    let {guest, cards} = this.props;
    let name = guest.nickname || "Seat "+guest.seat_number;

    return (
     <View style={styles.container}>
       <View style={styles.header}>
         <Text>{name}</Text>
         <Text>Seat {guest.seat_number}</Text>
       </View>
       <View style={styles.body}>
         {
           guest.card_ids.map( id => {
             let card = cards.find(card=>card.id === id);
             if(!card) return <Text key={id}>Card no longer available</Text>;
             return (
               <View key={guest.seat_number+'_'+card.id} style={{flexDirection: 'row', alignItems: 'center'}}>
                 <Image style={styles.cardIcon} source={card.icon}/>
                 <Text style={{flex:1}}>{card.name}</Text>
                 <Text>x{card.last4}</Text>
               </View>
             );
           })
         }
         {
           !guest.card_ids.length && (
             <Text>No Payment Methods available</Text>
           )
         }
       </View>
     </View>
    );
  }

  static propTypes = {

  }
}

export default GuestView;

const styles = EStyleSheet.create({
  container:  {
    minHeight: 75,
    borderBottomWidth: 1,
    paddingTop: 1,
    borderBottomColor: 'black',
    backgroundColor: 'white'
  },
  header: {
    padding: 5,
    flexDirection: 'row',
    backgroundColor: '#cdf',
    justifyContent: 'space-between'
  },
  cardIcon: {
    marginRight: 5
  },
  body: {
    padding: 5
  }
});
