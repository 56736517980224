import _ from 'lodash';
import ItemPart from './ItemPart';
import {Fraction} from 'fractional';

/**
 * Information for an individual fee, simplified. Used when getting the fees using the `getFees` method
 * @typedef {{id: string, name: string, total: number}} SimpleFeeData
 */

/**
 * Information for an individual fee as represented in CheckModel.fees. this includes the id, the pre-tax total (in
 * cents), the tax (in cents), and the total or sum of pre-tax total and tax (in cents).
 * @typedef {{id: string, tax_cents: number, pretax_cents: number, total: number}} FeeData
 */

/**
 * @typedef {Object} CartCheck
 * @property {?number} seat - Pretty sure this is not used. It uses ints 1 through number of seated guests, and null for the rest
 * @property {number} tip_cents - (In cents) the amount of money to tip
 * @property {FeeData[]} fees - The list of fees associated with this specific Check
 * @property {Charge} charge - Information on the id, type, and amount
 * @property {CartItemData[]} items - JSON repr of items in the CartCheck
 * @property {{phone: string, email: string}} sendReceipt - The email to receive receipts, the phone to text updates to
 */

/**
 * Information for a single item in this Check's Cart
 * @typedef {Object} CartItemData
 * @property {number} pretax_total - (In cents) The pretax, post-discount cost of this item
 * @property {number} tax_total - (In cents) The integer amount of tax for this item
 * @property {number} amount_cent - (In cents)
 * @property {string} cartItemId - The UUID of the cart this item belongs to
 * @property {number} numerator - The integer numerator of the fraction for this item
 * @property {number} denominator - The integer denominator of the fraction for this item
 */

/**
 * An object containing necessary information to charge this payment method, including the id and amount to charge. The
 * `tab_id` is given if the Charge refers to a PArtyTab, and the `card_id` is given if the Charge refers to a SavedCard.
 * Both `tab_id` and `card_id` will always match the `id` property whenever they appear.
 * TODO Find what string `type` is when payment type is a card. I can only assume 'card'
 * @typedef {{id: string, tab_id?: string, card_id?: string, type: 'tab' | *, amount_cents: number}} Charge
 */

/**
 * Check Model
 */
export default class CheckModel {

  /**
   * Creates a new CheckModel with the values given in `obj`, or the default values if a parameter is not given.
   *
   * @constructor
   * @param {Object | CheckModel} obj - Contains values to initialize for the Check. Basically all are optional
   * @param {Cart} cart - The parent cart which this Check is a child of
   */
  constructor(obj, cart) {
    /** @type {string} */
    this.label = obj.label || "Check";
    /**
     * Has no correlation with an actual seat in the Check Model. The first Check has seat 1, the next
     * check is 2, until the num checks exceeds num guests seated, at which point this is always null
     * @type {?number}
     */
    this.seat = obj.seat || null;
    /** @type {ItemPart[]} The list of items that belong to this Check */
    this.items = obj.items || [];
    /** @type {?Charge} */
    this.charge = obj.charge || null;
    /** @type {boolean} */
    this.saveCard = obj.saveCard === undefined ? true : obj.saveCard;
    /** @type {{email: string, phone: string}} */
    this.sendReceipt = obj.sendReceipt || { email: '', phone: '' };
    /** @type {number} The tip in integer number of cents */
    this.tip_cents = obj.tip_cents || 0;

    this.items.forEach((item, index) => {
      if(!(item instanceof ItemPart)){
        this.items[index] = new ItemPart(item);
      }
    });
    /** @type {FeeData[]} */
    this.fees = [];

    // Adds the cart as a non-enumerable property to prevent it from being added to the JSON repr from .toJSON()
    Object.defineProperty(this, 'cart', { value: cart, enumerable: false });
  }

  /**
   * Returns a sorted list of all the seat numbers spanned by the items in this check.
   * @returns {number[]} A sorted list of integer seat numbers
   */
  getUniqSeats(){
     let seats = _.uniq(_.flatten(_.map(this.items, p => p.item.seat_numbers)));
     seats.sort();
     return seats;
  }

  /**
   * Only the Sub-Total, or the sum of the costs of items without the discount
   * @returns {number} (in cents) non-negative number
   */
  get pretax_prediscount_total() {
    return this.items.reduce((total, i) => total += i.pretax_total, 0);
  }

  /**
   * The cost of: Sub-Total + Discounts
   * TODO - Confirm this actually adds discounts. I'm assuming so because of the existence of pretax_prediscount_total()
   * @returns {number} (in cents) non-negative number
   */
  get pretax_total() {
    return this.items.reduce((total, i) => total += i.pretax_amount, 0);
  }

  /**
   * Only the cost of Tax
   * @returns {number} (in cents) non-negative number
   */
  get tax_total(){
    return this.items.reduce((total, i) => total += i.tax_amount, 0);
  }

  /**
   * The cost of Sub-Total + Discounts + Tax + Fees
   * TODO - Confirm this actually adds discounts. I'm assuming so because of pretax_total()
   * @returns {number} (in cents) non-negative number
   */
  get total() {
    // + fees total
    let feesTotal = _.sumBy(this.fees, 'total') || 0;
    return this.items.reduce((total, i) => total += i.getTotal(), 0) + feesTotal;
  }

  /**
   * The cost of Sub_Total + Discounts + Tax + Fees + Tip
   * @returns {number} (in cents) non-negative number
   */
  get tip_total() {
    return this.total + (this.charge ? this.tip_cents : 0);
  }

  /**
   * Generates a JSON representation of this Check for the Cart to include in the payload when submitting the order to
   * the server.
   * @returns {CartCheck} JSON repr of this Check
   */
  getCartCheck(){
    return {
      seat: this.seat,
      charge: this.charge,
      tip_cents: this.tip_cents,
      sendReceipt: this.sendReceipt,
      fees: this.fees,
      items: this.items.map(itemPart => { return {
        cartItemId: itemPart.item.id,
        amount_cents: itemPart.getTotal(),
        pretax_total: itemPart.pretax_amount,
        tax_total: itemPart.tax_amount,
        numerator: itemPart.numerator,
        denominator: itemPart.denominator
      }})
    }
  }

  // not currently used
  getReceiptItems(){
    // If there are multiple items on this check with the same hash,
    // we should only show one of them and merge the qty.
    let receiptItems = {};
    this.items.forEach(item => {
      // only combine if identical
      let hash = item.item.getHash();
      if(receiptItems[hash]){
        receiptItems[hash].qty.add(new Fraction(item.numerator, item.denominator));
      } else {
        receiptItems[hash] = {
          item: item,
          qty: new Fraction(item.numerator, item.denominator)
        };
      }
    });
  }

  /**
   * @returns {Discount[]} All of the discounts applied to this specific check.
   */
  getDiscounts = () => {
    let discounts = {};
    this.items.forEach(item => {
      if(item.discounts) {
        item.discounts.forEach(discount => {
          discounts[discount.promotion_id] = discount.name;
        })
      }
    })
    let result = [];
    for(let promotion_id in discounts){
      result.push({
        promotion_id,
        name: discounts[promotion_id]
      })
    }
    return result;
  }

  /**
   * Returns a list of information for the fees associated with this check. Specifically, the id, name, and total for
   * each fee.
   * @returns {SimpleFeeData[]}
   */
  getFees = () => {
    let fees = this.fees;
    let results = [];
    fees.forEach(fee => {
      let parentFee = this.cart.fees.find(f => f.id === fee.id);
      results.push(
        {
          id: fee.id,
          name: parentFee.name_for_customer,
          total: fee.pretax_cents + fee.tax_cents
        }
      )
    })
    return results;
  }

  /**
   * @returns {boolean} True if all items in the check can still be fulfilled by the stations
   */
  isValid = () => {
    return this.items.every(item => item.item.is_fulfillable);
  }

  /**
   * Returns the amount of money saved by the discounts in this checkout (0 or negative number of cents)
   * @returns {number} (in cents) 0 or negative number of cents
   */
  get discounts_total(){
    return this.items.reduce((total, itemPart) => total += _.sumBy(itemPart.discounts, 'cents_added'), 0)
  }

  /**
   * Removes the ItemPart with the given UUID from the CheckModel's list of items.
   * @param {string} cartItemId The UUID of the ItemPart to remove from this CheckModel
   */
  removeItem = (cartItemId) => {
    this.items = _.filter(this.items, itemPart => itemPart.itemId !== cartItemId);
  }
}
