import _ from 'lodash';
import Model from "./Model";

/**
 * Todo: Make this use the 'update' function
 */

export default class MenuHeading extends Model {

  id = null;
  heading_name = '';
  display_position = 100;
  items = [];

  constructor(menu, obj){
    super();

    Object.defineProperty(this, 'menu', { value: menu });

    this.id = obj.id ||  null;
    this.heading_name = obj.heading_name || "";
    this.display_position = obj.display_position;
    this.items = obj.items.map(item => {
      menu.menuData.menuItemsById[item].menus.push(menu.menuId);
      return menu.menuData.menuItemsById[item]
    });

  }

}
