import React, {Component, useEffect, useState} from 'react';
import Stripe from 'react-native-stripe-terminal';
import {View, Image, TouchableWithoutFeedback, ActivityIndicator, Platform, FlatList} from 'react-native';
import {
  Body,
  Button,
  Container,
  Content, Footer, FooterTab,
  Icon,
  Left,
  ListItem,
  Right, Spinner,
  Text,
  Thumbnail
} from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import API from '../api';
import Loader from '../components/Loader';
import Colors from '../constants/Colors';
import ErrorBanner from "../components/ErrorBanner";
import CmdButton from "../components/CmdButton";
import {IS_PROD} from "../constants/Config";
import {requestLocationPermission} from "../helpers/HelperFunctions";
import AsyncStorage from "@react-native-community/async-storage";
import Alert from "../components/Alert";
import IconButton from "../components/IconButton";

/**
 * TODO: WARN IF NO LOCATION_ID FOUND
 */

const ReaderMap = {
  WISEPAD_3: {
    title: 'WisePad 3',
    image: require('../assets/images/readers/wisepad.png'),
    type: 'BT'
  },
  STRIPE_M2: {
    title: 'Stripe M2',
    image: require('../assets/images/readers/stripem2.png'),
    type: 'BT'
  },
  CHIPPER_2X: {
    title: 'Chipper 2X',
    image: require('../assets/images/readers/chipper2x.png'),
    type: 'BT'
  },
  VERIFONE_P400: {
    title: 'Verifone P400',
    image: require('../assets/images/readers/p400.png'),
    type: 'NET'
  },
  WISEPOS_E: {
    title: 'WisePOS E',
    image: require('../assets/images/readers/wisepos.png'),
    type: 'NET'
  },
  BBPOS_WISEPOS_E: {
    title: 'WisePOS E',
    image: require('../assets/images/readers/wisepos.png'),
    type: 'NET'
  }
}

const DiscoveryMethodPrettyNames = {
  BLUETOOTH_SCAN: 'Bluetooth',
  INTERNET: 'Internet'
}

export default class StripeConfig extends Component {

  static navigationOptions = ({navigation}) => {
    let goTo = navigation.getParam('goTo');
    return {
      title: 'Connect Reader',
      headerRight: () => (
        <View style={{marginRight: 10}}>
          <CmdButton
            text="Skip"
            onPress={() => {
              Stripe.cancelDiscovery();
              if (goTo) navigation.navigate(goTo);
              else navigation.goBack();
            }}/>
        </View>
      )
    }
  };

  _mounted = false;

  constructor(props) {
    super(props);
    let {navigation} = props;

    this._mounted = false;
    this.state = {
      initializing: true,
      initialized: false,
      loading: false,
      scanning: false,
      availableReaders: [],
      connectedReader: null,
      autoload: navigation.getParam('autoload'),
      goTo: navigation.getParam('goTo'),
      updateStatus: UPDATESTATE.UNKNOWN,
      updateProgress: 0,
      simulated: false,
      discoveryMethod: Platform.OS === 'web' ? "INTERNET" : "BLUETOOTH_SCAN"
    };

  }

  componentDidMount() {
    this._mounted = true;

    API.retrieveAsyncStorageData('bbpos_simulator').then(val => {
      this.setState({simulated: JSON.parse(val) || false}
      )
    });
    if (Platform.OS === 'android') {
      requestLocationPermission().then(granted => {
        if (granted)
          this._initializeStripe();
        else {
          this.setState({
            initializing: false,
            error: "Location permission is required for Stripe Terminal"
          });
        }
      });
    } else {
      this._initializeStripe();
    }

    this._blurListener = this.props.navigation.addListener('willBlur', () => {
      Stripe.isDiscovering().then((res) => {
        if (res) Stripe.cancelDiscovery();
      })
    });
  }

  componentWillUnmount() {
    this._mounted = false;

    if (this._blurListener) this._blurListener.remove();
  }

  _getSimulatedToggle = () => {
    if (IS_PROD) return null;
    return (
      <FooterTab style={{backgroundColor: 'black'}}>
        <IconButton
          label={'Simulated Devices'}
          icon={this.state.simulated ? 'check-square' : 'square'}
          iconType={'Feather'}
          onPress={this._toggleSimulated}
        />
      </FooterTab>
    )
  }

  _toggleSimulated = async () => {
    let value = !this.state.simulated;
    await AsyncStorage.setItem('bbpos_simulator', JSON.stringify(value));
    this.setState({simulated: value}, this._scanForReaders);
  }

  render() {
    if (!Array.isArray(this.state.availableReaders)) {
      console.error(this.state.availableReaders);
      return null;
    }
    return (
      <Container>
        {this.state.loading && <Loader/>}
        {this.state.error ? <ErrorBanner text={this.state.error}/> : null}
        <Content>
          <FlatList
            data={this.state.availableReaders}
            renderItem={this._renderReader}
            ListEmptyComponent={this._getListEmptyComponent}
            keyExtractor={item => item.serial_number}
          />
          { this.state.scanning && (
            <View style={styles.spinnerView}>
          <Text style={styles.statusMsg}>
            Scanning for {DiscoveryMethodPrettyNames[this.state.discoveryMethod]} devices</Text>
          <Text note>(Please make sure the card reader is turned on)</Text>
          <Image style={{marginTop: 10}} source={require("../assets/images/list-loading.gif")}/>
        </View>
          )}
        </Content>
        {this._getFooter()}
      </Container>
    )
  }


  _getListEmptyComponent = () => {
    const {scanning, loading, initializing, loaded} = this.state;
    if(scanning) return null;
    let content = (<Text>No devices found</Text>)
    if (initializing || loading){
      content = (<Spinner size={'large'}/>)
    }
    return (
      <ListItem>
        <Body>
          {content}
        </Body>
      </ListItem>
    )
  }

  _renderReader = ({item: reader}) => {

    return (
      <ReaderView
        connected={this.state.connectedReader?.serial_number === reader.serial_number}
        key={reader.serial_number}
        reader={reader}
        onPress={this._selectReader}
        onUpdate={this._installUpdate}
      />
    );
  }

  _getFooter() {
    const buttons = {
      "INTERNET": {
        label: "Find Net Readers",
        icon: <Icon type='Ionicons' name={'ios-globe'}/>
      },
      "BLUETOOTH_SCAN": {
        label: "Find BT Readers",
        icon: <Icon type="FontAwesome5" name={"bluetooth-b"}/>
      }
    }

    return (
      <Footer style={{backgroundColor: Colors.dark}}>
        {
          Stripe.AvailableMethods.map(method => (
            <FooterTab key={method} style={{backgroundColor: 'black'}}>
              <IconButton
                label={buttons[method].label}
                style={{color: 'white'}}
                onPress={() => this._scanForReaders({discoveryMethod: method})}
                icon={buttons[method].icon}
              />
            </FooterTab>
          ))
        }
        {this._getSimulatedToggle()}
      </Footer>
    );

    switch (this.state.updateStatus) {
      case UPDATESTATE.INSTALLING:
        return (
          <View style={styles.footer}>
            <Text>Update Progress - {this.state.updateProgress}%</Text>
          </View>
        );
      case UPDATESTATE.SUCCESS:
        return (
          <View style={styles.footer}>
            <Text>Update Installed Successfully</Text>
            <Button transparent style={{marginLeft: 20}}
                    onPress={() => this.setState({updateStatus: null})}><Text>Ok</Text></Button>
          </View>
        );
      case UPDATESTATE.AVAILABLE:
        return (
          <View style={styles.footer}>
            <Text>Update Available</Text>
            <Button transparent style={{marginLeft: 20}} onPress={this._installUpdate}>
              <Text>Install Now</Text>
            </Button>
          </View>
        );
      case UPDATESTATE.UNAVAILABLE:
        return (
          <TouchableWithoutFeedback onPress={this._checkForUpdate}>
            <View style={styles.footer}>
              <Text>No Update Available</Text>
              <Button transparent style={{marginLeft: 20}}
                      onPress={() => this.setState({updateStatus: null})}><Text>Ok</Text></Button>
            </View>
          </TouchableWithoutFeedback>
        );
      case UPDATESTATE.CHECKING:
        return (
          <View style={styles.footer}>
            <Text style={{marginRight: 5}}>Checking for update </Text>
            <ActivityIndicator color={Colors.primary}/>
          </View>
        );
      default:
        return (
          <TouchableWithoutFeedback onPress={this._checkForUpdate}>
            <View style={styles.footer}>
              <Text>Check for Update</Text>
            </View>
          </TouchableWithoutFeedback>
        )
    }
  }


  _initializeStripe = async () => {
    // Todo: simplify this... (always just call Stripe.init, since it internally handles it)
    let isInitialized = await Stripe.isInitialized();
    if (!isInitialized) {
      // We *should* always be initialized here, since we get initialized in AuthLoadingScreen
      try {
        await Stripe.init({
          fetchConnectionToken: API.getStripeConnectionToken,
          createPaymentIntent: API.createStripePaymentIntent,
          locationId: API.customer.stripe_terminal_location_id,
          autoReconnect: true
        });

        if (!this._mounted) return;
        this.setState({initialized: true}, this._getConnectedReader);
      } catch (err) {
        API.sendCaughtError(err);
        if (!this._mounted) return;
        this.setState({
          initializing: false,
          error: "Stripe Init failure: " + err
        });
      }
    } else {
      let scanning = await Stripe.isDiscovering();
      if (!scanning) this._getConnectedReader();
      else {
        Stripe.setDiscoverCallback(this._discoverReadersCallback);
        this.setState({
          initializing: false,
          scanning
        })
      }
    }
  }

  _getConnectedReader = async () => {
    let reader = await Stripe.getConnectedReader();
    if (reader) {
      if (this.state.autoload) {
        this.props.navigation.navigate(this.state.goTo || 'Pin');
      } else {
        if (!this._mounted) return;

        this.setState({
          initializing: false,
          connectedReader: reader,
          availableReaders: [reader]
        });
      }
    } else {
      this._scanForReaders();
    }
  }


  _scanForReaders = async (options) => {
    if (!this._mounted) return;

    try {
      let connectedReader = await Stripe.getConnectedReader();
      if (connectedReader) {
        await Stripe.disconnectReader(); // this promise doesn't resolve
      }
    } catch (err) {
      console.log(err)
    }
    let isDiscovering = await Stripe.isDiscovering();
    if (isDiscovering) {
      try {
        await Stripe.cancelDiscovery();
      } catch (err) {
      }
    }

    this._savedReader = await API.getReader();

    this.setState({
      initializing: false,
      availableReaders: [],
      error: null,
      scanning: true,
      connectedReader: false,
      discoveryMethod: options?.discoveryMethod || this.state.discoveryMethod
    }, async () => {
      try {
        await Stripe.discoverReaders({
          simulated: this.state.simulated,
          discoveryMethod: Stripe.DiscoveryMethods[this.state.discoveryMethod]
        }, this._discoverReadersCallback);
        if (this._mounted) {
          this.setState({
            scanning: false
          });
        }

      } catch (err) {

        this.setState({scanning: false})
      }
    });

  }

  _discoverReadersCallback = (readers) => {
    if (!this._mounted) return;
    this.setState({
      availableReaders: readers || [],
      scanning: this.state.discoveryMethod !== "INTERNET"
    });

    if (readers.length > 0 && this._savedReader && this.state.autoload) {
      // Connect to the reader we previously connected to:
      let foundReader = readers.find(r => r.serial_number === this._savedReader);
      if (foundReader) {
        this._selectReader(foundReader);
      }
    }
  }

  _selectReader = async (reader) => {
    const {goTo, connectedReader, discoveryMethod} = this.state;
    if (reader === connectedReader) {
      if (goTo) this.props.navigation.navigate(goTo);
      else this.props.navigation.goBack();
      return;
    }

    this.setState({
      loading: true,
      error: '',
      scanning: false
    });

    try {
      let locationId = API.customer.stripe_terminal_location_id || reader.location_id;
      let result;
      switch (discoveryMethod) {
        case "BLUETOOTH_SCAN":
          result = await Stripe.connectBluetoothReader(reader, {locationId});
          break;
        case "INTERNET":
          result = await Stripe.connectInternetReader(reader);
          break;
      }
      if (result.error) {
        Alert.alert("Error", result.error.message);
        this.setState({loading: false})
      } else {
        this._connectReaderCallback(result);
      }

    } catch (error) {
      if (!this._mounted) return;
      this.setState({
        loading: false,
        error: "Error connecting reader. Please try again: " + error
      })
      // Start scanning again
      //this._scanForReaders();
      API.sendCaughtError(error);
    }
  }

  _connectReaderCallback = async (reader) => {
    await API.setConnectedReader(reader);

    if (!reader.available_update) {
      if (this.state.goTo) {
        this.props.navigation.navigate(this.state.goTo);
      } else {
        this.props.navigation.goBack();
      }
    }
  }

  _listLocations = async () => {
    let locations = await Stripe.listLocations({});
  }

  _checkForUpdate = async () => {
    this.setState({
      updateStatus: UPDATESTATE.CHECKING
    });
    try {
      let updateAvailable = await Stripe.checkForUpdate();

      if (!this._mounted) return;

      this.setState({
        updateStatus: updateAvailable ? UPDATESTATE.AVAILABLE : UPDATESTATE.UNAVAILABLE
      });
    } catch (err) {
      API.sendCaughtError(err);
      if (this._mounted) {
        this.setState({
          updateStatus: UPDATESTATE.UNKNOWN,
          error: "An error occurred while checking for updates."
        })
      }
    }

  }

  _installUpdate = async () => {
    try {
      this.setState({
        updateStatus: UPDATESTATE.INSTALLING,
        updateProgress: 0
      });

      let success = await Stripe.installAvailableUpdate(this._progressUpdate);

      this.setState({
        updateStatus: success ? UPDATESTATE.SUCCESS : UPDATESTATE.FAILED,
        updateProgress: 0
      });

    } catch (error) {
      API.sendCaughtError(error);
      this.setState({
        error: error + "",
        updateStatus: UPDATESTATE.FAILED,
        updateProgress: 0
      })
    }
  };

  _progressUpdate = (progress) => {
    if (!this._mounted) return;
    try {
      this.setState({
        updateProgress: Math.round(progress * 100)
      });
    } catch (err) {
      console.log(err);
    }
  }

}


const UPDATESTATE = {
  UNKNOWN: 0,
  CHECKING: 1,
  AVAILABLE: 2,
  UNAVAILABLE: 3,
  INSTALLING: 4,
  SUCCESS: 5,
  FAILED: 6
}


const ReaderView = ({reader, onPress, connected, onUpdate}) => {
  let listItemStyle = connected ? styles.connected : null;
  const [updateProgress, setUpdateProgress] = useState(0);
  const [updateStatus, setUpdateStatus] = useState(null);

  function battLevel(fraction) {
    if (fraction != null)
      return Math.round(fraction * 100) + '%';
    else return "";
  }

  const deviceInfo = ReaderMap[reader.device_type.toUpperCase()]

  const UpdateInfo = () => {
    if (updateProgress > 0)
      return (<View style={{width: `${updateProgress}%`, height: 5, backgroundColor: Colors.success}}/>)

    return null;
  }

  useEffect(() => {
    if(connected && deviceInfo.type === 'BT') {
      const listener = Stripe.on('ReaderSoftwareUpdateProgress', (progress) => {
        setUpdateProgress(Math.round(progress*100));
        setUpdateStatus(UPDATESTATE.INSTALLING);
      });
      const finishListener = Stripe.on('FinishedInstallingUpdate', () => {
        setUpdateProgress(100);
        setUpdateStatus(UPDATESTATE.SUCCESS);
      })
      return () => {
        listener.remove();
        finishListener.remove();
      }
    }
  }, [connected])

  const RightSide = () => {
    if (!connected) {
      return (
        <Button icon onPress={() => onPress(reader)}>
          <Icon type={"Entypo"} name={"arrow-with-circle-right"}/>
        </Button>
      )
    } else {
      if (updateStatus === UPDATESTATE.INSTALLING) return null;
      else if (updateStatus === UPDATESTATE.SUCCESS) {
        return (
          <Button onPress={() => { setUpdateStatus(null); setUpdateProgress(0); }}>
            <Text>DONE</Text>
          </Button>
        )
      } else {
        return (
          <>
            <Text>{battLevel(reader.battery_level)}</Text>
            {!!reader.available_update && !updateStatus && (
              <Button style={{marginLeft: 10}} onPress={() => onUpdate(reader)}>
                <Text>Update</Text>
              </Button>
            )}
          </>
        )
      }
    }
  }

  return (
    <ListItem
      noIndent
      thumbnail
      accessible={true}
      accessibilityLabel={reader.serial_number}
      testID={reader.serial_number}
      style={listItemStyle}
    >
      <Left style={{flex: null}}>
        <Thumbnail square source={deviceInfo.image}/>
      </Left>
      <Body>
        <Text>{reader.label || deviceInfo.title}</Text>
        <Text note>{reader.serial_number}</Text>
        <UpdateInfo/>
      </Body>
      <Right>
        <RightSide/>
      </Right>
    </ListItem>
  )
}

const styles = EStyleSheet.create({
  reader: {
    flexDirection: 'row',
    padding: 5
  },
  connected: {
    backgroundColor: '#bfe7e8'
  },
  deviceType: {
    fontSize: '1.1rem'
  },
  readerBatt: {
    justifyContent: 'center',
    marginRight: 10
  },
  readerSerial: {
    color: Colors.gray,
    fontStyle: 'italic'
  },
  spinnerView: {
    alignItems: 'center',
    borderTopWidth: 1,
    padding: 10
  },
  helpMsg: {
    color: Colors.darkGray,
    fontSize: '0.9rem',
    marginBottom: 5
  },
  statusMsg: {
    margin: 10,
    fontWeight: 'bold',
    fontSize: 16
  },
  footer: {
    alignItems: 'center', justifyContent: 'center', height: 50, borderTopWidth: 1,
    flexDirection: 'row'
  }
});
