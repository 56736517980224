let CartModifier;
import _ from "lodash";

export function setCartModifierClass(cm){
  CartModifier = cm;
}

let hasModifiers = (superclass) => class extends superclass {

  _deselectAll(heading_id) {
    this.mods[heading_id].forEach(m => m.selected = false);
  }

  toggleModifier(modifier) {
    return new Promise( (resolve, reject) => {
      let heading = modifier.heading;
      if (!this.mods || !this.mods[heading.id]) this.mods[heading.id] = [];

      if ( this.errors && this.errors.indexOf(heading.id) > -1 ) {
        this.errors.splice(this.errors.indexOf(heading.id), 1);
      }

      let exists = this.mods[heading.id].find(m => m.menuItemId === modifier.menuItemId);
      if (heading.is_radio) {
        this._deselectAll(heading.id);
      } else if (heading.max_selected && (!exists || !exists.selected)) {
        let numSelected = this.mods[heading.id].filter(m => m.selected).length;
        if (numSelected === heading.max_selected) {
          // todo: Notify
          if (heading.max_selected === 1) {
            this._deselectAll(heading.id);
          } else {
            resolve({
              success: false,
              errorType: "max-selected",
              errorTitle: `Too many selected`,
              errorMessage: `You can only select ${ heading.max_selected }`
            });
            return;
          }
        }
      }
      if (exists) {
        exists.selected = !exists.selected;
      } else {
        exists = new CartModifier(this, modifier)
        this.mods[heading.id].push(exists);
      }
      resolve({ success: true, cartModifier: exists });
    })
  }

  isChecked(modifier) {
    if (!this.mods[modifier.heading.id]) return false;
    let exists = this.mods[modifier.heading.id].find(m => m.menuItemId === modifier.menuItemId);
    return exists && exists.selected;
  }

  hasRequiredModifiers() {
    let hasRequiredMods = false;
    this.modifier_groups.forEach((group) => {
      if (group.min_selected > 0) {
        hasRequiredMods = true;
      }
    })
    return hasRequiredMods;
  }

  get modifier_groups() {
    if ( !this.modifierGroups ) return [];
    if ( this._modifier_groups ) return this._modifier_groups;
    else this._modifier_groups = [];

    this.modifierGroups.forEach(mg => {
      let group_id = mg.modifierGroupId;
      let group = _.clone(this.api.menuData.modifierGroupsById[group_id]);

      group.modifiers = mg.modifierIds.map(id => this.api.menuData.modifiersById[id]).filter(m => m.is_fulfillable)

      if (group.modifiers.length) {
        group.modifiers = _.sortBy(group.modifiers, ['display_position', 'name']);
        this._modifier_groups.push(group);
      }
    });

    _.sortBy(this._modifier_groups, ['order_in_menu', 'heading_name']);

    return this._modifier_groups;
  }


};

export default hasModifiers;
