import {Input, Item, Label} from "native-base";
import Required from "./Required";
import React, {useEffect, useState} from "react";
import {styles} from './styles';

/**
 * TODO: FIGURE OUT WHY THIS ISN"T HIGHLIGHTING WHEN ERROR
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TextField = (props) => {
  const {
    field, index, label, required, fieldValue, setFieldValue, valid, setValid, highlightError,
    validator = (val) => required ? !!val : true, keyboardType, formatter, placeholder, ...rest
  } = props;

  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (highlightError) {
      setTouched(true);
      setValid(validator(fieldValue))
    }
  }, [highlightError]);

  const onChangeText = (text) => {
    let val = formatter ? formatter(text) : text;
    setFieldValue(val)
    let isValid = validator(val);
    setValid(isValid);
  }

  const onBlur = () => {
    setTouched(true);
  }

  return (
    <Item stackedLabel key={index} style={styles.fieldItem} {...rest} accessible={false}>
      {/* The Label for the Checkout Information */}
      <Label style={[styles.fieldLabel, !valid && touched && styles.invalidFieldLabel]}>
        {label}
        <Required show={required}/>
      </Label>
      <Input
        testID={'requiredCheckoutInfo-' + index}
        style={[styles.textInputField, (highlightError && !valid) ? styles.errorField : null]}
        keyboardType={keyboardType}
        placeholder={placeholder}
        onChangeText={onChangeText}
        value={fieldValue || ""}
        onBlur={onBlur}
      />
    </Item>
  )
}

export default TextField;
