import React, {Component} from 'react';
import {View, Text, TouchableWithoutFeedback} from 'react-native';
import Alert from "../components/Alert";
import GuestView from "../components/GuestView";
import EStyleSheet from "react-native-extended-stylesheet";
//import {SwipeListView} from 'react-native-swipe-list-view';

import API from "../api";
import CmdButton from "../components/CmdButton";
import LocationChooserModal from "../components/LocationChooserModal";

export default class GuestManager extends Component {

  static navigationOptions = (props) => {
    let {navigation} = props;
    let location = navigation.getParam('location');
    return {
      title: location.shortId + " Guests",
      headerRight: () => (
          <View style={{flexDirection: 'row'}}>
            <CmdButton text={"Move Group"} onPress={navigation.getParam('moveGroupFn')}/>
            {/* <CmdButton text={"Add Guest"} onPress={navigation.getParam('addGuestFn')}/>*/}
          </View>
      )
    }
  };

  constructor(props){
    super(props);

    let {navigation} = props;
    navigation.setParams({
      moveGroupFn: this._showLocationChooser,
      addGuestFn: this._addGuest
    });

    this.state = {
      error: null,
      all_cards: [],
      location: navigation.getParam('location'),
      showLocationChooser: false
    }
  }

  componentDidMount() {
    if(this.state.location.seated_group) {
      API.getCards(this.state.location.seated_group).then( data => {
        this.setState({
          all_cards: [...data.smart_ordering_cards, ...data.group_cards, ...data.group_tabs]
        });
      });
    }
  }

  _showLocationChooser = () => {
    this.setState({
      showLocationChooser: true
    })
  }

  _moveGroup = async (new_location) => {
    // Show list of Locations
    let {navigation} = this.props;

    this.setState({
      showLocationChooser: false,
      processing: true
    });

    let result = await API.moveGroup(this.state.location.seated_group, this.state.location, new_location);
    if(result.success){
      this.setState({
        processing: false,
        location: new_location
      });
      navigation.setParams({
        location: new_location
      })
    } else {
      Alert.alert("An Error Occurred", "Please try again");
    }
  };

  _addGuest = () => {
    console.log('add guest');
  };

  render(){
    let guests = this.state.location.seated_group ? this.state.location.seated_group.guests : [];
    return (
      <View style={{flex: 1}}>
        {/*<SwipeListView
          useFlatList
          data={guests}
          rightOpenValue={-75} // these need a value for swipe to work
          disableRightSwipe={true}
          keyExtractor={ this._keyExtractor }
          renderItem={ this._renderGuest }
          renderHiddenItem={ this._renderHiddenOptions }
        />*/}
        <LocationChooserModal visible={this.state.showLocationChooser}
                              onCancel={this._hideModal}
                              onSave={this._moveGroup}/>
      </View>
    )
  }

  _keyExtractor = guest => guest.seat_number.toString();

  _renderGuest = (data, rowMap) => (
    <GuestView guest={data.item} cards={this.state.all_cards}/>
  );

  _renderHiddenOptions = (guest, rowMap) => {
    return (
      <View style={styles.rowBack}>
        <View style={{flex: 1}}/>
        <TouchableWithoutFeedback onPress={()=>{}}>
          <View style={[styles.hiddenButton, styles.removeButton]}>
            <Text>Remove</Text>
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }

  _hideModal = () => {
    this.setState({
      showLocationChooser: false
    })
  }

  _sendReceipt = () => {
    console.log('sending receipt');
  }

  _removeGuest = () => {

  }

}

const styles = EStyleSheet.create({
  rowBack: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: '#DDD',
    flex: 1,
    height: '100%'
  },
  hiddenButton: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    width: 75
  },
  printBtn: {
    backgroundColor: '#84dbff',
  },
  removeButton: {
    backgroundColor: 'red'
  }
})
