import _ from "lodash";
import React, {useEffect, useState} from 'react';
import {Body, CheckBox, Item, Label, List, ListItem, Text} from "native-base";
import EStyleSheet from "react-native-extended-stylesheet";

import {Colors} from "../../../styles";
import Required from "./Required";
import {styles as fieldStyles} from "./styles";

const CheckboxField = ({field, index, fieldValue, setFieldValue, valid, setValid, highlightError, ...rest}) => {

  const [touched, setTouched] = useState(false);
  const validator = (value) => {
    return field.required_on_handheld ? value?.length > 0 : true;
  }

  useEffect(() => {
    if (highlightError) {
      setTouched(true);
      setValid(validator(fieldValue))
    }
  }, [highlightError]);

  const onCheckboxPressed = (choice) => {
    if (!fieldValue) fieldValue = [];
    if (fieldValue.includes(choice.label)) {
      const newValue = _.without(fieldValue, choice.label)
      setFieldValue(newValue);
      setValid(validator(newValue));
    } else {
      const newValue = fieldValue.concat(choice.label)
      setFieldValue(newValue);
      setValid(validator(newValue))
    }
    setTouched(true);
  }

  if (!field.choices || field.choices.length === 1) {
    let choice = field.choices ? field.choices[0] : [{label: 'yes'}];
    return (
      <Item style={[fieldStyles.fieldItem, styles.checkboxContainer]} {...rest}>
        <CheckBox
          color={Colors.primary}
          testID={'extraCheckoutInfo-' + index}
          onPress={() => {
            onCheckboxPressed(choice)
          }}
          checked={fieldValue?.includes(choice.label)}
          style={styles.checkbox}
        />
        <Label style={[fieldStyles.fieldLabel, styles.checkboxLabel, !valid && touched && fieldStyles.invalidFieldLabel ]}>
          {field.name_for_patron}
          <Required show={field.required_on_handheld}/>
        </Label>
      </Item>
    );
  } else {
    return (
      <Item stackedLabel {...rest}>
        <Label style={[fieldStyles.fieldLabel, !valid && touched && fieldStyles.invalidFieldLabel]}>
          {field.name_for_patron}
          <Required show={field.required_on_handheld}/>
        </Label>
        <List style={[{width: '100%'}, highlightError && !valid && fieldStyles.errorField]}>
          {
            field.choices.map((choice, i) => (
              <ListItem key={`${field.key}_choice_${i}`} style={{borderBottomWidth: 0}}>
                <CheckBox
                  color={Colors.primary}
                  onPress={() => onCheckboxPressed(choice)}
                  checked={fieldValue?.includes(choice.label)}
                />
                <Body>
                  <Text>{choice.label}</Text>
                </Body>
              </ListItem>
            ))
          }
        </List>
      </Item>
    )
  }
}

export default CheckboxField;


const styles = EStyleSheet.create({
  checkboxContainer: {
    paddingVertical: 10
  },
  checkboxLabel: {
    marginLeft: 20
  },
})
