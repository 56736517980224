import _ from 'lodash';
import API from "../api";
import moment from "moment";
import {showMessage} from "react-native-flash-message";
import PinPadModal from "../components/PinPad/PinPadModal";
import Alert from "../components/Alert";


export default class OrderHelper {

  static canRefund = (items) => {
    let user = API.getUser();
    if (!user) {
      return false;
    }
    return user.canRefund() && items.length && !items.some(i => i.status === 'refunded');
  }

  static changeItemsState = async (items, newState, reason = "") => {
    if (newState === 'botsent') newState = 'pleasesend'; // need to override this
    let orderitems = items.map(i => {
      return {
        orderitemid: i.orderitemid,
        name_for_customer: i.getName(),
        status: newState,
        reason: reason
      }
    });

    let response = await API.setItemsStatus(orderitems);

    if (response.success) {
      items.forEach(item => {
        item.notified = true;
        item.status = newState;
        item.time_modified = moment();
      });

      // We only ever change the items of a single order at a time:
      let order_ids = _.uniq(items.map(i => i.order.orderId));
      order_ids.forEach(orderId => {
        let order = API._orders[orderId];
        if (order) {
          const data = response.changedOrderStatuses[orderId];
          if (data.time_closed && !order.time_closed) {
            API.setLastClosedOrder(order);
          }
          order.update(data)
          order._lastLocalChange = order.last_modified;
        }
      });

      API.trigger('orders', order_ids);
    }

    return response;
  }

  static sortModifiers = mods => {
    if (!mods) return [];
    let mappedModifiers = mods.map(mod => ({...mod, modifier: API?.menuData?.modifiersById[mod.id]}));
    return _.orderBy(mappedModifiers, [
      'modifier.heading.display_position',
      'modifier.heading.heading_name',
      'modifier.display_position',
      'modifier.name_for_bartender'
    ])
  }

  static printTicket = (order, notify) => {
    const loggedInUser = API.getUser();
    return new Promise((resolve, reject) => {
      const doPrint = async () => {
        let response;
        let {has_printers} = API.menuData.customer;

        if (has_printers) {
          response = await API.printTicket(order);
        } else {
          response = await API.printTicketLocal(order);
        }
        if (notify && has_printers) {
          showMessage({
            position: 'top', floating: true, type: 'success',
            message: 'Ticket sent to printer'
          })
        }
        resolve(response);
        return response;
      }
      if (loggedInUser.isManager()) {
        doPrint();
      } else {
        PinPadModal.show(async (user) => {
          if (user.isManager()) {
            doPrint()
          } else {
            Alert.alert('Access Denied', 'User is not authorized to perform this action.');
            resolve(false);
          }
        });
      }
    });
  }

}
