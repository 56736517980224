import React from 'react';
import {Text, TouchableWithoutFeedback, View} from 'react-native';


export class MonoText extends React.Component {
  render() {
    return <Text {...this.props} style={[this.props.style, { fontFamily: 'space-mono' }]} />;
  }
}

