import React from 'react';
import {StyleSheet, View } from 'react-native';
import API from '../../api';
import Colors from "../../constants/Colors";
import PinPad from "../../components/PinPad/PinPad";
import {Content, Container, Button, Text} from 'native-base';

export default class PinScreen extends React.Component {

  static navigationOptions = {
    header: null
  }

  constructor(props) {
    super(props);

    this.state = {
      currPin: [],
      email: '',
      processing: false
    };
  }

  componentDidMount() {
    API.getAccount().then(email => {
      this.setState({
        email: email,
        currPin: []
      });
    })
  }

  render() {
    return (
      <Container>
        <Content contentContainerStyle={{ flexGrow: 1}}>
          <View testID={"pin-screen"} style={styles.container}>
            <PinPad
              changeUser={true}
              onSuccess={this._onPinSuccess}
            />
          </View>
          <View style={{flexDirection: 'row', margin: 10, alignItems: 'center', justifyContent: 'space-between'}}>
            <Text style={{color: Colors.gray}}>Account: {this.state.email}</Text>
            <Button transparent disabled={this.state.processing} onPress={() => {
              API.logout().then(() => {
                this.props.navigation.navigate('Login')
              })
            }}>
              <Text>LOGOUT</Text>
            </Button>
          </View>
        </Content>
      </Container>
    );
  }

  _onPinSuccess = async (user) => {
    let {navigation} = this.props;

    API.unlockScreen();
    let forwardTo = navigation.getParam('forwardTo') || API.config ? API.config.default_screen : 'AuthLoading';
    navigation.navigate(forwardTo);
  }

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  }
});
