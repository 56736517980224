import Model from './Model';
import AppProperties from './AppProperties';
import Promotion from "./Promotion";
import _ from "lodash";

export default class Customer extends Model {

  app_properties = {};
  banner_url = '';
  currency = 'USD';
  /** @type {?string} This Customer model's UUID */
  customer_id = null;
  customer_name = '';
  diet_icons = {};
  display_position = 0;
  emoji_icons = {};
  order_number_prefix = '';
  physical_address = {};
  timezone = null;
  locale = 'en';
  service_conditions = [];
  promotions = [];
  has_printers = false;
  tags = {}
  fullstory_enabled = false;
  owned_fee_configs = [];
  stripe_terminal_location_id = null;

  _field_map = {
    app_properties: ap => new AppProperties(ap),
    promotions: promotions => promotions.map(p => new Promotion(p)),
    service_conditions: conditions => conditions.map(condition => new ServiceCondition(condition)),
    owned_fee_configs: configs => _.keyBy(configs, 'id')
  }

  constructor(obj){
    super();
    this.update(obj);
  }

  /**
   * Text shown on Stripe Credit Card statement
   * @returns {string}
   */
  get statement_descriptor() {
    let desc = this.customer_name.replace(/[<>'"\\]/gi, '').toUpperCase();
    desc = desc.length < 18 ? desc + ' BBOT ORDER' : desc;
    return desc.substr(0,22)
  }
}


class ServiceCondition {
  constructor(obj) {
    this.widget = obj.widget;
    this.group_name = obj.group_name;
    this.choices = obj.choices.map(choice => new ServiceConditionChoice(choice))
  }
}

class ServiceConditionChoice {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
  }
}
