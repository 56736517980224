import LatLonSpherical from './LatLonSpherical';
import {PermissionsAndroid, Platform} from "react-native";
import {API_URL} from "../constants/Config";
import moment from "moment";
import Sentry from '../services/Sentry';
import md5 from "uuid";
import * as Location from "expo-location";

class HelperFunctions {

  static async requestLocationPermission() {
    try {
      let granted = 'false';
      if (Platform.OS === 'android')
        granted = await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION);
      else {
        granted = await Location.getForegroundPermissionsAsync().granted;
      }

      if (granted) return true;
      else {
        return new Promise((resolve, reject) => {

          // After 30 seconds, resolve false and continue (can happen if the PermissionsAndroid.request callback never resolves)
          const timeout = setTimeout(() => {
            resolve(false);
          }, 30 * 1000);

          PermissionsAndroid.request(
            PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
            {
              title: 'Terminal Location Permission',
              message: 'Terminal requires access to your location for the credit card reader to work. ' +
                'Without it, you will not be able to place orders.',
              buttonNeutral: 'Ask Me Later',
              buttonNegative: 'Cancel',
              buttonPositive: 'OK',
            },
          ).then(result => {
            clearTimeout(timeout);
            resolve(result === PermissionsAndroid.RESULTS.GRANTED);
          });

        })
      }
    } catch (error) {
      Sentry.captureException(error);
      return false;
    }
  }

}

export default HelperFunctions;

export const requestLocationPermission = HelperFunctions.requestLocationPermission;

export const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', {numeric: true});

export const escapeRegExp = (string) => {
  if (!string) return "";
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/**
 * TODO - Use an official Email Validating package here. The regex has too many false positives... For Example:
 *  {}=?!"~|*&^%$#`-+_'/@special-chars.com
 *  "space here and there if surrounded by quotes"@nasa.gov
 *  urls-cannot-start-with-hyphen@---bad.com"
 */
export const validateEmail = (email) => {
  let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return !!email && reg.test(email);
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

/**
 * Takes an input of any string, and returns a formatted number (can include negative sign and decimal)
 * @param string
 * @returns {string}
 */
export function formatNumber(string) {
  string = string.replace(/[^\d.-]/g, '');
  const match = string.match(/^(?<sign>-)?(?<int>\d+)?(?<dot>.)?(?<decimal>\d+)?/);
  if (!match) return "";
  const {groups} = match;
  const {sign = "", int = "", dot = "", decimal = ""} = groups;
  return `${sign}${int}${dot}${decimal}`;
}

export function normalizePhoneInput(value, previousValue) {
  // return nothing if no value
  if (!value) return value;

  // Don't normalize for international phone numbers:
  if (value.slice(0, 1) === "+") return value;

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {

    // returns: "x", "xx", "xxx"
    if (cvLength < 4) return currentValue;

    // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

    // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;

  }
};

export const getCsrfCookieName = () => {
  if (Platform.OS !== 'web') return 'csrftoken';
  const url = new URL(API_URL);
  const hostname = url.hostname;
  const parts = hostname.split('.');
  const subdomain = parts[0]; // bbot, www, staging, demo, dev, localhost etc...
  let suffix = 'prod';
  if (['staging', 'dev', 'demo'].includes(subdomain)) suffix = subdomain;
  else if (subdomain === 'localhost' || parseInt(subdomain) == subdomain) { // handles localhost, 127, 192, etc..
    suffix = 'localdev'; // handles IPs
  }
  return 'csrftoken' + suffix;
}


export const fashhash = (str) => {
  /* return str(
   b64encode(
      md5(
        str(s).encode("utf-8")
      ).digest()
    ).decode()[:22])
    utf-8 encode str
    md5 hash
   */

  return new Buffer(md5(new Buffer(str).toString('utf8'))).toString('base64');
}

export class Coordinate {
  constructor(obj) {
    this.accuracy = obj.coords.accuracy;  // radius in meters of 68% confidence
    this.altitude = obj.coords.altitude;  // meters above the WGS 84 reference ellipsoid.
    this.heading = obj.coords.heading;    // bearing, in degrees
    this.latitude = obj.coords.latitude;
    this.longitude = obj.coords.longitude;
    this.speed = obj.coords.speed;        // meters/second over ground

    this.mocked = obj.mocked;
    this.timestamp = obj.timestamp;

    this.point = new LatLonSpherical(this.latitude, this.longitude);

    this.distanceTravelled = null;
  }

  distanceTo = point => {
    if (point instanceof Coordinate) {
      this.distanceTravelled = this.point.distanceTo(point.point);
    } else if (point instanceof LatLonSpherical) {
      this.distanceTravelled = this.point.distanceTo(point);
    } else {
      this.distanceTravelled = this.point.distanceTo(LatLonSpherical.parse(point));
    }
    return this.distanceTravelled;
  }
}

export const formatCardExpirationDate = (month, year) => {
  return moment(`${month}-${year}`, 'MM-YYYY').format('MM / YYYY');
}

export const formatDate = (momentTime) => {
  return momentTime.isSame(moment(), 'day') ? momentTime.format('h:mm A') : momentTime.format('MMM Do, h:mm A');
}

export const fromNow = (momentTime) => {
  const hideSuffix = moment().diff(momentTime, 'seconds') < 45;
  return momentTime.fromNow(hideSuffix);
}

export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return "CIRCULAR";
      }
      seen.add(value);
    }
    return value;
  };
};
