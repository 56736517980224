import React, {useEffect, useState} from 'react';
import {QRCode} from 'react-native-custom-qr-codes-expo';
import {styles as globalStyles} from "../../styles/Global";
import {View, Text} from "native-base";
import {KeyboardAvoidingView, TouchableWithoutFeedback, Modal} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import Colors from "../../constants/Colors";

let showModal;
let hideModal;

const QRCodeModal = ({onClose, content, qrProps, visible}) => {
  const [visibleState, setVisible] = useState(false);
  const [contentState, setContent] = useState('');
  const [qrPropsState, setQRProps] = useState({});
  const [titleText, setTitleText] = useState('');

  showModal = (title, content, qrProps) => {
    setVisible(true);
    setTitleText(title);
    setContent(content);
    setQRProps(qrProps);
  };
  hideModal = () => setVisible(false);

  useEffect(() => {
    setVisible(visible);
    setContent(content);
    setQRProps(qrProps);
  }, [visible, content, qrProps]);

  const onCloseFn = () => {
    setVisible(false);
    if(onClose) onClose();
  }

  if(!contentState || !visibleState) return null;

  return (
    <Modal visible={visibleState} transparent={false} onRequestClose={onCloseFn}>
      <TouchableWithoutFeedback onPress={onCloseFn}>
        <View style={globalStyles.modalBackground}>
          <KeyboardAvoidingView style={[styles.modal]}>
            <Text style={styles.text}>{titleText}</Text>
            <QRCode content={contentState} {...qrPropsState}/>
            <Text style={styles.text}>SCAN HERE</Text>
          </KeyboardAvoidingView>
          <Text style={{color: 'white', marginTop: 15}}>(Tap anywhere to close)</Text>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
}

export default QRCodeModal;
export {showModal as show, hideModal as hide};

const styles = EStyleSheet.create({
  modal: {
    borderWidth: 1,
    borderRadius: 10,
    borderColor: Colors.dark,
    backgroundColor: Colors.light,
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    fontWeight: 'bold',
    fontSize: 20,
    textTransform: 'uppercase'
  }
})
