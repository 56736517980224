import {StyleSheet, View, Text} from "react-native";
import PropTypes from 'prop-types';
import {Icon} from 'native-base';
import React from "react";

export default class PinDigit extends React.PureComponent {

  static propTypes = {
    hideAfter: PropTypes.number,   // Time in ms to show the digit before hiding it
    value: PropTypes.number,   // The value to show
    style: PropTypes.any
  }

  static defaultProps = {
    hideAfter: 400
  }

  constructor(props){
    super(props);

    this.state = {
      show: false
    }

    this.timeout = null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.value !== prevProps.value){
      clearTimeout(this.timeout);

      if(this.props.hideAfter > 0 && this.props.value) {
        this.setState({show: true});
        this.timeout = setTimeout(()=> {
          if (!this._mounted) return;
          this.setState({
            show: false
          });
        }, this.props.hideAfter);
      } else {
        this.setState({ show: false});
      }
    }
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
    if(this.timeout)
      clearTimeout(this.timeout);
  }

  render() {
    const {value, style} = this.props;
    let digit = '';

    if(this.state.show){
      digit = <Text>{value}</Text>
    } else {
      let size = Number.isInteger(value) ? 'big':'small';
      digit = <Icon type={"Entypo"} name="dot-single" style={styles[size]}/>;
    }

    return (
      <View style={[styles.displayDigit, style]}>
        { digit }
      </View>
    )
  }
}

const styles = StyleSheet.create({
  displayDigit: {
    borderWidth: 1,
    borderColor: '#9b9b9b',
    borderRadius: 10,
    height: 60,
    width: 60,
    margin: 7,
    alignItems: 'center',
    justifyContent: 'center'
  },
  big: {
    fontSize: 75,
    textAlign: 'center'
  },
  small: {
    fontSize: 18,
    color: '#808080'
  }
});
