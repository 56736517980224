/**
 * StripeCardModal
 *
 * Displays a modal informing the user to tap their card on the card reader or allows manual card entry.
 * Cross Platform (Web/Android)
 *
 * @author Gilles St-Cyr
 * @date 2019-03-24
 */

import React, {useEffect, useState} from 'react';
import {View, Image, TouchableWithoutFeedback, Modal} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import PropTypes from 'prop-types';
import API from "../../api";
import {Button, Icon, Text} from "native-base";
import StripeTerminal from "react-native-stripe-terminal";
import CardForm from "../StripeElements/CardForm";
import Colors from "../../constants/Colors";
import {prompt} from "../Prompt"


/** TODO: When Card is Declined, onSuccess is still called like wtf over **/

const StripeCardModal = ({
                           visible,
                           onSuccess,
                           onClose,
                           onError,
                           reusable,
                           paymentIntentParams,
                           headerText,
                           saveButtonText
                         }) => {
  const [readerConnected, setReaderConnected] = useState(false);
  const [status, setStatus] = useState('');
  const [error, setError] = useState('');
  const [showSimulatorButton, setShowSimulatorButton] = useState(false);

  const methods = [];
  if (StripeTerminal.readerConnected) methods.push('reader');
  if (API.config.allow_manual_card_entry) methods.push('manual');

  const [mode, setMode] = useState(methods[0]);

  const toggleMode = () => {
    setMode(mode === 'manual' ? 'reader' : 'manual');
  }

  const handleError = (error) => {
    setError(error);
    if(error) {
      setStatus("Error");
      if (typeof onError === 'function') onError(error);
    }
  }

  const manualCardSuccess = (result) => {
    if(readerConnected) cancel();
    onSuccess(result);
  }

  const runSimulator = () => {
    prompt({
      title: "Test Card #",
      defaultValue: "4242 4242 4242 4242",
      onSubmit: (value) => {
        let testCardNumber = value.replace(/[^\d]/g, '');
        StripeTerminal.setSimulatorConfiguration({
          testCardNumber
        })
        startStripe();
      }
    })
  }

  const getContent = () => {
    if (mode === 'reader') {
      return (
        <View style={{alignItems: 'center'}}>
          {!!headerText && <Text style={styles.headerText}>{headerText}</Text>}
          <Image
            resizeMode={'contain'}
            source={require("../../assets/images/contactless-payment-symbol.png")}
            style={styles.image}
          />
          { showSimulatorButton && <Button onPress={runSimulator}><Text style={{color: 'white'}}>Charge Simulated Card</Text></Button>}
          <Text style={styles.status}>{status}</Text>
        </View>
      )
    } else if (mode === 'manual') {
      return (
        <CardForm
          paymentIntentParams={paymentIntentParams}
          saveButtonText={saveButtonText}
          reusable={reusable}
          onSuccess={manualCardSuccess}
          onError={handleError}
          onClose={onClose}
        />
      )
    } else {
      return (
        <View style={{alignItems: 'center'}}>
          <Text style={styles.status}>No payment options available</Text>
        </View>
      )
    }
  }

  const cancel = async () => {
    setStatus("Cancelling...");
    if (readerConnected) {
      try {
        if (reusable) await StripeTerminal.cancelReadReusableCard();
        else await StripeTerminal.cancelCollectPaymentMethod();
      } catch (err) {
      }
    } else {
      // API.cancelStripePaymentIntent...
    }
    // if(readerType === 'internet') API.cancelPaymentIntent()...
    onClose();
  }

  /**
   * Starts Stripe and prepares Reader for card capture
   * @returns {Promise<void>}
   */
  const startStripe = async () => {
    setStatus("Connecting...")
    setReaderConnected(true);
    try {
      if (reusable) {
        let {error, payment_method} = await StripeTerminal.readReusableCard();
        if (error) {
          handleError(error.message);
        } else {
          onSuccess(payment_method);
        }
      } else {
        let {error, clientSecret} = await StripeTerminal.createPaymentIntent(paymentIntentParams);
        if (error) {
          handleError(error.message);
        } else {
          await collectPaymentMethod();
        }
      }
    } catch (err) {
      handleError(err.toString());
      API.sendCaughtError(err)
    }
  }

  /**
   * Step 2 of Physical Card Capture
   * @returns {Promise<void>}
   */
  const collectPaymentMethod = async () => {
    try {
      let {error} = await StripeTerminal.collectPaymentMethod();
      if(error) {
        handleError(error.message);
      } else {
        await confirmPayment();
      }
    } catch (err) {
      console.log('err collectPaymentMethod', StripeTerminal.cancelling);
      if (!StripeTerminal.cancelling) {
        handleError("Card Read Failure: " + err.toString());
        setStatus("Error")
      }
    }
  }

  /**
   * Step 3 of Physical Card Capture
   * @returns {Promise<void>}
   */
  const confirmPayment = async () => {
    setStatus("Processing...");
    try {
      let {paymentIntent, error} = await StripeTerminal.processPayment();
      if(error){
        handleError(error.message);
      } else {
        onSuccess(paymentIntent);
      }
    } catch (err) {
      if (!StripeTerminal.cancelling) {
        setStatus('Error!');
        handleError(err.toString())
        console.error("Confirming Payment Intent failed: ", err.toString())
      }
    }
  }

  useEffect(() => {
    if (visible) {
      setStatus('')
      setError('');
      setShowSimulatorButton(false);
      StripeTerminal.getConnectedReader().then(reader => {
        if (reader) {
          if(reader.serial_number.includes("SIMULATOR")){
            setShowSimulatorButton(true);
          } else {
            startStripe();
          }
        } else setMode('manual');
      })
    }
  }, [visible])

  useEffect(() => {
    const listener = StripeTerminal.on('ReaderStatus', (status) => setStatus(status));
    return () => {
      listener.remove();
      cancel();
    };
  }, [])

  return (
    <Modal visible={visible} transparent animationType={'slide'}>
      <TouchableWithoutFeedback onPress={cancel}>
        <View style={styles.background}>
          <TouchableWithoutFeedback>
            <View style={styles.modal}>
              <View style={{width: '100%'}}>
                {getContent()}
                {!!error && (
                  <View style={styles.error}>
                    <Text style={styles.errorText}>{error}</Text>
                  </View>
                )}
              </View>
              {methods.length > 1 && (
                <View style={{alignItems: 'flex-end'}}>
                  <Button iconLeft transparent onPress={toggleMode}>
                    <Icon name={mode === 'reader' ? 'edit' : 'credit-card'} type={'FontAwesome5'}
                          style={{color: Colors.primary}}/>
                    <Text>{mode === 'reader' ? 'Manual Card Entry' : 'Card Reader'}</Text>
                  </Button>
                </View>
              )}
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  )
}

export default StripeCardModal;


const styles = EStyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '99%',
    maxWidth: 500,
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderWidth: 2,
    borderColor: 'black',
    backgroundColor: 'white',
    padding: 10
  },
  headerText: {
    fontSize: '1.5rem',
    marginBottom: 25,
  },
  image: {
    marginVertical: 30,
    height: 140,
    width: '100%'
  },
  status: {
    fontSize: '2rem',
    marginBottom: 10
  },
  error: {
    margin: 5
  },
  errorText: {
    fontSize: '1rem',
    color: 'red',
    textAlign: 'center'
  }
});
