import {Text, View} from "react-native";
import React from "react";

const OrderCheckoutInfo = ({checkoutInfo, blockStyle, fieldStyle, labelStyle, valueStyle}) => {
    if(!checkoutInfo.length) return null;

    let Fields = checkoutInfo.map(field => {
      if(field.key === 'user_desired_time' || !field.value) return null;

      if(field.key === 'address'){
        let res = [];
        let val = [
          [field.value.line1, field.value.line2].join(' '), field.value.city, field.value.zip
        ].join(', ')
        res.push(<Field key={field.key} label={field.name_for_bartender} value={val} fieldStyle={fieldStyle} labelStyle={labelStyle} valueStyle={valueStyle}/>)
        if(field.value.delivery_instructions)
          res.push(<Field key={'delivery_instructions'} label={"Instructions"} value={field.value.delivery_instructions} fieldStyle={fieldStyle} labelStyle={labelStyle} valueStyle={valueStyle}/>)

        return res;
      } else {
        return <Field key={field.key} label={field.name_for_bartender} value={field.value} fieldStyle={fieldStyle} labelStyle={labelStyle} valueStyle={valueStyle}/>;
      }
    }).filter(i=>i);

    if(!Fields.length) return null;

    return (
      <View style={blockStyle}>
        { Fields }
      </View>
    )
}

export default OrderCheckoutInfo;

const Field = ({label, value, fieldStyle, labelStyle, valueStyle}) => {
  const text = Array.isArray(value) ? value.join(', ') : value; // Todo: we shouldn't need this, since values are joined server-side
  return (
     <View style={[{flexDirection: 'row'}, fieldStyle]} key={label}>
       <Text numberOfLines={1} style={labelStyle}>{label}: </Text>
       <Text style={valueStyle} >{text}</Text>
     </View>
  )
}
