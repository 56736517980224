import React, {useEffect, useState} from 'react';
import {Body, Item, Label, List, ListItem, Radio, Text} from "native-base";

import {Colors} from "../../../styles";
import Required from "./Required";
import { styles as fieldStyles } from './styles';

const RadioField = ({field, fieldValue, valid, setValid, setFieldValue, highlightError, ...rest}) => {

  const [touched, setTouched] = useState(false);
  const validator = (val) => !!val;

  useEffect(() => {
    if (highlightError) {
      setTouched(true);
      setValid(validator(fieldValue))
    }
  }, [highlightError]);

  useEffect(() => {
    if(valid) setTouched(true);
  }, [valid])

  const onPress = (choice) => {
    setFieldValue(choice.label);
    setValid(true);
    setTouched(true);
  }

  return (
    <Item stackedLabel {...rest}>
      <Label style={[fieldStyles.fieldLabel, !valid && touched && fieldStyles.invalidFieldLabel ]}>
        {field.name_for_patron}
        <Required show={field.required_on_handheld}/>
      </Label>
      <List style={[{width: '100%'}, highlightError && !valid && fieldStyles.errorField]}>
        {
          field.choices.map(choice => (
            <ListItem key={choice.label} style={{borderBottomWidth: 0}} >
              <Radio
                color={Colors.primary}
                selectedColor={Colors.primary}
                onPress={() => onPress(choice)} selected={fieldValue === choice.label}/>
              <Body>
                <Text>{choice.label}</Text>
              </Body>
            </ListItem>
          ))
        }
      </List>
    </Item>
  )
}

export default RadioField;
