import {Button, Icon, Input} from "native-base";
import React from "react";
import {Text, View} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import {Buttons} from "../styles";

export default class FilterButtonInput extends React.Component {

  render() {
    let {label, value, onChange} = this.props;
    return (
      <View style={[Buttons.pillPrimary, {maxWidth: 250, width: 250}]}>
        <Text style={[Buttons.pillLabelPrimary, {marginRight: 10}]}>{label}</Text>
        <Input style={styles.input} onChangeText={onChange} value={value}/>
        {!!value && (
          <Button style={styles.closeButton} transparent icon onPress={() => onChange('')}>
            <Icon style={styles.closeIcon} name={'close'}/>
          </Button>
        )}
      </View>
    );
  }

}

const styles = EStyleSheet.create({
  closeButton: {
    backgroundColor: 'white',
    borderWidth: 0,
    borderRadius: 0,
    height: '100%',
    paddingBottom: 0,
    paddingTop: 0
  },
  closeIcon: {
    backgroundColor: 'white',
    color: 'black',
    fontSize: 16,
    marginLeft: 8,
    marginRight: 8
  },
  input: {
    color: 'black',
    backgroundColor: 'white',
    fontSize: 14,
    height: 21,
    padding: 0,
    width: '100%'
  }
});
