import React from "react";
import {Text, TouchableWithoutFeedback, View} from "react-native";
import {Buttons, Colors} from "../styles";

const FilterButton = ({label, onPress, value}) => (
  <TouchableWithoutFeedback onPress={onPress}>
    <View style={Buttons.pillPrimary}>
      <Text style={[Buttons.pillLabelPrimary, {marginRight: 10}]}>{label}</Text>
      <Text style={{color: Colors.light}}>{value}</Text>
    </View>
  </TouchableWithoutFeedback>
);

export default FilterButton;
