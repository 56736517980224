import React, {Component} from "react";
import Order from "./Order"
import {FlatList, Text, View} from "react-native";
import {Typography} from "../../styles";

class OrderList extends Component {
  render() {
    let { orders } = this.props;
    return (
      <View style={{ flex: 1 }}>
        { orders ? orders.map((order) => this.renderOrder(order)) : this.renderEmptyMessage()}
      </View>
    )
  }

  renderOrder = (order) => {
    return (<Order key={order.orderId} order={order} allowSelectItems={false} />);
  }

  renderEmptyMessage = () => {
    return (
      <View style={{ padding: 20, flex: 1 }}>
        <Text style={[ Typography.header2, { textAlign: 'center', marginBottom: 20 }]}>Empty</Text>
        <Text style={{ textAlign: 'center' }}>No orders found.</Text>
      </View>
    );
  }
}


export default OrderList;
