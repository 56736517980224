import React, {useState} from 'react';
import {Button, Input, Item, Text, View} from "native-base";

const PromoInput = ({cart}) => {

  const [promoCode, setPromoCode] = useState(cart.valid_promo_codes[0]);
  const [processing, setProcessing] = useState(false);

  const onSubmitPromoCode = async () => {
    setProcessing(true);
    let newPromoCode = promoCode.trim();
    //if (cart.valid_promo_codes.length) await cart.removePromoCode(cart.valid_promo_codes[0]);
    await cart.addPromoCode(newPromoCode);
    setProcessing(false);
  }

  return (
    <View style={{flexDirection: 'row', alignItems: 'center', marginVertical: 10, paddingLeft: '10%'}}>
      <Item rounded style={{borderRadius: 4, flex: 1, marginRight: 20, padding: 0}}>
        <Input
          placeholder={'Enter Promo'}
          value={promoCode}
          onSubmitEditing={onSubmitPromoCode}
          onChangeText={setPromoCode}
          style={{height: 43, backgroundColor: 'white', borderRadius: 5}} // match button height
        />
      </Item>
      <Button onPress={onSubmitPromoCode} disabled={processing}>
        <Text>APPLY</Text>
      </Button>
    </View>
  )
}


export default PromoInput;
