import _ from "lodash";
import axios from "axios";
import moment from "moment";
import React from 'react';
import Constants from 'expo-constants';
import {StyleSheet, Platform, Linking, ActivityIndicator, TextInput, AppState} from "react-native";
import {activateKeepAwake} from "@sayem314/react-native-keep-awake";
import {Container, Content, Icon, ListItem, List, Text, Left as NBLeft, Body, Right, Switch} from 'native-base';
import {hideMessage, showMessage} from "react-native-flash-message";
import API from '../api/';
import Alert from "../components/Alert";
import {show, hide} from "../components/NotificationBanner";
import PinPadModal from "../components/PinPad/PinPadModal";
import {prompt} from "../components/Prompt";
import Colors from "../constants/Colors";
import {SHOW_SERVER_CHOOSER, getSentryEnv, IS_ELO} from '../constants/Config';
import {URLS} from "../constants/Constants";
import WifiManager from "react-native-wifi-reborn";
import * as IntentLauncher from "expo-intent-launcher";
import ReactNativeForegroundService from "@supersami/rn-foreground-service";
import PollerService from "../services/PollerService";

const version = API.version;

const Left = ({children}) => {
  return <NBLeft style={{marginRight: 25, marginLeft: 5}}>{children}</NBLeft>
}

export default class SettingsScreen extends React.Component {
  static navigationOptions = {
    title: 'Settings',
  };

  devModeCount = 0;

  constructor(props) {
    super(props);

    this.state = {
      menuUpdateInfo: '',
      fullUpdateInfo: '',
      bundleUpdate: '',
      prefsUpdating: {},
      showDev: false,
      simulateError: false,
      requestHeaders: null,
      connectedWifi: '',
      lastPoll: 0,
      nextPoll: 0
    }

    this._loadSettings();
  }

  _loadSettings = async () => {
    let account = await API.getAccount();

    this.setState({
      account: account,
      kds_idle_screen: API.handheldDevice.preferences.kds_idle_screen,
      kds_autofocus: API.handheldDevice.preferences.kds_autofocus,
      keep_awake_on_battery: API.handheldDevice.preferences.keep_awake_on_battery,
      notifications: API.notifications
    })
  }

  componentDidMount() {
    this._mounted = true;
    // this.expoUpdateListener = Updates.addListener(this._expoUpdateEventListener);

    API.handheldDevice.on('update', this._forceUpdate);

    if (Platform.OS === 'android' && !IS_ELO) {
      AppState.addEventListener('change', this.updateSSID);
      this._focusListener = this.props.navigation.addListener('didFocus', this.updateSSID);
    }

    this._interval = setInterval(() => {
      if (this._mounted) {
        let secsTillNextPoll = moment(API._pollCompleteTime).add(API._pollInterval || 20, 'seconds').diff(moment(), 'seconds');
        const nextPoll = _.max([secsTillNextPoll, 0]);
        this.setState({
          lastPoll: moment().diff(moment(API._last_poll), 'seconds'),
          nextPoll: nextPoll > 0 ? `${nextPoll}s` : `Polling`
        })
      }
    }, 1000);
  }

  componentWillUnmount() {
    this._mounted = false;
    if (this._focusListener) this._focusListener.remove();
    if (Platform.OS === 'android' && !IS_ELO) AppState.removeEventListener('change', this.updateSSID);
    // if (this.expoUpdateListener) this.expoUpdateListener.remove();
    API.handheldDevice.off('update', this._forceUpdate);
    clearInterval(this._interval);
  }

  updateSSID = () => {
    WifiManager.getCurrentWifiSSID().then((wifi) => {
      if (this._mounted) {
        this.setState({
          connectedWifi: wifi
        })
      }
    })
  }

  _forceUpdate = () => {
    this.forceUpdate();
  }

  render() {
    let {config} = API;
    if(!config) return null;
    return (
      <Container>
        <Content>
          <List>
            <ListItem itemDivider>
              <Text>Configuration</Text>
            </ListItem>
            <ListItem icon first onPress={this._devicePressed}>
              <Left>
                <Icon style={styles.iconStyle} type={"MaterialIcons"} name={'perm-device-information'}/>
              </Left>
              <Body>
                <Text>Device</Text>
              </Body>
              <Right style={{flex: 1}}>
                <Text note>{API.handheldDevice.name}</Text>
                <Icon active4 name="chevron-right" type="Entypo"/>
              </Right>
            </ListItem>
            <ListItem icon onPress={this._goToConfigChooser}>
              <Left>
                <Icon style={styles.iconStyle} type={"Ionicons"} name={'ios-cog'}/>
              </Left>
              <Body>
                <Text>Device Profile</Text>
              </Body>
              <Right style={{flex: 1}}>
                <Text note>{config.config_name}</Text>
                <Icon name="chevron-right" type="Entypo"/>
              </Right>
            </ListItem>
            <ListItem icon onPress={this._doMenuUpdate}>
              <Left>
                <Icon style={styles.iconStyle} type={"MaterialIcons"} name={'restaurant-menu'}/>
              </Left>
              <Body>
                <Text>Update Menu</Text>
              </Body>
              <Right>
                {this.state.menuUpdateInfo ? <Text note>{this.state.menuUpdateInfo}</Text> :
                  <Icon name="chevron-right" type="Entypo"/>}
              </Right>
            </ListItem>
            <ListItem icon onPress={this._doFullUpdate}>
              <Left>
                <Icon style={styles.iconStyle} type={"MaterialIcons"} name={'refresh'}/>
              </Left>
              <Body>
                <Text>Refresh All Data</Text>
              </Body>
              <Right>
                {this.state.fullUpdateInfo ? <Text note>{this.state.fullUpdateInfo}</Text> :
                  <Icon name="chevron-right" type="Entypo"/>}
              </Right>
            </ListItem>

            {config?.allow_disabling_chirp && (
              <ListItem icon>
                <Left>
                  <Icon style={styles.iconStyle} type={"Ionicons"} name={'ios-volume-high'}/>
                </Left>
                <Body>
                  <Text>Play Notification Sounds</Text>
                </Body>
                <Right>
                  <Switch value={this.state.notifications} onValueChange={this._toggleNotifications}/>
                </Right>
              </ListItem>
            )}

            {Platform.OS === 'web' && document?.fullscreenEnabled && (
              <ListItem icon onPress={this.toggleFullscreen}>
                <Left><Icon style={styles.iconStyle} type={"MaterialIcons"} name={'fullscreen'}/></Left>
                <Body><Text>Toggle FullScreen</Text></Body>
                <Right>
                  <Icon name="chevron-right" type="Entypo"/>
                </Right>
              </ListItem>
            )}

            {Platform.OS === 'android' && (
              <ListItem icon>
                <Left><Icon style={styles.iconStyle} type={"MaterialIcons"} name={'wb-sunny'}/></Left>
                <Body><Text>Keep Awake On Battery</Text></Body>
                <Right>
                  {this.state.prefsUpdating['keep_awake_on_battery'] ?
                    <ActivityIndicator size={"small"} color={Colors.primary}/> :
                    <Switch value={this.state.keep_awake_on_battery}
                            onValueChange={(val) => this._updatePref('keep_awake_on_battery', val)}/>
                  }
                </Right>
              </ListItem>
            )}

            {config?.kds_stations?.length > 0 && (
              <ListItem icon last>
                <Left>
                  <Icon style={styles.iconStyle} type={"MaterialCommunityIcons"} name={'target'}/>
                </Left>
                <Body>
                  <Text>Auto-Focus Current Orders</Text>
                  <Text note numberOfLines={1}>Focus KDS Current Orders view after 3 minutes of no interaction and there
                    are new orders</Text>
                </Body>
                <Right>
                  {this.state.prefsUpdating['kds_autofocus'] ?
                    <ActivityIndicator size={"small"} color={Colors.primary}/> :
                    <Switch value={this.state.kds_autofocus}
                            onValueChange={(val) => this._updatePref('kds_autofocus', val)}/>
                  }
                </Right>
              </ListItem>
            )}

            {config?.kds_stations?.length > 0 && (
              <ListItem icon last>
                <Left>
                  <Icon style={styles.iconStyle} type={"MaterialCommunityIcons"} name={'dog'}/>
                </Left>
                <Body>
                  <Text>KDS Idle Images</Text>
                  <Text note numberOfLines={1}>Show images of animals on the KDS view when there are no orders.</Text>
                </Body>
                <Right>
                  {this.state.prefsUpdating['kds_idle_screen'] ?
                    <ActivityIndicator size={"small"} color={Colors.primary}/> :
                    <Switch value={this.state.kds_idle_screen}
                            onValueChange={(val) => this._updatePref('kds_idle_screen', val)}/>
                  }
                </Right>
              </ListItem>
            )}

            <ListItem icon onPress={() => {
            }}>
              <Left>
                <Icon style={styles.iconStyle} type={"Feather"} name={'clock'}/>
              </Left>
              <Body>
                <Text>Timezone</Text>
              </Body>
              <Right>
                <Text note>{API.main_customer?.timezone}</Text>
              </Right>
            </ListItem>

            {Platform.OS === 'android' && !IS_ELO && (
              <ListItem icon onPress={() => {
                IntentLauncher.startActivityAsync(
                  IntentLauncher.ACTION_WIFI_SETTINGS
                )
              }}>
                <Left>
                  <Icon style={styles.iconStyle} type={"Feather"} name={'wifi'}/>
                </Left>
                <Body>
                  <Text>Wifi Network</Text>
                </Body>
                <Right>
                  <Text note>{this.state.connectedWifi || 'Not Connected'}</Text>
                </Right>
              </ListItem>
            )}

            {Platform.OS === 'android' && !IS_ELO && (
              <ListItem icon onPress={() => {
                IntentLauncher.startActivityAsync(IntentLauncher.ACTION_DEVICE_INFO_SETTINGS)
              }}>
                <Left>
                  <Icon style={styles.iconStyle} type={"Feather"} name={'info'}/>
                </Left>
                <Body>
                  <Text>Device Info</Text>
                </Body>
                <Right>
                  <Icon name="chevron-right" type="Entypo"/>
                </Right>
              </ListItem>
            )}

            <ListItem itemDivider>
              <Text>Bulk Actions</Text>
            </ListItem>

            <ListItem icon onPress={this._closeOpenOrders}>
              <Left>
                <Icon style={styles.iconStyle} type={"Feather"} name={'check-circle'}/>
              </Left>
              <Body>
                <Text>Close Open Orders</Text>
              </Body>
              <Right>
                <Icon name="chevron-right" type="Entypo"/>
              </Right>
            </ListItem>

            <ListItem icon onPress={this._clearClosedOrders}>
              <Left>
                <Icon style={styles.iconStyle} type={"MaterialCommunityIcons"} name={'notification-clear-all'}/>
              </Left>
              <Body>
                <Text>Clear Closed Orders</Text>
              </Body>
              <Right>
                <Icon name="chevron-right" type="Entypo"/>
              </Right>
            </ListItem>

            <ListItem itemDivider>
              <Text>Build Info</Text>
            </ListItem>

            <ListItem icon onPress={this._releaseChannelPressed}>
              <Left>
                <Icon style={styles.iconStyle} type={"Feather"} name={'git-branch'}/>
              </Left>
              <Body>
                <Text>Version</Text>
              </Body>
              <Right style={{flex: 1}}>
                <Text note>{(Platform.OS === "web" ? "Web" : "Android") + " " + (version || 'dev')}</Text>
              </Right>
            </ListItem>

            <ListItem icon onPress={() => SHOW_SERVER_CHOOSER && this._navigate("ServerChooserSettings")}>
              <Left>
                <Icon style={styles.iconStyle} type={"FontAwesome"} name={'server'}/>
              </Left>
              <Body>
                <Text>Server</Text>
              </Body>
              <Right style={{flex: 1}}>
                <Text note>{axios.defaults.baseURL}</Text>
                {SHOW_SERVER_CHOOSER && <Icon name="chevron-right" type="Entypo"/>}
              </Right>
            </ListItem>

            <ListItem icon onPress={() => this._navigate("Changelog")}>
              <Left>
                <Icon style={styles.iconStyle} type={"Octicons"} name={'versions'}/>
              </Left>
              <Body>
                <Text>Changelog</Text>
              </Body>
              <Right>
                <Icon name="chevron-right" type="Entypo"/>
              </Right>
            </ListItem>

            <ListItem itemDivider>
              <Text>Support</Text>
            </ListItem>

            <ListItem icon onPress={this._emailSupport}>
              <Left><Icon style={styles.iconStyle} type={"Entypo"} name={'email'}/></Left>
              <Body>
                <Text>E-mail Us</Text>
              </Body>
              <Right>
                <Text note style={{color: 'blue'}}>support@bbot.menu</Text>
                <Icon name="chevron-right" type="Entypo"/>
              </Right>
            </ListItem>

            {API.is_admin && (
              <ListItem icon onPress={() => this._navigate('CustomerChooserSettings')}>
                <Left><Icon style={styles.iconStyle} type={"MaterialCommunityIcons"} name={'account-switch'}/></Left>
                <Body><Text>Change Customer</Text></Body>
                <Right style={{flex: 1}}>
                  <Text note>{API.main_customer.customer_name}</Text>
                  <Icon name="chevron-right" type="Entypo"/>
                </Right>
              </ListItem>
            )}

            <ListItem icon onPress={this._logout}>
              <Left><Icon style={styles.iconStyle} type={"Entypo"} name={'log-out'}/></Left>
              <Body><Text>Logout</Text></Body>
              <Right style={{flex: 1}}>
                <Text note>{this.state.account}</Text>
                <Icon name="chevron-right" type="Entypo"/>
              </Right>
            </ListItem>

            <ListItem icon>
              <Left><Icon style={styles.iconStyle} type={"MaterialCommunityIcons"} name={"update"}/></Left>
              <Body><Text>Last/Next Update</Text></Body>
              <Right style={{flex: 1}}>
                <Text note>{this.state.lastPoll}s/{this.state.nextPoll}</Text>
              </Right>
            </ListItem>

            {this._getDevMenu()}
          </List>


          {this.state.simulateError && <MissingComponentWhichSimulatesUncaughtError/>}

        </Content>
      </Container>
    )

  }

  _devicePressed = () => {
    PinPadModal.show((user) => {
      if (user.isManager())
        this._navigate('DeviceRegistrationSettings', {stayAfterSave: true})
      else {
        showMessage({
          message: "Invalid Pin",
          autoHide: true,
          backgroundColor: 'red',
          duration: 5000
        })
      }
    })
  }

  _goToConfigChooser = () => {
    PinPadModal.show(user => {
      if (user.isManager()) {
        API.lastPin = user.pin_number;
        this._navigate('ConfigChooserSettings', {forwardTo: 'SettingsScreen'});
      }
    })
  }

  _getDevMenu = () => {
    if (!this.state.devMode) return null;
    return [
      <ListItem itemDivider key={'dev_title'}>
        <Text>Developer Menu</Text>
      </ListItem>,
      <ListItem icon key={'dev_0'}>
        <Left><Icon styles={styles.iconStyle} type={"MaterialIcons"} name={"desktop-mac"}/></Left>
        <Body>
          <Text>Environment</Text>
        </Body>
        <Right style={{flex: 1}}>
          <Text note>{getSentryEnv()}</Text>
        </Right>
      </ListItem>,
      Platform.OS === 'web' && (
        <ListItem icon key={'dev_build'}>
          <Left><Icon styles={styles.iconStyle} type={"MaterialIcons"} name={"desktop-mac"}/></Left>
          <Body>
            <Text>Build Time</Text>
          </Body>
          <Right style={{flex: 1}}>
            <Text note>{__DEV__ ? 'DEVBUILD' : __BUILDTIME__}</Text>
          </Right>
        </ListItem>
      ),
      Platform.OS === 'android' && (
        <ListItem icon key={'check_poller'} onPress={() => {
          if(!ReactNativeForegroundService.is_running()){
            Alert.alert("Service Error", "The foreground service is not running", () => {
              API._pollerService = new PollerService(API.taskPoll);
            })
          } else if(!ReactNativeForegroundService.is_task_running('poller')){
              Alert.alert("Task Error", "The poller task is not running", () => {
                API._pollerService = new PollerService(API.taskPoll);
              });
          } else {
            Alert.alert("Success", "Everything appears to be running normally");
          }
        }}>
          <Left><Icon style={styles.iconStyle} type={"AntDesign"} name={'android'}/></Left>
          <Body>
            <Text>Check Poller Status</Text>
          </Body>
          <Right>
            <Icon name="chevron-right" type="Entypo"/>
          </Right>
        </ListItem>
      ),
      <ListItem icon key={'show_recall'} onPress={() => {API.config.kds_show_recall_button = true}}>
        <Left><Icon style={styles.iconStyle} type={"FontAwesome"} name={'undo'}/></Left>
        <Body>
          <Text>Allow Recall Button on KDS</Text>
        </Body>
        <Right>
          <Icon name="chevron-right" type="Entypo"/>
        </Right>
      </ListItem>,
      <ListItem icon key={'dev_2'} onPress={this._testError}>
        <Left><Icon style={styles.iconStyle} type={"Entypo"} name={'bug'}/></Left>
        <Body>
          <Text>Test Error Handler</Text>
        </Body>
        <Right>
          <Icon name="chevron-right" type="Entypo"/>
        </Right>
      </ListItem>,
      <ListItem icon key={'dev_2-5'} onPress={this._testUncaughtError}>
        <Left><Icon style={styles.iconStyle} type={"Entypo"} name={'bug'}/></Left>
        <Body>
          <Text>Test Uncaught Error</Text>
        </Body>
        <Right>
          <Icon name="chevron-right" type="Entypo"/>
        </Right>
      </ListItem>,
      <ListItem icon key={'dev_3'} onPress={this._testShowMessage}>
        <Left>
          <Icon style={styles.iconStyle} type={"Entypo"} name={'bug'}/>
        </Left>
        <Body>
          <Text>Test Show Message</Text>
        </Body>
        <Right>
          <Icon name="chevron-right" type="Entypo"/>
        </Right>
      </ListItem>,
      <ListItem icon key={'dev_4'} onPress={this._testShowBanner}>
        <Left><Icon style={styles.iconStyle} type={"Entypo"} name={'bug'}/></Left>
        <Body>
          <Text>Test Show Banner</Text>
        </Body>
        <Right><Icon name="chevron-right" type="Entypo"/></Right>
      </ListItem>,
      <ListItem icon key={'dev_5'} onPress={API.playNotificationSound}>
        <Left><Icon style={styles.iconStyle} type={"Entypo"} name={'sound'}/></Left>
        <Body>
          <Text>Play Notification Sound</Text>
        </Body>
        <Right><Icon name="chevron-right" type="Entypo"/></Right>
      </ListItem>,
      <ListItem icon key={'dev_6'} onPress={this._testShowHeaders}>
        <Left><Icon style={styles.iconStyle} type={"Entypo"} name={'bug'}/></Left>
        <Body>
          <Text>Show Request Headers</Text>
        </Body>
        <Right><Icon name="chevron-right" type="Entypo"/></Right>
      </ListItem>,
      <ListItem icon key={'dev_1'} onPress={() => {
        this.props.navigation.navigate('GPSDebug')
      }}>
        <Left><Icon style={styles.iconStyle} type={"Entypo"} name={'hair-cross'}/></Left>
        <Body>
          <Text>GPS Debug</Text>
        </Body>
        <Right>
          <Icon name="chevron-right" type="Entypo"/>
        </Right>
      </ListItem>,
      this.state.requestHeaders ? (
        <ListItem key={'requestHeaders'}>
          <Body>
            <TextInput multiline={true} numberOfLines={10}>{this.state.requestHeaders}</TextInput>
          </Body>
        </ListItem>
      ) : null
    ]
  }

  _navigate = (screen, params) => {
    this.props.navigation.navigate(screen, params);
  }

  _doMenuUpdate = async () => {
    this.setState({menuUpdateInfo: "Updating..."});
    await API.doMenuUpdate();
    this.setState({menuUpdateInfo: "Success!"});
    setTimeout(() => {
      if (this._mounted) this.setState({menuUpdateInfo: ""});
    }, 3000);
  }

  _doFullUpdate = async () => {
    this.setState({fullUpdateInfo: "Updating..."});
    await API.doMenuUpdate(true);
    this.setState({fullUpdateInfo: "Success!"});
    setTimeout(() => {
      if (this._mounted) this.setState({fullUpdateInfo: ""});
    }, 3000);
  }

  _toggleNotifications = () => {
    API.notifications = !API.notifications;
    this.setState({
      notifications: API.notifications
    })
  }

  _updatePref = async (key, val) => {
    const {prefsUpdating} = this.state;
    prefsUpdating[key] = true;
    this.setState({prefsUpdating})
    await API.handheldDevice.updatePrefs({[key]: val});
    prefsUpdating[key] = false;
    this.setState({
      [key]: API.handheldDevice.preferences[key],
      prefsUpdating
    })
    if (key === 'keep_awake_on_battery') activateKeepAwake();
  }

  _logout = async () => {
    await API.logout();
    this._navigate('Login');
  }

  _testError = () => {
    try {
      unknownFunction();
    } catch (err) {
      API.sendCaughtError(err);
    }
    showMessage({
      message: "Error triggered! It should appear in Sentry shortly.",
      autoHide: true,
      backgroundColor: 'red',
      duration: 5000
    })
  }

  _testUncaughtError = () => {
    this.setState({
      simulateError: true
    })
  }

  _clearClosedOrders = () => {
    API.clearClosedOrders()

    showMessage({
      message: "Closed orders have been cleared",
      position: 'top',
      floating: true,
      type: 'success'
    })
    API.trigger('orders');
  }

  _closeOpenOrders = () => {
    this.setState({closingOrders: true});

    PinPadModal.show(() => {
      prompt({
        title: "Close orders older than:",
        placeholder: '(number of hours)',
        suffix: 'hours old',
        onSubmit: (hours_txt) => {
          let hours = '';
          if (hours_txt.includes(':')) {
            hours = moment.duration(hours_txt).asHours()
          } else {
            hours = parseFloat(hours_txt);
          }
          if (isNaN(hours)) {
            Alert.alert("Invalid number", `"${hours_txt}" does not appear to be a valid number. Please try again`);
          } else {
            let description = hours > 0 ? `older than ${hours} hours old` : '';
            Alert.alert(
              "Are you sure?",
              `All open orders ${description} will be set to their closed status. Guests will NOT be notified`,
              [
                {
                  text: 'Cancel',
                  style: 'cancel'
                },
                {
                  text: 'Continue',
                  onPress: async () => {
                    let response = await API.closeOpenOrders(hours);

                    if (response.success) {
                      const message = response.orders_closed > 0
                        ? `Successfully closed ${response.orders_closed} orders` :
                        'No open orders found'
                      showMessage({
                        message,
                        type: "success",
                        position: "top"
                      })
                    } else {
                      showMessage({
                        message: response.error,
                        type: "error",
                        position: "top"
                      })
                    }
                  }
                }
              ]
            );
          }
        }
      })
    }, () => {
      this.setState({closingOrders: false})
    })
  }

  toggleFullscreen = () => {
    if (document.fullscreenElement)
      closeFullscreen();
    else
      openFullscreen();
  }

  _releaseChannelPressed = () => {
    this.devModeCount++;
    if (this.devModeCount === 7) this.setState({devMode: true})
  }

  _emailSupport = () => {
    const url = 'mailto:support@bbot.menu?subject=Terminal%20Support';
    if (Platform.OS === 'web') {
      window.open(url, '_blank')
    } else {
      Linking.openURL(url);
    }
  }

  _testShowMessage = () => {
    if (!this._messageShown) {
      this._messageShown = true;
      showMessage({
        floating: true,
        position: 'top',
        autoHide: false,
        hideOnPress: false,
        message: "This is not dismissable"
      })
    } else {
      this._messageShown = false;
      hideMessage();
    }
  }

  _testShowBanner = () => {
    if (!this._bannerShown) {
      this._bannerShown = true;
      show({
        floating: true,
        position: 'top',
        autoHide: false,
        hideOnPress: false,
        message: "This is not dismissable"
      })
    } else {
      this._bannerShown = false;
      hide();
    }
  }

  _testShowHeaders = async () => {
    try {
      const result = await axios.get(URLS.DEBUG.SHOW_HEADERS);
      this.setState({
        requestHeaders: result.data
      })
    } catch (err) {
    }
  }
}

/* View in fullscreen */
export const openFullscreen = () => {
  var elem = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) { /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE/Edge */
    elem.msRequestFullscreen();
  }
}

/* Close fullscreen */
export const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) { /* Firefox */
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { /* IE/Edge */
    document.msExitFullscreen();
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  iconStyle: {
    alignSelf: 'center',
    height: 24,
    width: 24,
    fontSize: 24
  },
  titleContainer: {
    paddingHorizontal: 15,
    paddingTop: 15,
    paddingBottom: 15,
    flexDirection: 'row',
  },
  titleIconContainer: {
    marginRight: 15,
    paddingTop: 2,
  },
  sectionHeaderContainer: {
    backgroundColor: '#e8e8e8',
    paddingVertical: 8,
    paddingHorizontal: 15,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: '#ededed',
  },
  sectionHeaderText: {
    fontSize: 14,
  },
  sectionContentContainer: {
    paddingTop: 8,
    paddingBottom: 12,
    paddingHorizontal: 15,
  },
  sectionContentText: {
    color: '#808080',
    fontSize: 14,
  },
  nameText: {
    fontWeight: '600',
    fontSize: 18,
  },
  slugText: {
    color: '#a39f9f',
    fontSize: 14,
    backgroundColor: 'transparent',
  },
  descriptionText: {
    fontSize: 14,
    marginTop: 6,
    color: '#4d4d4d',
  },
  colorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  colorPreview: {
    width: 17,
    height: 17,
    borderRadius: 2,
    marginRight: 6,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: '#ccc',
  },
  colorTextContainer: {
    flex: 1,
  },
  arrow: {width: 8, height: 13}
});
