import React, {Component} from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  Text,
  Image,
  ActivityIndicator,
  TouchableWithoutFeedback,
  View,
  ScrollView,
  Button,
  Platform,
  Pressable,
} from "react-native";
import Alert from "../../Alert";
import {Icon} from 'native-base';
import OrderHelper from "../../../helpers/OrderHelper";
import EStyleSheet from "react-native-extended-stylesheet";
import Colors from "../../../constants/Colors";
import ScrollButton from "../../ScrollButton";
import API from "../../../api";
import KDSOrderItem from "./KDSOrderItem";
import StatusIcon from "../../StatusIcon";
import {StatusIcons} from "../../../constants/Constants";
import OrderCheckoutInfo from "../../Order/OrderCheckoutInfo";
import OrderStaffNotes from "../../Order/OrderStaffNotes";
//import {ScalableText as Text} from "../../components/Text";

moment.updateLocale('en', {
  relativeTime: {
    s: 'just now'
  }
});

export const HeaderColors = {
  server_delivery: {
    name: 'Server Delivery',
    color: Colors.special,
    icon: <Image source={StatusIcons.large.wait_for_runner}/>
  },
  patron_pickup: {
    name: 'Pickup',
    color: Colors.ternary,
    icon: <Image source={StatusIcons.large.onshelf}/>
  },
  runner_with_cc: {
    name: 'Runner',
    color: Colors.quaternary,
    icon: <Icon name={'running'} type={"FontAwesome5"}/>
  },
  robot_delivery: {
    name: 'Robot',
    color: Colors.primary,
    icon: <Image source={StatusIcons.large.botsent}/>
  },
  driver_delivery: {
    name: 'Delivery',
    color: Colors.secondary,
    icon: <Image source={StatusIcons.large.wait_for_driver}/>
  },
  runner_nofeedback: {
    name: 'Runner',
    color: Colors.accent,
    icon: <Icon name={'check'}/>
  },
  pickup_nofeedback: {
    name: 'Pickup',
    color: Colors.pickupNoFeedback,
    icon: <Icon name={'check'}/>
  },
  catering: {
    name: 'Catering',
    color: Colors.success,
    icon: <Image source={StatusIcons.large.making}/>
  },
  driver_delivery_with_expo: {
    color: Colors.secondary,
  },
  patron_pickup_with_expo: {
    color: Colors.ternary,
  }
}

export default class KDSOrderTicket extends Component {

  static defaultProps = {
    onPress: () => {
    }
  }


  state = {
    processing: false,
    hasScroll: false,
    showPrev: false,
    showMore: false,
    more: 0
  }

  _lastScrollOffset = 0;
  _lastModified = null;

  constructor(props) {
    super(props);

    this.viewabilityConfig = {
      itemVisiblePercentThreshold: 75
    }

    this.state.data = this._getOrderItemsData()
  }

  componentDidMount() {
    this._mounted = true;
    let {order} = this.props;
    this._lastModified = order.last_modified;
    order.on('update', this._forceUpdate);
  }

  componentWillUnmount() {
    this._mounted = false;
    const {order} = this.props;
    order.off('update', this._forceUpdate);
  }

  _forceUpdate = () => {
    if (this._mounted) {
      this.setState({
        data: this._getOrderItemsData()
      });
    }
  }

  _getOrderItemsData = () => {
    let now = moment();
    let {order} = this.props;
    let isOpened = order.status !== 'waiting';
    let isFuture = order.snooze_till > now;

    return (isOpened || isFuture) ? Object.values(order.grouped_items) : [];
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    let isModified = !nextProps.order.last_modified.isSame(this._lastModified);
    if (isModified) this._lastModified = nextProps.order.last_modified;
    return nextProps.order !== this.props.order ||
      nextProps.columns != this.props.columns ||
      nextProps.height != this.props.height ||
      isModified ||
      !_.isEqual(this.state, nextState)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.order !== prevProps.order) {
      this.props.order.on('update', this._forceUpdate);
      this._forceUpdate();
    }
  }

  render() {
    const now = moment();
    let {order, columns, height, onToggle, isPrevRelated, isNextRelated} = this.props;
    let isOpened = order.status !== 'waiting' && !order.is_snoozed;
    const badgeInfo = HeaderColors[order.fulfillment_method] || {color: Colors.darkGray};
    return (
      <>
        {isPrevRelated && <View style={[styles.relatedLink, styles.relatedLinkPrev, {backgroundColor: badgeInfo.color}]}/>}
        {isNextRelated && <View style={[styles.relatedLink, styles.relatedLinkNext, {backgroundColor: badgeInfo.color}]}/>}
        { API.config.kds_group_related_tickets && order.related_orders.length > 0 && (
          <Pressable style={styles.collapseButton} onPress={() => onToggle(order.checkout_id)}>
            <Icon type={'MaterialCommunityIcons'} name={'arrow-collapse'}
                  style={{color: 'white', fontSize: 20}}/>
          </Pressable>
        )}
        <View style={[styles.orderTicketContainer, {flex: 1 / columns, height: height}]}>
          {this.state.processing && (
            <View style={styles.orderTicketLoading}><ActivityIndicator color={Colors.primary}/></View>
          )}
          <View style={styles.orderTicket}>
            <TicketHeader
              fulfillment={badgeInfo}
              onPress={this._onPress}
              order={order}
            />
            <View style={styles.orderTicketContent} onLayout={this._onContentLayout}>
              {
                !isOpened && !order.is_snoozed && (
                  <TouchableWithoutFeedback onPress={this._onPress}>
                    <View style={{padding: 5, flex: 1}}>
                      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                        <Text style={{color: Colors.darkGray, fontSize: 14}}>New Order. Tap to View</Text>
                        <Text
                          style={{color: Colors.darkGray}}>({order.items.length} item{order.items.length > 1 ? 's' : ''})</Text>
                      </View>
                    </View>
                  </TouchableWithoutFeedback>
                )
              }
              {(isOpened || order.is_snoozed) && (
                <TouchableWithoutFeedback onPress={this._onPress}>
                  <View style={{flex: 1}}>
                    <ScrollView
                      contentContainerStyle={{flexGrow: 1}}
                      ref={fl => this._scrollView = fl}
                      onContentSizeChange={this._onItemsLayout}
                      onScroll={this._onScroll}
                      scrollEventThrottle={16}
                      onTouchStart={this._onTouchStart}
                      onTouchEnd={this._onTouchEnd}
                      nestedScrollEnabled={true}
                      onStartShouldSetResponder={evt => true}
                    >
                      <View style={{borderBottomWidth: 3, borderBottomColor: Colors.gray, paddingBottom: 5}}>
                        {this.state.data.map(entry => this._renderItem(entry))}
                      </View>
                      {order.staff_notes.length > 0 && (
                        <OrderStaffNotes notes={order.staff_notes}/>
                      )}
                      <OrderCheckoutInfo
                        checkoutInfo={order.checkout_info}
                        blockStyle={{padding: 10}}
                        labelStyle={{fontWeight: 'bold', flex: 1}}
                        valueStyle={{flex: 2, flexWrap: 'wrap'}}
                      />
                      {
                        isOpened && !order.time_closed && (
                          <View style={{flex: 1, justifyContent: 'flex-end', marginTop: 10}}>
                            <UserDesiredTime time={order.user_desired_time}/>
                            {isOpened &&
                            <NextStatusButton
                              order={order}
                              onPress={this._setStatus}
                              style={{backgroundColor: badgeInfo.color}}
                            />}
                          </View>
                        )
                      }
                      {!isOpened && order.is_snoozed && (
                        <View style={{flex: 1, justifyContent: 'flex-end'}}>
                          <UserDesiredTime time={order.user_desired_time} snoozed_till={order.snooze_till}/>
                          <Button title={'UN-SNOOZE'} onPress={this._unSnooze}/>
                        </View>
                      )
                      }
                    </ScrollView>
                    {
                      !isOpened && !order.is_snoozed && order.user_desired_time > now && (
                        <UserDesiredTime time={order.user_desired_time}/>
                      )
                    }
                    {
                      !!order.time_closed && (
                        <ClosedOrderInfo order={order} color={badgeInfo.color}/>
                      )
                    }
                    <ScrollButton
                      visible={this.state.hasScroll}
                      enabled={this.state.showPrev}
                      onPress={() => {
                        this._scroll(-1)
                      }}
                      direction={'up'}/>
                    <ScrollButton
                      visible={this.state.hasScroll}
                      enabled={this.state.showMore}
                      onPress={() => {
                        this._scroll(1)
                      }}
                      direction={'down'}/>
                  </View>
                </TouchableWithoutFeedback>
              )}
            </View>
          </View>
        </View>
      </>
    )
  }

  _scroll = (dir) => {
    const scrollAmount = this._viewHeight - 30;
    let y = dir > 0 ? this._lastScrollOffset + scrollAmount : this._lastScrollOffset - scrollAmount;
    this._scrollView.scrollTo({
      x: 0,
      y: y
    })
  }

  _onScroll = (event) => {
    let {nativeEvent} = event;
    this._lastScrollOffset = nativeEvent.contentOffset.y;
    this._calcVisibleItems(nativeEvent);
  }

  _onTouchStart = (event) => {
    this._showModal = true;
    //const {setParentScroll} = this.props;
    //setParentScroll(false);
    // somehow prevent
  }

  /**
   * Only one of onTouchEnd or onDragEnd will ever be called:
   */

  _onTouchEnd = (event) => {
    if (this._showModal)
      this._onPress();
  }

  _calcVisibleItems = (nativeEvent) => {
    let offsetY = nativeEvent.contentOffset.y;
    let totalY = nativeEvent.contentSize.height;
    let layoutY = nativeEvent.layoutMeasurement.height;
    /*  This code calculates how many items are currently visible in the scrollview. Can be used to display "x more items"...
        let visible = 0;
        let before = 0;
        let after = 0;
        for(let i=0; i<this._itemRefs.length; i++){
          let item = this._itemRefs[i];
          if(item.yOffset < offsetY) before++;
          else if(item.yOffset >= offsetY && item.yOffset < (offsetY + layoutY)){
            visible++;
          }
          if(item.yOffset > offsetY + layoutY){
            after++;
          }
        }*/
    this.setState({
      hasScroll: totalY > layoutY,
      showPrev: offsetY !== 0,
      showMore: offsetY < (totalY - layoutY),
      //prev: before,
      //more: after,
    });
  }

  _renderItem = items => {
    //let items = data.item;
    let item = items[0];
    return (
      <KDSOrderItem
        key={item.orderitemid}
        items={items}
        ref={this._setRef}
        onPress={this._itemPressed}
      />
    )
  }

  _itemPressed = () => {
    this._showModal = false;
  }

  _itemRefs = []
  _setRef = (ref, yOffset) => {
    this._itemRefs.push(ref);
  }

  _onContentLayout = event => {
    this._viewHeight = event.nativeEvent.layout.height;
    this._triggerManualScrollButtonUpdate();
  }

  /**
   * Only this gets called when the FlatList Updates (Changing order from waiting -> making)
   */
  _onItemsLayout = (width, height) => {
    this._itemsHeight = height;
    if (this._viewHeight) {
      this._triggerManualScrollButtonUpdate();
    }
  }

  _triggerManualScrollButtonUpdate() {
    let fakeEvent = {
      contentOffset: {y: 0},
      contentSize: {height: this._itemsHeight},
      layoutMeasurement: {height: this._viewHeight}
    }
    this._calcVisibleItems(fakeEvent);
  }

  _onPress = () => {
    let {order, onPress} = this.props;
    if (this.state.processing) return;

    if (order.status === 'waiting' && !order.kds_future) { // todo: don't advance if order.snooze_till > now
      const nextStatus = order.nextStatus();
      if (nextStatus.key)
        this._setStatus(nextStatus.key);
      else {
        Alert.alert("Error", "Could not determine the next status for fulfillment method '" + order.fulfillment_method + "'." +
          "This likely means critical data failed to download. From Settings Screen, press 'Refresh All Data', and if that does not work please contact support");
      }
    } else {
      onPress(order);
    }
  }

  _setStatus = (status) => {
    let {order} = this.props;
    this._showModal = false;
    this.setState({
      processing: true
    });

    const items = order.getActionableItems();

    OrderHelper.changeItemsState(items, status).then((response) => {
      this._lastModified = order.last_modified;
      if (this._mounted) {
        this.setState({
          processing: false
        });
      }
    })
  }

  _unSnooze = async () => {
    this._showModal = false;
    const {order} = this.props;
    this.setState({processing: true});
    let response = await API.snoozeOrder(order, null);
    if (this._mounted)
      this._lastModified = order.last_modified;
    this.setState({processing: false})
  }
}

export const TicketHeader = ({onPress, order, fulfillment, onToggle}) => {
  const isOpened = order.status !== 'waiting';
  const {fulfillment_method} = order;
  const fulfillment_pretty_name = API.menuData.fulfillment_pretty_names?.[fulfillment_method] || fulfillment.name;

  return (
    <TouchableWithoutFeedback onPress={onPress} accessible={true}
                              accessibilityLabel={"ticket_for_order_id_" + order.orderId}>
      <View
        style={[styles.orderTicketHeader, isOpened ? styles.orderTicketHeaderOpened : null, {backgroundColor: fulfillment.color}]}>
        <View style={styles.headerIcon}>
          <StatusIcon status={order.status} style={styles.orderStatusIcon} color={'white'}/>
        </View>
        <View style={styles.headerLeft}>
          <Text style={styles.orderTicketOrderNumber}>#{order.orderNumber}</Text>
          <TicketRelatedOrders orders={order.related_orders}/>
        </View>
        <View style={styles.headerCenter}>
          <View style={{flex: 1, alignItems: 'center'}}>
            <Text
              style={{
                color: 'white',
                flex: 1,
                textAlign: 'center'
              }}>
              {order.location ? order.location.locationName : 'unknown'}
            </Text>
            <Text style={{color: '#ccc', flex: 1, textAlign: 'center', fontSize: 12}}>{fulfillment_pretty_name}</Text>
          </View>
        </View>
        <View style={styles.headerRight}>
          <TicketOrderTime time={order.time}/>
          <Icon type={"MaterialCommunityIcons"} name={'dots-vertical-circle'}
                style={{color: 'white', fontSize: 15}}/>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

/**
 * NextStatusButton
 * @param props
 * @returns {NextStatusButton}
 */

const NextStatusButton = (props) => {
  let {order, onPress, style} = props;
  let nextStatus = order.nextStatus();
  let disabled = false;
  let button_text = '';
  let next_status = '';
  if (nextStatus.key) {
    next_status = nextStatus.key;
    button_text = "SET " + nextStatus.value;
  } else {
    return null;
  }
  if (order.fulfillment_method === 'robot_delivery'
    && (
      // From the Ticket, we want to protect both the current and next status. If you want to make as delivered,
      // use the OrderStatusChanger.
      API.menuData.bot_protected_statuses.includes(next_status) ||
      API.menuData.bot_protected_statuses.includes(order.status)
    )
  ) {
    button_text = API.menuData.status_pretty_names[order.status];
    style = {backgroundColor: 'grey'}
    disabled = true;
  }
  const actionableItems = order.getActionableItems();

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        onPress(next_status)
      }}
      disabled={disabled}
      accessible={true}
      accessibilityLabel={"advance_order_id_" + order.orderId}
    >
      <View style={[styles.nextStatusButton, style]}>
        <Text style={styles.nextStatusButtonText}>{button_text}</Text>
        <Text style={{
          color: 'white',
          fontWeight: 'normal',
          fontSize: 12,
          paddingLeft: 5
        }}>({actionableItems.length} item{actionableItems.length > 1 ? 's' : ''})</Text>
      </View>
    </TouchableWithoutFeedback>
  )
};

export const TicketRelatedOrders = ({orders}) => {
  if (!orders.length) return null;
  return (
    <View style={{flexDirection: 'row'}}>
      <Icon type={"Entypo"} name={"link"} style={{color: Colors.light, fontSize: 14}}/>
      <Text style={styles.related_orders}>{_.map(orders, 'orderNumber').join(', ')}</Text>
    </View>
  )
}

/**
 *
 * @param props
 * @returns {null|JSX.Element}
 * @constructor
 */

export const UserDesiredTime = (props) => {
  const {time, snoozed_till, color} = props;
  const HALF_HOUR_FROM_NOW = moment().add(30, 'minutes');
  if (!time && !API.menuData.allow_order_ahead) return null;

  let time_format = time && time.isSame(moment(), 'day') ? '[Today at] LT' : 'MMM D, LT';
  let snooze_format = snoozed_till && snoozed_till.isSame(moment(), 'day') ? 'LT' : 'MMM D, LT';

  let bgColor = color || (time ? (time.isBefore(HALF_HOUR_FROM_NOW) ? '#ee312e' : '#ff9f00') : '#ff9f00');
  let formattedString = time ? "For " + time.format(time_format) : "ASAP";
  return (
    <View style={{alignItems: 'center', justifyContent: 'center', backgroundColor: bgColor}}>
      <Text style={{color: 'white'}}>{formattedString}</Text>
      {!!snoozed_till && <Text style={{color: 'yellow'}}>Snoozed till {snoozed_till.format(snooze_format)}</Text>}
    </View>
  )
};

/**
 *
 * @param order
 * @param color
 * @returns {JSX.Element}
 * @constructor
 */
export const ClosedOrderInfo = ({order, color}) => {
  let {time, user_desired_time, time_closed} = order;

  let time_format = (time.isSame(moment(), 'day')) ? '[today at] LT' : 'MMM D, LT';
  let desired_format = (user_desired_time && order.user_desired_time.isSame(order.time, 'day')) ? 'LT' : 'MMM D, LT';
  let desiredTimeStr = user_desired_time ? order.user_desired_time.format(desired_format) : 'ASAP';
  let delivered_format = time_closed.isSame(moment(), 'day') ? '[today at] LT' : 'MMM D, LT';

  return (
    <View style={{alignItems: 'center', justifyContent: 'center', backgroundColor: Colors.darkGray, padding: 3}}>
      <Text style={{color: 'white'}}>
        Placed <Bold>{time.format(time_format)}</Bold> for <Bold>{desiredTimeStr}</Bold>
      </Text>
      <Text style={{color: 'white'}}>
        {order.pretty_status} <Bold>{order.time_closed.format(delivered_format)}</Bold>
      </Text>
    </View>
  )
}

const Bold = ({children}) => {
  return (<Text style={{fontWeight: 'bold'}}>{children}</Text>)
}


/**
 * Time displayed on the top right corner of the ticket
 */
export class TicketOrderTime extends React.PureComponent {
  componentDidMount() {
    this._mounted = true;
    // update the clock every 30 seconds if the time is less then 1 hour from present
    if (this.props.time > moment().subtract(1, 'hour'))
      this.interval = setInterval(this._forceUpdate, 30000);
  }

  componentWillUnmount() {
    this._mounted = false;
    clearInterval(this.interval);
  }

  _forceUpdate = () => {
    if (this._mounted)
      this.forceUpdate();
  }

  render() {
    let {time} = this.props;
    return (
      <View>
        <Text style={{color: 'white'}}>{time.fromNow(true)}</Text>
      </View>
    )
  }
}

export const styles = EStyleSheet.create({
  orderStatusIcon: {
    tintColor: 'white',
    height: 24
  },
  orderTicketLoading: {
    position: 'absolute',
    margin: 10,
    top: 0, left: 0, bottom: 0, right: 0,
    zIndex: 100,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  orderTicket: {
    flex: 1,
    margin: 10,
    backgroundColor: '#f9f9f9',
    elevation: 4,
    ...Platform.OS === 'web' && {boxShadow: '2px 2px 4px rgba(0,0,0,0.2)'}
  },
  orderTicketContainer: {},

  orderTicketHeader: {
    flexDirection: 'row',
    backgroundColor: Colors.primary,
    padding: 4
  },
  headerIcon: {
    width: 35,
    marginRight: 5,
    alignItems: 'center',
    justifyContent: 'center'
  },
  related_orders: {
    color: Colors.light
  },
  headerLeft: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerCenter: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerRight: {
    alignItems: 'flex-end'
  },
  orderTicketHeaderOpened: {
    backgroundColor: Colors.secondary
  },
  orderTicketOrderNumber: {
    color: Colors.light,
    fontWeight: 'bold'
  },
  orderTicketHeaderNumItems: {
    color: Colors.light
  },
  orderTicketContent: {
    flex: 1
  },
  nextStatusButton: {
    backgroundColor: Colors.ternary,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    height: 45
  },
  nextStatusButtonText: {
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlignVertical: 'center'
  },
  collapseButton: {
    position: 'absolute',
    top: 0, right: 0,
    borderRadius: 50,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000,
    elevation: 5,
    width: 27,
    height: 27,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
  },
  relatedLink: {
    position: 'absolute',
    top: '50%',
    height: 15,
    width: 11,
  },
  relatedLinkPrev: {
    left: 0,
  },
  relatedLinkNext: {
    right: 0,
  }


});
