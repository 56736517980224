import React, {Component} from 'react';
import {View, Image, Text} from 'react-native';
import PropTypes from 'prop-types';
import _ from 'lodash';
import API from "../api";

let lastFetch = new Date();

export default class CutePic extends Component {

  static propTypes = {};
  static defaultInterval = 60 * 1000;

  state = {
    source: require('../assets/images/aspen.jpg'),
    size: 300,
    fact: "In the meantime, here's some cute animal pictures! You can disable this in Settings -> KDS Idle Images",
    hasFocus: false
  }

  async componentDidMount() {
    this._mounted = true;
    const last = await API.retrieveAsyncStorageData('CutePic');
    if(!this._mounted) return;

    let timeTillNextPic;

    // If this is the first load, show Aspen pic for 10 seconds. Otherwise, we show the initial pic for a time
    // calculated based on the remaining time from the last time.
    if(last){
      this.setState({
        source: last.source,
        fact: last.fact
      })
      timeTillNextPic = last.time;
    } else {
      timeTillNextPic = 10000;
    }

    setTimeout(() => {
      this._fetchPicture();
      this._interval = setInterval(this._fetchPicture, CutePic.defaultInterval);
    }, timeTillNextPic);

    this._focusListener = API.on('screen', screen => {
      this.setState({hasFocus: screen === 'KDSView'})
    })

  }

  componentWillUnmount() {
    this._mounted = false;

    this.saveState();
    clearInterval(this._interval);
    if(this._focusListener) this._focusListener.remove();
  }

  render() {
    const {source, fact, size} = this.state;
    let src;
    if(typeof source == 'string'){
      src = {uri: source}
    } else {
      src = source;
    }
    return (
      <View nativeID={'cutePic'} style={{justifyContent: 'center', alignItems: 'center', width: '80%', height: '80%'}} onLayout={this._onLayout}>
        <Image
          source={src}
          style={{height: size, width: size}}
          resizeMode={'contain'}
          fadeDuration={500}
        />
        <Text style={{fontSize: 14, textAlign: 'center', color: 'grey', marginTop: 15}}>{fact}</Text>
      </View>
    );
  }

  _onLayout = ({nativeEvent: {layout: {width, height}}}) => {
    if(this._mounted)
      this.setState({ size: _.min([width, height]) });
  }

  _fetchPicture = async () => {
    if(!this.state.hasFocus) return;

    try {
      let data = await API.kdsImage();
      if(!this._mounted) return;
      if(data.link){
        await Image.prefetch(data.link);
        if(!this._mounted) return;

        lastFetch = new Date();
        this.setState({
          source: data.link,
          fact: data.fact
        })

        this.saveState();
      }

    } catch (err) {
      console.log('Error in fetchPicture: ', err);
    }
  }

  saveState = () => {
    const {source, fact} = this.state;
    let diff = lastFetch ? new Date() - lastFetch : 50000;
    if(diff > CutePic.defaultInterval) diff = CutePic.defaultInterval;

    API.storeAsyncStorageData('CutePic', JSON.stringify({
      source,
      fact,
      time: CutePic.defaultInterval - diff
    }))
  }
}
