import {Platform} from "react-native";
import * as DeviceInfo from 'expo-device';
import * as Application from 'expo-application';
import axios from "axios";

/** Todo: Clean up this file, it's fugly **/

const IS_PROD_BUILD = process.env.NODE_ENV === 'production';

export const IS_ELO = Platform.OS === 'android' && DeviceInfo.manufacturer.indexOf('Elo') === 0;

export const DEFAULTS = {
  API_URL: "https://bbot.menu/",
  FULLSTORY_ENABLED: false,
  PAYMENT_ENGINE: 'STRIPE',
  RUNNING_TESTS: false,
  SHOW_SERVER_CHOOSER: false,
  SHOW_UPDATE_BANNER: false,
  SENTRY_ENV: 'localdev'
}

let envFromFile;
try {
  envFromFile = require('../env.json');
} catch (err) {
  envFromFile = {}
  console.log('env.json not defined, using defaults');
}
const ENV = Object.assign({}, DEFAULTS, envFromFile);

if(ENV.RUNNING_TESTS) {
  let testsConfig = require('../cypress.json');
  Object.assign(ENV, testsConfig);
}


if(__DEV__ || Platform.OS === 'android') ENV.FULLSTORY_ENABLED = false; // FullStory is currently not enabled for Android
if(Platform.OS === 'web') ENV.SHOW_SERVER_CHOOSER = false; // Server Chooser doesn't work on web due to CORS

/**
 *  For web production builds, we should always just use the current hostname:
 *  NOTICE: If you try to connect to staging/prod from localhost, you will fail the csrf check
 */

const getSubEnv = () => {
  let SUB_ENV = '';
  if (Platform.OS === 'web') {
    /**
     * For web prod builds, we always set the API_URL based on the hostname and ignore the config.json setting
     */
    let {protocol, hostname, port} = window.location;

    let trimmedHost = '';
    if (hostname.includes('static')) {
      trimmedHost = hostname.replace('static.', '');
    } else if (hostname.includes('oldterminal')) {
      SUB_ENV += '-old';
      trimmedHost = hostname.replace('oldterminal.', '');
    } else if (hostname.includes('betaterminal')) {
      SUB_ENV += '-beta';
      trimmedHost = hostname.replace('betaterminal.', '');
    } else {
      trimmedHost = hostname.replace('terminal.', '');
    }
    if (IS_PROD_BUILD) {
      /**
       *  If this is a prod web build, we always set the API_URL based on the current hostname
       *  So for example, if we are hosted at "https://betaterminal.staging.bbot.menu, we know we
       *  want our API to be "https://staging.bbot.menu"
       */
      ENV.API_URL = protocol + "//" + trimmedHost + (port ? (":" + port) : "") + "/";
    }
  } else if(Platform.OS === 'android'){
    if(Application.applicationId.includes('beta')){
      SUB_ENV = '-beta';
    }
  }
  return SUB_ENV;
}

export const setSentryEnv = (api_url) => {
  if (api_url === 'https://bbot.menu/') ENV.SENTRY_ENV = 'production';
  else if (api_url.includes('staging')) ENV.SENTRY_ENV = 'staging';
  else if (api_url.includes('demo')) ENV.SENTRY_ENV = 'demo';
  else if (api_url.includes('dev')) ENV.SENTRY_ENV = 'dev';

  const subEnv = getSubEnv();
  ENV.SENTRY_ENV += subEnv;

  return ENV.SENTRY_ENV;
}

export const getSentryEnv = () => {
  return `${ENV.SENTRY_ENV}-${Platform.OS}`;
}

setSentryEnv(ENV.API_URL);

if(ENV.SHOW_SERVER_CHOOSER){
  let api_url = axios.defaults.baseURL || "";
  setSentryEnv(api_url);
}


ENV.IS_PROD = ENV.API_URL === "https://bbot.menu/";

export default ENV;
export const {
  API_URL,
  FULLSTORY_ENABLED,
  IS_PROD,
  PAYMENT_ENGINE,
  RUNNING_TESTS,
  SHOW_SERVER_CHOOSER,
  SHOW_UPDATE_BANNER,
  SENTRY_ENV,
} = ENV;
