import React from "react";
import {createStackNavigator} from 'react-navigation-stack';
import {defaultNavigationOptions} from './Defaults';
import TabBarIcon from "../components/TabBarIcon";
import Changelog from "../screens/Changelog";
import ConfigChooser from "../screens/ConfigChooser";
import CustomerChooser from "../screens/CustomerChooser";
import DeviceRegistration from "../screens/DeviceRegistration";
import GPSDebug from "../screens/GPSDebug";
import ServerChooser from "../screens/ServerChooser";
import SettingsScreen from '../screens/SettingsScreen';


const settingsStack = createStackNavigator(
  {
    SettingsScreen: SettingsScreen,
    ConfigChooserSettings: ConfigChooser,
    CustomerChooserSettings: CustomerChooser,
    DeviceRegistrationSettings: DeviceRegistration,
    ServerChooserSettings: ServerChooser,
    Changelog,
    GPSDebug: GPSDebug
  },
  {
    navigationOptions: ({navigation}) => {
      return {
        tabBarLabel: 'Settings',
        tabBarTestID: 'settings',
        tabBarIcon: (props) => (
          <TabBarIcon
            {...props}
            name={'md-settings'}/>
        )
      }
    },
    defaultNavigationOptions: defaultNavigationOptions
  }
);


export default settingsStack;
