import {Text, View} from "react-native";
import React from "react";
import EStyleSheet from 'react-native-extended-stylesheet';

export default class OrderListViewHeader extends React.PureComponent {

  render() {
    return (
      <View style={styles.menuItemsHeader}>
        <View style={{flexDirection: 'row', flex: 1}}>
          <Text style={[styles.headerText, {flex: 1}]}>NAME</Text>
          <Text style={[styles.headerText, {textAlign: 'right'}]}>QTY</Text>
          <Text style={[styles.headerText, {width: '16%', textAlign: 'right'}]}>EACH</Text>
          <Text style={[styles.headerText, {width: '18%', textAlign: 'right'}]}>TOTAL</Text>
        </View>
      </View>
    )
  }
}


const styles = EStyleSheet.create({
  menuItemsHeader: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    borderBottomColor: '#bebebe',
    borderBottomWidth: 2,
    paddingHorizontal: 21,
    paddingVertical: 5
  },
  headerText: {
    fontSize: 12,
    fontWeight: 'bold'
  }
});
