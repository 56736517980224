import React, {useState} from "react";
import Card from "./Card";
import {Text} from "native-base";
import {Platform, TextInput} from "react-native";
import {formatPhoneNumber, validateEmail} from "../../helpers/HelperFunctions";
import EStyleSheet from "react-native-extended-stylesheet";
import Colors from "../../constants/Colors";
import Segment, {SegmentButton} from "../../components/Segment";

/**
 * The <ContactInfoCard> contains fields for entering methods of notification, such as Email and Phone Number for SMS.
 *
 * @param {Object} props
 * @param {string} props.email - The value to put in the email address input
 * @param {string} props.phoneNumber - The value to put in the phone number input
 * @param {ReactHook<string>} props.setEmail - Updates <TipScreen>'s state for the email to send receipts to
 * @param {ReactHook<string>} props.setPhoneNumber - Updates <TipScreen>'s state for the phone to send updates to
 * @returns {JSX.Element}
 * @constructor
 */
const ReceiptInfoCard = ({email, phoneNumber, setEmail, setPhoneNumber, receiptType, setReceiptType, ...rest}) => {

  /** @type {[boolean, ReactHook<boolean>]} True if the email address is empty or valid */
  const [validEmailAddress, setValidEmailAddress] = useState(true);
  /** @type {[boolean, ReactHook<boolean>]} True if the phone number is empty or valid */
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);



  return (
    <Card
      testID={'tipScreenContactInfoCard'}
      title={'Receipt'}
      titleRight={(
        <Segment style={{width: 150}}>
          <SegmentButton
            style={{padding: 3}}
            textStyle={{fontSize: 12}}
            display={"Email"}
            value={'email'}
            selected={receiptType === 'email'}
            onPress={setReceiptType}/>
          <SegmentButton
            style={{padding: 3}}
            textStyle={{fontSize: 12}}
            display={"SMS"}
            selected={receiptType === 'sms'}
            value={'sms'}
            onPress={setReceiptType}
          />
        </Segment>
      )}
      {...rest}
    >

      {/* Email Input */}
      {receiptType === 'email' && (
        <>
          {!validEmailAddress && (
            <Text testID={'tipScreenContactInfoEmailError'} style={styles.contactInfoErrorMessage}>
              The email address appears to be invalid.
            </Text>
          )}
          <TextInput
            testID={'tipScreenContactInfoEmailInput'}
            style={[styles.inputs, styles.contactInfoInput, !validEmailAddress && styles.invalidContactInfoInput]}
            value={email}
            keyboardType={'email-address'}
            placeholder={'email@address.com'}
            onChangeText={setEmail}
            onBlur={() => {
              const validity = email.length === 0 || validateEmail(email);
              setValidEmailAddress(validity);
            }}
          />
        </>
      )}

      {/* Phone Number Input */}
      {receiptType === 'sms' && (
        <>
          {!validPhoneNumber && (
            <Text testID={'tipScreenContactInfoPhoneError'} style={styles.contactInfoErrorMessage}>
              The phone number appears to be invalid.
            </Text>
          )}
          <TextInput
            testID={'tipScreenContactInfoPhoneNumberInput'}
            style={[styles.inputs, styles.contactInfoInput, !validPhoneNumber && styles.invalidContactInfoInput]}
            value={phoneNumber}
            keyboardType={'phone-pad'}
            placeholder={'(650) 555-5555'}
            onChangeText={setPhoneNumber}
            onBlur={() => {
              const validity = phoneNumber.length === 0 || formatPhoneNumber(phoneNumber) !== null;
              setValidPhoneNumber(validity);
            }}
          />
        </>
      )}

    </Card>
  );
}

export default ReceiptInfoCard;

const styles = EStyleSheet.create({

  inputs: {
    fontSize: 14,
    marginVertical: 8,
    padding: 8,
    borderWidth: 1,
    borderColor: '#DDD',
    ...Platform.OS === 'web' ? {boxSizing: 'border-box'} : {},
    borderRadius: 4
  },

  // Styles for the Contact Info Card
  contactInfoLabels: {
    fontSize: 14,
    fontWeight: 'bold',
    marginVertical: 5
  },
  contactInfoInput: {},
  invalidContactInfoInput: {
    borderWidth: 1,
    borderColor: '#C00'
  },
  contactInfoErrorMessage: {
    color: '#c00',
    fontSize: 14
  }
})
