import React, {Component} from 'react';
import API from "../../api";

// Components
import { FlatList, Text, View } from "react-native";
import Alert from '../../components/Alert';
import OrderListViewHeader from "../OrderListViewHeader";
import { Footer, FooterTab } from "native-base";
import OrderListView from "../OrderListView";
import { FormattedCurrency } from "react-native-globalize";
import OrderItemStatusChanger from "../OrderItemStatusChanger";
import RefundIconButton from "../RefundIconButton";
import IconButton from "../IconButton";
import KeypadModal from "../KeypadModal";
import Cart from "../../models/Cart";

// Styles
import Colors from "../../constants/Colors";
import ClaimButton from "../IconButtons/ClaimButton";

class OrdersView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedOrders: [],
      selectedItems: [],
    };

    this._mounted = null;
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  _refreshSelectedItems = (obj, selectedItems) => {
    if (!this._mounted) return;
    this.setState({
      selectedItems: [...selectedItems]
    });
  }

  render() {
    const numGuests = this.props.location.getNumGuests();
    const config = API.getConfig();
    const { selectedItems } = this.state;
    const { orders, testID } = this.props

    return (
      <View style={{ flex: 1 }} testID={testID}>
        <OrderListViewHeader/>
        <View style={{flex: 1}}>
          <FlatList
            style={{flexBasis: 0, paddingBottom: 10}}
            data={orders}
            renderItem={ ({ item }) => this.renderOrder(item, true) }
            keyExtractor={item => item.orderId}
            ListEmptyComponent={this.renderEmptyMessage}
          />
        </View>
        <OrderItemStatusChanger
          allowRegress={true}
          selectedItems={ selectedItems }
          onChange={this.props.refresh}
        />
        <View>
          {/* TODO: Either All orders or selected Items */}
          <OrderTotals orders={ orders }/>

          <Footer style={{backgroundColor: 'black'}}>
            <FooterTab style={{backgroundColor: 'black'}}>
              <RefundIconButton
                testID={'ordersViewFooterButtonRefund'}
                orders={ orders }
                selectedItems={ selectedItems }
                onSuccess={() => this._refreshSelectedItems(null, selectedItems )}
              />
              <IconButton
                testID={'ordersViewFooterButtonGuests'}
                icon={'people'}
                badge={0}
                onPress={() => this.setState({ showNumGuestsChooser: true }) }
                label={`${ numGuests } Guest${ numGuests !== 1 ? "s" : "" }`}
              />
              <IconButton
                testID={'ordersViewFooterButtonClear'}
                icon={'account-remove'}
                iconType={'MaterialCommunityIcons'}
                label={'Clear'}
                onPress={this._clearTable}
                shown={!selectedItems.length}
                //disabled={!numGuests}
              />
              <IconButton
                testID={'ordersViewFooterButtonDeselect'}
                icon={'select-off'}
                iconType={'MaterialCommunityIcons'}
                label={'De-select'}
                shown={!!selectedItems.length}
                onPress={() => this.setState({selectedItems: []})}
              />
              <IconButton
                testID={'ordersViewFooterButtonReorder'}
                icon={'redo-alt'}
                iconType={'FontAwesome5'}
                label={'Re-Order'}
                shown={config?.show_create_order}
                disabled={!selectedItems.length}
                disabledMsg={"Select at least 1 item"}
                onPress={this._orderAgain}
              />
              <ClaimButton
                selectedItems={selectedItems}
                onPress={() => this._refreshSelectedItems(null, selectedItems)}
              />
            </FooterTab>
          </Footer>
          {/*========= Guests Chooser Modal ===========*/}
          <KeypadModal
            testID={'numberOfGuestsKeypadModal'}
            title="Number of Guests?"
            minValue={this.props.location.getNumGuests() || 1}
            maxValue={36}
            value={this.props.location.getNumGuests() || 1}
            visible={this.state.showNumGuestsChooser}
            onClose={this._setNumGuests}
            onCancel={() => this.setState({ showNumGuestsChooser: false })}
          />
        </View>
      </View>
    );
  }

  _orderAgain = () => {
    const {navigation} = this.props;
    try {
      let cart = new Cart({
        locationId: this.props.location.id
      });
      this.state.selectedItems.forEach(item => {
        if (!item.seat_numbers.length) {
          cart.addItem(item);
        } else {
          item.seat_numbers = [1];
          cart.addItem(item);
        }
      });

      navigation.navigate("OrderCreator", {
        location: this.props.location,
        refreshFn: this._showLoading,
        cart: cart
      })
    } catch (err) {
      Alert.alert("Error", "Error re-ordering this item. Please use 'New Order' button.");
      console.log(err);
    }
  }

  _onSelectedItemsChange = (obj, selectedItems) => {
    this.setState({
      selectedItems: [...selectedItems]
    });
  }

  renderEmptyMessage = () => {
    return (
      <View style={{height: 40, alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1}}>
        <Text style={{color: Colors.gray}}>No Orders</Text>
      </View>
    );
  }

  renderOrder(order, show_groups = false) {
    return <OrderListView
      key={order.orderId}
      order={order}
      onHeaderPress={this._onSelectedItemsChange}
      onItemPress={this._onSelectedItemsChange}
      refresh={this.props.refresh}
      selectedItems={ this.state.selectedItems?.slice() }
    />;
  }

  clear = () => {
    this.setState({
      show_more: false,
      old_orders: []
    });
  }

   _clearTable = async () => {
    Alert.alert(
      "Clear Table?",
      null,
      [
        {
          text: 'Cancel',
          style: 'cancel'
        },
        {
          text: 'Continue',
          onPress: async () => {
            this.setState({
              loading: true
            });

            // this._locationOrdersView.clear();

            let response = await API.clearLocation(this.props.location);
            if (!this._mounted) return;
            // todo handle error state

            this.props.refresh();

            this.setState({
              //showNumGuestsChooser: true,
              loading: false,
              show_more: false,
              old_orders: []
            });
          }
        }
      ]
    )
  }

  _goToOrderCreator = () => {
    let {navigation} = this.props;
    navigation.navigate("OrderCreator", {
      location: this.props.location,
      location_overview_key: navigation.state.key,
      refreshFn: this._showLoading
    });
  }

  _setNumGuests = async (number) => {
    if (!this._mounted) return;
    const {orders} = this.props;
    this.setState({
      loading: true,
      showNumGuestsChooser: false
    });

    let response = await this.props.location.changeNumGuests(number);

    if (response.success) {
      this.setState({
        location: this.props.location,
        loading: false
      });
      if (!orders.length && API.getConfig().show_create_order) {
        // If there's no items, let's go straight to the order creator:
        this._goToOrderCreator();
      }
    } else {
      if (!this._mounted) return;
      this.setState({
        loading: false,
        error: "There was a problem communicating with the server"
      })
    }
  }

}
  // TODO: Pass in Order ITems, If there is an order Items length the iterate over the order Items and not the list of orders
  const OrderTotals = ({orders}) => {
     let totals = {
        subTotal: 0,
        fees: 0,
        tax: 0,
        tip: 0,
        total: 0
      }
      orders.forEach(order => {
        totals.subTotal += order.subtotal_cents;
        totals.fees += order.fee_pretax_cents;
        totals.tax += order.tax_cents;
        totals.tip += order.tip_cents;
        totals.total += order.total_cents;
      })
    const labelStyle = {flex: 1, color: Colors.darkGray, fontSize: 13, textAlign: 'right'}
    const totalStyle = {flex: 1, textAlign: 'right'}

    return (
      <View style={{paddingVertical: 5, paddingHorizontal: 10}}>
        <View style={{flexDirection: 'row'}}>
          <Text style={labelStyle}>Sub-Total</Text>
          { totals.fees > 0 && <Text style={labelStyle}>Fees</Text> }
          <Text style={labelStyle}>Tip</Text>
          <Text style={labelStyle}>Tax</Text>
          <Text style={labelStyle}>Total</Text>
        </View>
        <View style={{flexDirection: 'row'}}>
          <FormattedCurrency style={totalStyle} value={totals.subTotal/100}/>
          { totals.fees > 0 && <FormattedCurrency style={totalStyle} value={totals.fees/100}/> }
          <FormattedCurrency style={totalStyle} value={totals.tip/100}/>
          <FormattedCurrency style={totalStyle} value={totals.tax/100}/>
          <FormattedCurrency style={totalStyle} value={totals.total/100}/>
        </View>
      </View>
    )
}

OrdersView.propTypes = {};

export default OrdersView;
