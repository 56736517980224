import {createStackNavigator} from 'react-navigation-stack';
import StationsScreen from '../screens/StationsScreen';
import StationView from '../screens/StationView';
import {defaultNavigationOptions} from './Defaults';
import TabBarIcon from "../components/TabBarIcon";
import React from "react";


const stationsStack = createStackNavigator(
  {
    StationsScreen: StationsScreen,
    StationView: StationView
  },
  {
    navigationOptions: ({navigation}) => {
      return {
        tabBarLabel: 'Stations',
        tabBarTestID: 'stations',
        tabBarIcon: (props) => (
          <TabBarIcon
            {...props}
            name={'md-tablet-landscape'}
            type={'Ionicons'}
          />
        ),
        tabBarVisible: navigation.state.index === 0
      }
    },
    defaultNavigationOptions: defaultNavigationOptions
  }
);


export default stationsStack;
