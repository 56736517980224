import React, {Component} from 'react';
import {View, FlatList} from 'react-native';
import {Container, Header, Content, Item, Input, ListItem, Icon, Image, Text, Body, Button, Right} from 'native-base';

import Alert from '../components/Alert';
import Loader from "../components/Loader";

import PropTypes from 'prop-types';
import API from "../api";
import Colors from "../constants/Colors";
import {escapeRegExp} from "../helpers/HelperFunctions";

export default class CustomerChooser extends Component {

  static navigationOptions = {
    title: "Choose Customer"
  }

  static propTypes = {}

  state = {
    all_customers: [], // non-filtered customers
    processing: true,
    search: ""
  }

  componentDidMount() {
    this._mounted = true;
    let {navigation} = this.props;
    let customers = navigation.getParam('customers');

    if(!customers){
      this.getAllowedCustomers();
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  async getAllowedCustomers() {
    this.setState({
      processing: true
    });
    let result = await API.getAllowedCustomers();
    // TODO: if (!result.success) show error + retry button
    if(this._mounted) {
      let customers = result.allowed_customers || [];
      this.setState({
        processing: false,
        all_customers: customers,
      })
    }
  }

  render() {
    const search = escapeRegExp(this.state.search);
    let regexp = new RegExp(search, "gi");
    return (
      <Container>
        <Header searchBar style={{backgroundColor: Colors.darkGray}}>
          <Item>
            <Icon name={"md-search"}/>
            <Input testID={"search-customer-input"} placeholder={"Search"} onChangeText={this._filter} autoFocus={true}/>
          </Item>
        </Header>
        <View style={{flex: 1}}>
          { this.state.processing && <Loader/>}
          <FlatList
            data={this.state.all_customers.filter(c => c.name_for_owner.search(regexp) >= 0)}
            renderItem={this._listItem}
            testID={"customerScrollView"}
            keyExtractor={item => item.customer_id}
          />
        </View>
      </Container>
    );
  }

  _filter = (text) => {
    this.setState({
      search: text
    });
  }

  _listItem = ({item}) => {
    return (
      <ListItem>
        <Body>
          <Text>{item.name_for_owner}</Text>
        </Body>
        <Right>
          <Button icon transparent onPress={() => this._setCustomer(item)} testID={'cust_' + item.name_for_owner}>
            <Icon name={"arrow-with-circle-right"} type={"Entypo"}/>
          </Button>
        </Right>
      </ListItem>
    )
  }

  _setCustomer = async customer => {
    let {navigation} = this.props;

    this.setState({ processing: true});
    let {success, error} = await API.selectCustomer(customer.customer_id);
    if(success){
      navigation.navigate('AuthLoading');
    } else {
      Alert.alert("Error","Selecting customer failed: "+error);
    }
    if(this._mounted)
      this.setState({processing: false});
  }
}
