import Model from "./Model";

export default class Fee extends Model {

  id = null;
  customer_id = null;
  name_for_customer = "";
  name_for_owner = "";
  pretax_cents = 0;
  tax_cents = 0;
  line_item_ids = [];
  primary_line_item_id = null;
  external_id = '';

  constructor(cart, data) {
    super();
    this.update(data);

    Object.defineProperty(this, 'cart', {value: cart});
  }

}
