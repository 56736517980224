/**
 * CurrencyInput
 *
 * @author Gilles St-Cyr
 * @date 2019-04-30
 */

import React, {useEffect, useRef} from 'react';
import {TextInput, Text, View} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";

const SymbolInput = ({prefix, suffix, style, autoFocus, inputref, ...rest}) =>  {
  const input = useRef(null);

  useEffect(() => {
    if(input.current && autoFocus){
      input.current.focus();
      if(inputref) inputref(input.current);
    }
  }, [input])

  return (
    <View style={[styles.container, style]}>
      { !!prefix && <Text style={styles.prefix}>{prefix}</Text> }
      <TextInput
        ref={input}
        style={styles.input}
        keyboardType={"number-pad"}
        autoCapitalize="none"
        selectTextOnFocus
        {...rest}
      />
      { !!suffix && <Text style={styles.suffix}>{suffix}</Text> }
    </View>

  );
}

SymbolInput.propTypes = {};

export default SymbolInput;


const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 2,
    padding: 5,
    marginRight: 20,
    width: 100
  },
  prefix: {
    marginRight: 5,
    fontSize: 16
  },
  suffix: {
    fontSize: 16
  },
  input: {
    fontSize: 16,
    margin:0,
    borderWidth: 0,
    width: 75,
    padding: 0
  }
});


