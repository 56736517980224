import {Platform} from "react-native";

export const URLS = {
  APK_UPDATE: 'api/apk/update',

  PING: 'api/ping',

  AUTH: 'api/checkauth',
  LOGIN: 'api/login_to_cc',

  ALLOWED_CUSTOMERS: 'api/get_allowed_customers',
  SELECT_CUSTOMER: 'api/select_customer',

  DEVICE_INFO: 'api/getdeviceinfo',
  UPDATE_DEVICE: 'api/update_terminal',

  IS_DEVICE_NAME_VALID: 'api/validatedevicename',

  GET_CONFIGS: 'api/availableconfigs',
  SET_CONFIG: 'api/setconfig',

  CHECKPIN: 'api/user/check-pin',

  MENU: 'api/getMenusAndItems',

  HANDHELDPOLL: 'api/handheldpoll',
  POLL_BIG: 'api/handheldpollbig',
  POLL_NORMAL: 'api/handheldpollnormal',
  LOCATIONDETAIL: 'api/locationdetail',
  SEARCHORDERS: 'api/ordersearch',
  SETSTATUS: 'api/terminal/order/status',
  CLAIM: 'api/claimorderitems',

  MORE_ORDERS: 'api/locationhistory', //DEPRECATED

  CHECK_DELIVERABLE: 'api/terminal/validate-address',
  GET_CART_PRICE: 'api/cartpricecheck',
  PAIDORDER: 'api/handheldorder',

  SEAT_GROUP: 'api/seatgroup',
  EDIT_GROUP: 'api/editgroup',
  MOVE_GROUP: 'api/movegroup',
  CLEAR_LOCATION: 'api/clearlocation',
  CLEAR_ZONE: 'api/clearzone',

  GET_CARDS: 'api/getgroupcards',
  ADD_CARD_TO_GROUP: 'api/handheldsavecard',
  SAVE_REUSABLE_CARD: 'api/terminal/card/save',
  STRIPE_AUTH: 'api/getstripeconnectiontoken',

  GOOGLE_MAPS : 'api/googlemaps',

  STRIPE: {
    AUTH: 'api/terminal/stripe/get-connection-token',
    CREATE_INTENT: 'api/terminal/stripe/create-payment-intent'
  },
  CARD: {
    SAVE: 'api/terminal/card/save'
  },

  GET_TAB_URL: 'api/getjointaburl',
  GET_TAB_INFO: 'api/gettabinfo',

  TAB : {
    OPEN: 'api/opentab',    // legacy endpoint, to be removed
    CREATE: 'api/terminal/tab/create',
    RENAME: 'api/terminal/tab/rename',
    CLOSE: 'api/terminal/tab/close',
    EXPAND: 'api/terminal/tab/expand',
    FUND: 'api/terminal/tab/fund-from-chipper',
    URL: 'api/terminal/tab/create-url',
    INFO: 'api/terminal/tab/info',
    EDIT_TIP: 'api/terminal/tab/edit-tip'
  },

  SEND_RECEIPT: 'api/sendreceiptfromcc',
  PRINT_TICKET: 'api/printticket',
  PRINT_TICKET_PDF: 'api/printTicketToPdf',
  PRINT_RECEIPT_PDF: 'api/printReceiptToPdf',
  SNOOZE_ORDER: 'api/snoozeOrder',
  TEXT_PATRON_AGAIN: 'api/textPatronAgain',
  TIP_REPORT: 'api/getTipReport',
  ORDER_REFUND: 'api/addOrderRefund',
  REFUND_ORDER_ITEMS_AND_OR_FEES: 'api/refundOrderItemsAndOrFees',
  MODIFY_ORDER: 'api/modifyOrder',

  STOCKUPDATE: 'api/setItemStock',

  KDS : {
    CUTE_PIC: 'api/kdsImage'
  },

  // ORDER CONTROL:
  TOGGLE_FULFILLMENT: 'api/togglefulfillmentmethod',
  SET_ALLOWED_LOCATIONS: 'api/setLocationOrderAllowed',
  GET_ALLOWED_LOCATIONS: 'api/getLocationOrderAllowed',
  SET_ORDER_ALLOWED: 'api/stationOrderAllowed',
  SET_SERVICE_CONDITIONS: 'api/setServiceConditions',
  CLOSE_OPEN_ORDERS: 'api/closeOpenOrders',

  ERROR: 'api/handhelderror',

  GET_SQUARE_AUTH: 'api/getMobileAuthCode',

  ROBOT: {
    FORCE_DELIVERY: 'api/botcontrol/forcedelivery',
    RECALL: 'api/botcontrol/recall',
    RECOVER: 'api/botcontrol/recover',
    SHUTDOWN: 'api/botcontrol/shutdown',
    STOW: 'api/botcontrol/stowbasket'
  },

  DRIVER: {
    CANCEL: 'api/cancelDriver',
    CHANGE_TIME: 'api/changeDriverTime',
    GET_COURIER_CAPABILITIES: 'api/getCourierCapabilities',
  },

  NOTIFICATIONS: {
    GET: 'api/getMoreNotifications',
    SET: 'api/setNotificationState'
  },
  GET_MORE_NOTIFICATIONS: 'api/getMoreNotifications',
  SET_NOTIFICATION_STATE: 'api/setNotificationState',

  DEBUG: {
    SHOW_HEADERS: 'api/tests/showheaders'
  }

};

export const PING_RESULT = "KC4ghn2oWbO32jk9A7mFKCVNI0ljAt";

export const CONFIG = {
  NUM_COLUMNS: 4
};

export const Images = {
  bbotLogo: require('../assets/images/bbot-logo.png'),
  bbotLogoSmallWhite: require('../assets/images/bbot-logo-white.png'),
  smartphone: require('../assets/images/smartphone.png'),
  cart: require('../assets/images/cart.png')
}

let sounds = {}
if(Platform.OS === 'web') {
   sounds = {
    notification: require('../assets/sounds/notification.aac'),
    alarm: require('../assets/sounds/alarm.aac'),
    newMessage: require('../assets/sounds/new_message.mp3')
  }
} else {
  sounds = {
    notification: {uri: 'asset:/notification_old.mp3'},
    alarm: {uri: 'asset:/alarm_old.mp3'},
    newMessage: {uri: 'asset:/new_message.mp3'}
  }
}

export {sounds as Sounds};

export const StatusIcons = {
  large : {
    botsent: require('../assets/images/itemstatus/32px/botsent.png'),
    botattable: require('../assets/images/itemstatus/32px/botsent.png'), // todo make unique
    delivered: require('../assets/images/itemstatus/32px/delivered.png'),
    humandelivered: require('../assets/images/itemstatus/32px/delivered.png'),
    humandelivering: require('../assets/images/itemstatus/32px/humandelivering.png'),
    runnerdelivering: require('../assets/images/itemstatus/32px/humandelivering.png'), // todo make unique
    loaded: require('../assets/images/itemstatus/32px/loaded.png'),
    making: require('../assets/images/itemstatus/32px/making.png'),
    makingclosed: require('../assets/images/itemstatus/32px/makingclosed.png'),
    notified: require('../assets/images/itemstatus/32px/notified.png'),
    onshelf: require('../assets/images/itemstatus/32px/onshelf.png'),
    refunded: require('../assets/images/itemstatus/32px/refunded.png'),
    wait_for_driver: require('../assets/images/itemstatus/32px/wait_for_driver.png'),
    wait_for_runner: require('../assets/images/itemstatus/32px/wait_for_runner.png'),
    waiting: require('../assets/images/itemstatus/32px/waiting.png'),
    with_driver_closed: require('../assets/images/itemstatus/32px/with_driver_closed.png'),

    smartorder: require('../assets/images/smartphone.png'),
  },
  small: {
    botsent: require('../assets/images/itemstatus/16px/botsent.png'),
    botattable: require('../assets/images/itemstatus/16px/botsent.png'), // todo make unique
    delivered: require('../assets/images/itemstatus/16px/delivered.png'),
    humandelivered: require('../assets/images/itemstatus/16px/delivered.png'),
    humandelivering: require('../assets/images/itemstatus/16px/humandelivering.png'),
    runnerdelivering: require('../assets/images/itemstatus/16px/humandelivering.png'), // todo make unique
    loaded: require('../assets/images/itemstatus/16px/loaded.png'),
    making: require('../assets/images/itemstatus/16px/making.png'),
    makingclosed: require('../assets/images/itemstatus/16px/makingclosed.png'),
    notified: require('../assets/images/itemstatus/16px/notified.png'),
    onshelf: require('../assets/images/itemstatus/16px/onshelf.png'),
    refunded: require('../assets/images/itemstatus/16px/refunded.png'),
    wait_for_driver: require('../assets/images/itemstatus/16px/wait_for_driver.png'),
    wait_for_runner: require('../assets/images/itemstatus/16px/wait_for_runner.png'),
    waiting: require('../assets/images/itemstatus/16px/waiting.png'),
    with_driver_closed: require('../assets/images/itemstatus/16px/with_driver_closed.png'),

    smartorder: require('../assets/images/smartphone.png'),
  }
}

