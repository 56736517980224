import {View} from "react-native";
import EStyleSheet from 'react-native-extended-stylesheet';
import React from "react";


export class CommandBar extends React.PureComponent {
  render() {
    return (
      <View style={styles.commandBar}>
        {this.props.children}
      </View>
    )
  }
}

export class CommandBarLeft extends React.PureComponent {
  render() {
    return (
      <View style={styles.commandBarLeftButtons}>
        {this.props.children}
      </View>
    )
  }
};
export class CommandBarCenter extends React.PureComponent {
  render(){
    return (
      <View style={styles.commandBarCenter}>
        {this.props.children}
      </View>
    )
  }
}
export class CommandBarRight extends React.PureComponent {
  render(){
    return (
      <View style={styles.commandBarRightButtons}>
        {this.props.children}
      </View>
    )
  }
}


let styles = EStyleSheet.create({
  commandBar: {
    alignItems: 'center',
    backgroundColor: '#505050',
    flexDirection: 'row',
    height: 50
  },
  commandBarLeftButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%'
  },
  commandBarCenter: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flex: 1
  },
  commandBarRightButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'nowrap',
    height: '100%'
  }
});
