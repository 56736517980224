import React from "react";
import _ from 'lodash';
import {View, Text, ActivityIndicator, CheckBox, TouchableWithoutFeedback} from 'react-native';
import Alert from './Alert';
import EStyleSheet from 'react-native-extended-stylesheet';
import PropTypes from 'prop-types';

import API from '../api';
import Breadcrumb from "./Breadcrumb";
import Colors from "../constants/Colors";
import OrderHelper from '../helpers/OrderHelper';
import PinPadModal from "./PinPad/PinPadModal";
import HelpIcon from "./HelpIcon";


export default class OrderItemStatusChanger extends React.Component {

  static propTypes = {
    selectedItems: PropTypes.array,
    order: PropTypes.object,
    onChange: PropTypes.func
  }

  static defaultProps = {
    selectedItems: [],
    onChange: () => {}
  }

  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      sendText: true
    }
  }

  render() {
    let items = this._actionableItems();
    let count = items.length;
    let {order} = this.props;
    const numOrders = this._numOrders();
    let currStatus = order ? API.menuData.status_pretty_names[order.status] : '';
    return (
      <View style={styles.container}>
        {this.state.processing &&
        <View style={styles.processing}>
          <ActivityIndicator color={Colors.primary}/>
        </View>
        }
        <View style={{alignItems: 'center', justifyContent: 'center', flexDirection: 'row', paddingVertical: 5}}>
          <Text>{items.length} item{(!count || count > 1) ? 's':''} selected</Text>
          { numOrders > 1 && (<Text> in {numOrders} orders</Text>)}
        </View>
        { order && !this._actionableItems().length && <Text>{currStatus}</Text>}
        <View style={styles.breadcrumbContainer}>
          { this.getBreadCrumbs() }
        </View>
      </View>
    )
  }

  _actionableItems = () => {
    // make closed_statueses not actionable unless a special property is set
    let inactionableStatuses = ['refunded'];
    let {allowRegress} = this.props;
    if(!allowRegress) inactionableStatuses = API.menuData.closed_statuses;
    return this.props.selectedItems.filter(i => !inactionableStatuses.includes(i.status));
  }

  getBreadCrumbs() {
    let items = this._actionableItems();
    if(!items.length) return null;

    let item_statuses = _.map(items, i => i.status);
    let items_by_status = _.groupBy(items, i => i.status);
    let statuses = _.uniq(item_statuses);
    let {order} = this.props;
    let fulfillment_method = null;
    if(order){
      fulfillment_method = order.fulfillment_method;
    } else {
      let fulfillment_methods = _.uniq(_.map(items, i => i.order.fulfillment_method));
      if (fulfillment_methods.length > 1) return (
        <Text style={{padding: 10, textAlign:'center', flex:1, color: Colors.error}}>Selected orders have different fulfillment methods.</Text>
      );
      fulfillment_method = fulfillment_methods[0];
    }

    if(!API.menuData?.status_sequences){

      return <Text style={{padding: 10}}>Error loading Status Sequence. Please go to Settings tab and click 'Update Menu'.</Text>
    }
    //if (!statuses.length || statuses.includes('refunded')) return null;
    let status_sequence = API.menuData.status_sequences[fulfillment_method] || [];
    let min_status = status_sequence.findIndex(s => statuses.includes(s));

    return status_sequence.map((status, i) => {
      let pretty_name = API.menuData.status_pretty_names[status];
      let count = _.get(items_by_status, [status, 'length']);
      if(count > 0) pretty_name += " ("+count+")";
      return <Breadcrumb key={i}
                         disabled={ i < min_status || !items.length}
                         text={pretty_name}
                         position={i}
                         bgColor={statuses.includes(status) ? Colors.primary : ''}
                         count={status_sequence.length}
                         onPress={() => {
                           this._changeItemState(status)
                         }}/>
    });

  }

  _changeItemState = async (status) => {
    let items = this._actionableItems();
    if(!items.length) return;

    const {bot_protected_statuses} = API.menuData;
    if(bot_protected_statuses.includes(status)){
      Alert.alert('Invalid Action', 'You can only perform this action from KDS View');
      return;
    }

    let fulfillment_method = items[0].order.fulfillment_method;
    const status_sequence = API.menuData.status_sequences[fulfillment_method];
    if(!status_sequence) return;
    const new_status_index = status_sequence.indexOf(status);
    let require_pin = false;

    items.forEach( item => {
      let index = status_sequence.indexOf(item.status);
      if(new_status_index < index) require_pin = true;
    })

    if(require_pin){
      PinPadModal.show((user) => {
        if(user.isManager())
          this._doChangeState(items, status);
        else Alert.alert('Access Denied', 'You do not have access to perform this action');
      });
    } else {
      this._doChangeState(items, status);
    }


  }

  _doChangeState = async (items, status) => {
    let {onChange} = this.props;
     this.setState({
      processing: true
    });
    let response = await OrderHelper.changeItemsState(items, status, "", true);

    this.setState({
      processing: false
    });

    if(response.success){
      onChange(items);
    }
  }

  _numOrders = () => {
    return _.uniq(this._actionableItems().map(i => i.orderId)).length;
  }

}


const styles = EStyleSheet.create({
  container: {
    borderTopWidth: 1,
    alignItems: 'center',
    backgroundColor: '#e9e6ea',
  },
  breadcrumbContainer: {
    flexDirection: 'row',
    width: '100%',
    marginBottom: 5
  },
  processing: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 100,
    backgroundColor: 'rgba(1,1,1,0.5)',
    alignItems: 'center',
    justifyContent: 'center'
  }
})
