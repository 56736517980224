import Model from "./Model";

export default class Patron extends Model {

  user = null;
  user_id = null;
  first_name = '';
  last_name = '';
  email = '';
  mobile = '';
  verified = false;

  constructor(patronData){
    super();

    this.update(patronData);
  }

  get fullName(){
    return this.first_name + " " + this.last_name;
  }

  get mobilePhoneNumber(){
    return this.mobile;
  }
}
