import Model from "./Model";
import Modifier from "./Modifier.js";
import hasModifiers, {setCartModifierClass} from "./mixins/hasModifiers.js";
import mix from "./mixins/Mixin.js";

export default class CartModifier extends mix(Model).with(hasModifiers) {

  _parent = null;
  menuItemId = null;
  menu_heading_id = null;
  name = null;
  name_for_customer = '';
  name_for_bartender = '';
  pretax_cents = null;
  tax_cents = null;
  mods = {};
  selected = null;
  errors = [];  // List of Modifier Group Ids that have invalid selections (min or max not respected)

  _field_map = {
    mods: mods => {
      if (Array.isArray(mods)) {
        let res = {};
        mods.forEach(mod => {
          let menuModifier = this.api.menuData.modifiersById[mod.id];
          if (!res[menuModifier.heading.id]) res[menuModifier.heading.id] = [];
          res[menuModifier.heading.id].push(new CartModifier(this, mod))
        });
        return res;
      } else {
        for (let groupId in mods) {
          mods[groupId] = mods[groupId].map(mod => new CartModifier(this, mod));
        }
        return mods;
      }
    }
  }


  constructor(parent, data) {
    super();

    // Handle simplified OrderItem modifier format:
    if (data.id && !data.menuItemId) {
      let modifier = this.api.menuData.modifiersById[data.id];
      data = Object.assign(data, {
        menuItemId: data.id,
        menu_heading_id: modifier.heading.id
      })
    }

    if (data instanceof Modifier) {
      Object.defineProperty(this, 'modifier', {value: data});
    } else {
      Object.defineProperty(this, 'modifier', {value: this.api.menuData.modifiersById[data.menuItemId]});
    }

    this._parent = parent;
    this.menuItemId = data.menuItemId;
    this.name_for_bartender = this.modifier.name_for_bartender;
    this.name_for_customer = this.modifier.name_for_customer;
    this.pretax_cents = this.modifier.pretax_cents;
    this.tax_cents = this.modifier.tax_cents;
    this.menu_heading_id = data.menu_heading_id;
    this.selected = true;
    this.errors = [];

    this.update(data, false);
  }

  get id() {
    return this.menuItemId
  }

  set id(val) {
    this.menuItemId = val;
  }

  get parent() {
    return this._parent;
  }

  get heading() {
    return this.api.menuData.modifierGroupsById[this.menu_heading_id];
  }

  get menuModifier() {
    return this.api.menuData.modifiersById[this.menuItemId];
  }

  get menuItem(){
    return this.api.menuData.modifiersById[this.menuItemId];
  }

  getChildModsString(result) {

    // need to cache this, clear when child mods changes
    let numMissing = 0;
    if (!result) {
      result = {
        groups: [],
        buttonText: "Edit selections"
      }
    }
    this.modifier.modifier_groups.forEach(group => {
      if (!this.mods[group.id]) this.mods[group.id] = [];

      let selectedMods = this.mods[group.id].filter(m => m.selected);
      if (selectedMods.length < group.min_selected) {
        numMissing += (group.min_selected - selectedMods.length);
      }

      if (selectedMods.length) {
        let obj = {
          title: group.heading_name,
          text: []
        }
        selectedMods.forEach(mod => {
          obj.text.push(mod.name_for_customer);
          obj.text = this.getModsRecursive(obj.text, mod.mods);

          numMissing += mod.getNumMissing()

        });
        result.groups.push(obj);
      }
    });

    if (numMissing === 0) {
      result.buttonText = "Customize";
    } else {
      result.buttonText = "Required";
    }

    return result;
  }

  getModsRecursive(text, mods) {
    for (let key in mods) {
      mods[key].forEach(mod => {
        text.push(mod.name);
        if (Object.keys(mod.mods).length) {
          text = this.getModsRecursive(text, mod.mods);
        }
      });
    }
    return text;
  };


  /**
   * Recursively goes down the child modifiers and returns the list of modifiers with errors and
   * marks the modifiers that have errors by populating their errors property with the Group IDs
   * @returns {array} the list of modifier with errors
   */
  hasModErrors(errors) {
    const MenuModifier = this.modifier;
    this.errors = []; // reset the errors array

    MenuModifier.modifier_groups.forEach( (group) => {
      let cartMods = this.mods[group.id] || [];
      let selected = cartMods.filter(m => m.selected);
      if (group.min_selected > selected.length || group.max_selected < selected.length) {
        this.errors.push(group.id)
      }
      selected.forEach(mod => {
        let childrenErrors = mod.hasModErrors(errors);
        errors.concat(childrenErrors);
      });
    })

    if (this.errors.length > 0) {
      errors.push(this)
    }

    return errors;
  }

  /**
   * Recursively goes down the child modifiers and returns the total number of missing selections
   * @returns {number}
   */
  getNumMissing() {
    let numMissing = 0;
    this.modifier.modifier_groups.forEach(group => {
      let cartMods = this.mods[group.id] || [];
      let selected = cartMods.filter(m => m.selected);
      if (group.min_selected > selected.length) {
        numMissing += (group.min_selected - selected.length);
      }
      selected.forEach(mod => numMissing += mod.getNumMissing());
    });
    return numMissing;
  }

  getName() {
    return this.name || this.name_for_bartender;
  }

  getTaxTotal() {
    let total = this.tax_cents;
    for (let groupId in this.mods) {
      total += this.mods[groupId].reduce((tot, modifier) => tot += modifier.selected ? modifier.getTaxTotal() : 0, 0);
    }
    return total;
  }

  getPretaxTotal = () => {
    let total = this.pretax_cents;
    for (let groupId in this.mods) {
      total += this.mods[groupId].reduce((tot, modifier) => tot += modifier.selected ? modifier.getPretaxTotal() : 0, 0);
    }
    return total;
  }

  getTotal = () => {
    return this.getPretaxTotal() + this.getTaxTotal();
  }

  getCartMods() {
    let res = [];
    for (let groupId in this.mods) {
      if (!Array.isArray(this.mods[groupId])) continue;
      res = res.concat(this.mods[groupId].filter(m => m.selected));
      res.forEach(m => m.getCartMods()); // this bad. needs to be non-destructive
    }
    return res;
  }

  isValid () {
    if (!this.selected) return true;

    for (let groupId in this.mods) {
      let group = this.api.menuData.modifierGroupsById[groupId];
      let selected = this.mods[groupId].filter(m => m.selected);
      // We dont check group.max_selected here because we check that when a user selected an item
      // TODO: add ( && selected.length > group.max_selected )
      if (selected.length < group.min_selected) return false;
      if (selected.find(m => !m.isValid())) return false;
    }

    return true;
  }

  /**
   * Checks if all its modifierGroups have their min and max conditions met else it throws an error describing which group failed
   * @returns {boolean}
   */
  isCurrentLevelValid () {
    return new Promise( (resolve, reject) => {
      if ( !this.selected ) resolve(true);
      this.modifier.modifier_groups.forEach( (group) => {
        let groupId = group.id;
        let selected = this.mods[groupId]?.filter(m => m.selected) || [];
        if (selected.length < group.min_selected || selected.length > group.max_selected ) {
          let error = new Error(`Group "${ group.heading_name }" requires you to "${ group.description }" before proceeding.`);
          error.group = group;
          reject(error);
        }
      })
      resolve(true);
    })
  }

  hasMods() {
    return Object.keys(this.mods).length > 0;
  }

  toJSON() {
    let obj = super.toJSON();
    obj.mods = {};
    for(let id in this.mods){
      obj.mods[id] = this.mods[id].map(mod => mod.toJSON());
    }
    return obj;
  }

  getModifierString() {
    if (Object.values(this.mods).length === 0) {
      return this.getName();
    } else {
      let selectedMods = Object.values(this.mods).flat().filter( (m) => m.selected );
      if (selectedMods.length === 0) {
        return this.getName();
      } else {
        return this.getName() + ", " +  Object.values(this.mods).flat().map((m) => m.getModifierString()).join(', ');
      }
    }
  }

}
setCartModifierClass(CartModifier);
