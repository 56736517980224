import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  View,
  Text,
  TouchableWithoutFeedback,
  FlatList,
  Dimensions,
  Modal
} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import Colors from "../constants/Colors";
import {Header, Icon, Input, Item} from "native-base";
import {lightGray, white} from "../styles/Colors";

const targetBtnSize = 200;
const {width} = Dimensions.get('window');


export default class LocationChooserModal extends Component {

  state = {
    searchText: '',
    selectedLocations: [],
    locations: [],
    refresh: false,
    columns: Math.floor(width / targetBtnSize) || 1,
  }

  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    multiple: PropTypes.bool
  };
  static defaultProps = {
    visible: true,
    onCancel: () => {},
    onSave: () => {},
    multiple: false
  }

  constructor(props) {
    super(props);
    this.state.locations = props.locations;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.locations !== this.props.locations){
      this.setState({
        locations: this.props.locations
      })
    }
    if(!prevProps.visible && this.props.visible){
      this.setState({
        selectedLocations: []
      })
    }
  }

  _onLayout = () => {
    let {width, height} = Dimensions.get('window');
    const numColumns = Math.floor(width / targetBtnSize) || 1;
    this.setState({
      columns: numColumns,
    });
  }

  render() {
    let {visible, onCancel, headerTitle} = this.props;
    let {columns, searchText, locations, refresh, selectedLocations} = this.state
    return (
      <Modal visible={visible} transparent={true} onRequestClose={onCancel}>
        <View style={styles.modal} onLayout={this._onLayout}>
          <View style={styles.container}>
            <Header style={{backgroundColor: 'black', margin: 5}}>
              <Text style={styles.headerTitle}>
                {headerTitle}
              </Text>
            </Header>
            <Header searchBar style={styles.header}>
              <Item style={{flex: 1}}>
                <Icon name={"ios-search"}/>
                <Input
                  placeholder={"Search Location"}
                  value={searchText}
                  onChangeText={(val) => {
                    this._search(val)
                  }}
                />
              </Item>
            </Header>
            <View style={styles.body}>
              <FlatList
                key={'locationChooser_'+columns}
                data={locations}
                numColumns={columns}
                renderItem={this._renderItem}
                scrollEnabled={true}
                columnWrapperStyle={columns > 1 ? styles.row : null}
                persistentScrollbar={true}
                keyExtractor={(item, index) => index.toString()}
                horizontal={false}
                extraData={refresh}
                contentContainerStyle={styles.contentContainer}
                style={styles.flatListContainer}>
              </FlatList>
            </View>
            <View style={styles.footer}>
              <TouchableWithoutFeedback onPress={onCancel}>
                <View style={[styles.footerButton, {backgroundColor: Colors.gray}]}>
                  <Text>Cancel</Text>
                </View>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback onPress={this._onSave} disabled={!selectedLocations.length}>
                <View
                  style={[styles.footerButton, {backgroundColor: selectedLocations.length ? Colors.primary : Colors.darkGray}]}>
                  <Text style={styles.selectLocation}>Select Location</Text>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </View>
        </View>
      </Modal>
    );
  }


  _renderItem = ({item, index}) => {
    const {selectedLocations, columns} = this.state;
    let itemWidth = (100 / columns) + '%'
    const selected = selectedLocations.includes(item);

    return (
      <LocationListItem
        location={item}
        selected={selected}
        onPress={this._setLocation}
        itemWidth={itemWidth}
      />
    )
  };

  _setLocation = (location) => {
    const {multiple} = this.props;
    let {selectedLocations} = this.state;

    if(multiple)
      selectedLocations = _.xor(selectedLocations, [location]);
    else
      selectedLocations = selectedLocations.includes(location) ? [] : [location];

    this.setState({
      selectedLocations,
      refresh: !this.state.refresh
    })
  }

  _search = (val) => {
    const {locations} = this.props;
    const newData = locations.filter(item => {
      const itemName = item.locationName.toLowerCase()
      const searchVal = val.toLowerCase()

      if (!val.replace(/\s/g, '').length) {
        return item;
      }
      if (itemName.includes(searchVal)) {
        return item;
      }
    });

    this.setState({
      searchText: val,
      refresh: !this.state.refresh,
      locations: newData
    })
  }

  _onSave = () => {
    let {onSave, multiple} = this.props;
    let {selectedLocations} = this.state;
    if(multiple)
      onSave(selectedLocations);
    else
      onSave(selectedLocations[0]);
  }
}

class LocationListItem extends React.PureComponent {

  static propTypes = {
    onPress: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    selected: PropTypes.bool
  }

  static defaultProps = {
    onPress: () => {}
  }

  render() {
    let {location, selected, itemWidth} = this.props;
    return (
      <TouchableWithoutFeedback onPress={this._onPress}>
        <View style={[styles.locationListItemCont, {width: itemWidth}]}>
          <View
            style={[styles.locationListItem, selected ? styles.selected : styles.notSelected]}>
            <Text style={[selected ? styles.selectedText : styles.notSelectedText]}>
              {location.locationName}
            </Text>
          </View>
        </View>
      </TouchableWithoutFeedback>
    )
  }

  _onPress = () => {
    const {onPress, location} = this.props;
    onPress(location);
  }
}

const styles = EStyleSheet.create(
  {
    modal: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,0.7)'
    },
    container: {
      backgroundColor: 'white',
      borderWidth: 0.1,
      borderRadius: 5,
      flex: 1,
      width: '80%',
      height: '80%',
      maxHeight: '80%',
      overflow: 'hidden'
    },
    flatListContainer: {
      flex: 1,
      margin: 10,
      marginBottom: 70
    },
    row: {
      flex: 1,
    },
    header: {
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
      paddingTop: 10,
      borderBottomWidth: 0.5,
      backgroundColor: white
    },
    headerText: {
      fontWeight: "bold",
      fontSize: 15,
      color: Colors.light
    },
    loader: {
      margin: 'auto'
    },
    scrollView: {
      width: '100%',
      height: '100%',
    },
    contentContainer: {
      width: '100%',
    },
    flatList: {
      marginBottom: 50,
      padding: 5,
      flex: 1,
      width: '100%',
    },
    locationListItemCont: {
      padding: 5,
    },
    locationListItem: {
      height: 100,
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 0.5,
      borderRadius: 10,
      borderColor: lightGray
    },
    body: {
      flex: 1,
    },
    footer: {
      flexDirection: 'row',
      bottom: 0,
      width: '100%',
      position: 'absolute'
    },
    footerButton: {
      height: 50,
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1
    },
    notSelected: {
      backgroundColor: Colors.lightGray
    },
    selected: {
      backgroundColor: '#9ebcea'
    },
    notSelectedText: {
      color: Colors.darkGray
    },
    selectedText: {
      fontWeight: "500",
      color: "black"
    },
    selectLocation: {
      color: "white",
      fontWeight: '700'
    },
    headerTitle: {
      margin: 'auto',
      color: 'white',
      fontSize: 18,
      fontWeight: '500',
    }
  }
)

