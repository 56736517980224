import _ from 'lodash';

import MenuHeading from './MenuHeading';
import Model from "./Model";
import moment from "moment";

export default class Menu extends Model {

  menuId = null;
  menuName = "";

  allow_order_ahead = false;
  allowed_user_desired_times = [];
  combine_into_id = null;
  customer = "";
  desired_time_widget = null;
  display_position = 1000;
  enabled = false;
  headings = [];
  last_modified = null;
  min_order_ahead_minutes = null;
  max_order_ahead_minutes = null;
  name_for_customer = '';
  use_schedule = false;
  visible_per_schedule = true;
  show_unfulfillable_items = true;
  show_out_of_stock_items = true;

  timepicker_block_interval = null; // 30 mins  (12, 12:30, 1)
  timepicker_block_width = null; // 60 mins (12-1, 12:30-1:30, 1-2)
  timepicker_title = '';

  _field_map = {
    last_modified: dt => moment(dt),
    headings: headings => headings.map(heading => new MenuHeading(this, heading))
  }

  constructor(menuData, data){
    super();
    Object.defineProperty(this, 'menuData', { value: menuData });

    this.update(data);
  }

  get name(){
    return this.name_for_customer;
  }

  get items() {
    return this.headings.reduce((acc, heading) => acc.concat(heading.items), []);
  }


}
