import PouchDB from 'pouchdb';

const newDB = () => new PouchDB('terminal', {revs_limit: 1});
let DB = newDB();

export default ()=>DB;

export const resetDB = async () => {
  try {
    await DB.destroy()
    DB = newDB();
    return DB;
  } catch(err){
    return null;
  }
}

