import React from 'react';
import {View, Text, TouchableWithoutFeedback} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import PropTypes from 'prop-types';
import Colors from "../constants/Colors";

const arrowPartWidth = 20;
const margin = 5;
const height = 36;


class Breadcrumb extends React.PureComponent {

  static propTypes = {
    position: PropTypes.number,
    count: PropTypes.number
  }
  static defaultProps = {
    count: 1
  }

  constructor(props) {
    super(props);
    this.state = {
      disabled: null
    }
  }

  render() {
    let {style, position, text, count, disabled, onPress, bgColor, testID} = this.props;

    if (!style)
      style = { };

    let childText = text ? <Text style={[styles.textStyle, disabled ? styles.disabledText : null]} ellipsizeMode='tail' numberOfLines={2}>{text}</Text> : null;
    let bgStyle, bgStyleR, bgStyleL = null;

    if (bgColor) {
      bgStyle = {backgroundColor: bgColor};
      bgStyleR = {borderLeftColor: bgColor};
      bgStyleL = {borderTopColor: bgColor, borderRightColor: bgColor, borderBottomColor: bgColor};
    }

    return (
      <TouchableWithoutFeedback
        testID={testID}
        onPress={()=>{ onPress(position)}}
      >
        <View style={[{flexDirection: 'row', flex: 1/count, marginLeft: position === 0 ? 5 : -15}]}>
          {position > 0 && <View style={[styles.arrow, styles.arrowL, bgStyleL]}/>}
          <View style={[styles.buttonContainer, style, bgStyle]}>
            {this.props.children || childText}
          </View>
          <View style={[styles.arrow, styles.arrowR, bgStyleR]}/>
        </View>

      </TouchableWithoutFeedback>
    )
  }

  _calculateWidth(totalWidth){
    if(this.props.position === 0){
      return totalWidth - arrowPartWidth + margin;
    } else {
      return totalWidth - (arrowPartWidth*2) + (margin * 2);
    }
  }
}

const styles = EStyleSheet.create({
  buttonContainer: {
    backgroundColor: '#cdcdcd',
    height: height,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    //marginTop: 5,
    //marginBottom: 5,
    flex: 1
  },
  arrow: {
    //marginTop: 5,
    width: 0,
    height: 0,
    borderTopWidth: height/2,
    borderBottomWidth: height/2,
    borderRightWidth: 0,
    borderLeftWidth: arrowPartWidth,
    marginLeft:-1, // needed to prevent a weird gap :(
  },
  arrowR: {
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: '#cdcdcd'
  },
  arrowL: {
    borderTopColor: '#cdcdcd',
    borderRightColor: '#cdcdcd',
    borderBottomColor: '#cdcdcd',
    borderLeftColor: 'transparent'
  },
  textStyle: {
    fontSize: 14,
    textAlign: 'center',
    color: '#000'
  },
  disabledText: {
    color: Colors.darkGray
  }

});

export default Breadcrumb;
