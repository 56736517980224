import React from 'react';
import {Pressable} from "react-native";
import {Icon, Text, View} from "native-base";
import EStyleSheet from "react-native-extended-stylesheet";
import {Buttons, Colors} from "../styles";

const FilterPillToggle = ({active, label, onPress, color = Colors.primary}) => {

  const style = active ? {
    borderColor: color,
    color: color
  } : {
    borderColor: Colors.darkGray, color: Colors.darkGray
  }

  return (
    <Pressable onPress={onPress}>
      <View style={[styles.pill, style]}>
        {active ? <Icon name={'check'} type={'FontAwesome'} style={[{fontSize: 16, marginRight: 8}, style]}/> : null }
        <Text style={[{ fontSize: 14 },style]}>{label}</Text>
      </View>
    </Pressable>
  )

}

export default FilterPillToggle

const styles = EStyleSheet.create({
  pill: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingVertical: 2,
    marginRight: 10
  }
})
