import React from 'react';
import {View, Text, Image, TouchableWithoutFeedback} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import Stripe from 'react-native-stripe-terminal';
import {withNavigation} from "react-navigation";
import Fontello from "../assets/fonts/Fontello";

import API from '../api';
import {showMessage} from "react-native-flash-message";
import moment from "moment";
import PinPadModal from "./PinPad/PinPadModal";

class ReaderStatus extends React.Component {

  static lowBatteryShown = false;
  _listeners = [];

  constructor(props) {
    super(props);

    this.state = {
      status: Stripe.readerConnected ? "CONNECTED" : "NOT_CONNECTED",
      discovering: false
    };
  }

  async componentDidMount() {
    this._mounted = true;
    this._listeners = [
      Stripe.on('UnexpectedDisconnect', this.statusChanged),
      Stripe.on('ConnectionStatusChange', this.statusChanged),
      Stripe.on('LowBatteryWarning', this.lowBattery),
      Stripe.on('Discovering', this._discoveryChange),
      API.on('config_updated', this._refresh)
    ];
    this.setState({
      status: Stripe.readerStatus,
      discovering: await Stripe.isDiscovering()
    })
  }

  componentWillUnmount() {
    this._mounted = false;
    this._listeners.forEach(l => l.remove());
  }

  _refresh = () => {
    if(this._mounted)
      this.forceUpdate();
  }

  _discoveryChange = (discovering) => {
    console.log('discoveryChange triggered', discovering);
    this.setState({
      discovering
    });
  }

  statusChanged = (status) => {
    if(!this._mounted) return;
    this.setState({
      status: status
    });
  }

  /** Low battery message. Shows at most once every 10 minutes */
  lowBattery = () => {
    // show at most once every 10 minutes:
    if(ReaderStatus.lowBatteryShown && ReaderStatus.lowBatteryShown.isAfter(moment().subtract(10,'minutes'))) return;

    ReaderStatus.lowBatteryShown = moment();
    showMessage({
      floating: true,
      position: 'top',
      type: 'warning',
      message: 'Card Reader has low battery. Please charge.'
    });
  }

  render() {
    let config = API.getConfig();
    if(!config.use_stripe_chip_reader) return null;

    let style = styles[this.state.status];
    if(!style) {style = { color: '#878787'}}
    if(this.state.discovering) style = {color: '#0d939f'}

    return (
      <TouchableWithoutFeedback onPress={ this.onPress }>
        <View style={[styles.readerStatus]}>
          <Fontello name={'card-reader'} style={[styles.icon, style]}/>
        </View>
      </TouchableWithoutFeedback>
    )
  }

  onPress = () => {
    if(this.props.requirePin){
      PinPadModal.show(this._goToStripe)
    } else {
      this._goToStripe();
    }
  }

  _goToStripe = () => {
    let props = {
      autoload: this.state.status === "NOT_CONNECTED"     // If we're not connected, we probably want to auto-connect to the saved reader
    };
    this.props.navigation.navigate("StripeConfig", props);
  }
}

export default withNavigation(ReaderStatus);

let styles = EStyleSheet.create({
  readerStatus: {
    marginLeft: 10,
    marginRight: 10,
  },
  icon: {
    fontSize: 35
  },
  CONNECTED: {
    color: '#25c500'
  },
  NOT_CONNECTED: {
    color: 'red',
  },
  CONNECTING: {
    color: 'yellow'
  }
});
