// ==== NPM  ==== //
import React from 'react';
import {View, Text, BackHandler, Platform, Dimensions} from 'react-native';
import Alert from '../components/Alert';
import _ from 'lodash';
import uuid from 'uuid';
import {FormattedCurrency, withGlobalize} from "react-native-globalize";
import PropTypes from 'prop-types';

// ==== API ==== //
import API from '../api';

// ==== Components === //
import CmdButton from '../components/CmdButton';
import KeypadModal from '../components/KeypadModal';
import {CommandBar, CommandBarLeft, CommandBarCenter, CommandBarRight} from "../components/CommandBar";
import ItemViewer from '../components/ItemViewer';
import ItemBuilder from './ItemBuilder';

// ==== Models ==== //
import Cart from '../models/Cart';

import {LocationProvider} from "../providers/LocationProvider";
import { ItemPart} from "../models";
import HeaderIconButton from "../components/HeaderIconButton";
import IconButton from "../components/IconButton";
import {Footer, FooterTab} from "native-base";
import Colors from "../constants/Colors";


/***
 *  The Order Creation screen
 *
 *  Creates a Cart model, to which you can add and remove CartItems.
 */

class OrderCreator extends React.Component {

  // navigation Options injected after withGlobalize called, since withGlobalize seems to
  // break the navigation options when they are here...

  constructor(props) {
    super(props);
    let location = props.navigation.getParam('location');
    let cart = props.navigation.getParam('cart');

    if (!cart) cart = new Cart({
      locationId: location.id,
      fulfillment_method: location.fulfillment_method,
      customer_id: API.getCustomerId(),
    });

    props.navigation.setParams({
      onDelete: this._itemDelete
    });

    this.state = {
      cart: cart,
      location: location,
      selectedItem: null,
      selectedIndex: null,
      showNumGuestsChooser: !location.seated_group_id,
      windowWidth: Dimensions.get('window').width,
      cartErrors: {} // dict of errors for the cart. Each item id with
    }
  }

  componentDidMount() {
    this._mounted = true;
    this.backHandler = BackHandler.addEventListener("hardwareBackPress", this.handleBackPress );
    this.checkCartForErrors();
    let {location} = this.state;
    this._locationListener = location.on('update', this.refresh)
    this._dimensionsListener = Dimensions.addEventListener("change", this._updateDimensions);
  }

  componentWillUnmount() {
    this.backHandler.remove();
    this._mounted = false;
    if(this._locationListener)
      this._locationListener.remove();
    this._dimensionsListener?.remove();
  }

  refresh = () => {
    if(this._mounted){
      this._itemViewer.forceUpdate(); // this is terrible, optimize later
      this._itemBuilder.forceUpdate();
    }
  }

  handleBackPress = () => {
    if(this.props.navigation.isFocused()){
      if(this._itemBuilder && this._itemBuilder.state.breadcrumbs.length){
        this._itemBuilder._breadcrumbClicked(this._itemBuilder.state.breadcrumbs.length - 2);
        return true;
      } else if( this.state.cart.items.length) {
        Alert.alert("Are you sure?",
          "You have an unfinished order. Clicking continue will delete it. Continue?",
          [
            {text: "Cancel", style: 'cancel'},
            {
              text: "Continue", onPress: () => {
                this.props.navigation.goBack()
              }
            }
          ]
        );
        return true;
      }
    }
  }

  _updateDimensions = ({window, screen}) => {
    const {width, height} = window;
    this.setState({windowWidth: width});
  }

  render() {
    const { cartErrors, windowWidth } = this.state;
    const {navigation} = this.props;
    const tab_id = navigation.getParam('tab_id');


    return (
      <LocationProvider location={this.state.location}>
        <View style={{flex: 1}}>
          <ItemViewer
            ref={iv => this._itemViewer = iv}
            style={{flexGrow: 0.4, flexShrink: 0, flexBasis: 0}}
            items={this.state.cart.items}
            selectedItems={[this.state.selectedItem]}
            onItemPress={ this._setSelectedItem }
            current={ this.state.current }
          />
          <View style={{alignItems:'flex-end', borderTopWidth:1, paddingRight: 10}}>
             <Text testID={'orderCreatorTotalCost'}>
              Total: <FormattedCurrency value={this.state.cart.getPretaxTotal() / 100}/>
            </Text>
          </View>
          <Footer style={{backgroundColor: Colors.dark}}>
            <FooterTab style={{backgroundColor: Colors.dark}}>
              <IconButton
                testID={'orderCreatorCopyButton'}
                label={"Guests"}
                icon={'people'}
                badge={this.state.location.getNumGuests()}
                onPress={() => this.setState({ showNumGuestsChooser: true })}
              />
              <IconButton
                testID={'orderCreatorCopyButton'}
                label={"Copy"}
                icon={"content-copy"}
                iconType={"MaterialIcons"}
                onPress={this._copySelected}
                disabled={!this.state.selectedItem}
              />
              <IconButton
                testID={'orderCreatorCheckoutButton'}
                label={"Checkout"}
                icon={"shopping-cart"}
                iconType={"FontAwesome"}
                onPress={ this._goToSplitOrder }
                disabled={!this.state.cart.items.length || !!this.state.selectedItem || !this._allItemsAvailable()}
                highlight={Platform.OS === 'web' && !Object.values(cartErrors).flat().length}
              />
              <IconButton
                testID={'orderCreatorPayNowButton'}
                shown={API.config.use_stripe_chip_reader && !tab_id}
                label={"Quick Pay"}
                icon={"payment"}
                iconType={"MaterialIcons"}
                disabled={!this.state.cart.items.length || !!this.state.selectedItem || !this._allItemsAvailable() }
                highlight={!Object.values(cartErrors).flat().length}
                onPress={ this._goToTipScreen }
              />
              <IconButton
                testID={'orderCreatorAddToTab'}
                shown={!!tab_id}
                disabled={!this.state.cart.items.length || !!this.state.selectedItem || !this._allItemsAvailable() }
                label={"Add to Tab"}
                icon={"cash-multiple"}
                iconType={"MaterialCommunityIcons"}
                onPress={ this._addToTab }
                highlight={!Object.values(cartErrors).flat().length}
              />
            </FooterTab>
          </Footer>
          <ItemBuilder
            ref={itemBuilder => this._itemBuilder = itemBuilder }
            style={{ flexGrow: 0.6, flexShrink: 1, flexBasis: 0 }}
            cart={this.state.cart}
            location={this.state.location} // todo this should probably only pass fulfillable_items?
            numGuests={this.state.location.getNumGuests()}
            onItemChange={ this._onItemChange }
            onModifiersChange={ this._onModifiersChange }
            onDone={ this._doneEditing }
          />

          {/*========= Guests Chooser Modal ===========*/}
          <KeypadModal
            testID={'guestChooserModal'}
            title="Number of Guests"
            minValue={this.state.location.getNumGuests() || 1}
            maxValue={36}
            value={this.state.location.getNumGuests() || 1}
            visible={this.state.showNumGuestsChooser}
            onClose={ this._changeNumGuests }
            onCancel={()=>{
              this.setState({showNumGuestsChooser: false});
            }}
          />
        </View>
      </LocationProvider>
    )
  }

  // todo: put this into a state variable and update only when necessary
  _allItemsAvailable = () => {
    let {location} = this.state;
    return this.state.cart.items.every(i => location.fulfillable_items.includes(i.menuItemId))
  }

  /**
   *   If the server re-orders items from the OrdersView and those orderItems come from the consumer
   *   site then there will be no seat number associated with the order. Therefore run a validation across all cart Items
   *   to display errors when a seat number is not associated with the orders.
    */
  checkCartForErrors = () => {
    const { cart } = this.state;

    let cartErrors = {};
    cart.items.forEach( (item, index) => {
      let itemErrors = item.hasModErrors([]);
      if (itemErrors.length > 0) {
        cartErrors[index] = item.hasModErrors();
      }
    })

    this.setState({
      cartErrors: cartErrors,
      cart: cart
    })
  }

  _changeNumGuests = async (number) => {
    await this.state.location.changeNumGuests(number);

    this.setState({
      showNumGuestsChooser: false,
      location: this.state.location
    }, () => {
      this.checkCartForErrors();
    });
  }

  _copySelected = () => {
    let newItem = _.cloneDeep(this.state.selectedItem);
    newItem.id = uuid.v4();
    let num_guests = this.state.location.getNumGuests();
    newItem.seat_numbers = [];
    if(num_guests < 2) newItem.seat_numbers.push(1);

    this.state.cart.addItem(newItem);
    this._setSelectedItem(newItem);

  }

  _setSelectedItem = (item) => {
    let {cart} = this.state;
    let index = cart.items.indexOf(item);

    this.props.navigation.setParams({
      item: item
    });

    this.setState({
      selectedIndex: index,
      selectedItem: item
    });

    this._itemBuilder.selectItem(item);

  }


  _onItemChange = (item) => {
    let { cart } = this.state;

    if (!this.state.selectedItem) {
      cart.addItem(item);
      this.state.selectedIndex = cart.items.length - 1;
      this.props.navigation.setParams({
        item: item
      })
    } else {
      cart.items[this.state.selectedIndex] = item;
    }

    cart._needPriceCheck = true;

    this.setState({
      cart: cart,
      selectedItem: item,
      //items: this.state.items
    }, () => {
      this.checkCartForErrors()
      }
    )
  }

  _onModifiersChange = (current) => {
    let { selectedItem } = this.state;
    this.setState({
      selectedItem: selectedItem,
      current: current
    })
  }

  _itemDelete = () => {
    if (this.state.selectedItem) {
      //this.state.cart.items.splice(this.state.selectedIndex, 1);
      this.state.cart.removeItem(this.state.selectedItem);
      this.props.navigation.setParams({item: null});
      this.setState({
        selectedItem: null,
        selectedIndex: null,
        //items: this.state.items
      });
      this._itemBuilder.reset();
    }
  }

  _doneEditing = () => {
    this.props.navigation.setParams({ item: null });

    this.state.cart._needPriceCheck = true;

    this.setState({
      selectedItem: null,
      selectedIndex: null
    }, () => {
      this.checkCartForErrors()
    })
  }

  /**
   * @private
   */
  _goToSplitOrder = () => {
    let {navigation} = this.props;
    let {cart, location, cartErrors} = this.state;

    if (Object.values(cartErrors)?.length > 0 && Object.values(cartErrors).flat().length > 0) {
      Alert.alert("Cannot proceed to checkout. Cart contains errors.", Object.values(cartErrors).flat().map( (item) => item.getName()).join("\r\n"));
      return null;
    }
    cart._needPriceCheck = true;
    navigation.navigate("SplitOrderScreen", {
      cart,
      location,
      oc_go_back_key: navigation.state.key,
      onGoBack: () => {
        this.setState({
          cart: cart
        })
      }
    });
  }

  _addToTab = () => {
    const {navigation} = this.props;
    const tab_id = navigation.getParam('tab_id');
    const tab = API._tabs[tab_id];

    this._goToTipScreen(tab);
  }

  _goToTipScreen = (card = null) => {
    let {navigation} = this.props;
    let {navigate} = navigation;
    let {cart} = this.state;
    let check = cart.checks[0];

    cart._needPriceCheck = true;
    if(cart.checks.length > 1) {
      cart.checks.length = 1;
    }
    check.items = [];

    this.state.cart.items.forEach((item, i) => {
      item._parts = [];
      check.items.push(
        new ItemPart({
          check,
          item,
          numerator: item.qty
        })
      )
    });

    navigate('CheckoutScreen', {
      check: check,
      card: card,
      processImmediately: true,
      // TODO: Get rid of this refreshFn (use API listeners instead)
      refreshFn: this._postTipScreen, // passed along from SplitOrderScreen
      go_back_key: navigation.state.key,
      location: this.state.location
    });
  }

  /**
   * Submits the Order - Called after all checks have been finalized
   * @returns {Promise<void>}
   * @private
   */
  _postTipScreen = async () => {
    let {navigation} = this.props;
    let refreshFn = navigation.getParam('refreshFn');

    let payload = this.state.cart.getPayload();
    let result = await API.submitOrder(payload);

    if(result.error){
      Alert.alert(result.message);
    } else {
      let {cart} = this.state;
      if(result.failures.length){
        // todo: Handle Failures!
        // Todo: lineItemIdsToRemove
        let errorCodes = [];
        result.failures.forEach(failure => {
          errorCodes.push(failure.errorCode);
          if(failure.lineItemIdsToRemove.length){
            failure.lineItemIdsToRemove.forEach( itemId => {
              cart.removeItem(itemId)
            })
          }
        })
        this.forceUpdate();
        Alert.alert("Checkout Failed", errorCodes.join("\r\n"));
      } else {
        // Todo verify this is correct
        if(refreshFn) refreshFn();
      }
      API.handheldPoll();
    }

  }

}

OrderCreator.propTypes = {
  onNumGuestsChange: PropTypes.func
}
OrderCreator.defaultProps = {
  onNumGuestsChange: () => {
  }
}

const OrderCreatorG = withGlobalize(OrderCreator);

OrderCreatorG.navigationOptions = ({navigation}) => {
  let location = navigation.getParam('location');
  let item = navigation.getParam('item');
  let removeItemFn = navigation.getParam('onDelete');

  return {
    title: "Create Order for " + location.locationName,
    headerRight: () => ([
     // location.uses_promo_codes ? <HeaderIconButton name={"Promos"} type={"MaterialIcons"} onPress={()=>{}} icon={"money-off"} key={'promos'}/> : null,
      <HeaderIconButton testID={'orderCreatorRemoveButton'} name="Remove" disabled={!item} onPress={ removeItemFn } key={"remove"} icon={"trash"}/>
    ])
  }
};

export default OrderCreatorG;
