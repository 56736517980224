/**
 * Model class for HandheldConfig aka DeviceProfile
 *
 * @author Gilles St-Cyr
 * @date 2019-03-15
 */

import Model from './Model';
import _ from 'lodash';

const ONE_HUNDRED_DOLLARS = 10000;
const screen_map = {
  show_locations_overview: 'LocationsScreen',
  show_stations_overview: 'StationsScreen',
  show_kds_view: 'KDSView',
  default: 'OrderSearchScreen'
}

// aka Device Profile
export default class HandheldConfig extends Model {

  id = null;
  customer_id = null;
  config_name = '';
  description = '';

  poll_interval = 20; // TODO: change this back to 10

  show_locations_overview = false;
  show_stations_overview = false;
  show_create_order = false;
  show_menu_editor = false;
  show_order_control = false;
  show_service_conditions = false;
  show_kds_view = false;
  show_bot_control = false;
  show_tip_report = false;
  use_stripe_chip_reader = false;

  statuses_to_chirp_for = [];
  allow_disabling_chirp = false;
  receive_service_requests = false;
  force_volume_level = 1;

  default_tab_cents = ONE_HUNDRED_DOLLARS;
  tab_multiplier = 1.5;

  kds_station_ids = [];
  kds_show_prices = false;
  kds_edit_item_status = false;                // Allows you to edit Order Item statuses individually
  kds_idle_screen = false;
  kds_partial_refunds = false;
  kds_group_related_tickets = false;
  kds_show_recall_button = false;

  allow_manual_card_entry = false;
  allow_pay_with_consumer_tab = false;

 // #kds_stations = []; // Mapped to array of Station models;
 // #monitored_stations = [];

  post_checkout_image = null;

  get kds_stations() {
    if(!Object.values(this.api._stations).length) return [];
    return _.orderBy(this.kds_station_ids.map(id => this.api._stations[id]), 'station_name');
  };

  set kds_stations(stations){  }
  /**
   * Returns the default start screen
   * @returns {string}
   */
  get default_screen(){
    if(this.show_kds_view) return screen_map.show_kds_view;
    if(this.show_stations_overview) return screen_map.show_stations_overview;
    if(this.show_locations_overview) return screen_map.show_locations_overview;
    else return screen_map.default
  }

  constructor(data = {}) {
      super();
      this.update(data);
  }

  update = (data) => {
    super.update(data);
    this.kds_station_ids = data.kds_stations;
  }

  get customer(){
    return this.api._customers[this.customer_id] || {};
  }

  /**
   * Maps a screen testID to an access flag
   * @param screen
   * @returns {boolean}
   */
  hasScreenAccess(screen) {
    const map = {
      locations: this.show_locations_overview,
      stations: this.show_stations_overview,
      menu: this.show_menu_editor,
      ordercontrol: this.show_order_control && this.kds_station_ids?.length,
      kdsview: this.show_kds_view,
      tips: this.show_tip_report,
      botcontrol: this.kds_stations.some(station => station?.stationControlsRobot)
    };

    // if screen is not in the map, it's not access controlled, so allow access:
    if(!(screen in map)) return true;
    return map[screen];
  }

}
