import {createStackNavigator} from "react-navigation-stack";
import BotControlScreen from "../screens/BotControl";
import TabBarIcon from "../components/TabBarIcon";
import {defaultNavigationOptions} from "./Defaults";
import React from "react";

const BotControlStack = createStackNavigator(
  {
    BotControlScreen,
  },
  {
    navigationOptions: ({navigation}) => {
      return {
        tabBarLabel: 'Bot Control',
        tabBarTestID: 'botcontrol',
        tabBarIcon: (props) => (
          <TabBarIcon
            {...props}
            type={'MaterialCommunityIcons'}
            name={'robot'}
          />
        )

      }
    },
    defaultNavigationOptions: defaultNavigationOptions
  }
);

export default BotControlStack;
