import React from 'react';
import {Text} from "native-base";
import EStyleSheet from "react-native-extended-stylesheet";

const Required = ({show}) => {
  if(!show) return null;

  return <Text style={styles.requiredCheckoutStar}> *</Text>
}

export default Required;

const styles = EStyleSheet.create({
  requiredCheckoutStar: {
    color: '#c00'
  }
})
