import React from "react";

import CheckboxField from "./CheckboxField";
import RadioField from "./RadioField";
import AddressField from "./AddressField";
import DropdownField from "./DropdownField";
import PhoneNumberField from "./PhoneNumberField";
import EmailField from "./EmailField";
import NumberField from "./NumberField";
import TextField from "./TextField";


/**
 * Denotes the type of the field for the Custom Input Fields.
 * @enum {string}
 */

export const InputType = {
  DIGITS: 'digits',
  NUMBER: 'number',
  PHONE_NUMBER: 'phone_number',
  TEXT: 'text',
  ADDRESS: 'address',
  EMAIL: 'email',
  CHECKBOX: 'checkbox',
  RADIO: 'radio',
  DROPDOWN: 'dropdown'
}

/**
 * A <ExtraCheckoutField> is a single checkout field in the <RequiredCheckoutInfoCard>. Creates a standard required
 * checkout information field with a label and input. The exact input format is determined by
 *
 * @param props
 * @param {ExtraFieldData} props.field - Information on this specific field
 * @param {number} props.index - The 0-indexed ordering of this field relative to the other checkout fields
 * @param {?InputValidator} props.inputValidator - A method provided to check if this field is properly filled out
 * @param {function(string, *)} props.setFieldValue - Notify parent of this field's value (given key, value)
 * @param {function(string, boolean)} props.setFieldStatus - Notify parent of field's validity (given key, boolean)
 * @returns {JSX.Element}
 * @constructor
 */
const ExtraCheckoutField = (props) => {
  const {field} = props;

  // Assign the values above depending on the input type
  switch (field.type) {
    case InputType.CHECKBOX:
      return (<CheckboxField {...props} />)
    case InputType.RADIO:
      return (<RadioField  {...props} />)
    case InputType.ADDRESS:
      return (<AddressField {...props} />)
    case InputType.DROPDOWN:
      return (<DropdownField {...props} />)
    case InputType.PHONE_NUMBER:
      return <PhoneNumberField {...props}/>
    case InputType.EMAIL:
      return <EmailField {...props}/>
    case InputType.DIGITS: case InputType.NUMBER:
      return <NumberField {...props} />
    case InputType.TEXT:
      return <TextField {...props} />
    default:
      console.error(`Unrecognized Input Type for Required Checkout Info: \n${field.type}`);
      return null;
  }
}

export default ExtraCheckoutField;

export {AddressField, CheckboxField, DropdownField, EmailField, NumberField, PhoneNumberField, RadioField, TextField};
