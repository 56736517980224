import React, {Component} from "react";
import API from "../../api";
import {TouchableWithoutFeedback, View} from "react-native";
import {Badge, Icon, Text} from "native-base";
import EStyleSheet from "react-native-extended-stylesheet";
import NotificationModal from "./NotificationModal";

export default class NotificationToggle extends Component {

  static propTypes = {}

  state = {
    newMessageCount: 0
  }

  componentDidMount() {
    this._listener = API.on('notices', this._update);
    this.setState({
      newMessageCount: this._newMessageCount()
    })
  }

  componentWillUnmount() {
    this._listener.remove();
  }

  _update = () => {
    this.setState({ newMessageCount: this._newMessageCount() })
  }

  render() {
    const {showLabel} = this.props;
    const {newMessageCount} = this.state;
    const {config} = API;
    if (!config.receive_service_requests) return null;
    return (
      <View style={{marginHorizontal: 10}}>
        <TouchableWithoutFeedback onPress={this._togglePopup}>
          <View>
            <Icon name={"message-text"} type={"MaterialCommunityIcons"} style={{color: 'white'}}/>
            {newMessageCount > 0 && (
              <Badge style={NotificationToggle.style.badge}>
                <Text>{this.state.newMessageCount}</Text>
              </Badge>
            )}
            {showLabel && <Text style={{color: 'white', fontSize: 12}}>Inbox</Text>}
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }

  _newMessageCount = () => {
    const count = Object.values(API._notices).filter(n => !n.time_read && n.status !== 'dismissed').length;
    return count;
  }

  _togglePopup = ({nativeEvent}) => {
    let {showLabel} = this.props;
    NotificationModal.show(showLabel);
  }

  static style = EStyleSheet.create({
    badge: {
      position: 'absolute',
      transform: [{scale: 0.7}],
      top: -5,
      right: -10
    }
  })
}
