import _ from 'lodash';
import Model from './Model';
import OrderItem from './OrderItem';
import MenuItem from './MenuItem';
import sh from 'shorthash';
import uuid from 'uuid';
import CartModifier from "./CartModifier";
import ItemPart from "./ItemPart";
import mix from "./mixins/Mixin";
import hasModifiers from "./mixins/hasModifiers";

/**
 * @class CartItem
 */
export default class CartItem extends mix(Model).with(hasModifiers) {

  /** @type {string} This CartItem's specific UUID */
  id = uuid.v4();
  /** @type {?string} The ID of the customer that made the order for this item */
  customer_id = null;
  /** @type {?string} */
  menuId = null;
  /** @type {?string} */
  menu_heading_id = null;
  /** @type {?string} */
  menuItemId = null;
  /** @type {?string} */
  name = null;
  /** @type {Dictionary<CartModifier>} Maps group ids */
  mods = {};
  pretax_cents = 0;
  tax_cents = 0;
  tax_fraction = 0;
  /** @type {number[]} The list of integer seat numbers this item applies to */
  seat_numbers = [];
  qty = 1;
  /** @type {?string} The set of special instructions to the kitchen about this particular item */
  special_instructions = null;
  errors = [];
  // Fields for the Price Check
  discounts = [];
  lineitem_pretax_cents = null;
  lineitem_tax_cents = null;

  _field_map = {
     mods: mods => {
      if (Array.isArray(mods)) {
        let res = {};
        mods.forEach(mod => {
          let menuModifier = this.api.menuData.modifiersById[mod.id];
          if (!res[menuModifier.heading.id]) res[menuModifier.heading.id] = [];
          res[menuModifier.heading.id].push(new CartModifier(this, mod))
        });
        return res;
      } else {
        for (let groupId in mods) {
          mods[groupId] = mods[groupId].map(mod => new CartModifier(this, mod));
        }
        return mods;
      }
    },
    lineitem_pretax_cents: val => {
       if(this._parts.length) {
         let weights = this._parts.map(part => part.numerator / part.denominator);
         let values = ItemPart.distributeByWeights(val, weights);
         for (let i = 0; i < this._parts.length; i++) {
           this._parts[i].lineitem_pretax_cents = values[i];
         }
       }
       return val;
    },
    lineitem_tax_cents: val => {
       if(this._parts.length) {
         let weights = this._parts.map(part => part.numerator / part.denominator);
         let values = ItemPart.distributeByWeights(val, weights);
         for (let i = 0; i < this._parts.length; i++) {
           this._parts[i].lineitem_tax_cents = values[i];
         }
       }
       return val;
    },
    discounts: discounts => {
      if(this._parts.length){
        this._parts.forEach(part => {
          part.discounts = [];
        });
        discounts.forEach(discount => {
          let weights = this._parts.map(part => part.numerator / part.denominator);
          let values = ItemPart.distributeByWeights(discount.cents_added, weights);
          for (let i = 0; i < this._parts.length; i++) {
            this._parts[i].discounts = [];
            this._parts[i].discounts.push({
              promotion_id: discount.promotion_id,
              name: discount.name,
              cents_added: values[0]
            })
          }
        })
      }
      return discounts;
    }
  }
  /**
   * A list of properties to always include when converting CartItem into its JSON representation. Takes priority over
   * properties in the _do_not_serialize list of properties.
   * @type {string[]}
   * @private
   */
  _force_serialize = ['displayed_tax_cents', 'displayed_pretax_cents'];
  /**
   * A list of properties to never include when converting CartItem into its JSON representation
   * @type {string[]}
   * @private
   */
  _do_not_serialize = ['cart']

  _parts = [];

  constructor(cart, obj) {
    super();

    if (obj instanceof OrderItem) {
      return CartItem.fromOrderItem(cart, obj);
    }

    this.update(obj);

    // We define it this way so it isn't enumerated
    Object.defineProperty(this, 'cart', {
      value: cart,
      writable: true,
      enumerable: false
    });
  }

  /**
   * @returns {Location} The restaurant location this Cart Item will be sent to
   */
  get location() {
    return this.cart.location;
  }

  /**
   * @returns {boolean} True if this item's location is able to fulfill this menu item, false otherwise.
   */
  get is_fulfillable(){
    return this.cart.location.fulfillable_items.includes(this.menuItemId);
  }

  /**
   * @returns {string | boolean} The customer id if a customer was found, or false if no customer was found.
   */
  getCustomerId() {
    let menuData = this.api.getMenu();
    let menu = menuData.menus.find(m => m.menuId === this.menuId);
    return menu.customer && menu.customer.customer_id;
  }

  /**
   * Recursively goes down the child modifiers and returns the list of modifiers with errors and
   * marks the modifiers that have errors by populating their errors property with the Group IDs
   *
   * @returns {Array} the list of modifier with errors
   */
  hasModErrors(errors = []) {
    this.errors = []; // reset the errors array

    this.menuItem.modifier_groups.forEach( (group) => {
      let cartMods = this.mods[group.id] || [];
      let selected = cartMods.filter(m => m.selected);
      if (group.min_selected > selected.length || group.max_selected < selected.length) {
        this.errors.push(group.id)
      }
      selected.forEach(mod => {
        let childrenErrors = mod.hasModErrors(errors);
        errors.concat(childrenErrors);
      });
    })

    // if the location has a seated group and the seated group has more than 1 guest then make sure a seat number
    // is associated with the cartItem
    if (this.cart.location?.seated_group?.guests.length > 1 && this.seat_numbers.length === 0) {
      this.errors.push("seats")
    }
    // if the location has a seated group and number of guests is 1 then just default the item to be associated with
    // the only guest at that location
    else if (this.cart.location.seated_group?.guests.length == 1) {
      this.seat_numbers = [1];
    }

    if (this.errors.length > 0) {
      errors.push(this);
    }

    return errors;
  }

  static fromOrderItem(cart, obj) {
    const menuData = this.api.getMenu();

    let menuItem = menuData.menuItemsById[obj.itemId];
    let heading = menuItem.menu_heading;
    let menu = heading.menu;

    let newObj = {
      customer_id: obj.order.customer_id,
      menuId: menu.menuId,
      menu_heading_id: heading.id,
      menuItemId: obj.itemId,
      name: obj.itemName,
      mods: obj.mods,
      seat_numbers: obj.seat_numbers.slice(), //shallow copy to prevent carrying the memory reference over
      pretax_cents: menuItem.pretax_cents,
      tax_cents: menuItem.tax_cents,
      tax_fraction: menuItem.tax_fraction,
    };

    return new CartItem(cart, newObj);
  }

  /**
   * @returns {string} The ID of the Cart Item
   */
  getId() {
    return this.id;
  }

  getTotal() {
    return this.getPretaxTotal() + this.getTaxTotal();
  }

  getName() {
    return this.name || this.name_for_bartender;
  }

  getCreatedTime() {
    return this.cart.time;
  }

  getPretaxTotal(taxOnly) {
    let field = taxOnly ? 'tax_cents' : 'pretax_cents';
    let sum = this[field];
    for (let groupId in this.mods) {
      sum += this.mods[groupId].reduce((tot, modifier) => tot += modifier.selected ? modifier.getPretaxTotal() : 0, 0);
    }
    return sum;
  }

  getTaxTotal() {
    let sum = this.tax_cents;
    for (let groupId in this.mods) {
      sum += this.mods[groupId].reduce((tot, modifier) => tot += modifier.selected ? modifier.getTaxTotal() : 0, 0);
    }
    return sum;
  }

  getModifiers() {
    return this.mods;
  }

  getModifierString() {
    if (!this.mods) return null;
    let modsString = "";
    let index = 0;
    let l = Object.keys(this.mods).length;
    for(let heading_id in this.mods){
      let modList = this.mods[heading_id];
      modList.forEach( (mod) => {
        if (mod.selected) {
           modsString = modsString + mod.getModifierString();
        }
      })
      if ( index !== l - 1) {
        modsString = modsString + ", "
      }
      index++;
    }
    return modsString;
  }

  getHash() {
    let string = this.getName().replace(/\W/g, '');
    let mods = [...this.mods]; // Don't want to sort the actual mods, just a copy for reference
    mods.sort((a, b) => a.name > b.name).forEach(i => {
      string += "_" + i.name.replace(/\W/g, '');
    });
    return sh.unique(string);
  }

  get menuItem(){
    return this.api.menuData.menuItemsById[this.menuItemId];
  }

  get displayed_pretax_cents(){
     if (this.hasOwnProperty('lineitem_pretax_cents') && this.lineitem_pretax_cents != null) {
      return this.lineitem_pretax_cents;
    } else {
      return this.frontend_post_discount_cents;
    }
  }

  get displayed_tax_cents() {
    if (this.lineitem_tax_cents != null) {
      return this.lineitem_tax_cents;
    } else {
      let total = this.tax_cents;
      if(typeof this.mods === 'object') {
        // nested modifiers
        for (let groupId in this.mods) {
          total += this.mods[groupId].reduce((sum, modifier) => sum += modifier.selected ? modifier.getTaxTotal() : 0, 0);
        }
      } else {
        // non-nested modifiers
        if (this.mods) total += this.mods.reduce((sum, mod) => sum += mod.tax_cents, 0);
      }
      return total * this.qty;
    }
  }

  set displayed_tax_cents(val) {} // do nothing

  get frontend_post_discount_cents() {
    if (this.hasOwnProperty('lineitem_pretax_cents') && this.lineitem_pretax_cents != null) {
      return this.lineitem_pretax_cents;
    } else {
      return this.frontend_pre_discount_cents // no discounts;
    }
  }

  set frontend_post_discount_cents(val) {
  } // do nothing

  get frontend_pre_discount_cents() {
    if (this.hasOwnProperty('lineitem_pretax_cents') && this.lineitem_pretax_cents != null) {
      //Price came from server. Undo the effects of discounts, to back-calculate the pre-discount price to display.
      return this.lineitem_pretax_cents - _.sumBy(this.discounts, 'cents_added');
    } else {
      let total = this.pretax_cents;

      for (let groupId in this.mods) {
        this.mods[groupId].forEach(modifier => {
          if (modifier.selected) total += modifier.getPretaxTotal();
        })
      }
      return total * this.qty;
    }
  }

  set frontend_pre_discount_cents(val) {
  }

  /**
   * @returns {boolean} True if this Cart Item has a non empty list of mods.
   */
  hasMods(){
    return Object.keys(this.mods).length > 0;
  }

  isValid() {
    for(let i=0; i < this.menuItem.modifier_groups.length; i++){
      let group = this.menuItem.modifier_groups[i];
      let selected = this.mods[group.id] ? this.mods[group.id].filter(m=>m.selected) : [];
      if( group.min_selected && ( selected.length < group.min_selected || selected.length > group.max_selected ) ) return false;
    }

    for(let k in this.mods){
      if(this.mods[k].find(m=>!m.isValid())) return false;
    }
    return this.special_instruction_config?.required ? !!this.special_instructions : true;
  }

  locateErrors() {
    let errors = [];
    for(let i=0; i < this.menuItem.modifier_groups.length; i++){
      let group = this.menuItem.modifier_groups[i];
      let selected = this.mods[group.id] ? this.mods[group.id].filter(m=>m.selected) : [];
      if( group.min_selected && ( selected.length < group.min_selected || selected.length > group.max_selected ) ) {
        errors.push(this);
        this.errors[group.id] = []

      }
    }

    for(let k in this.mods){
      if(this.mods[k].find(m=>!m.isValid())) return false;
    }
    return this.special_instruction_config?.required ? !!this.special_instructions : true;
  }

  isCurrentLevelValid () {
    return new Promise( (resolve, reject) => {
      let menuItem = this.api.menuData.menuItemsById[this.menuItemId]

      // Traverse all the modifier groups even ones that dont have selections yet
      // and determine if there are the necessary amount of selections for each
      menuItem.modifier_groups.forEach( (group) => {
        let groupId = group.id;
        let selected = this.mods[groupId]?.filter(m => m.selected) || [];
        // We dont check group.max_selected here because we check that when a user selected an item
        if (selected.length < group.min_selected || selected.length > group.max_selected)  {
          let error = new Error(`Group "${ group.heading_name }" requires you to "${ group.description }" before proceeding.`);
          error.group = group;
          reject(error);
        }
      })
      resolve(true);
    })
  }

  /**
   * @returns {Object} A JSON repr of this CartItem model
   */
  toJSON() {
    let obj = super.toJSON();
    obj.mods = {};
    for(let id in this.mods){
      obj.mods[id] = this.mods[id].map(mod => mod.toJSON());
    }
    return obj;
  }
}
