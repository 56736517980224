import {ActivityIndicator, Modal, Text, View} from "react-native";
import Colors from "../../constants/Colors";
import React from "react";
import {modalStyles} from "../../styles/Global";
import EStyleSheet from "react-native-extended-stylesheet";

/**
 * Modal Loader Component which can render a full screen Loading Spinner overtop of existing modals
 * @param message
 * @param shown
 * @returns {JSX.Element}
 * @constructor
 */
const MLoader = ({message, shown}) => {
    let msgTxt = message ? <Text style={styles.message}>{message}</Text> : null;

    return (
      <Modal transparent visible={shown}>
        <View style={modalStyles.background}>
          <ActivityIndicator size="large" color={Colors.primary}/>
          {msgTxt}
        </View>
      </Modal>
    );
}

export default MLoader;

const styles = EStyleSheet.create({
  message: {
    fontSize: 18,
    color: 'white'
  }
})
