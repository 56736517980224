import _ from 'lodash';
import React from 'react';
import {View, Keyboard, FlatList, ActivityIndicator, StatusBar, Dimensions} from 'react-native';
import {
  Text,
  Container,
  Content,
  Drawer,
  Button,
  Form,
  Item,
  Label,
  Input,
  Picker,
  Footer,
  FooterTab
} from 'native-base';
import OrderListView from '../components/OrderListView';
import EStyleSheet from 'react-native-extended-stylesheet';
import PropTypes from 'prop-types';

/* ========= App Imports ========== */
import API from "../api";
import CmdButton from "../components/CmdButton";
import Loader from "../components/Loader";
import OrderListViewHeader from "../components/OrderListViewHeader";
import OrderItemStatusChanger from "../components/OrderItemStatusChanger";

import {CommandBar, CommandBarCenter, CommandBarLeft, CommandBarRight, SendReceiptModal} from "../components";
import Colors from "../constants/Colors";
import RefundCmdButton from "../components/RefundCmdButton";
import HeaderIconButton from "../components/HeaderIconButton";
import OrderHelper from "../helpers/OrderHelper";
import DateTimePickerField from "../components/DateTimePickerField";
import IconButton from "../components/IconButton";
import RefundIconButton from "../components/RefundIconButton";
import {offsetInput} from "../helpers/DateTimePickerHelper";

class OrderSearchScreen extends React.Component {

  static navigationOptions = (props) => {
    let {navigation} = props;
    let onShowFilters = navigation.getParam('onShowFilters');
    let onRefresh = navigation.getParam('onRefresh');
    return {
      title: 'Orders',
      headerRight: () => (
        <View style={{flexDirection: 'row'}}>
          <HeaderIconButton name={"Refresh"} icon={"refresh"} onPress={onRefresh} />
          <HeaderIconButton name={"Search"} icon={"filter"} onPress={onShowFilters} type={"FontAwesome"}/>
        </View>
      )
    }
  }

  constructor(props) {
    super(props);
    let {navigation} = props;

    navigation.setParams({
      onShowFilters: this.openDrawer,
      onRefresh: this._onRefresh
    });

    const {width} = Dimensions.get('window');

    this.state = {
      loading: false,
      loadingMore: false,
      orders: [],
      orderFees: [],
      page: 1,
      numPages: 0,
      selectedOrders: [],
      selectedItems: [],
      showPinPad: false,
      offset: this._calculateOffset(width)
    };
  }

  closeDrawer = () => {
    if (this.drawer) {
      Keyboard.dismiss();
      this.drawer._root.close()
    }
  };

  openDrawer = () => {
    this.drawer._root.open()
  };

  componentDidMount() {
    const {navigation} = this.props;
    this.focusListener = navigation.addListener('didFocus', () => setTimeout(this.openDrawer, 200))
    Dimensions.addEventListener('change', this._dimensionsChanged);
  }

  componentWillUnmount() {
    this.focusListener.remove();
    Dimensions.removeEventListener('change', this._dimensionsChanged);
  }

  _calculateOffset = (width) => {
    return width > 400 ? 1-(400/width) : 0.2;
  }

  _dimensionsChanged = ({window}) => {
    const {width} = window;
    this.setState({ offset: this._calculateOffset(width) })
  }

  refreshOrders = async (params) => {
    try {
      this.closeDrawer();

      params.page = 1;
      this.searchParams = params;

      this.setState({
        loading: true,
        orders: [],
        selectedItems: [],
        orderFees: [],
        numPages: 0,
        page: 1,
        receiptModal: false
      });

      params.hours_in_past = parseInt(params.hours_in_past);
      if (isNaN(params.hours_in_past)) params.hours_in_past = null;

      let result = await API.searchOrders(params);

      this.setState({
        loading: false,
        orders: result.orders,
        orderFees: result.orderFees,
        numPages: result.numPages
      });
    } catch (err) {
    }
  }

  _onRefresh = () => {
    this.refreshOrders(this.searchParams);
  }

  render() {
    const { selectedOrders, offset } = this.state;

    return (
      <Drawer
        ref={(ref) => { this.drawer = ref; }}
        side="right"
        openDrawerOffset={offset}
        content={<FilterDrawer navigator={this.navigator} onSearch={this.refreshOrders}/>}
        onClose={this.closeDrawer}
        tapToClose={true}
      >
        <View style={{flex: 1}}>
          {this.state.loading && <Loader/>}
          <FlatList
            ListHeaderComponent={<OrderListViewHeader/>}
            stickyHeaderIndices={[0]}
            data={this.state.orders}
            renderItem={this._renderItem}
            extraData={this.state.dataChanged}
            keyExtractor={item => item.orderId}
            onEndReached={this.showMore}
            onEndReachedThreshold={0.5}
            initialNumToRender={10}
            onRefresh={this._onRefresh}
            refreshing={false}
            ListFooterComponent={this._renderFooter}
            ListEmptyComponent={this._listEmpty}
          />
          <OrderItemStatusChanger
            selectedItems={this.state.selectedItems}
            onChange={this._onItemStatusChange}
            allowRegress={true}
          />
          <Footer style={{backgroundColor: 'black'}}>
            <FooterTab style={{backgroundColor: 'black'}}>
              <RefundIconButton
                selectedItems={this.state.selectedItems}
                orders={[selectedOrders[0]]}

              />
              <IconButton
                label={"Send Receipt"}
                disabled={this._numOrders()!==1}
                onPress={()=>this.setState({receiptModal: true})}
                icon={'send'}
                iconType={'FontAwesome'}
              />
              <IconButton
                label={"Re-print Ticket"}
                 icon={'ticket'}
                  iconType={'Entypo'}
                disabled={this._numOrders()!==1}
                onPress={()=>{ OrderHelper.printTicket(this.state.selectedItems[0].order, true)}}
              />
              <IconButton
                label="De-Select"
                disabled={!this.state.selectedItems.length}
                onPress={this._deselectAll}
                icon={"select-off"}
                iconType={"MaterialCommunityIcons"}
              />
            </FooterTab>
          </Footer>
          <SendReceiptModal
            visible={this.state.receiptModal}
            onCancel={()=>this.setState({receiptModal: false})}
            onSave={()=>this.setState({receiptModal: false})}
            order={this.state.selectedItems[0] ? this.state.selectedItems[0].order : null}
          />
        </View>
      </Drawer>
    );
  }

  _renderItem = (data) => {
    const order = data.item;
    return (
      <OrderListView
        order={order}
        onHeaderPress={this._updateSelected}
        onItemPress={this._updateSelected}
        selectedItems={this.state.selectedItems}
      />
    )
  }

  _listEmpty = (
    <View style={{height: 50, alignItems: 'center', justifyContent: 'center'}}>
      <Text style={{color: Colors.gray}}>No Orders Found</Text>
    </View>
  )

  _renderFooter = () => {
    if (!this.state.loadingMore) return null;
    return (
      <View style={{paddingVertical: 20, borderTopWidth: 1, borderTopColor: Colors.gray}}>
        <ActivityIndicator size={"large"} color={Colors.primary}/>
      </View>
    )

  }

  showMore = async () => {

    if (this.searchParams.page < this.state.numPages) {
      let page = this.state.page + 1;
      this.setState({
        loadingMore: true,
        page: page
      });

      this.searchParams.page = page;
      let result = await API.searchOrders(this.searchParams);

      if (!result.error) {
        this.setState({
          loadingMore: false,
          orders: this.state.orders.concat(result.orders),
          orderFees: this.state.orderFees.concat(result.orderFees),
          numPages: result.numPages,
        });
      } else {
        this.setState({
          loadingMore: false
        })
      }
    }
  }

  _updateSelected = (obj, selectedItems) => {
    const { orders } = this.state;
    this.setState({
      selectedOrders: orders.filter((order) => selectedItems.map((item) => item.orderId).includes(order.orderId)),
      selectedItems: [...selectedItems]
    })
  }

  _onItemStatusChange = (selectedItems) => {
    //let orders = this.state.orders;
    this.setState({
      dataChanged: !this.state.dataChanged
    });
  }

  _deselectAll = () => {
    this.setState({
      selectedItems: []
    })
  }

  _numOrders = () => {
    return _.uniqBy(this.state.selectedItems, 'orderId').length;
  }

  static propTypes = {}
}


class FilterDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.state = {
      order_number: '',
      hours_in_past: '',
      phone_number: '',
      locationShortId: '',
      last4: '',
      exp_month: '',
      exp_year: '',
      checkout_info: '',
      start_date: undefined,
      end_date: undefined
    }
  }

  render() {
    let locationCodes = API.getLocationCodes().sort();

    return (
      <Container>
        <Content padder>
          <Form>
            <Item stackedLabel accessible={false}>
              <Label>Order Number</Label>
              <Input
                value={this.state.order_number}
                onChangeText={text => this.setState({order_number: text})}/>
            </Item>
            { /*
            <Item stackedLabel accessible={false}>
              <Label>Hours In Past</Label>
              <Input
                value={this.state.hours_in_past}
                keyboardType={'number-pad'}
                onChangeText={text => this.setState({hours_in_past: text})}/>
            </Item>
            */ }
            <Item stackedLabel accessible={false}>
              <Label>Start Date</Label>
              <DateTimePickerField
                value={this.state.start_date}
                onChange={text => this.setState({start_date: text})}
                pickerOptions={{
                  maximumDate: this.state.end_date ? offsetInput(this.state.end_date) : null
                }}
              />
            </Item>

            <Item stackedLabel accessible={false}>
              <Label>End Date</Label>
              <DateTimePickerField
                value={this.state.end_date}
                onChange={text => this.setState({ end_date: text })}
                pickerOptions={{
                  minimumDate: this.state.start_date ? offsetInput(this.state.start_date) : null
                }}
              />
            </Item>
            <Item picker stackedLabel accessible={false}>
              <Label>Location</Label>
              <Picker
                mode={"dialog"}
                selectedValue={this.state.locationShortId}
                onValueChange={val => {
                  this.setState({locationShortId: val})
                }}
                style={{width: '100%', alignSelf: 'flex-start'}}
              >
                <Picker.Item key={'none'} label={'All'} value={''}/>
                {locationCodes.map(code => <Picker.Item key={code} label={code} value={code}/>)}
              </Picker>
            </Item>
            <Item stackedLabel accessible={false}>
              <Label>Phone Number</Label>
              <Input
                value={this.state.phone_number}
                keyboardType={'phone-pad'}
                onChangeText={text => this.setState({phone_number: text})}
              />
            </Item>
            <Item stackedLabel accessible={false}>
              <Label>Card Last 4</Label>
              <Input
                value={this.state.last4}
                keyboardType={'number-pad'}
                maxLength={4}
                onChangeText={text => this.setState({last4: text})}
              />
            </Item>
            <Item stackedLabel accessible={false}>
              <Label>Card Expiry Month</Label>
              <Input
                value={this.state.exp_month}
                keyboardType={'number-pad'}
                onChangeText={text => this.setState({exp_month: text})}
              />
            </Item>
            <Item stackedLabel accessible={false}>
              <Label>Card Expiry Year</Label>
              <Input
                value={this.state.exp_year}
                keyboardType={'number-pad'}
                onChangeText={text => this.setState({exp_year: text})}
              />
            </Item>
            <Item stackedLabel accessible={false}>
              <Label>Checkout Info</Label>
              <Input
                value={this.state.checkout_info}
                onChangeText={text => this.setState({checkout_info: text})}
              />
            </Item>
             <View style={{flexDirection: 'row', width: '100%', paddingLeft: 15, paddingTop: 5}}>
              <Button block light style={{flex: 1, marginRight: 5}} onPress={this.resetForm}>
                <Text>Reset</Text>
              </Button>
              <Button block style={{flex: 1, marginLeft: 5}} primary onPress={this._onSearch}>
                <Text style={{color: 'white'}}>Search Orders</Text>
              </Button>
            </View>

          </Form>
        </Content>
      </Container>
    )
  }

  _onSearch = () => {
    this.props.onSearch(this.getParams());
  }

  getParams = () => {
    return {
      ...this.state
    }
  }

  resetForm = () => {
    this.setState({
      order_number: '',
      hours_in_past: '',
      phone_number: '',
      locationShortId: '',
      last4: '',
      exp_month: '',
      exp_year: '',
      start_date: null,
      end_date: null
    });
  }

  static propTypes = {
    onSearch: PropTypes.func.isRequired,
  }
}

export default OrderSearchScreen;
