import _ from 'lodash';
import React, {useState, useEffect, useRef} from 'react';
import {View, ScrollView, Platform} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import {showMessage} from "react-native-flash-message";
import API, {Orientation} from '../../api';
import StripeCardModal from '../../components/Stripe/StripeCardModal';
import Loader from "../../components/Loader";
import ReaderStatus from '../../components/ReaderStatus';
import CartItemsCard from "./CartItemsCard";
import TipsCard from "./TipsCard";
import ExtraCheckoutInfoCard from "./ExtraCheckoutInfoCard";
import {InputType} from "./Form";
import PaymentInfoCard from "./PaymentInfoCard";
import ReceiptInfoCard from "./ReceiptInfoCard";
import CheckoutButtonCard from "./CheckoutButtonCard";
import FulfillmentMethodCard from "./FulfillmentMethodCard";

/**
 * The Terminal Checkout Screen
 *
 * @param props
 * @constructor
 */
const CheckoutScreen = ({navigation}) => {

  /** @type {Location} */
  const location = navigation.getParam('location');
  /** @type {CheckModel} */
  const check = navigation.getParam('check');
  /** @type {CartModel} */
  const {cart} = check;

  const [fulfillmentMethod, setFulfillmentMethod] = useState(cart.fulfillment_method);

  /** @type {SavedCard | PartyTab} The currently selected payment method, either an entered card or a party tab */
  const card = navigation.getParam('card');

  /** @type {boolean} Only show the Extra Checkout Information for the first check in the cart */
  const showExtraCheckoutInfoCard = check.cart.checks[0].label === check.label;

  /** @type {Dictionary<ExtraFieldData>} A list of data for each extra checkout field (only ones for handheld) */
  const extraCheckoutInfo = _.pickBy(location.required_checkout_info, field => field.show_on_handheld);

  /** @type {ExtraFieldData[]} Extra Checkout Info sorted in order of display */
  const fields = _.sortBy(
    _.values(extraCheckoutInfo).filter(field => !field.method || field.method === fulfillmentMethod),
    'display_order');

  /** @type {Dictionary<boolean>} Maps keys of fields to true if they are optional, false if required (default state) */
  let defaultValidFields = {};
  fields.forEach(f => {
    defaultValidFields[f.key] = !f.required_on_handheld
  })

  /** @type {[Dictionary<boolean>, ReactHook<Dictionary<boolean>>]} Maps the key of a field to whether it is valid */
  const [validFields, setValidFields] = useState(defaultValidFields);

  /** @type {Object<string, *>} Maps the keys of checkbox fields to their default choice */
  let defaultFieldValues = {};

  // If not first check, defaultFieldValues is empty to not override the first check.
  useEffect(() => {
    // If its first check AND there already exist values (i.e. editing old answers), repopulate defaultFieldValues
    if (check.cart.extra_checkout_info.length) {
      check.cart.extra_checkout_info.forEach(fieldData => defaultFieldValues[fieldData.key] = fieldData.value);
    } else {
      // Otherwise, populate defaultFieldValues with the default_choice if given in fieldData
      fields.forEach(field => {
        if (field.type === InputType.CHECKBOX || field.type === InputType.RADIO) {
          if (field.choices && field.choices.length > 1) {
            defaultFieldValues[field.key] = field.value;
          } else {
            // for backwards compatibility:
            defaultFieldValues[field.key] = field.value || (field.default_choice ? ['yes'] : []);
            if (field.key === 'marketing_opt_in' && !field.choices) {
              field.choices = [{label: 'yes'}];
            }
          }
        } else if (field.type === InputType.ADDRESS) {
          defaultFieldValues[field.key] = field.value || {};
        } else {
          defaultFieldValues[field.key] = field.value;
        }
      })
      setFieldValues(defaultFieldValues);
    }
  }, [showExtraCheckoutInfoCard]);


  /** @type {[boolean, ReactHook<boolean>]} If true, blurs the prices in the Items Card (while checking promo codes, etc) */
  const [checkingPrices, setCheckingPrices] = useState(location.require_get_cart_price);
  /** @type {[boolean, ReactHook<boolean>]} Used when verifying a Card that was just submitted */
  const [loading, setLoading] = useState(false);
  /** @type {[Orientation, ReactHook<Orientation>]} For adjusting the padding on landscape handhelds */
  const [orientation, setOrientation] = useState(API.orientation);
  /** @type {[number, ReactHook<number>]} (In cents) The amount of money for tips. Computations in <TipsCard> */
  const [tipAmount, setTipAmount] = useState(0);
  /** @type {[boolean, ReactHook<boolean>]} True to show add card modal when no card on paid order, false otherwise */
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  /** @type {[boolean, ReactHook<boolean>]} True if all required checkout fields have been filled out (correctly) */
  const [highlightErrors, setHighlightErrors] = useState(false);
  /** @type {[Map<string, *>, ReactHook<Map<string, *>>]} Maps Field keys to their values (only saves valid values) */
  const [fieldValues, setFieldValues] = useState(defaultFieldValues);
  /** @type {[string, ReactHook<string>]} The email address to send the check to. */
  const [email, setEmail] = useState(check.sendReceipt.email);
  /** @type {[string, ReactHook<string>]} The phone number to send status updates of this check to. */
  const [phone, setPhone] = useState(check.sendReceipt.phone);
  const [receiptType, setReceiptType] = useState('email');

  const [yCoords, setYCoords] = useState({});

  const scrollView = useRef(null);

  // If true, the Approve / Continue button at the bottom of the screen cannot be clicked
  const continueButtonDisabled = checkingPrices || !check.isValid();
  const continueButtonLabel = (!card && (check.total + tipAmount) > 0) ? 'Pay Now' : 'Approve';

  const setExtraCheckoutInfo = (newValues) => {
    check.cart.extra_checkout_info = _.reduce(newValues, (res, value, key) => {
      return res.concat({
        key,
        value,
        name_for_patron: extraCheckoutInfo[key].name_for_patron,
        name_for_bartender: extraCheckoutInfo[key].name_for_bartender,
        show_on_ticket: extraCheckoutInfo[key].show_on_ticket,
        show_on_kds: extraCheckoutInfo[key].show_on_kds
      })
    }, []);
    // Update the sendReceipt values:
    if (newValues?.email) setEmail(newValues.email);
    if (newValues?.phone_number) setPhone(newValues.phone_number);

    setFieldValues(newValues);
  }

  useEffect(() => {
    // Initial getCartPrice
    check.cart.getCartPrice().then(() => {
      // TODO: Update Tip Value!!
      setCheckingPrices(false)
    });

    // equivalent to componentDidMount
    API.on('orientation', setOrientation);
    const updateListener = check.cart.on('updating', (isUpdating) => setCheckingPrices(isUpdating));
    // equivalent to componentWillUnmount
    return function cleanup() {
      API.off('orientation', setOrientation);
      updateListener.remove();
    }
  }, []);

  /**
   * If the Terminal User attempts to approve an order, but the order has no card and a non-zero total, then the
   * <StripeCardModal>  will appear, prompting them to enter a payment method. Once they have finished, this method will be
   * called, creating the payment method and goes to the Thank You screen.
   *
   * @param paymentIntent
   * @param paymentIntent.id
   * @param paymentIntent.amount
   */
  const onCardAdded = async (paymentIntent) => {
    const tabName = '';
    const seatedGroup = location.seated_group;

    setShowAddCardModal(false);
    setLoading(true);

    const seated_group_id = seatedGroup ? seatedGroup.id : null;
    try {
      // TODO: Don't openTab, dont' fundTab from paymentIntent. Just use the paymentIntent to pay...
      /* check.charge = {
         payment_intent_id: paymentIntent.id,
         type: 'payment_intent_to_verify',
         amount_cents: parseInt(paymentIntent.amount)
       }
       */
      let data = await API.openTab(seated_group_id, '', location.id, null);
      if (data.error) {
        showMessage({
          type: 'danger',
          message: data.error
        });
        setLoading(false);
        return;
      }
      let tab = data.tab;
      let amount = parseInt(paymentIntent.amount);
      let charge = await API.fundTabFromPaymentIntent(tab, paymentIntent.id, amount, true);

      check.charge = tab.getCharge(amount);
      check.charge.keep_open = false;
      check.tip_cents = tipAmount;

      goToThankYou();
    } catch (err) {

      showMessage({
        type: 'danger', floating: true, position: 'top',
        message: `An unknown error occurred during payment processing. Please try again or contact support: ${err}`
      })
      setLoading(false);
    }
  };

  /**
   * Navigates to the thank you screen for the patron to return the device to the Terminal User. Sends the request to
   * the Bbot server ot charge the payment method the information found in the `approve` method.
   */
  const goToThankYou = () => {
    const processImmediately = navigation.getParam('processImmediately');
    let refreshFn = navigation.getParam('refreshFn');
    if (processImmediately) {
      refreshFn();
      refreshFn = () => {};
    }
    navigation.navigate('CaptureSuccessScreen', {
      refreshFn: refreshFn,
      joinURL: card?._joinURL,
      go_back_key: navigation.getParam('go_back_key')
    });
    if (card?._joinURL) card._joinURL = null;
  }

  const validateCheckoutInfo = () => {
    // just need to look at validFields
    let errors = [];
    for (let key in validFields) {
      if (!validFields[key] && (!extraCheckoutInfo[key].method || extraCheckoutInfo[key].method === fulfillmentMethod)) errors.push(key);
    }
    return errors;
  }

  /**
   * Gets the information required to charge the payment method, or notes that this is free. Navigates to the Thank You
   * Screen after this check.
   */
  const approve = async () => {

    const errors = validateCheckoutInfo();
    if (errors.length) {
      setHighlightErrors(true);
      scrollView.current.scrollTo({
        y: yCoords[errors[0]] - 56 // header height
      })
      return;
    }

    // Updates the Send Receipt Statuses before submitting.
    check.sendReceipt = {
      email: receiptType === 'email' ? email : null,
      phone: receiptType === 'sms' ? phone : null
    }

    // Check for a valid payment method if the total is non-zero
    const total = check.total + tipAmount;


    if (card && card.type === 'tab') {
      setLoading(true);
      if (card.available_cents < total) {
        try {
          // Fund Tab:
          const response = await API.expandConsumerTab(card, total, location.id);
          if (response.errorCode) {
            showMessage({
              type: 'danger',
              message: response.errorCode
            });
            setLoading(false);
            return false;
          }
        } catch (err) {
        }
      }
    }
    // If no card on record, and there is a non-zero total, request the card
    if (!card && total > 0) {
      setShowAddCardModal(true);
    } else {
      if (total) {
        check.charge = card.getCharge(check.total + tipAmount);
        check.tip_cents = tipAmount;
      } else {
        check.charge = {
          type: 'free',
          amount_cents: 0
        }
      }
      goToThankYou();
    }
  };

  return (
    <View testID={'tipScreen'} style={styles.container}>
      {loading && <Loader/>}
      <View style={styles.innerContainer}>
        {/* keyboardShouldPersistTaps fixes issue with AddressField popup not being tappable */}
        <ScrollView ref={scrollView} contentContainerStyle={styles.scrollview} keyboardShouldPersistTaps={'always'}>
          { /** Cart Items, totals, and Promo Input **/}
          <CartItemsCard
            check={check}
            checkingPrices={checkingPrices}
            tipAmount={tipAmount}
            orientation={orientation}
          />
          { /** Tips Section **/}
          {location.showTipSection() && (
            <TipsCard
              check={check}
              setTipAmount={setTipAmount}
              tipAmount={tipAmount}
              orientation={orientation}
              checkingPrices={checkingPrices}
            />
          )}
          {
            location.possible_fulfillment_methods?.length > 1 && (
              <FulfillmentMethodCard
                cart={cart}
                fulfillmentMethod={fulfillmentMethod}
                setFulfillmentMethod={(val) => {
                  cart.fulfillment_method = val;
                  setFulfillmentMethod(val);
                }}
              />
            )
          }
          {/* Only Show the Extra Checkout Info one time per checkout. SplitScreen will track this. */}
          {showExtraCheckoutInfoCard && (
            <ExtraCheckoutInfoCard
              cart={cart}
              fields={fields}
              highlightErrors={highlightErrors}
              fieldValues={fieldValues}
              setFieldValues={setExtraCheckoutInfo}
              orientation={orientation}
              updateElementPosition={(key, val) => {
                yCoords[key] = val;
                setYCoords(yCoords);
              }}
              validFields={validFields}
              setValidFields={setValidFields}
            />
          )}
          <PaymentInfoCard
            card={card}
            location={location}
            orientation={orientation}
          />
          <ReceiptInfoCard
            email={email}
            phoneNumber={phone}
            setEmail={setEmail}
            setPhoneNumber={setPhone}
            receiptType={receiptType}
            setReceiptType={setReceiptType}
            orientation={orientation}
          />
          <CheckoutButtonCard
            onApprove={approve}
            check={check}
            disabled={continueButtonDisabled}
            label={continueButtonLabel}
          />
        </ScrollView>

        {/* ---------------------- Modals ---------------------- */}
        <StripeCardModal
          saveButtonText={"SUBMIT"}
          visible={showAddCardModal}
          onSuccess={onCardAdded}
          onClose={() => setShowAddCardModal(false)}
          paymentIntentParams={{
            currency: API.customer.currency,
            amount: check.total + (tipAmount || 0),
            statement_descriptor: API.customer.statement_descriptor
          }}
        />
      </View>
    </View>
  );
}


CheckoutScreen.navigationOptions = (props) => ({
  title: 'Checkout',
  headerRight: () => (
    <View style={styles.header}>
      <ReaderStatus requirePin={true}/>
    </View>
  )
});


export default CheckoutScreen;

const styles = EStyleSheet.create({
  header: {
    marginRight: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  container: {flex: 1, alignItems: 'center'},
  innerContainer: {flex: 1, maxWidth: 600, width: '100%'},
  scrollview: {
    alignItems: 'center',
    flexGrow: 1,
    ...Platform.OS === 'web' ? {flexBasis: 0} : {}
  }
})
