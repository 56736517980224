import React from 'react';
import { Item, Label, Picker } from "native-base";
import Required from "./Required";
import EStyleSheet from "react-native-extended-stylesheet";

const DropdownField = ({field, fieldValue, setFieldValue, valid, setValid, ...rest}) => {

  return (
    <Item style={styles.dropdownContainer} {...rest}>
      <Label>{field.name_for_patron} <Required show={field.required_on_handheld}/></Label>
      <Picker
        selectedValue={fieldValue}
        onValueChange={(value) => {
          setFieldValue(value);
        }}
      >
        {
          field.choices.map(choice => (
            <Picker.Item key={choice.label} label={choice.label}/>
          ))
        }
      </Picker>
    </Item>
  )
}

export default DropdownField;

const styles = EStyleSheet.create({
  dropdownContainer: {
    flexDirection: 'row',
    paddingVertical: 10
  }
})
