import React, {Component} from 'react';
import _ from 'lodash';
import { View, FlatList, ActivityIndicator, Image, TouchableWithoutFeedback, Dimensions } from 'react-native';
import Alert from "../components/Alert";
import { Container, Header, Item, Input, Icon, Button, Content, Text, ListItem, Left, Body, Right, Switch, Segment } from 'native-base';
import PropTypes from 'prop-types';
import EStyleSheet from "react-native-extended-stylesheet";
import {Header as RNHeader} from 'react-navigation-stack';
import Colors from "../constants/Colors";
import API from '../api';
import {FormattedCurrency} from "react-native-globalize";
import {MenuItem, Modifier} from "../models";
import EditMenuItemModal from "../components/EditMenuItemModal";
import Loader from "../components/Loader";
import {escapeRegExp} from "../helpers/HelperFunctions";

let {wwidth, wheight} = Dimensions.get('window');
const MIXER = require('../assets/images/umbrella.png');
const ADDON = require('../assets/images/french-fries.png');
//const headerHeight  = RNHeader.HEIGHT;

class MenuManagement extends Component {

  static propTypes = {};

  state = {
    showloader: true,
    refreshing: false,
    dataChanged: false,
    searchText: '',
    data: [],
    processing: {},
    filter: 'all',
    filteredItems: [],
    orientation: ''
  }

  constructor(props) {
    super(props);

    this.filters = {
      all: {
        menu_item_class: ['food','drink','mixer','addon']
      },
      menuitems: {
        menu_item_class: ['food', 'drink']
      },
      modifiers: {
        menu_item_class: ['mixer', 'addon']
      }
    }

    this.state.orientation = wwidth > wheight ? 'landscape':'portrait';
    this.allItems = API.menuData?.itemsAndModifiersByName ? API.menuData.itemsAndModifiersByName() : [];
  }

  componentDidMount() {
    API.on('menu', this._update);
    this._listener = this.props.navigation.addListener('willFocus', this._update);
    this._update();
  }

  componentWillUnmount() {
    API.off('menu', this._update);

    if(this._listener) this._listener.remove();
  }

  _onLayout = ({nativeEvent}) => {
    let {layout: {width, height}} = nativeEvent;
    this.setState({
      orientation: width > height ? 'landscape':'portrait'
    })
  }

  _filters = () => {
    return (
      <Segment style={{backgroundColor: 'black'}}>
        <Button first onPress={()=>this._changeFilter('all')} active={this.state.filter === 'all'}>
          <Text>All Items</Text>
        </Button>
        <Button onPress={()=>this._changeFilter('menuitems')} active={this.state.filter === 'menuitems'}>
          <Text>Menu Items</Text>
        </Button>
        <Button last onPress={()=>this._changeFilter('modifiers')} active={this.state.filter === 'modifiers'}>
          <Text>Modifiers</Text>
        </Button>
      </Segment>
    )
  }

  _changeFilter = (filter) => {
    this.setState({
      showloader: true
    })
    setTimeout(()=>{
      let selectedFilter = this.filters[filter];

      let data = this.allItems.filter(item => {
        for (let key in selectedFilter) {
          if (!selectedFilter[key].includes(item[key]))
            return false;
        }
        return true;
      });

      this.setState({
        filter: filter,
        filteredItems: data
      }, this._onSearch);
    })
  }

  render() {
    return (
     <Container onLayout={this._onLayout}>
       <Header searchBar hasSegment style={styles.header}>
         <Item style={{flex: 1}}>
           <Icon name={"ios-search"}/>
           <Input 
             placeholder={"Search Menu"}
             value={this.state.searchText}
             onChangeText={(val)=>{
               this.setState({searchText: val}, this._onSearch)
             }}
           />
           <Icon type="MaterialCommunityIcons" name={"arrow-right"}/>
         </Item>
         <View style={{flexDirection: 'row', margin: 20}}>
            <Button transparent onPress={this.clearSearch }>
              <Icon name={'ios-close'} ios={'ios-close'} android={'md-close'}/>
            </Button>
         </View>
         { this.state.orientation === 'landscape' && this._filters() }
       </Header>
       { this.state.orientation === 'portrait' && this._filters() }
       <View style={{flex:1}}>
         <Loader shown={this.state.showloader}/>
         <FlatList
           onContentSizeChange={()=>{
             if(this.state.loading){
               this.setState({ loading: false})
             }
           }}
           ListHeaderComponent={<ListHeader/>}
           stickyHeaderIndices={[0]}
           data={this.state.data}
           keyExtractor={item=>item.menuItemId}
           onRefresh={this._refresh}
           renderItem={this._renderItem}
           refreshing={this.state.refreshing}
           extraData={this.state.dataChanged}
           initialNumToRender={15}
           ListEmptyComponent={this.renderEmptyMessage}
           getItemLayout={this._getItemLayout}
          />
       </View>
       <EditMenuItemModal item={this.state.item} visible={this.state.showEditModal} onClose={()=>this.setState({showEditModal: false})} />
     </Container>
    );
  }

  renderEmptyMessage = () => {
    const { refreshing, searchText } = this.state;
    return (
      <ListItem style={{justifyContent: "center"}}>
        <View>
          <Text>No Results for "{searchText}"</Text>

          {this.allItems === [] &&
          <View style={{marginTop: 20, alignItems: "center"}}>
            <Text style={{ color: "#777", marginBottom: 10 }}>There were issues getting your menu items.</Text>
            <Button disabled={ refreshing } onPress={this._refreshMenu} style={{maxWidth: 250, justifyContent: "center"}}>
              <Text>Refresh Menu Items</Text>
              <Icon name={'refresh'} ios={'ios-refresh'} android={'md-refresh'}/>
            </Button>
          </View>
          }
        </View>
      </ListItem>
    );
  }

  _refreshMenu = async () => {
    this.setState({
      refreshing: true
    })

    await API.doMenuUpdate();
    this.allItems = API.menuData.itemsAndModifiersByName ? API.menuData.itemsAndModifiersByName() : [];

    this.setState({
      refreshing: false
    })
  }

  _getItemLayout = (data, index) => ({
    length: 44,
    offset: 44 * index,
    index
  })

  clearSearch = () => {
    this.setState({
      searchText: ''
    }, this._onSearch)
  }

  _update = () => {
    this.allItems = API.menuData?.itemsAndModifiersByName ? API.menuData.itemsAndModifiersByName() : [];
    this._changeFilter(this.state.filter)
  }

  _onSearch = () => {
    const search = escapeRegExp(this.state.searchText);
    let query = new RegExp(search, 'gi');
    this.setState({
      data: _.filter(this.state.filteredItems, item => item.search_string.search(query) >= 0)
    }, () => {
      this.setState({
        showloader: false
      })
    })
  }

  _renderItem = ({item}) => {
    return (
       <ListMenuItem
         item={item}
         onToggle={ this._toggleInStock }
         onPress={ this._menuItemPress }
         processing={ this.state.processing[item.menuItemId] }
       />
     )
  }

  _refresh = () => {
    this.setState({
      dataChanged: !this.state.dataChanged
    })
  }

  _setProcessing = (item, val) => {
    this.state.processing[item.menuItemId] = val;
    return this.state.processing;
  }

  _toggleInStock = async item => {
    this.setState({
      dataChanged: !this.state.dataChanged,
      processing: this._setProcessing(item, true)
    });

    let result = await API.stockUpdate(item, !item.in_stock);
    if(result.error){
      Alert.alert("Error", result.error);
    }

    this.setState({
      dataChanged: !this.state.dataChanged,
      processing: this._setProcessing(item, false)
    });
  }

  _menuItemPress = async item => {
    this.setState({
      item: item,
      showEditModal: true
    })
  }
}


const ListHeader = () => {
  return (
    <ListItem itemDivider style={{borderBottomWidth: 1}}>
      <Left style={styles.headerLeft}></Left>
      <Body style={styles.headerBody}><Text>Menu Item</Text></Body>
      <Right><Text>In Stock</Text></Right>
    </ListItem>
  )
}


class ListMenuItem extends React.Component {

  static propTypes = {
    item: PropTypes.oneOfType([
      PropTypes.instanceOf(MenuItem),
      PropTypes.instanceOf(Modifier)
    ]),
    onToggle: PropTypes.func,
    onPress: PropTypes.func,
    processing: PropTypes.bool
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    let currItem = this.props.item;
    let nextItem = nextProps.item;
    return this._in_stock !== nextItem.in_stock || this.props.processing !== nextProps.processing;
  }

  render() {
    let {item, processing } = this.props;
    this._in_stock = item.in_stock;

    return (
      <ListItem key={item.menuItemId} icon style={{height: 44}}>
        <Left style={{paddingRight: 30}}>
          {this._getIcon(item.menu_item_class)}
        </Left>
        <Body>
          <TouchableWithoutFeedback onPress={this._itemPressed}>
            <View>
              <Text numberOfLines={1}>{item.name}</Text>
              {this._itemDescription(item)}
            </View>
          </TouchableWithoutFeedback>
        </Body>
        <Right>
          {
            processing ?
              <ActivityIndicator size={'small'} color={Colors.primary}/> :
              <Switch
                onValueChange={this._onToggle}
                value={item.in_stock}
              />
          }
        </Right>
      </ListItem>
    )
  }

  _onToggle = (e) => {
    let {item, onToggle} = this.props;
    onToggle(item);
  }

  _itemPressed = () => {
    let {item, onPress} = this.props;
    onPress(item);
  }

   _getIcon = item_class => {
    switch(item_class){
      case 'drink': return <Button style={{ backgroundColor: '#00bfff'}}><Icon name='ios-beer'/></Button>;
      case 'food': return <Button style={{ backgroundColor: '#24cc00'}}><Icon name='ios-pizza'/></Button>;
      case 'mixer': return <Button style={{ backgroundColor: '#be5eff'}}><Image resizeMode='contain' source={MIXER}  style={{height: 16, width:16, tintColor: '#fff'}}/></Button>;
      case 'addon': return <Button style={{ backgroundColor: '#ffae00'}}><Image resizeMode='contain' source={ADDON} style={{height: 16, width:16, tintColor: '#fff'}}/></Button>;
    }
  }

  _itemDescription = item => {
    if(['food','drink'].includes(item.menu_item_class)){
      return (
        <View style={{flexDirection: 'row'}}>
          <Text style={styles.note}>{item.menu_heading.menu.menuName} -</Text>
          <FormattedCurrency style={styles.note} value={item.pretax_cents/100}/>
        </View>
      )
    } else {
      let amount = item.pretax_cents;
      return (
        <View style={{flexDirection:'row'}}>
          <Text style={styles.note}>{item.heading ? item.heading.heading_name : ''}</Text>
          { amount > 0 && <Text style={styles.note}>(+<FormattedCurrency value={amount/100}/>)</Text> }
        </View>
      )
    }
  }

}

export default MenuManagement;

const styles = EStyleSheet.create({
  header: {
    height: 80, // this is hacky, need a way to calculate proper height
    backgroundColor: Colors.dark,
    alignItems: 'center'
  },
  note: {
    fontSize: 13,
    color: Colors.darkGray
  },
  listHeader: {
    paddingRight: 70
  },
  headerLeft: {
    flex: 0,
    width: 150
  },
  headerBody: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: 20
  }
});
