import {Button, Icon} from "native-base";
import PropTypes from "prop-types";
import React, {useEffect, useRef} from "react";
import {Animated, Platform, Text, TouchableWithoutFeedback, View} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import {Buttons, Colors} from "../styles";

const FilterButtonToggle = ({active, leftLabel, onPress, rightLabel, color}) => {

  const animatedIconTranslateX = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(animatedIconTranslateX, {
      toValue: active ? 20 : 0, // toggleButton.width / 2 = 20
      duration: 150,
      useNativeDriver: Platform.OS !== 'web'
    }).start();
  }, [active]);

  return (
    <TouchableWithoutFeedback onPress={() => onPress(active)}>
      <View style={[Buttons.pillPrimary, color ? {backgroundColor: color} : null]}>
        <Text style={[Buttons.pillLabelPrimary, {color: active ? Colors.lightGray: 'white', marginRight: 10}]}>
          {leftLabel}
        </Text>
        <Button style={styles.toggleButton} transparent icon onPress={() => onPress(active)}>
          <Animated.View style={{left: 2, position: 'absolute', transform: [{translateX: animatedIconTranslateX}]}}>
            <Icon style={[styles.toggleIcon, color ? {color}: null]} type={'MaterialIcons'} name={'lens'}/>
          </Animated.View>
        </Button>
        <Text style={[Buttons.pillLabelPrimary, {color: active ? 'white' : Colors.lightGray, marginLeft: 10}]}>
          {rightLabel}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  );
}

FilterButtonToggle.propTypes = {
  leftLabel: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  rightLabel: PropTypes.string.isRequired,
};

const styles = EStyleSheet.create({
  toggleButton: {
    backgroundColor: 'white',
    borderWidth: 0,
    borderRadius: 10,
    height: 18,
    paddingBottom: 0,
    paddingRight: 20,
    paddingTop: 0,
    position: 'relative',
    width: 40,
  },
  toggleIcon: {
    backgroundColor: 'white',
    borderRadius: 10,
    color: Colors.primary,
    fontSize: 16,
    marginLeft: 0,
    marginRight: 0,
  }
});

export default FilterButtonToggle;
