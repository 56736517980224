import React, {Component} from 'react';
import moment from 'moment';
import {View, TouchableWithoutFeedback, ActivityIndicator, Modal} from 'react-native';
import {List, ListItem, Body, Button, Text} from 'native-base';
import EStyleSheet from 'react-native-extended-stylesheet';
import PropTypes from 'prop-types';
import Colors from "../../constants/Colors";
import API from "../../api";

export default class SnoozeOrderModal extends Component {

  static propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func
  }

  static defaultProps = {
    visible: false,
    onCancel: () => {},
    onSuccess: () => {}
  }

  state = {
    choice: null,
    processing: false
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.visible !== prevProps.visible) {
      if (this.props.visible) {
        let {order} = this.props;
        if (order.snooze_till) {
          this.setState({
            choice: order.snooze_till.diff(order.user_desired_time, 'minutes')
          })
        }
      } else {
        this.setState({choice: null});
      }
    }
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    let {visible, order} = this.props;
    if (!order) return null;

    const {station} = order;
    if(!station) return null;

    let {snooze_choices} = station;
    let udt = order.user_desired_time;
    // if user_desired_time is not today, show the date:
    let format = udt.isSame(moment(), 'day') ? 'h:mm A' : 'MMM Do, h:mm A';

    return (
      <Modal visible={visible} onRequestClose={this._hideModal} transparent={true}>
        <TouchableWithoutFeedback onPress={this._hideModal}>
          <View style={styles.background}>
            <TouchableWithoutFeedback>
              <View style={styles.modal}>
                <View style={styles.header}>
                  <Text style={styles.headerText}>Snooze Order for {order.user_desired_time.format(format)}</Text>
                </View>
                <View style={styles.content}>
                  <List>
                    <ListItem itemDivider><Text>From Now</Text></ListItem>
                    {
                      snooze_choices.filter(c => c > 0).map(choice => (
                        <SnoozeChoice key={choice} selected={choice == this.state.choice} order={order} choice={choice}
                                      onPress={this._selectChoice}/>
                      ))
                    }
                    <ListItem itemDivider><Text>From User Desired Time</Text></ListItem>
                    {
                      snooze_choices.filter(c => c < 0).sort().map(choice => (
                        <SnoozeChoice key={choice} selected={choice == this.state.choice} order={order} choice={choice}
                                      onPress={this._selectChoice}/>
                      ))
                    }
                  </List>
                </View>
                <View style={styles.footer}>
                  <Button danger onPress={this._hideModal}>
                    <Text style={{color: 'white'}}>CANCEL</Text>
                  </Button>
                  <View style={{width: 40}}/>
                  <Button
                    primary
                    iconRight={this.state.processing}
                    onPress={this._saveChoice}
                    disabled={!this.state.choice || this.state.processing}
                    style={{paddingHorizontal: 10}}>
                    <Text style={{color: 'white'}}>SET</Text>
                    {this.state.processing && <ActivityIndicator color={'white'} size={'small'}/>}
                  </Button>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    )
  }

  _selectChoice = (choice) => {

    this.setState({
      choice: choice
    })
  }

  _saveChoice = async () => {
    let {order, onSuccess} = this.props;
    this.setState({processing: true});
    await API.snoozeOrder(order, this.state.choice);
    this.setState({processing: false});
    this._hideModal();
    onSuccess(order);
  }

  _hideModal = () => {
    let {onCancel} = this.props;
    onCancel();
  }
}

const SnoozeChoice = (props) => {
  let {order, choice, onPress, selected} = props;
  let text = choice < 0 ?
    (Math.abs(choice) + " minutes till due") :
    (choice + " minutes from now");
  let choiceTime = choice < 0 ?
    moment(order.user_desired_time).add(choice, 'minutes') :
    moment().add(choice, 'minutes');

  let subText = (choice < 0 ? '' : '~') + choiceTime.format('h:mm A');

  let disabled = choiceTime > order.user_desired_time || choiceTime < moment();

  // todo return null if choicetime > userdesiredtime
  return (
    <ListItem onPress={() => {
      if (!disabled) onPress(choice)
    }} style={{backgroundColor: selected ? Colors.primaryLight : null}} noIndent>
      <Body>
        <Text style={{textDecorationLine: disabled ? 'line-through' : 'none'}}>{text}</Text>
      </Body>
      <Text numberOfLines={1} style={{fontSize: 12, color: Colors.darkGray}}>{subText}</Text>
    </ListItem>
  )
}

const styles = EStyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  modal: {
    backgroundColor: 'white',
    minWidth: 300
  },
  header: {
    backgroundColor: Colors.secondary,
    padding: 10
  },
  headerText: {
    color: Colors.light
  },
  content: {},
  footer: {
    padding: 5,
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});
