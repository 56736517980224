import _ from 'lodash';
import moment from 'moment';
import React from "react";
import {View, ScrollView} from "react-native";
import AsyncStorage from "@react-native-community/async-storage";
import DateTimePicker from "@react-native-community/datetimepicker";
import EStyleSheet from "react-native-extended-stylesheet";
import {
  ActionSheet,
  FilterButton,
  FilterButtonInput,
  FilterButtonSecondary,
  FilterButtonToggle,
  LocationFilterModal
} from "./index";
import API from "../api";
import {offsetInput, offsetOutput} from "../helpers/DateTimePickerHelper";
import Location from "../models/Location"
import KDSOrdersView from "../screens/KDSOrdersView";
import {Colors} from "../styles";
import DraggableScrollView from "./DraggableScrollView";

export default class KDSFilteredOrdersView extends React.Component {

  _defaultFilters = {
    start_time: moment.tz(moment(), API.main_customer.timezone).startOf('day').add(-7, 'days'),
    end_time: moment.tz(moment(), API.main_customer.timezone).startOf('day').add(7, 'days'),
    date_filter: 'created',
    fulfillment_method: '',
    locations: Location.AllLocationIDs(),
    order_status: '',
    order_status_text: 'All',
    search_input: '',
    vendor: '',
    vendor_text: 'All',
  }

  _kdsView = null;

  state = {
    ...this._defaultFilters,
    show_location_filter_modal: false,
    show_time_picker: '',
    vendors: Object.values(API._customers)
  }

  async componentDidMount() {
    this._mounted = true;
    await this._deriveStateFromAsyncStorage();
    API.on('locations', this._updateLocations);
    API.on('vendors', this._updateVendors);
  }

  componentWillUnmount() {
    this._mounted = false;
    API.off('locations', this._updateLocations);
    API.off('vendors', this._updateVendors);
  }

  render() {
    let {
      start_time,
      end_time,
      date_filter,
      fulfillment_method,
      locations,
      order_status_text,
      search_input,
      show_location_filter_modal,
      show_time_picker,
      vendor_text,
      vendors
    } = this.state;

    let fulfillmentPrettyName = API.menuData.fulfillment_pretty_names[fulfillment_method] || 'All';
    let locationsAreFiltered = locations.length !== Location.AllLocationIDs().length;
    let minDate = show_time_picker === 'end_time' ? start_time.toDate() : null;

    return (
      <View style={{flex: 1}}>
        <View style={styles.filtersContainer}>
          <DraggableScrollView horizontal keyboardShouldPersistTaps="always" contentContainerStyle={styles.filters} nativeID={'filters'}>
            <View style={{alignItems: 'center', flexDirection: 'row'}}>
              <View style={styles.datetimeFilters}>
                <FilterButtonToggle active={date_filter !== 'created'} leftLabel={"Created"} rightLabel={"Desired"} onPress={this._handleDateToggle}/>
                <FilterButton label={"Start Time"} value={start_time.format('MMM D, LT')} onPress={this._showStartTimePicker}/>
                <FilterButton label={"End Time"} value={end_time.format('MMM D, LT')} onPress={this._showEndTimePicker}/>
              </View>
              <View style={{flexDirection: 'row', paddingVertical: 5}}>
                <FilterButton label={"Locations"} value={locationsAreFiltered ? 'Filtered' : 'All'} onPress={this._showLocationFilterModal}/>
                {vendors.length > 1 && <FilterButton label={"Vendor"} value={vendor_text || 'All'} onPress={this._setVendor}/>}
                <FilterButton label={"Fulfillment Method"} value={fulfillmentPrettyName} onPress={this._setFulfillmentMethod}/>
                <FilterButton label={"Order Status"} value={order_status_text || 'All'} onPress={this._setOrderStatus}/>
                <FilterButtonInput label={"Search"} value={search_input} onChange={this._setSearchInput} />
              </View>
            </View>
            <View style={{flex: 1, justifyContent: 'flex-end', flexDirection: 'row', paddingVertical: 5}}>
              <FilterButtonSecondary label={"RESET"} onPress={this._resetFilters}/>
            </View>
          </DraggableScrollView>
        </View>
        <KDSOrdersView
          ref={me => this._kdsView = me}
          filterFn={ this._filterFn }
          sortField={"last_modified"}
          sortDir={'desc'}
          emptyMsg={'No Results'}
        />
        {!!show_time_picker && (
          <DateTimePicker
            onChange={this._handleDateChange}
            minimumDate={minDate}
            value={offsetInput(this.state[show_time_picker])}
            is24Hour={true}
            mode={'datetime'}
          />
        )}
        {show_location_filter_modal && (
          <LocationFilterModal
            headerTitle={'Filter by Locations'}
            locations={locations}
            onCancel={() => this.setState({show_location_filter_modal: false})}
            onSave={this._filterLocations}
          />
        )}
      </View>
    )
  }

  _filterFn = (order) => {
    let {date_filter, end_time, fulfillment_method, locations, order_status, search_input, start_time, vendor} = this.state;
    return (date_filter === 'created' ? order.time.isBetween(start_time, end_time, undefined, '[]') : true)
      && (date_filter === 'desired' ? (order.user_desired_time || order.time).isBetween(start_time, end_time, undefined, '[]') : true)
      && locations.includes(order.location_id)
      && (vendor ? order.customer_id === vendor : true)
      && (fulfillment_method ? order.fulfillment_method === fulfillment_method : true)
      && (order_status ? order[order_status] : true)
      && (search_input ? this._orderContainsSearchInput(order, search_input) : true)
  }

  _resetFilters = () => {
    this.setState(this._defaultFilters, () => {
      this._refresh();
      this._saveStateToAsyncStorage();
    })
  }

  _refresh = () => {
    if (this._kdsView && this._mounted) this._kdsView.refresh(true);
  }

  _saveStateToAsyncStorage = async () => {
    let {show_location_filter_modal, show_time_picker, vendors, ...rest} = this.state;
    let filters = JSON.stringify(rest);
    await AsyncStorage.setItem('kds_filters', filters).catch(error => console.log('Error saving to AsyncStorage: ', error));
  }

  _deriveStateFromAsyncStorage = async () => {
    await AsyncStorage.getItem('kds_filters')
      .then(filters => {
        if (!filters) return;

        filters = JSON.parse(filters);
        filters['start_time'] = moment(filters['start_time']);
        filters['end_time'] = moment(filters['end_time']);

        this.setState(filters, this._refresh);
      })
      .catch(error => console.log(error));
  }

  _handleDateToggle = (isLeftToggleActive) => {
    this.setState({
      date_filter: isLeftToggleActive ? 'created': 'desired'
    }, () => {
      this._refresh();
      this._saveStateToAsyncStorage();
    });
  }

  _handleDateChange = (event, date) => {
    let newState = { show_time_picker: false};
    if(date){
      newState[this.state.show_time_picker] = moment(offsetOutput(date));
    }
    if(this._mounted) {
      this.setState(
        newState,
        () => {
          this._refresh();
          this._saveStateToAsyncStorage();
        }
      );
    }
  }

  _showStartTimePicker = () => {
    this.setState({
      show_time_picker: 'start_time'
    })
  }

  _showEndTimePicker = () => {
    this.setState({
      show_time_picker: 'end_time'
    })
  }

  _showLocationFilterModal = () => {
    this.setState({
      show_location_filter_modal: true
    })
  }

  _filterLocations = (locations) => {
    this.setState({
      locations,
      show_location_filter_modal: false
    }, () => {
      this._refresh();
      this._saveStateToAsyncStorage();
    });
  }

  _updateLocations = () => {
    this.setState({
      locations: Location.AllLocationIDs(),
    })
  }

  _updateVendors = () => {
    this.setState({
      vendors: Object.values(API._customers),
    })
  }

  _setVendor = () => {
    let opts = _.orderBy(_.map(this.state.vendors, (vendor) => ({text: vendor.customer_name, value: vendor.customer_id})), 'text');
    opts.unshift({text: 'All', value: ''});

    ActionSheet.show({
      options: opts,
      title: 'Vendor'
    }, (id) => {
      let pressed = opts[id];
      this.setState({
        vendor: pressed.value,
        vendor_text: pressed.text
      }, () => {
        this._refresh();
        this._saveStateToAsyncStorage();
      });
    });
  }

  _setFulfillmentMethod = () => {
    let methods = _.uniq([].concat(..._.map(API.config.kds_stations, 'possible_fulfillment_methods')));
    let opts = _.orderBy(_.map(methods, (key) => ({text: API.menuData.fulfillment_pretty_names[key], value: key})), 'text');
    opts.unshift({text: 'All', value: ''});

    ActionSheet.show({
      options: opts,
      title: 'Fulfillment Method'
    }, (id) => {
      let pressed = opts[id];
      this.setState({
        fulfillment_method: pressed.value
      }, () => {
        this._refresh();
        this._saveStateToAsyncStorage();
      });
    });
  }

  _setOrderStatus = () => {
    let opts = [
      { text: 'All', value: ''},
      { text: 'Open', value: 'kds_open'},
      { text: 'Closed', value: 'kds_closed'},
      { text: 'Future', value: 'kds_future'},
    ];

    ActionSheet.show({
      options: opts,
      title: 'Order Status'
    }, (id) => {
      let pressed = opts[id];
      this.setState({
        order_status: pressed.value,
        order_status_text: pressed.text
      }, () => {
        this._refresh();
        this._saveStateToAsyncStorage();
      });
    });
  }

  _debouncedRefresh = _.debounce(() => {
    this._refresh();
    this._saveStateToAsyncStorage();
  }, 250);

  _setSearchInput = (value) => {
    this.setState({
      search_input: value
    }, this._debouncedRefresh);
  }

  _orderContainsSearchInput(order, input) {
    let {extra_checkout_info, items, orderNumber} = order;
    let extraCheckoutInfo = Object.values(extra_checkout_info);
    input = input.toLowerCase();

    if (String(orderNumber).toLowerCase().includes(input)) return true;

    let extraCheckoutInfoContainsSearchInput = extraCheckoutInfo && extraCheckoutInfo.some(info => {
      let {key, name_for_bartender, value} = info;
      return name_for_bartender?.toLowerCase().includes(input)
        || value?.toString().toLowerCase().includes(input)
        || (key === 'address' && this._addressContainsSearchInput(value, input));
    });
    if (extraCheckoutInfoContainsSearchInput) return true;

    let itemsContainSearchInput = items && items.some(item => {
      let {itemName, mods} = item;
      return (itemName.toLowerCase().includes(input) || this._itemModsContainSearchInput(mods, input));
    });
    if (itemsContainSearchInput) return true;

    return false;
  }

  _addressContainsSearchInput(address, input) {
    let {delivery_instructions, formatted_address} = address;
    return (delivery_instructions?.toLowerCase().includes(input) || formatted_address?.toLowerCase().includes(input));
  }

  _itemModsContainSearchInput(mods, input) {
    return mods && mods.some(mod => {
      let {mods, name} = mod;
      return (name.toLowerCase().includes(input) || this._itemModsContainSearchInput(mods, input));
    });
  }
}

const styles = EStyleSheet.create({
  datetimeFilters: {
    backgroundColor: '#f2f2f2',
    borderColor: Colors.gray,
    borderRadius: 5,
    borderStyle: 'dotted',
    borderWidth: 1,
    flexDirection: 'row',
    paddingVertical: 5
  },
  filters: {
    alignItems: 'center',
    flexGrow: 1,
    paddingVertical: 5
  },
  filtersContainer: {
    backgroundColor: 'white',
    borderBottomColor: '#ccc',
    borderBottomWidth: 1,
    paddingHorizontal: 5,
  },
});
