import {createStackNavigator} from 'react-navigation-stack';
import OrderSearchScreen from '../screens/OrderSearchScreen';
import {defaultNavigationOptions} from './Defaults';
import TabBarIcon from "../components/TabBarIcon";
import React from "react";


const ordersStack = createStackNavigator(
  {
    OrderSearchScreen: OrderSearchScreen
  },
  {
    navigationOptions: ({navigation}) => {
      return {
        tabBarLabel: 'Search Orders',
        tabBarTestID: 'ordersearch',
        tabBarIcon: (props) => (
          <TabBarIcon
            {...props}
            name={'md-search'}/>
        )
      }
    },
    defaultNavigationOptions: defaultNavigationOptions
  }
);


export default ordersStack;
