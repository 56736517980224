import React, {Component} from 'react';
import {View, Text, Image, TouchableWithoutFeedback, BackHandler} from 'react-native';
import {styles as gStyles} from '../styles/Global';
import Colors from '../constants/Colors';
import {TextM, TextS} from "../components/Text";
import API from "../api";


export default class ThankYouScreen extends Component {

  static navigationOptions = {
    title: "Thank You",
    headerLeft: () => null
  }


  componentDidMount() {
    this.backHandler = BackHandler.addEventListener("hardwareBackPress", this._onTap );
  }

  componentWillUnmount() {
    this.backHandler.remove();
  }

  render(){
    let imgSource = API.config.post_checkout_image ?
      {uri: API.config.post_checkout_image} : require("../assets/images/thank-you.png");

    return (
      <TouchableWithoutFeedback style={{flex: 1}}
                                accessible={true}
                                accessibilityLabel={'Thank You'}
                                onPress={ this._onTap }>
        <View style={{flex: 1}}>
          <View style={[gStyles.centered,{flex: 1}]}>
            <Image style={{width:"80%", height: 300}} source={imgSource} resizeMode={"contain"}/>
            <TextM style={{marginTop: 50}}>Please pass the device back to your server</TextM>
          </View>
          <View style={[gStyles.centered, {margin: 10}]}>
            <TextS style={{color: Colors.gray}}>(Touch anywhere to continue)</TextS>
          </View>
        </View>
      </TouchableWithoutFeedback>
    )
  }

  _onTap = () => {

    let {navigation} = this.props;

    navigation.getParam('refreshFn')();
    navigation.goBack(navigation.getParam('go_back_key'));

    return true;
  }
}
