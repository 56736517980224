import React from 'react';
import {TouchableWithoutFeedback} from "react-native";
import {Icon} from "native-base";
import Alert from "../components/Alert";

/**
 * Provides a help icon which when clicked can perform an action or provide a help message.
 *
 * @param onPress (optional) if provided, this is the function which is called when a user presses the help icon
 * @param title (optional - required if onPress is null). The title of the alert
 * @param message (optional - required if onPress is null). The help message.
 * @param color
 * @param style
 * @returns {*}
 */

export default ({onPress, title, message, color = 'white', style = {}}) => {
  if(!onPress)
    onPress = () => {
      Alert.alert(title, message);
    }
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <Icon type={"MaterialIcons"} color={color} name={"help"} style={[{color}, style]}/>
    </TouchableWithoutFeedback>
  )
}
