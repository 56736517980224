import React from 'react';
import _ from 'lodash';
import {View, Text, ScrollView, FlatList} from 'react-native';
import Alert from '../components/Alert';
import PropTypes from 'prop-types';
import OrderListView from "../components/OrderListView";
import Station from "../models/Station";
import OrderItemStatusChanger from "../components/OrderItemStatusChanger";
import Loader from "../components/Loader";
import API from "../api";
import Colors from "../constants/Colors";
import OrderListViewHeader from "../components/OrderListViewHeader";
import {CommandBar, CommandBarCenter, CommandBarRight} from "../components";
import CmdButton from "../components/CmdButton";
import IconButton from "../components/IconButton";
import {Footer, FooterTab} from "native-base";
import ClaimButton from "../components/IconButtons/ClaimButton";


// TODO: This view doesn't update once opened

class StationView extends React.Component {

  static navigationOptions = ({navigation}) => {
    let station = navigation.getParam('station');
    return {
      title: station.station_name
    }
  }

  constructor(props) {
    super(props);
    let station = props.navigation.getParam('station');
    let filters = props.navigation.getParam('filters');
    this.state = {
      station: station,
      filters: filters,
      orders: station.getFilteredOrders(filters.showClosed, filters.showSnoozed),
      selectedItems: [],
      loading: false,
      dataChanged: false
    };
  }

  componentDidMount() {
    this._mounted = true;
    API.on('orders', this._update);
  }

  componentWillUnmount() {
    this._mounted = false;
    API.off('orders', this._update);
  }

  render() {
    let {orders, selectedItems, dataChanged} = this.state;

    return (
      <View style={{flex: 1}}>
        <Loader shown={this.state.loading}/>
        <OrderListViewHeader/>
        <FlatList
          data={orders}
          renderItem={this._renderItem}
          style={{flexBasis: 0}}
          extraData={this.state.dataChanged}
          keyExtractor={item => item.orderId}
          ListEmptyComponent={this._listEmpty}
        />
        <OrderItemStatusChanger
          selectedItems={this.state.selectedItems}
          onChange={this.refresh}
          allowRegress={true}
        />
        <Footer style={{backgroundColor: 'black'}}>
          <FooterTab style={{backgroundColor: 'black'}}>
            <ClaimButton
              selectedItems={selectedItems}
              onPress={()=>this.setState({selectedItems: [...selectedItems]})}
            />
          </FooterTab>
        </Footer>
      </View>
    );
  }

  _getStationOrders = () => {
    let {station, filters} = this.state;
    return station.getFilteredOrders(filters.showClosed, filters.showSnoozed)
  }

  _update = async () => {
    if (this._mounted) {
      this.setState({
        dataChanged: !this.state.dataChanged,
        orders: this._getStationOrders()
      });
    }
  }

  _renderItem = (data) => {
    let order = data.item;
    return (
      <OrderListView
        order={order}
        onHeaderPress={this._setSelectedItems}
        onItemPress={this._setSelectedItems}
        selectedItems={this.state.selectedItems}
      />
    )
  }

  _listEmpty = (
    <View style={{height: 50, alignItems: 'center', justifyContent: 'center'}}>
      <Text style={{color: Colors.gray}}>No Orders</Text>
    </View>
  )

  refresh = async () => {
    let {filters, station} = this.state;

    // todo: this could probably use the 'dataChanged' flag instead of the setState callback
    this.setState({
      selectedItems: [],
      loading: true,
      orders: []
    }, () => {
      this.setState({
        loading: false,
        orders: station.getFilteredOrders(filters.showClosed, filters.showSnoozed)
      });
    });
  }

  _setSelectedItems = (obj, selectedItems) => {
    this.setState({
      selectedItems: [...selectedItems],
      dataChanged: !this.state.dataChanged
    });
  }

  static propTypes = {
    station: PropTypes.instanceOf(Station)
  }
}


export default StationView;
