import React from "react";
import {View} from "react-native";

import Card from "./Card";
import {CardView, TabView} from "../../components";


/**
 * The <PaymentCard> contains the payment method currently being used in checkout. Most commonly, this would contain
 * the credit card preview, but could display other forms of payment.
 *
 * @param props
 * @param {SavedCard | PartyTab} props.card The credit card or party tab for the payment method
 * @param props.location
 * @param {function(string)} props.onSubmitPromoCode The callback function that will remove the old promocode and enter a new one
 * @constructor
 */
const PaymentInfoCard = ({
                           card,
                           location,
                           ...rest
                         }) => {

  if (!card) return null;

  return !!card ? (
    <Card testID={'tipScreenPaymentCard'} title={'Payment Method'} {...rest}>
      {/* Cards are optional on free purchases, so we need to check if `card` is given */}
      <View style={{marginTop: 20, alignSelf: 'stretch'}}>
        {card.type === 'tab' ? (
          <TabView tab={card} hideRadio/>
        ) : (
          <CardView card={card} hideRadio/>
        )}
      </View>
    </Card>
  ) : null;
}

export default PaymentInfoCard;
