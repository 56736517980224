import _ from 'lodash';
import React, {useState} from 'react';
import Layout from '../constants/Layout';
import PropTypes from 'prop-types';
import API from "../api";

// Models
import CartItem from '../models/CartItem';

// Components
import {ScrollView, View, findNodeHandle, TextInput, TouchableWithoutFeedback, Dimensions} from 'react-native';
import {Button, Text, Spinner, Footer, FooterTab} from 'native-base';
import {CommandBar, CommandBarCenter, CommandBarLeft, CommandBarRight} from "../components/CommandBar";
import Breadcrumb from '../components/Breadcrumb';
import IndicatorScrollView from '../components/IndicatorScrollView';
import ItemButton from '../components/ItemButton';
import CmdButton from '../components/CmdButton';
import {Icon} from 'native-base';
import Alert from '../components/Alert';
import {prompt} from '../components/Prompt';

// Styles
import EStyleSheet from 'react-native-extended-stylesheet';
import {Typography} from "../styles"
import Colors from '../constants/Colors';
import IconButton from "../components/IconButton";
import KeypadModal from "../components/KeypadModal";


export default class ItemBuilder extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      item: null,
      menuData: API.getMenu(), // Todo: Don't get this from State! (gilles bad)
      breadcrumbs: [],
      search: '',
      path: [],
      errors: [],
      current: null,
      parent: null,
      modifierLevel: 0,
      numColumns: 4,
      showQuantityEdit: false
    }

    this.breadcrumbContainer = null;
  }

  componentDidMount() {
    this._mounted = true;
    API.on('menu', this._updateMenu);

    this.setState({
      breadcrumbs: this._getDefaultBreadCrumbs(),
    });

    this._handleLayoutChange({
      nativeEvent: {
        layout: {
          width: Dimensions.get('window').width,
        }
      }
    })
  }

  componentWillUnmount() {
    this._mounted = false;
    this.breadcrumbContainer = null;
    API.off('menu', this._updateMenu);
  }

  _updateMenu = () => {
    if (this._mounted) {
      this.setState({
        menuData: API.getMenu()
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.current !== this.state.current) {
      this.props.onModifiersChange(this.state.current);
    }
  }

  render() {
    this._focusedModifier = null;
    let depth = this.state.breadcrumbs.length;
    // If there's only one menu, pre-select it
    let breadcrumbFlex = depth / 4;
    let searchFlex = (4 - depth) / 4;
    let searchString = depth === 0 ? 'all menus' : (depth === 3 ? 'modifiers' : this.state.breadcrumbs[depth - 1].label);

    return (
      <View testID={'itemBuilder'} style={this.props.style}>

        { /* ====== BreadCrumbs Section ========== */}
        <View
          style={{flexDirection: 'row', height: 50, width: "100%", borderBottomColor: 'black', borderBottomWidth: 1}}>
          <MenuHome onPress={() => {
            this._breadcrumbClicked(-1);
            this.setState({search: ''})
          }}/>
          <View style={{flexDirection: 'row', flex: breadcrumbFlex, marginTop: 5}}>
            {
              this.state.breadcrumbs.map((crumb, i) => {
                const homeBtnWidth = 36;
                const margin = 5;
                const width = (Layout.window.width - homeBtnWidth - margin) / 3;
                return (
                  <Breadcrumb testID={'itemBuilderBreadcrumb-' + crumb.label} key={'bc_' + i} position={i}
                              text={crumb.label} onPress={this._breadcrumbClicked}/>
                )
              })
            }
          </View>
          <MenuSearch
            value={this.state.search}
            onKeyPress={val => this.setState({search: val})}
            style={{flex: searchFlex}}
            searchString={searchString}
          />
        </View>

        {/* ====== Display Items ========== */}
        <View style={{flex: 1}} onLayout={this._onLayout}>
          <ScrollView ref={sv => this._itemBuilderSV = sv} contentContainerStyle={{justifyContent: 'center'}}>
            {this._getDisplayItems()}
          </ScrollView>
        </View>

        {/*=========== Quantity / Item Commands */}
        <Footer style={{backgroundColor: Colors.dark}}>
          <FooterTab style={{flexDirection: 'row', alignItems: 'center', backgroundColor: Colors.dark, maxWidth: 200}}>
            <IconButton
              testID={'itemBuilderDecrementButton'}
              icon={"minus"}
              iconType={"Entypo"}
              onPress={() => this._changeQty(-1)}
              disabled={!this.state.item}
            />
            <TouchableWithoutFeedback
              onPress={() => this.setState({showQuantityEdit: true})}
              disabled={!this.state.item}
            >
              <View style={{alignItems: 'center'}}>
                <Text
                  testID={'itemBuilderItemQuantity'}
                  style={{
                    color: this.state.item ? 'white' : 'grey',
                    fontSize: 24
                  }}>
                  {this.state.item && this.state.item.qty || 0}
                </Text>
                <Text style={{color: this.state.item ? 'white' : 'grey', fontSize: 12}}>QTY</Text>
              </View>
            </TouchableWithoutFeedback>
            <IconButton
              icon={"plus"}
              testID={'itemBuilderIncrementButton'}
              iconType={"Entypo"}
              onPress={() => this._changeQty(1)}
              disabled={!this.state.item}
            />
          </FooterTab>
          <FooterTab style={{backgroundColor: Colors.dark}}>
            <IconButton
              label={"Requests"}
              testID={'itemBuilderSpecialRequestButton'}
              icon={"bubble"}
              iconType={"SimpleLineIcons"}
              onPress={this._specialRequest}
              disabled={!this.state.item}
            />
            <IconButton
              label={"Done"}
              testID={'itemBuilderDoneButton'}
              icon={'done'}
              iconType={"MaterialIcons"}
              disabled={!this.state.item}
              highlight={!this.state.errors.length}
              onPress={this._doneEditing}
            />
          </FooterTab>
        </Footer>

        {/*========= Guests Chooser Modal ===========*/}
        {this.state.showQuantityEdit && (
          <KeypadModal
            visible={true}
            testID={'quantityDialog'}
            title="Edit Quantity"
            minValue={1}
            maxValue={999}
            value={this.state.item.qty}
            onClose={(val) => {
              this.state.item.qty = val;
              this.setState({
                item: this.state.item,
                showQuantityEdit: false
              })
            }}
            onCancel={() => {
              this.setState({showQuantityEdit: false});
            }}
          />
        )}

      </View>

    )
  }

  _onLayout = ({nativeEvent}) => {
    this._displaySectionHeight = nativeEvent.layout.height;
    this.forceUpdate()
  }

  _cartItemFromMenuItem = (item) => {
    let {cart, onItemChange} = this.props;
    let menuId = item.menus[0];
    let menu = this.state.menuData.menus.find(m => m.menuId === menuId);
    let heading = item.menu_heading;

    let cartItem = new CartItem(cart, {
      menuId: menu.menuId,
      menu_heading_id: heading.id,
      customer_id: menu.customer.customer_id,
      menuItemId: item.menuItemId,
      name: item.name,
      pretax_cents: item.pretax_cents,
      tax_cents: item.tax_cents,
      tax_fraction: item.tax_fraction,
    });

    this.selectItem(cartItem);

  }

  selectItem = (item) => {
    if (!this.state.menuData) return;
    let {onItemChange} = this.props;
    let menuId = item.menuId || item.menus[0];
    let menu = this.state.menuData.menus.find(m => m.menuId === menuId);
    let heading = menu.headings.find(h => h.id === item.menu_heading_id);
    let menuItem = this.state.menuData.menuItemsById[item.menuItemId];

    this.state.breadcrumbs = [
      {obj: menu, label: menu.menuName},
      {obj: heading, label: heading.heading_name},
      {obj: menuItem, label: menuItem.name}
    ];

    this.setState({
      search: '',
      item: item,
      current: item,
      breadcrumbs: this.state.breadcrumbs,
      errors: item.hasModErrors([]),
    }, () => {
      this._applyDefaultModifiers(item.menuItem);
      onItemChange(item);
    });

  }

  _showSearchResults = () => {
    let {breadcrumbs, search} = this.state;
    let {location} = this.props;
    let depth = breadcrumbs.length;
    let items = depth ?
      breadcrumbs[depth - 1].obj.items :
      location.menus.reduce((acc, menu) => acc.concat(menu.items), []);

    let query = new RegExp(search.trim(), 'i');

    items = _.uniqBy(_.filter(items, item => {
      return item.name_for_bartender.search(query) >= 0
    }), 'menuItemId');

    if (!items.length) return (
      <View style={styles.noModifiers}>
        <Text style={styles.noModifiersText}>No matches for "{search}"</Text>
        <TouchableWithoutFeedback onPress={() => this.setState({search: ''})}>
          <Text style={{color: Colors.primary, fontSize: 16, fontWeight: 'bold', marginTop: 10}}>Clear Search</Text>
        </TouchableWithoutFeedback>
      </View>
    )

    return (
      <View>
        <View
          style={{justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.primaryLight, padding: 5}}>
          <Text>Showing search results for: {search}</Text>
        </View>
        <View style={styles.buttonGroup}>
          {
            items.map(item => (
              <ItemButton
                testID={'itemBuilderItemButton-' + item.name_for_bartender}
                disabled={!location.fulfillable_items.includes(item.menuItemId)}
                disabledMsg={"Item currently unfulfillable"}
                key={item.menuItemId}
                text={item.name_for_bartender}
                item={item}
                onPress={this._cartItemFromMenuItem}
              />
            ))
          }
        </View>
      </View>
    )
  }

  _getDisplayItems() {
    let {location} = this.props;
    // todo: use _displaySectionHeight to figure out ItemButton height (_displaySectionHeight/7)?
    if (!this.state.menuData || !this.state.menuData.menus) {
      return (
        <View style={{alignItems: 'center', justifyContent: 'center', flex: 1}}>
          <Text style={{margin: 15}}>There was an error loading the Menu</Text>
          <Button transparent onPress={this._refreshMenus} disabled={this.state.refreshingMenus}
                  style={{alignSelf: 'center'}} iconRight={this.state.refreshingMenus}>
            <Text>Refresh</Text>
            {this.state.refreshingMenus ? <Spinner size={'small'}/> : null}
          </Button>
        </View>
      );
    } else {
      let level = this.state.breadcrumbs.length;
      if (this.state.search && level < 3) return this._showSearchResults();
      switch (level) {
        // Menus
        case 0:
          let available_menus = location.menus;

          return (
            <View style={styles.buttonGroup}>
              {
                available_menus.map((menu, index) => {
                  return (
                    <ItemButton
                      testID={'itemBuilderMenuButton-' + menu.name} key={'menubtn' + index} text={menu.name}
                      onPress={() => {
                        this._addBreadcrumb(menu, menu.name);
                      }}/>
                  );
                })
              }
              {
                !available_menus.length && (
                  <View style={{alignItems: 'center', justifyContent: 'center', flex: 1}}>
                    <Text style={{margin: 15}}>No menus available</Text>
                    <Button transparent onPress={this._refreshMenus} disabled={this.state.refreshingMenus}
                            style={{alignSelf: 'center'}} iconRight={this.state.refreshingMenus}>
                      <Text>Refresh</Text>
                      {this.state.refreshingMenus ? <Spinner size={"small"}/> : null}
                    </Button>
                  </View>
                )
              }
            </View>
          );
        // Primary Types
        case 1:
          let menu = this.state.breadcrumbs[0].obj;
          return (
            <View style={styles.buttonGroup}>
              {
                menu.headings.map(heading => {
                  let f_items = _.intersection(location.fulfillable_items, heading.items.map(i => i.menuItemId));

                  return (
                    <ItemButton
                      testID={'itemBuilderHeadingButton-' + heading.heading_name}
                      key={heading.id}
                      text={heading.heading_name}
                      disabled={!f_items.length}
                      disabledMsg={"Heading contains no fulfillable items"}
                      onPress={() => {
                        this._addBreadcrumb(heading, heading.heading_name)
                      }}/>
                  )
                })
              }
            </View>
          );
        // Items
        case 2:
          let heading = this.state.breadcrumbs[1].obj;
          return (
            <View style={styles.buttonGroup}>
              {
                heading.items.map((item, index) => {
                  let fulfillable = location.fulfillable_items.includes(item.menuItemId);
                  return (
                    <ItemButton
                      testID={'itemBuilderItemButton-' + item.name_for_bartender}
                      key={'itemBtn_' + index}
                      item={item}
                      disabled={!fulfillable}
                      disabledMsg={"Item currently unfulfillable"}
                      text={item.name}
                      onPress={this._itemButtonPressed}
                    />
                  )
                })
              }
            </View>
          );
        // CartItemModifiers
        case 3:
          let item = this.state.breadcrumbs[2].obj;
          let {current, search} = this.state;
          let modifierGroups = current?.menuItem.modifier_groups;

          let allModifiersFiltered = modifierGroups ? modifierGroups.map((group) => Object.values(group.modifiers)).flat() : [];

          if (search && allModifiersFiltered.length > 0) {
            let query = new RegExp(search.trim(), 'i');
            allModifiersFiltered = _.filter(allModifiersFiltered, modifier => modifier.name_for_bartender.search(query) >= 0);
          }
          let emptyString = search ? 'No matches for "' + search + '"' : "No options available";

          return (
            <View>
              <View style={{flex: 1}}>
                {this.renderPath()}
                {
                  modifierGroups.map((group) => this.renderModifierGroup(group))
                }
                {
                  (allModifiersFiltered.length === 0 && search.length > 0) &&
                  <View key={'no_options'} style={styles.search__noResults}>
                    <Text style={[Typography.header3]}>{emptyString}</Text>
                    {search !== '' && (
                      <TouchableWithoutFeedback onPress={() => {
                        this.setState({
                          search: '',
                        }, () => {
                          this.props.onModifiersChange(current)
                        })
                      }}>
                        <View>
                          <Text style={{color: Colors.primary, fontSize: 16, fontWeight: 'bold', marginTop: 10}}>Clear
                            Search</Text>
                        </View>
                      </TouchableWithoutFeedback>
                    )}
                  </View>
                }
              </View>
              {
                (current.menuItemId === item.menuItemId) && this._guestChooser()
              }
            </View>
          );
      }
    }
  }

  _guestChooser() {
    if (this.props.numGuests <= 1) return null;

    let buttons = _.times(this.props.numGuests, (n) => {
      return (
        <ItemButton testID={`itemBuilderGuestButton-${n + 1}`}
                    key={'guestBtn_' + n}
                    columns={4}
                    selected={this.state.item && this.state.item.seat_numbers.indexOf(n + 1) >= 0}
                    onPress={() => {
                      this._onGuestBtnPress(n + 1);
                    }}>
          <Text ellipsizeMode='tail' style={{textAlign: 'center'}}>{n + 1}</Text>
        </ItemButton>
      )
    });
    buttons.push(
      <ItemButton testID={'itemBuilderAllGuestsButton'}
                  key={'allGuestsBtn'}
                  columns={4}
                  selected={this.state.item && this.state.item.seat_numbers.length === this.props.numGuests}
                  onPress={this._onAllGuestsBtnPress}>
        <Text>All</Text>
      </ItemButton>
    );

    return (
      <View testID={'itemBuilderGuestSelectionView'}>
        <View key="guestSelection" style={styles.modifierGroupHeader}>
          <View style={styles.modifierGroupHeader}>
            <Text style={{
              fontWeight: "bold",
              color: "#000"
            }}>Seat #</Text>
            {this.state.item?.errors.includes('seats') ?
              <Text style={[styles.requiredBadge, {color: Colors.error, borderColor: Colors.error}]}>Required</Text>
              : <Icon type={"AntDesign"} name={"checkcircle"}
                      style={{color: Colors.success, fontSize: 16, marginLeft: 15}}/>
            }
          </View>
        </View>
        <IndicatorScrollView columns={4}>
          {buttons}
        </IndicatorScrollView>
      </View>
    )
  }

  _onGuestBtnPress = (number) => {
    let {item} = this.state;

    if (item.seat_numbers.indexOf(number) >= 0) {
      item.seat_numbers = _.without(item.seat_numbers, number);
    } else {
      item.seat_numbers.push(number);
      item.seat_numbers.sort();
    }
    let errors = this._validateItem(item)
    this.setState({
      item: item,
      errors: errors,
    });
    this.props.onItemChange(item);
  };

  _onAllGuestsBtnPress = () => {
    let {item} = this.state;
    let {numGuests} = this.props;

    if (item.seat_numbers.length === numGuests) {
      item.seat_numbers = [];
    } else {
      item.seat_numbers = _.times(numGuests, i => i + 1);
    }
    let errors = this._validateItem(item)
    this.setState({
      item: item,
      errors: errors,
    });
    this.props.onItemChange(item);
  };

  _itemButtonPressed = item => {
    let menu = this.state.breadcrumbs[0].obj;
    let menuHeading = this.state.breadcrumbs[1].obj;

    let cartItem = new CartItem(this.props.cart, {
      menuId: menu.menuId,
      menu_heading_id: menuHeading.id,
      customer_id: menu.customer.customer_id,
      menuItemId: item.menuItemId,
      name: item.name,
      pretax_cents: item.pretax_cents,
      tax_cents: item.tax_cents,
      tax_fraction: item.tax_fraction,
    });

    this.setState(
      {
        item: cartItem,
        current: cartItem,
      }
      , () => {
        this.setState({
          errors: cartItem.hasModErrors([])
        })
        this._applyDefaultModifiers(item);
      });


    this._addBreadcrumb(item, item.name);

    // notify parent:
    this.props.onItemChange(cartItem);
  }

  _applyDefaultModifiers = (item) => {

    item.modifierGroups.forEach(group => {
      let modifierGroup = this.state.menuData.modifierGroupsById[group.modifierGroupId];

      group.modifierIds.forEach(modifierId => {
        let modifier = modifierGroup.modifiers[modifierId];
        if (modifier.pre_selected) this._toggleModifier(modifier, false);
      })

      //TODO: apply recursively for all modifiers of the modifier
    })
  }

  _changeQty = (by) => {
    if (!this.state.item) return;
    if (this.state.item.qty === 1 && by < 1) return;
    this.state.item.qty += by;

    this.setState({
      item: this.state.item
    });

    this.props.onItemChange(this.state.item);
  }

  _addBreadcrumb = (obj, label, props) => {
    this.setState({
      breadcrumbs: [...this.state.breadcrumbs, {obj, label, props}]
    });
  }

  _breadcrumbClicked = (i) => {
    this.state.breadcrumbs.length = i + 1;
    this.setState({
      breadcrumbs: this.state.breadcrumbs,
      item: i < 2 ? null : this.state.item
    })
  }

  _getDefaultBreadCrumbs(menuData) {
    menuData = menuData || this.state.menuData;
    if (!menuData || !menuData.menus) return [];
    if (menuData && menuData.menus.length === 1) return [{obj: menuData.menus[0], label: menuData.menus[0].menuName}];
    return [];
  }

  // Dont use setState inside _validateItem because validateItem is used on every render
  // to highlight the Done button if the item is valid
  _validateItem(itemOrMod) {
    let errors = itemOrMod?.hasModErrors([]) || [];

    return errors;
  }

  /**
   * Called when we are Done editing an item
   * @private
   */
  _doneEditing = () => {
    let {item, current} = this.state;
    let errors = this._validateItem(item);

    if (!errors.length) {
      this.setState({
        item: null,
        current: null,
        errors: [],
        breadcrumbs: this._getDefaultBreadCrumbs(),
      }, () => {
        this.props.onModifiersChange(null);
      });

      this.props.onDone();
    } else {
      let message = errors.map((cartMod, index) => {
        let text = cartMod === item && cartMod.errors?.includes("seats") ? cartMod.getName() + "∙ Seat #" : cartMod.getName();
        return (index !== 0 ? "∙ " : "") + text;
      }).join("\r\n");

      Alert.alert("The following selections require customization:", message);

      if (errors[0] === item) {
        this.setState({
          errors: errors,
          current: item,
        }, () => {
          this.props.onModifiersChange(current);
        })
      } else {
        this.setState({
          errors: errors,
          current: errors[0],
        }, () => {
          // Callback after state has been set and view has re-rendered:
          // This function will scroll the first modifier that has an error into view
          if (this._focusedModifier) {
            // In this case, we have a modifier with an error, so we want to scroll it into view
            // MeasureLayout finds the offset from the modifier group with the error to the parent ScrollView
            this._focusedModifier.measureLayout(findNodeHandle(this._itemBuilderSV), (x, y, w, h) => {
              this._itemBuilderSV.scrollTo({y: y});
            });
          } else {
            this._itemBuilderSV.scrollToEnd();
          }
        });
      }
    }
  }

  reset = () => {
    this.setState({
      item: null,
      current: null,
      errors: [],
      breadcrumbs: this._getDefaultBreadCrumbs()
    });
  }

  _specialRequest = () => {

    prompt({
      title: "Special Requests",
      onSubmit: (value) => {
        this.state.item.special_instructions = value;
        this.props.onItemChange(this.state.item);
      },
      defaultValue: this.state.item.special_instructions,
      placeholder: "eg. Dressing on the side"
    });
  }

  _refreshMenus = async () => {
    let availableMenus = this.props.location.menus;
    this.setState({refreshingMenus: true});
    if (!availableMenus.length) {
      await API.doMenuUpdate();
    }
    this.setState({refreshingMenus: false});

    this.forceUpdate();
  }

  _handleLayoutChange = ({nativeEvent}) => {
    let {layout} = nativeEvent;
    let {width} = layout;

    const minColWidth = 240; // Allows for 2 decent sized columns on a Moto G4

    let numColumns = 0;
    if (width < 500) {
      numColumns = 2;
    } else {
      numColumns = Math.floor(width / minColWidth);
    }

    this.setState({
      numColumns: numColumns,
    })
  }

  renderModifierGroup = (modifierGroup) => {
    let {current, numColumns, search} = this.state;

    let modifiers = Object.values(modifierGroup.modifiers)
    let cartModifiers = current.mods[modifierGroup.id]

    // let optionToCustomize = modifiers.some( (mod) => mod.modifier_groups?.length > 0 )
    let hasError = current.errors.indexOf(modifierGroup.id) !== -1;
    let required = modifierGroup.min_selected > 0;
    let requirementsMet = current.mods[modifierGroup.id]?.filter((mod) => mod.selected).length >= modifierGroup.min_selected

    let filteredModifiers = modifiers;

    if (search) {
      let query = new RegExp(search.trim(), 'i');
      filteredModifiers = _.filter(modifiers, modifier => modifier.name_for_bartender.search(query) >= 0);
    }

    if (filteredModifiers?.length === 0) {
      return null;
    }

    return (
      <View
        testID={'itemBuilderModifierGroup-' + modifierGroup.heading_name}
        key={modifierGroup.id + "-modifier-group"}
        style={styles.modifierGroup}
        onLayout={this._handleLayoutChange}
      >
        <View testID={'itemBuilderModifierGroupHeader'} style={styles.modifierGroupHeader}>
          <Text testID={'itemBuilderModifierGroupHeader-' + modifierGroup.heading_name} style={{
            fontWeight: "bold",
            color: "#000"
          }}>{modifierGroup.heading_name}</Text>
          <Text style={{color: '#878787', fontSize: 11, marginLeft: 15}}>{modifierGroup.description}</Text>
          {required ?
            (
              requirementsMet ?
                <Icon type={"AntDesign"} name={"checkcircle"}
                      style={{color: Colors.success, fontSize: 16, marginLeft: 15}}/>
                : <Text style={[styles.requiredBadge, hasError && {
                  color: Colors.error,
                  borderColor: Colors.error
                }]}>Required</Text>
            )
            : <Text style={{marginLeft: 4, fontSize: 10, color: Colors.darkGray}}>(Optional)</Text>
          }
        </View>
        <IndicatorScrollView columns={numColumns}>
          {
            filteredModifiers.map((modifier, index) => {
              let cartModifier = cartModifiers?.find((mod) => mod.menuItemId === modifier.menuItemId)
              return (
                <ItemButton
                  testID={`itemBuilderModifier-${modifierGroup.heading_name}-${modifier.name}`}
                  key={'itemBtn_' + index + '-' + modifier.id}
                  text={modifier.name}
                  selected={this._isModifierSelected(modifier)}
                  columns={numColumns}
                  onPress={() => {
                    this._toggleModifier(modifier);
                  }}
                  modifier={modifier} // pass the cart modifier if available so that you can check if it iis valid and show "Customize"
                  cartModifier={cartModifier}
                  customizeModifier={() => this._handleCustomizeModifier(modifier)}
                />
              )
            })
          }
        </IndicatorScrollView>
      </View>);
  }

  _selectCurrent = (mod) => {
    this.props.onModifiersChange(mod)
    this.setState({
      current: mod,
      parent: mod.parent
    })
  }

  calculatePath = (current, node, path) => {
    let breadCrumbArrowHeight = 40;

    if (!node.parent) {
      return (
        <>
          <TouchableWithoutFeedback testID={'itemBuilderPathNode-' + node.getName()}
                                    onPress={() => this._selectCurrent(node)}>
            <View style={[styles.breadcrumb, styles.breadcrumb__cartItem, {height: breadCrumbArrowHeight}]}>
              <Text style={{fontWeight: "bold"}}>{node.getName()}</Text>
            </View>
          </TouchableWithoutFeedback>
          <View style={{height: "100%", justifyContent: "center"}}>
            <Icon type={"AntDesign"} name="caretright" style={{fontSize: 12, color: Colors.darkGray}}/>
          </View>
        </>
      );
    }

    return <>
      {this.calculatePath(current, node.parent, path)}
      <TouchableWithoutFeedback testID={'itemBuilderPathNode-' + node.getName()}
                                onPress={() => this._selectCurrent(node)}>
        <View
          style={[styles.breadcrumb, {height: breadCrumbArrowHeight}]}
        >
          <Text style={[node === current ? {
            textDecorationLine: "underline",
            color: Colors.dark
          } : {color: Colors.darkGray}]}>{node.getName()}</Text>
        </View>
      </TouchableWithoutFeedback>
      {
        node !== current &&
        <View style={{height: "100%", justifyContent: "center"}}>
          <Icon type={"AntDesign"} name="caretright" style={{fontSize: 12, color: Colors.darkGray}}/>
        </View>
      }
    </>;
  }

  renderPath = () => {
    const {current} = this.state;
    if (!current.parent) return null; //Only show breadcrumbs after the first nested modifier is selected
    return (
      <ScrollView horizontal={true} ref={(ref) => {
        this.breadcrumbContainer = ref
      }} contentContainerStyle={{padding: 5, display: "flex", flexDirection: "row", alignItems: "center"}}>
        {
          this.calculatePath(current, current, "")
        }
      </ScrollView>
    )
  }

  _isModifierSelected(modifier) {
    let {current} = this.state;
    if (!current.mods) return false;
    if (current.mods[modifier.menu_heading_id]) {
      let cartModifier = current.mods[modifier.menu_heading_id].find(m => m.menuItemId === modifier.menuItemId)
      return cartModifier && cartModifier.selected;
    }
  }

  _toggleModifier = async (modifier, doUpdate = true) => {
    let {item, current} = this.state;
    let response = await current.toggleModifier(modifier);

    if (!response.success) {
      Alert.alert(response.errorTitle, response.errorMessage);
      return
    }

    if (doUpdate) {
      let errors = this._validateItem(item)
      this.setState({
        errors: errors,
        current: current,
        search: '',
      }, () => {
        this.props.onModifiersChange(current)
      });
    }
  }

  _handleCustomizeModifier = async (modifier) => {
    const {current} = this.state;

    // Need to check if is already selected
    let cartMod = current.mods && current.mods[modifier.menu_heading_id]?.find((m) => m.menuItemId === modifier.menuItemId)

    let response = null;

    if (cartMod) {
      if (!cartMod.selected) {
        response = await current.toggleModifier(modifier);
      }
      // else do nothing because the cartModifier is already selected and you just want to enter the cartModifier level
    } else {
      response = await current.toggleModifier(modifier);
      cartMod = response.cartModifier;
    }

    if (response && !response.success) {
      Alert.alert(response.errorTitle, response.errorMessage);
      return;
    }

    try {
      await current.isCurrentLevelValid() //Return true if valid or throws error if invalid
      this.setState({
        errors: this._validateItem(cartMod),
        current: cartMod,
        parent: cartMod.parent,
      }, () => {
        this.breadcrumbContainer?.scrollToEnd({animated: true})
        this.props.onModifiersChange(cartMod)
      })

    } catch (error) {
      // One of the modifier groups for the current level does not have its requirements met therefore alert and fail.
      Alert.alert("Invalid Modifier!", error.message)
      this.props.onModifiersChange(current)
    }
  }
}

ItemBuilder.propTypes = {

  onItemChange: PropTypes.func,
  onDone: PropTypes.func,
  numGuests: PropTypes.number
};
ItemBuilder.defaultProps = {
  onItemChange: () => {
  },
  onDone: () => {
  }
};


const MenuHome = ({onPress}) => {
  return (
    <TouchableWithoutFeedback testID={'itemBuilderMenuHomeButton'} onPress={onPress}>
      <View style={styles.homeBreadcrumb}>
        <Icon type={"Entypo"} name="dots-three-vertical"/>
      </View>
    </TouchableWithoutFeedback>
  )
}

const MenuSearch = ({value, onKeyPress, style, searchString}) => {
  return (
    <View style={[{flexDirection: 'row', alignItems: 'center', marginHorizontal: 20}, style]}>
      <TextInput
        testID={'itemBuilderMenuSearchInput'}
        style={{flex: 1}}
        value={value}
        onChangeText={val => {
          onKeyPress(val)
        }}
        placeholder={"Search " + searchString}
        underlineColorAndroid={'transparent'}
      />
      <TouchableWithoutFeedback testID={'itemBuilderMenuClearSearchButton'} onPress={() => {
        onKeyPress('')
      }}>
        <Icon type={"MaterialIcons"} name={"clear"} style={{color: value ? 'black' : 'lightgray'}}/>
      </TouchableWithoutFeedback>
    </View>
  )
}

const styles = EStyleSheet.create({
  content: {
    flex: 1
  },
  itemButton: {
    width: Layout.window.width / 4 - 10,
    height: 40,
    margin: 5,
    backgroundColor: '#ccc',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentContainer: {
    margin: 10
  },
  homeBreadcrumb: {
    backgroundColor: '#cdcdcd',
    width: 36,
    height: 36,
    marginLeft: 5,
    marginTop: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  scrollContent: {},
  buttonGroup: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '101%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  modifierGroupHeader: {
    padding: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  modifierGroup: {
    marginBottom: 20,
  },
  requiredBadge: {
    marginLeft: 10,
    borderRadius: 20,
    paddingVertical: 3,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: Colors.darkGray,
    color: Colors.darkGray,
    fontWeight: "bold",
    fontSize: 10,
  },
  highlightedText: {
    fontWeight: "bold",
    color: Colors.primary,
  },
  breadcrumb: {
    display: "flex",
    justifyContent: "center",
    paddingHorizontal: 16,
  },
  breadcrumb__cartItem: {},
  breadcrumb__cartModifier: {},
  breadcrumb__currentItem: {
    textDecorationLine: "underline"
  },
  search__noResults: {
    textAlign: "center",
    paddingVertical: 20,
    paddingHorizontal: 10,
  }
});
