import React from 'react';
import PropTypes from 'prop-types';
import RefundReasonModal from "./RefundReasonModal";
import {CmdButton} from "./index";
import {showMessage} from "react-native-flash-message";

export default class RefundCmdButton extends React.Component {

  static propTypes = {
    order: PropTypes.object,
    items: PropTypes.array.isRequired,
    onSuccess: PropTypes.func
  }

  state = {
    showModal: false
  }

  render(){
    let { items, style, order } = this.props;
    const orderId = items.length ? items[0].orderId : null;
    let canRefund = items.length && !items.some(i => i.status === 'refunded') && items.every(i => i.orderId === orderId);

    return [
      <CmdButton style={style} disabled={!canRefund} onPress={this._startRefund} text={"Refund"} key={'refund_btn'}/>,
      <RefundReasonModal
        key={'refundreasonmodal'}
        order={ order }
        items={items}
        selectedItems={items.map(i => i.orderitemid)}
        visible={this.state.showModal}
        onCancel={this._hideModal}
        onSuccess={this._onSuccess} />
    ]
  }

  _hideModal = () => this.setState({showModal: false});

  _onSuccess = () => {
    let {onSuccess, items} = this.props;
    this.setState({
      showModal: false
    });
    onSuccess(items);
    showMessage({
      floating: true, position: 'top', type: 'success',
      message: 'Refund completed successfully'
    })
  }

  _startRefund = () => {
    this.setState({ showModal: true })
  }


}
