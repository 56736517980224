import moment from 'moment';
/**
 * Base Model class
 * @author Gilles St-Cyr
 * @description The Base model class which all CC Models should extend.
 * Provides some base functionality and helper functions which can automatically populate models based on JSON objects
 * received from the server. If a child Model has a _field_map public property, it will automatically run the field
 * through the provided method. This is useful for converting objects into child classes or strings into DateTime fields.
 *
 * In the constructor of any child class, be sure to call ```this.update(data)``` to run the auto-field populating and
 * post-processing.
 */

export default class Model {

  static api;
  /** @type {moment.Moment} The time of creation */
  _created_date = moment();
  /** @type {moment.Moment} The time of last modification to the model */
  _last_modified = this._created_date;

   // can be used to modify incoming properties ie: datetime string to moment() instance
  _field_map = {
    // property_name: mapping_function
    // examples:
    // order_time: order_time => moment(order_time)
    // orders: orders => orders.map(order => new Order(order))
  };
  /** @type {string[]} Attributes that'll always be serialized when converted to json. Priority over _do_not_serialize */
  _force_serialize = []
  /** @type {string[]} Attributes in the model that will not get serialized when converted to json. */
  _do_not_serialize = []

  update(obj, addMissingFields = true){

    if(!obj) return;
    let key, missing = [];
    if (addMissingFields) {
      for(key in obj){
        if(obj.hasOwnProperty(key)){
          if(!this.hasOwnProperty(key) && !Object.getOwnPropertyDescriptor(this.constructor.prototype, key))
            missing.push(key);
          if(!(key in this._field_map))
            this[key] = obj[key];
        }
      }
    }

    this.applyPostProcessing(obj);

    this._last_modified = moment();

    if(missing.length) console.warn(this.constructor.name+" is missing the fields: ", missing.join(', '));
  }

  applyPostProcessing = (obj) => {
    // Run fields through post-processors. We do this afterwards since some post-processing might rely on all fields being set.
    for(let key in this._field_map){
      if(obj.hasOwnProperty(key)){
        this[key] = this._field_map[key].call(this, obj[key]);
      }
    }
  }

  /**
   * Creates a serialized object representation of this object. Does not serialize attributes that are listed in
   * `this._do_not_serialize`, that are private (as indicated by a prefixed '_'), or that are functions. However,
   * if the key is included in `this._force_serialize`, then it is serialized without exception.
   * @returns {Object} A serialized object of this Model, with all of this model's keys and their respective values
   */
  toJSON() {
    let res = {};
    for(let key in this) {
      if (this._do_not_serialize.includes(key) || key[0] === '_' || typeof this[key] === 'function') continue;
      // this[key].toJSON
      res[key] = this[key];
    }

    this._force_serialize.forEach( key => {
      res[key] = this[key];
    });

    return res;
  }

  get api(){
    return Model.api;
  }

}
