import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Icon, Input, Item, Label, Text, View} from "native-base";
import API from "../../api";
import {Dimensions, Modal, KeyboardAvoidingView} from "react-native";
import {modalStyles} from "../../styles/Global";
import Colors from "../../constants/Colors";
import {showMessage} from "react-native-flash-message";
import MLoader from "../Modals/MLoader";
import StripeCardModal from "../Stripe/StripeCardModal";

/**
 * TODO: Fix Lots of duplicated code between this and CardChooser.
 * @param location
 * @param onSuccess
 * @param buttonContent
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const NewTabButton = ({location, onSuccess, buttonContent, ...props}) => {
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [showTabNameModal, setShowTabNameModal] = useState(false);
  const [tabName, setTabName] = useState(false);
  const [loading, setLoading] = useState(false);

  if (!buttonContent) buttonContent = <Text style={[{color: 'white'}]}>New Tab</Text>

  const onStripeSuccess = async (paymentMethod) => {
    const cardDetails = paymentMethod?.cardDetails || paymentMethod.card;
    try {
      if (!['visa', 'mastercard'].includes(cardDetails.brand)) {
        setShowAddCardModal(false);
        showMessage({
          type: 'warning',
          message: 'Only Visa and Mastercard supported at this time.'
        });
        return;
      }

      setLoading(true);
      setShowAddCardModal(false);
      const {seated_group} = location;
      let result = await API.saveReusableCard(seated_group, paymentMethod.id);
      if (result.error) {
        showMessage({
          type: 'danger',
          message: 'Error saving card information'
        })
        setLoading(false);
        return;
      }
      const {card} = result;

      // todo: pass seat # if only 1 uniqSeat, otherwise prompt for Seat# with Tab Name.
      // todo: Pass param letting us know this is a keep-open tab

      result = await API.openTab(seated_group.id, tabName, location.id, card.id);
      const {tab} = result;
      if (tab) {
        setLoading(false);
        onSuccess(tab);
      } else {
        showMessage({
          type: 'warning',
          message: "There was an error opening the Tab."
        });
      }
    } catch (err) {
      // showErrorMessage?
    }
    setLoading(false);
  }

  const onStripeError = (error) => {
    showMessage({
      type: 'warning',
      message: JSON.stringify(error)
    })
  }

  return (
    <Button
      onPress={() => setShowTabNameModal(true)}
      {...props}
    >
      {buttonContent}
      <>
        {loading && <MLoader message={"Processing..."}/>}

        <StripeCardModal
          visible={showAddCardModal}
          reusable={true}
          headerText={"Only VISA/MC Supported"}
          onClose={() => setShowAddCardModal(false)}
          onSuccess={onStripeSuccess}
          //onError={onStripeError}
        />

        {showTabNameModal && (
          <NewTabModal
            location={location}
            onClose={() => setShowTabNameModal(false)}
            onSuccess={(tabName) => {
              setShowTabNameModal(false);
              setTabName(tabName);
              setShowAddCardModal(true);
            }}
          />
        )}
      </>
    </Button>
  )

}

/**
 * TODO: Make all Modals in Terminal
 * @param location
 * @param onClose
 * @param onSuccess
 * @returns {JSX.Element}
 * @constructor
 */

export const NewTabModal = ({location, onClose, onSuccess, defaultName = ''}) => {
  const [tabName, setTabName] = useState(defaultName);
  const [modalWidth, setModalWidth] = useState(400);
  const [error, setError] = useState(false);
  const input = useRef(null);
  const {width} = Dimensions.get('window');

  useEffect(() => {
    if (input?.current?._root) input.current._root.focus();
  }, [input])

  useEffect(() => {
    setModalWidth(_.min([400, (width - 10)]));
  }, [width]);

  return (
    <Modal transparent onRequestClose={onClose}>
      <KeyboardAvoidingView style={modalStyles.background} behavior={"padding"}>
        <View style={{backgroundColor: 'white', width: modalWidth}}>
          <View style={modalStyles.header}>
            <Text style={modalStyles.headerText}>New Tab</Text>
          </View>
          <Form>
            <Item error={!!error}>
              <Input placeholder={"Tab Name"} ref={input} onChangeText={text => setTabName(text)} value={tabName}/>
              {!!error && <Icon name={'close-circle'}/>}
            </Item>
            {typeof (error) === 'string' && (
              <Text note style={{color: 'red', marginLeft: 15}}>{error}</Text>
            )}
            {/* Here we'll add Manual / Credit card options*/}
            <View style={modalStyles.footer}>
              <Button onPress={onClose} transparent style={{marginRight: 10}}>
                <Text style={{color: Colors.primary}}>CANCEL</Text>
              </Button>
              <Button onPress={() => {
                if (!tabName.length) setError(true);
                else if (location.tabs.find(tab => tab.tab_name === tabName)) {
                  setError('Tab name already taken');
                } else {
                  onSuccess(tabName)
                }
              }}>
                <Text>NEXT</Text>
              </Button>
            </View>
          </Form>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  )
}

export default NewTabButton;
