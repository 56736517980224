import React, { Component } from 'react';
import {Platform, StyleSheet} from 'react-native';
import PropTypes from 'prop-types';
import {
  Text,
  TextInput,
  TouchableWithoutFeedback,
  View,
  Modal
} from 'react-native';
let globalInstance = null;

export const prompt = (props) => {
  if(globalInstance)
    globalInstance.show(props);
}

export default class Prompt extends Component {
  static propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    onCancel: PropTypes.func,
    cancelText: PropTypes.string,
    onSubmit: PropTypes.func,
    submitText: PropTypes.string,
    onChangeText: PropTypes.func,
    borderColor: PropTypes.string,
    promptStyle: PropTypes.object,
    titleStyle: PropTypes.object,
    buttonStyle: PropTypes.object,
    buttonTextStyle: PropTypes.object,
    submitButtonStyle: PropTypes.object,
    submitButtonTextStyle: PropTypes.object,
    cancelButtonStyle: PropTypes.object,
    cancelButtonTextStyle: PropTypes.object,
    inputStyle: PropTypes.object,
    textInputProps: PropTypes.object,
  };

  static defaultProps = {
    visible: false,
    title: '',
    onCancel: ()=>{},
    onSubmit: ()=>{},
    defaultValue: '',
    cancelText: 'Cancel',
    submitText: 'OK',
    borderColor:'#ccc',
    promptStyle: {},
    placeholder: '',
    titleStyle: {},
    buttonStyle: {},
    buttonTextStyle: {},
    submitButtonStyle: {},
    submitButtonTextStyle: {},
    cancelButtonStyle: {},
    cancelButtonTextStyle: {},
    inputStyle: {},
    onChangeText: () => {},
    suffix: '',
  };

  state = {
    value: '',
    visible: false,
  };

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    globalInstance = this;
    this.setState({value: this.props.defaultValue});
  }

  componentWillUnmount() {
    globalInstance = null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {visible} = this.props;
    if(visible && visible !== this.state.visible){
      this.setState({
        visible,
        value: this.props.defaultValue
      })
    }
  }

  _onChangeText = (value) => {
    this.setState({ value });
    this.props.onChangeText(value);
  };

  _onSubmitPress = () => {
    let { value, onSubmit} = this.state;
    if(!onSubmit) onSubmit = this.props.onSubmit;
    this.close();
    onSubmit(value);
  };

  _onCancelPress = () => {
    let onCancel = this.state.onCancel || this.props.onCancel;
    if(onCancel) onCancel();
    this.close();
  };

  close = () => {
    this.setState({...Prompt.defaultProps});
  };

  _renderDialog = () => {
    let {
      title,
      placeholder,
      cancelText,
      onCancel,
      onChangeText,
      submitText,
      borderColor,
      promptStyle,
      titleStyle,
      buttonStyle,
      buttonTextStyle,
      submitButtonStyle,
      submitButtonTextStyle,
      cancelButtonStyle,
      cancelButtonTextStyle,
      inputStyle,
      textInputProps,
      suffix,
      defaultValue
    } = this.props;

    let value, visible, onSubmit;
    // Override prop values with state values:
    for(let key in this.state){
      if(key in this.props)
        eval(key+ ' = this.state[key]');
    }

    return (
      <View style={styles.dialog} key="prompt">
        <View style={styles.dialogOverlay}/>
        <View style={[styles.dialogContent, { borderColor }, promptStyle]}>
          <View style={[styles.dialogTitle, { borderColor }]}>
            <Text style={[styles.dialogTitleText, titleStyle]}>
              { title }
            </Text>
          </View>
          <View style={styles.dialogBody}>
            <View style={{flex: 1}}>
              <TextInput
                style={[styles.dialogInput, inputStyle]}
                onChangeText={this._onChangeText}
                value={this.state.value}
                placeholder={placeholder}
                autoFocus={true}
                underlineColorAndroid="white"
                {...textInputProps} />
            </View>
            { !!suffix && <Text style={{marginLeft: 5}}>{suffix}</Text> }
          </View>
          <View style={[styles.dialogFooter, { borderColor }]}>
            <TouchableWithoutFeedback onPress={this._onCancelPress}>
              <View style={[styles.dialogAction, buttonStyle, cancelButtonStyle]}>
                <Text style={[styles.dialogActionText, buttonTextStyle, cancelButtonTextStyle]}>
                  {cancelText}
                </Text>
              </View>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback onPress={this._onSubmitPress}>
              <View style={[styles.dialogAction, buttonStyle, submitButtonStyle]}>
                <Text style={[styles.dialogActionText, buttonTextStyle, submitButtonTextStyle]}>
                  {submitText}
                </Text>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </View>
      </View>
    );
  };

  show = (props) => {
    this.setState({
      ...props,
      value: props.defaultValue || '',
      visible: true
    }, () => {
      if(Platform.OS === 'web') {
        // Hack to make sure modal shows on top of other modals:
        setTimeout(() => {
          const element = this.modalRef.current.parentNode.parentNode;
          const style = getComputedStyle(element);
          element.style.zIndex = style.zIndex + 1;
        })
      }
    })
  }

  render() {
    return (
      <Modal ref={this.modalRef} onRequestClose={() => this.close()} transparent={true} visible={this.state.visible}>
        {this._renderDialog()}
      </Modal>
    );
  }
};

const styles = StyleSheet.create({
  dialog: {
    flex: 1,
    alignItems: 'center'
  },
  dialogOverlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  dialogContent: {
    elevation: 5,
    marginTop: 150,
    width: 300,
    backgroundColor: 'white',
    borderRadius: 5,
    borderWidth: 1,
    overflow: 'hidden'
  },
  dialogTitle: {
    borderBottomWidth: 1,
    paddingVertical: 10,
    paddingHorizontal: 15
  },
  dialogTitleText: {
    fontSize: 18,
    fontWeight: '600'
  },
  dialogBody: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10
  },
  dialogInput: {
    height: 50,
    fontSize: 18
  },
  dialogFooter: {
    borderTopWidth: 1,
    flexDirection: 'row',
  },
  dialogAction: {
    flex: 1,
    padding: 15
  },
  dialogActionText: {
    fontSize: 18,
    textAlign: 'center',
    color: '#006dbf'
  }
});
