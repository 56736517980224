import {useGlobalize} from "react-native-globalize";
import React, {useEffect, useRef, useState} from "react";
import {Text, TextInput, View, Platform} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import _ from 'lodash';

const numberFormatOptions = {minimumFractionDigits: 2, maximumFractionDigits: 2};

// TODO: Refactor this component. Maybe use Symbol Input with an added 'formatter' prop.
// Can maybe use HelperFunctions.formatNumber
// Or even better, use screen/CheckoutScreen/Form/CurrencyField (need to create)?

const CurrencyInput = ({value, onChange=()=>{}, onBlur=()=>{}, inputref, testID, ...rest}) => {
  const {formatCurrency, formatNumber, parseNumber, getCurrencySymbol} = useGlobalize();
  const input = useRef(null);

  // Hack to get selectTextOnFocus working. NOTE: selectTextOnFocus DOES NOT WORK with autoFocus
  useEffect(() => {
    if(input?.current){
      input.current.focus();
      if(inputref) inputref(input.current);
    }
  }, [input]);


  let valAsFloat = parseFloat(value);

  const [mode, setMode] = useState('display');
  const [floatVal, setFloatVal] = useState(valAsFloat);
  const [displayVal, setDisplayVal] = useState(formatCurrency(floatVal));
  const [editVal, setEditVal] = useState(formatNumber(valAsFloat, numberFormatOptions));

  const currencySymbol = getCurrencySymbol();
  const symbolPosition = displayVal[0] === currencySymbol ? 'first' : 'last';

  const decimalSeparator = formatNumber(0, numberFormatOptions).substr(1,1);
  const separatorRegex = /[.,]/gi; // can add other separators here
  const validCharacterRegex = /[^0-9.,]/gi;

  let lastValidVal = editVal;

  let outputVal = mode === 'display' ? displayVal : editVal;

  return (
    <View style={styles.container}>
      { mode === 'edit' && symbolPosition === 'first'  && <Text style={{fontSize: 16}}>{currencySymbol}</Text>}
      <TextInput
        testID={testID}
        ref={input}
        keyboardType={Platform.OS === 'web' ? null : 'numeric'}
        value={outputVal}
        onFocus={() => {
          setMode('edit');
        }}
        onBlur={() => {
          setMode('display');
          if (onBlur) onBlur(floatVal);
        }}
        onChangeText={(text) => {
          let editVal = text;
          // Remove any invalid characters:
          editVal = editVal.replace(validCharacterRegex, "");

          // Replace invalid separators with the valid separator:
          editVal = editVal.replace(separatorRegex, decimalSeparator);

          // If more then one separator, need to remove all but the last one:
          let count = _.sumBy(editVal, x => x === decimalSeparator);
          if(count > 1){
            let regEx = new RegExp("["+decimalSeparator+"](?=.*["+decimalSeparator+"])", "gi");
            editVal = editVal.replace(regEx, "");
          }

          try {
            // If there's more then 2 decimal places, prevent further:
            let separatorPosition = editVal.indexOf(decimalSeparator);
            if(separatorPosition >= 0){
              let cents = editVal.substr(separatorPosition+1);
              if(cents.length > 2){
                editVal = editVal.substr(0, text.length-1);
              }
            }
            let parsedVal = editVal ? parseNumber(editVal) : 0;

            // This check shouldn't be necessary anymore, since we remove invalid characters
            if (isNaN(parsedVal)) {
              editVal = lastValidVal;
              parsedVal = floatVal;
            } else {
              lastValidVal = editVal;
            }

            setEditVal(editVal);
            setFloatVal(parsedVal);
            setDisplayVal(formatCurrency(parsedVal));

            if (onChange) onChange(parsedVal);
          } catch (err){
            console.log(err);
          }
        }}
        selectTextOnFocus

        {...rest}
      />
      { mode === 'edit' && symbolPosition === 'last'  && <Text style={{fontSize: 16}}>{currencySymbol}</Text>}
    </View>
  )
}

export default CurrencyInput;


const styles = EStyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 2,
    //height: 40,
    padding: 5,
    marginRight: 20,
    width: 100
  }
})

/*onBlur={({nativeEvent}) => {
          let newVal = nativeEvent.text;
          let floatVal = parseFloat(newVal);
          if(isNaN(floatVal)){
            floatVal = initialVal;
          }
          let formatted = formatCurrency(floatVal);
          setVal(formatted);
        }}*/
