import EventModel from './EventModel';
import _ from 'lodash';

export default class Station extends EventModel {

  id = null;

  accepting_orders = false;
  customer_id = null;
  is_polling = false;
  station_name = "";

  accept_orders_offline = false;
  basket_stowed = false;
  description = "";
  dont_accept_orders_until = null;
  extra_properties = {};
  fulfillment_notice = null;
  may_change_fulfillment_method = false;
  open_based_on_schedule = false;
  order_allowed = false;
  stationControlsRobot = false;
  order_allowed_schedule = {};
  possible_fulfillment_methods = [];
  possible_refund_reasons = [];
  print_on_checkout = false;
  printers = [];
  prompt_for_refund_reason = "optional";
  rails = []; // { railName, railId, deliverylocation_set}
  refund_requires_pin = true;
  selected_fulfillment_methods = [];
  service_condition_ids = [];
  snooze_choices = []; // negative values 'before desired time', positive values 'from now'

  constructor(station){
    super(station);
    this.update(station);
  }

  get orders(){
    return Object.values(this.api._orders).filter(order => order.bartending_station_id === this.id);
  }

  get customer(){
    return this.api._customers[this.customer_id];
  }

  // This is debounced so if we call it multiple times on the same station, the trigger only gets fired once:
  ordersUpdated = _.debounce(() => {
    this.trigger('orders');
  }, 100);

  getFilteredOrders(showClosed=false, showSnoozed=false){
    return this.orders.filter(o => {
      let show = true,
        now = new Date();

      show &= showClosed ? true : !o.time_closed;
      show &= showSnoozed ? true : o.snooze_till < now;

      return show;
    })
  }

  get statuses(){
    let orderitems = [].concat(...this.orders.map(o=>o.items));
    return _.mapValues(_.groupBy(orderitems, i=>i.status), v=>v.length);
  }

  /**
   * Returns filtered Order Item statuses
   * @param showClosed
   * @param showSnoozed
   * @returns {Dictionary<unknown>}
   */
  getFilteredStatuses(showClosed=false, showSnoozed=false){
    let orderitems = [].concat(...this.getFilteredOrders(showClosed, showSnoozed).map(o=>o.items));
    // todo: order by status_sequence (maybe order by distance to closed, except waiting and making
    // Group orderItems by status, then map output to a number representing the number of grouped items:
    return _.mapValues(_.groupBy(orderitems, i=>i.status), v=>v.length);
  }

  /**
   * Returns all available robot delivery locations for this station
   * @returns {Location[]}
   */
  getRobotLocations = () => {
    return _.orderBy(
      this.rails.reduce((acc, rail) => acc.concat(rail.deliverylocation_set), []).map(location_id => this.api._locations?.[location_id]),
      ['locationName']
    );
  }

}
