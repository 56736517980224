import React from 'react';
import {StyleSheet, View, Text, TouchableOpacity, Vibration} from 'react-native';
import {Icon} from 'native-base';

export default class PinButton extends React.PureComponent {

  render() {
    const {value, onPress} = this.props;
    let text = value;

    if (value === "") {
      return (
        <View style={styles.wrap}/>
      )
    } else if (value === "Del") {
      text = <Icon type={"MaterialIcons"} name="backspace" size={20}/>
    }

    return (
      <TouchableOpacity
          accessible={true}
          testID={'pin' + String(value)}
          accessibilityLabel={'pin' + String(value)}
          style={[styles.wrap, styles.button]}
          onPress={() => {
            Vibration.vibrate(50);
            onPress(value)
          }}
      >
        <Text style={styles.buttonText} fsClass="fs-exclude">{text}</Text>
      </TouchableOpacity>
    );
  }


}

const styles = StyleSheet.create({
  wrap: {
    width: 65,
    height: 65,
    margin: 10
  },
  button: {
    borderRadius: 100,
    backgroundColor: '#dfdfdf',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonText: {}
});
