import axios from 'axios';
import {Form, Item, Label, Input, Button, Text, Icon, CheckBox} from 'native-base';
import React from 'react';
import {Dimensions, View, TouchableWithoutFeedback, Platform, KeyboardAvoidingView} from 'react-native';
import AsyncStorage from "@react-native-community/async-storage";
import EStyleSheet from 'react-native-extended-stylesheet';
import API from '../api';
import Loader from '../components/Loader';
import {prompt} from '../components/Prompt';
import Colors from '../constants/Colors';
import {IS_ELO, SHOW_SERVER_CHOOSER} from "../constants/Config";
import {validateEmail} from '../helpers/HelperFunctions';
import * as IntentLauncher from "expo-intent-launcher";
import Fontello from "../assets/fonts/Fontello";

const version = API.version;

export default class LoginScreen extends React.Component {

  constructor(props) {
    super(props);
    const dim = Dimensions.get('window');
    this.state = {
      errorMsg: '',
      processing: false,
      emailValid: false,
      passValid: false,
      orientation: dim.width > dim.height ? 'LANDSCAPE' : 'PORTRAIT',
      serverURL: axios.defaults.baseURL,
      showPass: false,
      email: "",
      password: "",
      rememberEmail: false,
      emailFocused: true,
    };
  }

  componentDidMount() {
    if (Platform.OS !== 'web')
      this._interval = setInterval(this._checkConnection, 10000);

    API.multiGet(['rememberEmail', 'email']).then(values => {
      const isValid = validateEmail(values.email);
      this.setState({
        rememberEmail: values.rememberEmail === 'true',
        email: values.rememberEmail === 'true' ? values.email : '',
        emailValid: isValid,
        emailFocused: !isValid,
      });
    })
  }

  componentWillUnmount() {
    clearInterval(this._interval);
  }

  _checkConnection = () => {
    API.checkConnection().then(online => {
      API.trigger('online', online);
    })
  }

  get style() {
    return this.state.orientation === 'LANDSCAPE' ? landscapeStyles : portraitStyles;
  }

  render() {
    return (
      <View style={{flex: 1, alignItems: 'center'}}>
        {this.state.processing && <Loader/>}
        <View onLayout={this.onLayout} style={[styles.contentContainer, this.style.contentContainer]}>
          <View style={[this.style.header]}>
            <Fontello
                name={'bbot-logo'}
                style={{ fontSize: 60, color: '#1167E8'}}
            />
            <Text style={{
              fontSize: 30,
              fontWeight: 'bold',
              marginTop: 15}}>
              Bbot Terminal
            </Text>
          </View>
          <KeyboardAvoidingView style={this.style.body}>
            <Form style={this.style.form}>
              <Item accessible={false}>
                <Label style={{width: 100}}>E-Mail</Label>
                <Input
                  name="email"
                  testID="emailInput"
                  accessibilityLabel="ccEmail"
                  autoFocus={this.state.emailFocused}
                  keyboardType="email-address"
                  onChangeText={(text) => {
                    this.setState({
                      email: text,
                      emailValid: validateEmail(text)
                    });
                  }}
                  value={this.state.email}
                />
              </Item>
              <Item accessible={false}>
                <Label style={{width: 100}}>Password</Label>
                <Input
                  name="Password"
                  accessibilityLabel="ccPassword"
                  testID="passwordInput"
                  secureTextEntry={!this.state.showPass}
                  autoFocus={!this.state.emailFocused}
                  returnKeyType={'send'}
                  onChangeText={(pass) => {
                    this.setState({
                      password: pass,
                      passValid: pass.length > 0
                    })
                  }}
                  value={this.state.password}
                  onSubmitEditing={({nativeEvent}) => {
                    if (this._isValid()) this.doLogin();
                  }}
                />
                <TouchableWithoutFeedback onPress={() => this.setState({showPass: !this.state.showPass})}>
                  <Icon name={this.state.showPass ? 'visibility-off' : 'visibility'} type={'MaterialIcons'}/>
                </TouchableWithoutFeedback>
              </Item>
              <Item
                style={{borderBottomWidth: 0, justifyContent: 'center', padding: 10}}
                onPress={() => this.setState({rememberEmail: !this.state.rememberEmail})}>
                <Label>Remember me</Label>
                <CheckBox disabled checked={this.state.rememberEmail}/>
              </Item>

              <Button block light
                      accessibilityLabel="ccLogin"
                      testID={"loginButton"}
                      style={{marginTop: 10}}
                      disabled={!this._isValid()}
                      onPress={this.doLogin}
              >
                <Text>Login</Text>
              </Button>
              {this._errorMessage()}
            </Form>

          </KeyboardAvoidingView>
          <View style={this.style.footer}>
            <TouchableWithoutFeedback onPress={this._changeIP}>
              <View style={{alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{color: '#ddd'}}>{this.state.serverURL}</Text>
                <Text style={{color: '#ddd'}}>{ version }</Text>
              </View>
            </TouchableWithoutFeedback>
            {Platform.OS === 'android' && !IS_ELO && (
              <Button transparent onPress={() => {
                IntentLauncher.startActivityAsync(
                  IntentLauncher.ACTION_WIFI_SETTINGS
                )
              }}>
                <Text>Wifi Settings</Text>
              </Button>
            )}
          </View>
        </View>
      </View>
    );
  }

  _isValid = () => {
    return !this.state.processing && this.state.emailValid && this.state.passValid;
  }

  onLayout = () => {
    const dimensions = Dimensions.get('window');
    this.setState({
      orientation: dimensions.width > dimensions.height ? 'LANDSCAPE' : 'PORTRAIT'
    })
  }

  _changeIP = () => {
    if (SHOW_SERVER_CHOOSER) {
      this.props.navigation.navigate('ServerChooser');
    } else if (axios.defaults.baseURL !== 'https://bbot.menu/') {
      prompt({
        title: 'Server IP Address:',
        onSubmit: val => {
          axios.defaults.baseURL = val;
          this.setState({
            serverURL: val
          })
        },
        defaultValue: axios.defaults.baseURL,
        placeholder: "https://dev.bbot.menu/"
      })
    }
  }

  doLogin = async () => {
    let {navigation} = this.props;

    this.setState({
      processing: true,
      showPass: false,
      errorMsg: null
    });

    let response = await API.login(this.state.email, this.state.password);

    if (response.success) {
      AsyncStorage.setItem('rememberEmail', this.state.rememberEmail.toString());
      if (response.allowed_customers.length > 1) {
        navigation.navigate("CustomerChooser", {
          customers: response.allowed_customers
        });
      } else {
        navigation.navigate("AuthLoading");
      }
    } else {
      this.setState({
        processing: false,
        errorMsg: response.error
      })
    }
  }

  _errorMessage() {
    if (this.state.errorMsg)
      return (
        <View style={this.style.errorMessage}>
          <Text style={this.style.errorText}>{this.state.errorMsg}</Text>
        </View>
      )
  }
}

const styles = EStyleSheet.create({
  contentContainer: {
    flex: 1,
    alignItems: 'center'
  }
})

const portraitStyles = EStyleSheet.create({
  contentContainer: {
    width: '80%',
  },
  header: {
    alignItems: 'center',
    margin: 30
  },
  headerLogo: {},
  body: {
    flex: 1,
    minHeight: 300,
    minWidth: 340
  },
  form: {
    flex: 1,
    marginBottom: 10
  },
  errorMessage: {
    marginTop: 5,
    alignItems: 'center',
  },
  errorText: {
    color: Colors.error
  },
  footer: {
    alignItems: 'center',
    marginBottom: 5
  }
});

const landscapeStyles = EStyleSheet.create({
  contentContainer: {
    width: '70%'
  },
  header: {
    alignItems: 'center',
    margin: 30
  },
  headerLogo: {},
  body: {
    flex: 1,
    minHeight: 250,
    minWidth: 400
  },
  form: {
    marginBottom: 10
  },
  errorMessage: {
    marginTop: 5,
    alignItems: 'center'
  },
  errorText: {
    color: Colors.error
  },
  footer: {
    alignItems: 'center',
    marginBottom: 5
  }
});
