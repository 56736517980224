import _ from "lodash";
import React, {useEffect, useState} from "react";
import {Text} from "native-base";
import {View} from "react-native";
import {FormattedCurrency, FormattedNumber} from "react-native-globalize";
import EStyleSheet from "react-native-extended-stylesheet";

import API from "../../api";
import Segment, {SegmentButton} from "../../components/Segment";
import Card from "./Card";
import SymbolInput from "../../components/SymbolInput";
import {TextM} from "../../components";
import CurrencyInput from "../../components/CurrencyInput";


/**
 * Computes the value of the tip in cents given the type of the tip (percentage or amount), the numerical value, and the
 * check (for computing the value of a percentage)
 *
 * @param {TipType} tipType The type of tip the tipValue is representing
 * @param {number} tipValue 0-1 if the tip type is TipType.PERCENTAGE, any non-negative integer number of cents otherwise
 * @param {CheckModel} check The current check, for getting the amount of money
 * @returns {number} (In cents) The final tip amount
 */
export const getTipAmount = (tipType, tipValue, check) => {
  // TipType.AMOUNT, return the value
  if (tipType === TipType.AMOUNT)
    return tipValue;
  // TipType.PERCENT, compute the percentage, same way as Consumer App: https://github.com/BbotLLC/consumer/blob/master/src/stores/CartStore.js
  const fees = _.sumBy(check.getFees(), 'total') || 0;
  const total = check.pretax_prediscount_total + fees + check.discounts_total;
  return Math.round(total * tipValue);
}

/**
 * Denotes the type of tip, whether it's a fixed amount or a percentage of the total cost
 * @enum {string}
 */
export const TipType = {
  AMOUNT: 'custom$',
  PERCENTAGE: 'custom%'
}





/**
 * The <TipsCard> contains the row of buttons to select presets or custom tip amounts, and allows the patron to enter
 * their custom amount or percentage for tip if they choose.
 *
 * The selections will update the <TipScreen> component's state to be accurately displayed in the total cost.
 *
 * @param props
 * @param {CheckModel} props.check Information on the current order, including items and various prices
 * @param {ReactHook<number>} props.setTipAmount The React hook for setting the final dollar amount of the Tip
 * @returns {JSX.Element}
 * @constructor
 */
const TipsCard = ({
                    check,
                    setTipAmount,
                    tipAmount,
                    checkingPrices,
                    ...rest
                  }) => {

  const menuData = API.getMenu();
  const {choices, default_tip} = menuData.customer.app_properties.tipping;
  const defaultSelected = check.total !== 0 ? default_tip : TipType.AMOUNT;

   /** @type {[(number | TipType), ReactHook<number | TipType>]} One of the values in tipChoices, or TipType if custom */
  const [selected, setSelected] = useState(defaultSelected);
  /** @type {[number, ReactHook<number>]} (In dollars) Persistence of the number in custom amount */
  const [customAmount, setCustomAmount] = useState(0);
  /** @type {[number, ReactHook<number>]} (Between 0-1) Persistence of the number in custom percentage */
  const [customPercentage, setCustomPercentage] = useState(0);
  /** @type {[string, ReactHook<string>]} Persistence of the entry in custom percentage (to allow for decimals) */
  const [customPercentageString, setCustomPercentageString] = useState('0');




  useEffect(() => {
    let value = selected;
    if(Object.values(TipType).includes(selected)){
      value = customAmount;
    }
    const tipAmount = getTipAmount(selected, value, check);
    // set the amount on initial load
    setTipAmount(tipAmount);
  }, [checkingPrices]);



  /** @type {number} The total amount of fees on this check */
  const fees = _.sumBy(check.getFees(), 'total') || 0;
  /** @type {number} The total cost of this check before tax, for computing Tip percentage */
  const total = check.pretax_prediscount_total + fees + check.discounts_total;

  // Builds a list of <SegmentButton>
  const presetTipButtons = choices.map((tipChoice, i) => {
    const tipAmount = getTipAmount(TipType.PERCENTAGE, tipChoice, check);
    return (
      <SegmentButton
        testID={`tipScreenPresetPercent-${tipChoice * 100}`}
        key={i}
        display={tipChoice * 100 + '%'}
        value={tipChoice}
        selected={selected === tipChoice}
        onPress={(value) => {
          setSelected(value);
          setTipAmount(tipAmount);
        }}
      />
    )
  });

  return (
    <Card
      testID={'tipScreenTipsCard'}
      title={'Tip'}
      titleRight={<FormattedCurrency value={tipAmount / 100}/>}
      {...rest}
    >
      <Segment nativeID={'tipSelector'} style={styles.segmentStyle}>

        {/* The Preset Values of Tips */}
        {presetTipButtons}

        {/* Enter a Custom Fixed Amount */}
        <SegmentButton
          testID={'tipScreenCustomAmountButton'}
          display={'$'}
          value={TipType.AMOUNT}
          selected={selected === TipType.AMOUNT}
          onPress={(val) => {
            setTipAmount(customAmount);
            setSelected(val);
          }}
        />

        {/* Enter a Custom Percentage Amount*/}
        <SegmentButton
          testID={'tipScreenCustomPercentageButton'}
          display={'%'}
          value={TipType.PERCENTAGE}
          selected={selected === TipType.PERCENTAGE}
          onPress={(val) => {
            const tipAmount = getTipAmount(TipType.PERCENTAGE, customPercentage, check);
            setTipAmount(tipAmount);
            setSelected(val);
          }}
        />
      </Segment>

      {/* Space to enter a Custom Fixed Amount */}
      {selected === TipType.AMOUNT && (
        <View style={styles.customTipInputs}>
          <TextM>Tip Amount: </TextM>
          <CurrencyInput
            testID={'tipScreenCustomAmountInput'}
            value={_.round(customAmount / 100, 2)}
            style={{width: 80, fontSize: 16, padding: 0}}
            onChange={(amount) => {
              // Convert user tip in dollars to tip in cents
              setCustomAmount(amount * 100);
              setTipAmount(amount * 100);
              const pc = (amount * 100 / total)
              setCustomPercentageString(_.round(pc * 100, 2))
              setCustomPercentage(pc)
            }}
            selectTextOnFocus
          />
          <Text style={{fontSize: 16}}>
            (<FormattedNumber value={(customAmount * 100) / total} maximumFractionDigits={2}/> <TextM>%</TextM>)
          </Text>
        </View>
      )}

      {/* Space to enter a Custom Percentage Amount */}
      {selected === TipType.PERCENTAGE && (
        <View style={styles.customTipInputs}>
          <TextM>Tip Percentage: </TextM>
          <SymbolInput
            testID={'tipScreenCustomPercentageInput'}
            suffix={'%'}
            value={customPercentageString}
            onChangeText={(text) => {
              text = text.replace(/[^0-9.,]+/, '')
              setCustomPercentageString(text);
              const percentage = text?.length > 0 ? parseFloat(text) / 100 : 0;
              const tipAmount = getTipAmount(TipType.PERCENTAGE, percentage, check);
              setCustomPercentage(percentage);
              setTipAmount(tipAmount);
              setCustomAmount(tipAmount);
            }}
            autoFocus={true}
            selectTextOnFocus
          />
        </View>
      )}
    </Card>
  );
}

export default TipsCard;

const styles = EStyleSheet.create({
  segmentStyle: {
    marginTop: 5,
    marginBottom: 10
  },
  customTipInputs: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5
  },
})
