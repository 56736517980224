import Model from "./Model";
import moment from "moment";

export default class UserInfo extends Model {

  id = null;
  date_joined = null;
  username = ''; // send with anything requiring tracking
  email = '';
  first_name = '';
  last_name = '';
  is_active = false;

  _field_map = {
    date_joined: t => moment(t)
  }

  constructor(obj) {
    super();
    this.update(obj);
  }
}