import _ from "lodash";
import {Button, Icon, Input, Item, Picker, Text, View} from "native-base";
import PropTypes from "prop-types";
import React, {Component} from "react";
import {SectionList, TouchableWithoutFeedback} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import API from "../api";
import {Location} from "../models";
import {escapeRegExp} from "../helpers/HelperFunctions";

/**
 * TODO: When searching, if a zone contains a result, auto-expand zone
 */

export default class LocationFilter extends Component {

  static propTypes = {
    filterLocations: PropTypes.func.isRequired,
    filterStation: PropTypes.func,
    /** Array of selected locations */
    locations: PropTypes.arrayOf(PropTypes.string).isRequired, // Location.AllLocationIDs()
    station: PropTypes.string,
    startCollapsed: PropTypes.bool,
    style: PropTypes.any
  }

  static defaultProps = {
    startCollapsed: false
  }

  state = {
    locationSearch: '',
    stations: [],
    locationsByZone: [],
    zoneData: [],
    allLocations: [],
    expandedState: {} // map of zone to expanded state, true being expanded
  }

  constructor(props) {
    super(props);

    this.state.stations = Object.values(API._stations);
  }

  componentDidMount() {
    API.on('locations', this._updateLocations);
    API.on('stations', this._updateStations);

    const data = this._getLocationData();
    this.setState({
      ...data,
      expandedState: _.mapValues(data.locationsByZone, () => !this.props.startCollapsed)
    })
  }

  componentWillUnmount() {
    API.off('locations', this._updateLocations);
    API.off('stations', this._updateStations);
  }

  _getLocationData = () => {
    const locationsByZone = Location.LocationsByZone();
    return {
      locationsByZone,
      zoneData: Object.keys(locationsByZone).map(zone => ({title: zone, data: locationsByZone[zone]})),
      allLocations: _.reduce(locationsByZone, (acc, zone) => acc.concat(zone), [])
    }
  }

  _updateLocations = () => {
    this.setState({
      ...this._getLocationData()
    });
  }

  _updateStations = () => {
    this.setState({
      stations: Object.values(API._stations)
    });
  }

  render() {
    const {locations} = this.props;
    const {allLocations, locationSearch, zoneData, expandedState} = this.state;

    let allLocationsIcon = 'check-box-outline-blank';
    if (locations.length)
      allLocationsIcon = locations.length === allLocations.length ? 'check-box' : 'indeterminate-check-box';

    return (
      <View style={[{backgroundColor: 'white', flex: 1}, this.props.style]}>
        <Item style={styles.locationSearch}>
          <Icon name={"md-search"}/>
          <Input style={{width: '100%'}} value={locationSearch} placeholder={"Search Locations"}
                 onChangeText={this._changeLocationSearch}/>
          {!!locationSearch && (
            <Button transparent icon onPress={() => this.setState({locationSearch: ''})}>
              <Icon name={'close'}/>
            </Button>
          )}
        </Item>
        {this.props.station !== undefined && !!this.props.filterStation && (
          <Item style={styles.stationSelect}>
            <Picker
              mode={'dropdown'}
              selectedValue={this.props.station}
              onValueChange={this.props.filterStation}
              style={{alignSelf: 'flex-start'}}
            >
              <Picker.Item key={'none'} label={'All Tablets'} value={''}/>
              {this.state.stations.map(station => (
                <Picker.Item
                  key={station.id}
                  label={station.station_name}
                  value={station.id}
                />
              ))}
            </Picker>
          </Item>
        )}
        <View style={styles.locationHeader}>
          <TouchableWithoutFeedback testID={'locationsFilterModalToggleAllLocations'} onPress={() => {
            if (locations.length)
              this._toggleLocations(locations)
            else {
              this._toggleLocations(allLocations.map(l => l.id));
            }
          }}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Icon name={allLocationsIcon} type={"MaterialIcons"} style={{marginRight: 10}}/>
              <Text>LOCATIONS</Text>
            </View>
          </TouchableWithoutFeedback>
          <View style={{flexDirection: 'row', flex: 1, justifyContent: 'flex-end', alignItems: 'center'}}>
            <TouchableWithoutFeedback onPress={() => this._toggleExpandAll(true)}>
              <View style={{marginRight: 10}}>
                <Icon name={'arrow-expand-vertical'} type={'MaterialCommunityIcons'}/>
              </View>
            </TouchableWithoutFeedback>
          </View>
          <TouchableWithoutFeedback onPress={() => this._toggleExpandAll(false)}>
            <View style={{marginRight: 10}}>
              <Icon name={'arrow-collapse-vertical'} type={'MaterialCommunityIcons'}/>
            </View>
          </TouchableWithoutFeedback>
        </View>

        <SectionList
          sections={zoneData}
          keyExtractor={(item, index) => item + index}
          renderItem={({item}) => this._renderLocationItem(item)}
          renderSectionHeader={({section: {title}}) => (
            <ZoneHeader
              title={title}
              onPress={this._toggleZone}
              onExpand={this._toggleZoneExpand}
              expanded={expandedState[title]}
              state={this._getZoneHeaderState(title)}
            />
          )}
          extraData={locationSearch}
        />
      </View>
    )
  }

  _changeLocationSearch = (value) => {

    this.setState({
      locationSearch: value
    })
  }

  _renderLocationItem = (item) => {
    if (this.state.locationSearch) {
      let regEx = new RegExp(escapeRegExp(this.state.locationSearch), 'gi');
      if (!(item.locationName.search(regEx) >= 0 || item.shortId.search(regEx) >= 0)) return null;
      else if (!this.state.expandedState[item.zone_for_reports]) {
        this.state.expandedState[item.zone_for_reports] = true;
        this.setState({
          expandedState: this.state.expandedState
        })
      }
    }

    if (!this.state.expandedState[item.zone_for_reports]) return null;
    return (
      <LocationItem
        location={item}
        selected={this.props.locations.includes(item.id)}
        onPress={this._toggleLocations}
      />
    )
  }

  _getZoneHeaderState = (zone) => {
    let zoneLocations = this.state.locationsByZone[zone];
    let selected = this.props.locations.filter(id => zoneLocations.find(loc => loc.id === id));
    if (!selected.length) return 'none';
    return selected.length === zoneLocations.length ? 'full' : 'partial';
  }

  _toggleLocations = (locations) => {
    let newLocations = _.xor(this.props.locations, locations);
    this.props.filterLocations(newLocations);
  }

  _toggleZone = (zone) => {
    let zoneLocations = this.state.locationsByZone[zone];
    let selectedLocations = this.props.locations.filter(id => zoneLocations.find(loc => loc.id === id));
    let newLocations = selectedLocations.length ?
      _.without(this.props.locations, ...selectedLocations) :
      this.props.locations.concat(zoneLocations.map(l => l.id));

    this.props.filterLocations(newLocations);
  }

  _toggleZoneExpand = (zone) => {
    this.state.expandedState[zone] = !this.state.expandedState[zone];
    this.setState({
      expandedState: this.state.expandedState
    })
  }

  _toggleExpandAll = (expand) => {
    this.setState({expandedState: _.mapValues(this.state.expandedState, () => expand)})
  }

}

const LocationItem = ({location, onPress, selected}) => {
  let icon = selected ? 'check-box' : 'check-box-outline-blank';
  return (
    <TouchableWithoutFeedback testID={`locationsFilterModalLocationToggle-${location.locationName}`} onPress={() => {
      onPress([location.id])
    }}>
      <View style={{padding: 5, borderBottomWidth: 1, borderBottomColor: 'grey', flexDirection: 'row'}}>
        <Icon name={icon} type={"MaterialIcons"} style={{marginRight: 10}}/>
        <Text style={{marginLeft: 10}}>{location.locationName}</Text>
      </View>
    </TouchableWithoutFeedback>
  )
}

const ZoneHeader = ({title, onPress, state, expanded, onExpand}) => {
  let icon;
  switch (state) {
    case 'full':
      icon = 'check-box';
      break;
    case 'partial':
      icon = 'indeterminate-check-box';
      break;
    case 'none':
      icon = 'check-box-outline-blank';
  }
  return (
    <View style={{
      backgroundColor: 'black',
      padding: 5,
      flexDirection: 'row',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomColor: 'white'
    }}>
      <TouchableWithoutFeedback testID={`locationsFilterModalZoneToggle-${title}`} onPress={() => onPress(title)}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <Icon name={icon} type={"MaterialIcons"} style={{marginRight: 10, color: 'white'}}/>
          <Text style={{color: 'white', fontWeight: 'bold'}}>{title}</Text>
        </View>
      </TouchableWithoutFeedback>
      <TouchableWithoutFeedback onPress={() => onExpand(title)}>
        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-end', marginRight: 10}}>
          <Icon name={expanded ? 'caretdown' : 'caretright'} type={'AntDesign'} style={{color: 'white', fontSize: 18}}/>
        </View>
      </TouchableWithoutFeedback>

    </View>
  )
}

const styles = EStyleSheet.create({
  locationHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderTopWidth: 2,
    padding: 5,
    alignItems: 'center',
  },
  locationSearch: {
    borderBottomWidth: 0,
    paddingLeft: 8
  },
  stationSelect: {
    borderBottomColor: 'transparent',
    paddingBottom: 7,
    paddingLeft: 6,
    paddingRight: 12
  }
});
