import React, {useState} from "react";

import Card from "./Card";
import ExtraCheckoutField from "./Form";



/**
 * The <ExtraCheckoutInfoCard> is displayed only if there is checkout information, required or optional, associated
 * with the current location specified by the restaurant. It contains all of the <RequiredCheckoutField> instances with
 * each field's label and input fields based on the type of checkout information.
 *
 * @param props
 * @param {Dictionary<cart>} cart - The Cart
 * @param {Array} fields List of all the RequiredCheckoutFields
 * @param {Boolean} highlightErrors - Whether or not to highlight errors (true after trying to submit)
 * @param {Dictionary<*>} fieldValues - Maps field keys to their respective field values, given by TipScreen
 * @param {ReactHook<Object<string, *>>} setFieldValues - Updates the value of fieldValues tracked in TipScreen
 * @param {ReactHook<Object<string, *>>} updateElementPosition - Let's the parent UI know the Y position of child elements
 * @param {Dictionary<*>} validFields - Map of valid fields. Tracked by the parent to determine when user can checkout
 * @param {ReactHook<Object<string, *>>} setValidFields - When a field validates, let the parent know
 * @returns {?JSX.Element} The card if there are required fields, null otherwise.
 * @constructor
 */
const ExtraCheckoutInfoCard = ({
                                 cart,
                                 fields,
                                 highlightErrors,
                                 fieldValues,
                                 setFieldValues,
                                 updateElementPosition,
                                 validFields,
                                 setValidFields,
                                 ...rest
                               }) => {

  const [elementOffsets, setElementOffsets] = useState({});
  /**
   * Whenever the validity state of a child <RequiredCheckoutField> changes, it will notify this Card using this method,
   * which will check if all requirements have been satisfied. For instance, all required fields must be have non-empty
   * values. Additionally, all fields (including optional fields) must be well-formed if they are non-empty and an
   * InputValidator was provided to the Field to verify it is well-formed. This card will notify <TipScreen> that
   * RequiredCheckoutInfoCard is complete and the patron may proceed to Approval.
   *
   * @param {string} key - The key for the respective Checkout Field
   * @param {boolean} validity - True if the checkout field has met its requirements (see above for requirements)
   * @param {boolean} [update=true] - If true, triggers the setFieldStatus ReactHook, which will re-render the card
   */
  const updateFieldStatus = (key, validity, update = true) => {
    // Updates the value for the Field Statuses (Use spread notation to create new object, to re-render the component)
    validFields[key] = validity;
    if (update) setValidFields({...validFields});
  }

  const updateElementOffset = (key, value) => {
    elementOffsets[key] = value;
    setElementOffsets(elementOffsets);
  }

  /**
   * Whenever a valid input is entered to a child <RequiredCheckoutField>, it will notify this Card using this method,
   * which will save the state of the field.
   *
   * @param {string} key The key of the Field
   * @param {*} value The value of the Field, whatever type that may be (usually text)
   */
  const updateFieldValue = (key, value) => {
    fieldValues[key] = value;
    setFieldValues({...fieldValues});
  }

  /**
   * We only want to update the element position once the card has been laid out since the field layout is relative
   * to its parent, in this case the card.
   * @param nativeEvent
   */
  const onCardLayout = ({nativeEvent}) => {
    const cardYOffset = nativeEvent.layout.y;
    for(let key in elementOffsets){
      updateElementPosition(key, cardYOffset + elementOffsets[key]);
    }
  }

  // If no extra checkout fields, return null to not render the card.
  if (fields.length === 0) return null;

  return (
    <Card testID={'tipScreenCheckoutInfoCard'} title={'Checkout Information'} onLayout={onCardLayout} {...rest} >
      { fields.map((field, i) => (
          <ExtraCheckoutField
            key={field.key}
            highlightError={highlightErrors}
            field={field}
            label={field.name_for_patron}
            required={field.required_on_handheld}
            valid={validFields[field.key]}
            setValid={(val) => updateFieldStatus(field.key, val)}
            fieldValue={fieldValues[field.key]}
            setFieldValue={(val) => updateFieldValue(field.key, val)}
            last={i === fields.length - 1}
            index={i}
            cart={cart}
            onLayout={({nativeEvent: {layout}}) => {
              updateElementOffset(field.key, layout.y);
            }}
          />
      ))
    }
    </Card>
  );
}

export default ExtraCheckoutInfoCard;

