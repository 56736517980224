import React from 'react';
import {View, Text} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import Colors from "../constants/Colors";
import PropTypes from 'prop-types';


export default class ErrorBanner extends React.PureComponent {
  render() {
    let {text} = this.props;
    return text ? (
      <View style={styles.banner}>
        <Text style={styles.text}>{text}</Text>
      </View>
    ) : null;
  }
}


ErrorBanner.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

const styles = EStyleSheet.create({
  banner: {
    width: "100%",
    minHeight: "1.4rem",
    backgroundColor: Colors.errorBackground,
    paddingLeft: 10
  },
  text: {
    color: Colors.errorText,
    fontSize: '1rem'
  }
});