import EStyleSheet from 'react-native-extended-stylesheet';
import Colors from "../constants/Colors";

const styles = EStyleSheet.create({
  centered: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  textSmall: {
    fontSize: '1rem'
  },
  textMed: {
    fontSize: '1.25rem'
  },
  textLg: {
    fontSize: '1.5rem'
  },
  modalBackground: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)'
  }
});

const modalStyles = EStyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)'
  },
  modal: {
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    backgroundColor: Colors.primary
  },
  headerText: {
    color: Colors.light,
    flex: 1
  },
  body: {
    backgroundColor: 'white',
    maxHeight: '80%'
  },
  cancelBtn: {
    backgroundColor: Colors.error
  },
  submitBtn: {
    backgroundColor: Colors.primary
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 10,
    backgroundColor: Colors.light
  },
  errorMsg: {
    padding: 5,
    paddingLeft: 10,
    backgroundColor: Colors.errorBackground
  },
  errorMsgText: {
    color: Colors.errorText
  }
})


export {
  styles,
  modalStyles
};
