import * as DeviceInfo from 'expo-device';
import {Platform} from 'react-native';
import EventModel from "./EventModel";
import {SENTRY_ENV} from "../constants/Config"

import Constants from "expo-constants";
import manifest from "../app.json";

export default class HandheldDevice extends EventModel {

  id = null;
  name = "";                          // Name as given by user
  customer_id = '';
  config_id = null;
  reader_serial = '';

  unique_id = Constants.installationId;
  device_name = Constants.deviceName; // Name reported by the OS
  /** (Android) **/
  make = '';
  model = '';

  /** (Android) Device Serial Number (no longer available as of Android 8) **/
  serial = '';

  /** App Version (called apk_version because Terminal used to be Android Only) **/
  apk_version = manifest.expo.version;

  /** Deprecated **/
  bundle_version = '';
  release_channel = SENTRY_ENV;
  api_level = 0;

  platform = Platform.OS;

  preferences = {
    kds_idle_screen: true,
    kds_autofocus: true,
    keep_awake_on_battery: false,
    order_filters: {},
    theme: 'default'
  };
  
  constructor(obj){
    super();

    this.update(obj);

    if(Platform.OS === "web" && window.Cypress) {
      // Make sure this value is the same as railbot_app/textFixtureConstants.py `TEST_HANDHELD_DEVICE_ID`
      Object.defineProperty(this, 'id', {value: '77cb584e-aa0a-450b-ac12-60b6c43a7de2'});
    }

    this.load().then(()=>this._ready = true);
  }

  async updatePrefs(obj){
    const newPrefs = Object.assign({}, this.preferences, obj);
    await this.api.updateDevice({
      preferences: newPrefs
    });
  }

  async load() {
    if(Platform.OS === 'web') await this.loadWeb();
    if(Platform.OS === 'android') await this.loadAndroid();
  }

  async save() {

  }

  async loadWeb() {
    //this.device_name = window.navigator.userAgent;
    this.make = window.navigator.vendor;
    this.model = window.navigator.userAgent;
  }

  async loadAndroid(){
    this.make = DeviceInfo.brand;
    this.model = DeviceInfo.modelName;
    this.api_level = DeviceInfo.platformApiLevel;
  }

}
