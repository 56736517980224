import React from 'react';
import {View, Icon, Badge, Text} from 'native-base';
import PropTypes from 'prop-types';
import Colors from '../constants/Colors';

export default class TabBarIcon extends React.Component {

  static defaultProps = {
    type: 'Ionicons'
  };

  render() {
    let {focused, horizontal, tintColor, disabled, name, type, badge} = this.props;
    let color = disabled ? Colors.darkGray : tintColor;

    return (
      <View>
        { !!badge && (
          <Badge style={{position: 'absolute', top: -5, right: -10, transform: [{scale: 0.7}], zIndex: 100}}>
            <Text>{badge}</Text>
          </Badge>
        )}
        <Icon type={type}
               name={ name }
               style={{ marginBottom: -3, color: color, fontSize: 26 }}
        />
      </View>)
  }
}
