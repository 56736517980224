import {createStackNavigator} from "react-navigation-stack";
import KDSView from "../screens/KDSView";
import TabBarIcon from "../components/TabBarIcon";
import {defaultNavigationOptions} from "./Defaults";
import React, {useState, useEffect} from "react";
import API from '../api';

export const KDSViewStack = createStackNavigator(
  {
    KDSView
  },
  {
    headerMode: 'none',
    navigationOptions: ({navigation}) => {
      return {
        tabBarLabel: 'KDS',
        tabBarTestID: 'kdsview',
        tabBarIcon: (props) => <TabBarIconWithBadge {...props} />
      }
    },
    defaultNavigationOptions: defaultNavigationOptions
  }
)


class TabBarIconWithBadge extends React.PureComponent {
  state = {
    count: API.newKDSOrderCount()
  }

  componentDidMount() {
    this._mounted = true;
    API.on('orders', this._setCount, 'TabBarIconWithBadge');
  }

  componentWillUnmount() {
    this._mounted = false;
    API.off('orders', this._setCount)
  }

  _setCount = async () => {
    if(this._mounted)
      this.setState({
        count: API.newKDSOrderCount()
      })
  }

  render(){
    let {focused} = this.props;
    let {count} = this.state;
    return (
      <TabBarIcon
        {...this.props}
        badge={focused ? null : count}
        name="grid"
        type="Entypo"
      />
    )
  }

}
