import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, useEffect, useRef, useState } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import API from '../../api';
import { offsetInput, offsetOutput } from '../../helpers/DateTimePickerHelper';

// Components
import { Button, Form, Icon, Input, Item, Label, Picker, Right, Text, Textarea } from 'native-base';
import { ActivityIndicator, Platform, ScrollView, TouchableWithoutFeedback, View, Modal } from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';

// Styles
import { Colors, Forms } from '../../styles';
import { styles as gStyle, modalStyles } from '../../styles/Global';

class EditOrderModal extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    onSave: PropTypes.func, // Passes the newly updated order
    order: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired
  };

  static defaultProps = {
    visible: false
  };

  constructor(props) {
    super(props);

    this.state = {
      courierCapabilities: {},
      isLoading: false,
      orderEdits: {},
      showDateTimePicker: false,
      showSpecialInstructions: true
    };

    this._locations = _.sortBy(Object.values(API._locations), ['locationName']);
    this._bartenders = _.sortBy(Object.values(API._bartenders), ['first_name']);
    this._mounted = null;
    this._staffNotesMaxLength = 500;
  }

  componentDidMount() {
    this._mounted = true;
    API.getCourierCapabilities().then(courierCapabilities => {
      if (this._mounted) {
        this.setState({
          courierCapabilities
        });
      }
    });
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    const { visible, order } = this.props;
    const { isLoading, orderEdits, showDateTimePicker, showSpecialInstructions } = this.state;
    const location_id = orderEdits.location_id || order.location_id;
    const bartender_id = orderEdits.bartender_id || order.bartender_id;
    const staff_notes = orderEdits.staff_notes !== undefined ? orderEdits.staff_notes : (order.staff_notes || '');
    const user_desired_time = orderEdits.user_desired_time !== undefined ? orderEdits.user_desired_time : order.user_desired_time;

    if(!visible) return null;
    const disabledStyle = !!order.time_closed && {color: Colors.disabled}

    return (
      <Modal onRequestClose={this._handleCancel} transparent={true} visible={visible}>
        <TouchableWithoutFeedback onPress={this._handleCancel}>
          <View style={gStyle.modalBackground}>
            {/* Prevent touches from hiding modal. */}
            <TouchableWithoutFeedback>
              <View style={{ maxHeight: '80%', maxWidth: 600, width: '80%' }}>
                <View style={modalStyles.header}>
                  <Text style={[modalStyles.headerText, gStyle.textMed]}>Edit Order #{order.orderNumber}</Text>
                </View>
                <ScrollView style={[modalStyles.body, { maxHeight: 'auto' }]}>
                  <Form style={{ flex: 1, marginBottom: 5, padding: 15 }}>
                    {/* Staff Notes */}
                    <Item style={[Forms.formField]} accessible={false}>
                      <Label style={styles.formField__label}>Staff Notes</Label>
                      <Textarea
                        maxLength={this._staffNotesMaxLength}
                        onChangeText={text => this._updateOrderEditsByKey('staff_notes', text)}
                        rowSpan={7}
                        style={styles.formField__textarea}
                        value={staff_notes}
                      />
                      <MaxLengthHint maxLength={this._staffNotesMaxLength} value={staff_notes} />
                    </Item>
                    {/* Special Instructions */}
                    <Item style={[Forms.formField]} accessible={false}>
                      <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <Label style={styles.formField__label}>Special Instructions</Label>
                        {order.items.length > 1 && (
                          <Button
                            onPress={() => this.setState({ showSpecialInstructions: !showSpecialInstructions })}
                            style={{ margin: 0, padding: 0, height: 20 }}
                            transparent
                          >
                            <Text style={{ color: Colors.primary, paddingRight: 0 }}>
                              {showSpecialInstructions ? 'Hide All' : 'Show All'}
                            </Text>
                          </Button>
                        )}
                      </View>
                      { showSpecialInstructions &&
                        order.items.map((item, index) => (
                          <SpecialInstructionsEditor
                            disabled={!!order.time_closed}
                            edits={orderEdits.items?.find(editedItem => editedItem.orderitemid === item.orderitemid)}
                            item={item}
                            key={item.itemId}
                            maxLength={30}
                            onUpdate={this._updateOrderItemSpecialInstructions}
                          />
                        ))}
                    </Item>
                    {/* Change Order Date / Time */}

                    <Item picker stackedLabel style={{ border: 0, marginBottom: 20, marginLeft: 0 }} accessible={false}>
                      <Label style={styles.formField__label}>Order Date / Time</Label>
                      {this._mounted && this._displayDeliveryJobUpdateWarning()}
                      <TouchableWithoutFeedback disabled={!!order.time_closed} onPress={this._toggleShowDatetimePicker}>
                        <Text style={[styles.formField__picker, disabledStyle]}>
                          {user_desired_time ? moment(user_desired_time).format('MMM D, LT') : 'ASAP'}
                        </Text>
                      </TouchableWithoutFeedback>
                    </Item>

                    {/* Change Table / Location */}
                    <Item picker stackedLabel style={{ border: 0, marginLeft: 0 }} accessible={false}>
                      <Label style={styles.formField__label}>Table / Location</Label>
                      <Picker
                        mode={'dropdown'}
                        onValueChange={value => this._updateOrderEditsByKey('location_id', value)}
                        selectedValue={location_id}
                        style={styles.formField__picker}
                      >
                        {this._locations.map(location => (
                          <Picker.Item key={location.id} label={location.locationName} value={location.id} />
                        ))}
                      </Picker>
                    </Item>

                    {/* Change Server */}
                    <Item picker stackedLabel style={{ border: 0, marginLeft: 0 }}>
                      <Label style={styles.formField__label}>Server</Label>
                      <Picker
                        mode={'dropdown'}
                        onValueChange={value => this._updateOrderEditsByKey('bartender_id', value)}
                        selectedValue={bartender_id}
                        style={styles.formField__picker}
                        placeholder={'(Select Server)'}
                      >
                        <Picker.Item label={"(No Server Assigned)"} value={""}/>
                        {this._bartenders.map(bartender => (
                          <Picker.Item key={bartender.id} label={bartender.first_name + ' ' + bartender.last_name} value={bartender.id} />
                        ))}
                      </Picker>
                    </Item>
                  </Form>
                </ScrollView>
                <View style={[modalStyles.footer, { borderColor: Colors.lightGray, borderTopWidth: 1 }]}>
                  <Right style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button transparent onPress={this._handleCancel} style={{ marginRight: 10 }}>
                      <Text style={{ color: Colors.primary }}>Cancel</Text>
                    </Button>
                    <Button icon primary onPress={this._handleSave} disabled={isLoading}>
                      {isLoading
                        ? <ActivityIndicator size={"small"} style={{ marginHorizontal: 23.5 }} color={Colors.primary}/>
                        : <Text>Save</Text>
                      }
                    </Button>
                  </Right>
                </View>
              </View>
            </TouchableWithoutFeedback>
            {!!showDateTimePicker && (
              <DateTimePicker
                is24Hour={true}
                minimumDate={moment().toDate()}
                mode={'datetime'}
                onChange={this._updateUserDesiredTime}
                value={offsetInput(user_desired_time || moment())}
              />
            )}
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }

  _clearOrderEdits = () => {
    this.setState({ orderEdits: {} });
  }

  _displayDeliveryJobUpdateWarning = () => {
    const { order } = this.props;
    const { driver_delivery_jobs } = order;

    if (driver_delivery_jobs.length) {
      const { courierCapabilities } = this.state;
      const deliveryProvider = driver_delivery_jobs[0].delivery_provider;
      const canUpdateJob = courierCapabilities[deliveryProvider]?.update_job;

      return (
        !canUpdateJob && (
          <View style={{ marginBottom: 8 }}>
            <Text style={{ color: Colors.lightTheme.error.regular, fontWeight: 'bold', marginBottom: 8 }}>
              Warning:
            </Text>
            <Text style={{ color: Colors.lightTheme.error.regular }}>
              The delivery provider for this order does not allow the pickup time to be automatically updated. If you
              change the desired pickup time, then you must contact the delivery provider to cancel or reschedule.
            </Text>
          </View>
        )
      );
    }
  };

  _handleCancel = () => {
    this._clearOrderEdits();
    this.props.onCancel();
  };

  _handleSave = async () => {
    const { onSave, order } = this.props;
    const { orderEdits } = this.state;

    const fieldsToEdit = Object.keys(orderEdits).reduce((fieldsToEdit, field) => {
      let value = orderEdits[field];

      if (field === 'user_desired_time') {
        fieldsToEdit[field] = moment.isMoment(value) ? moment.utc(value).format() : value;
      } else {
        fieldsToEdit[field] = value;
      }

      return fieldsToEdit;
    }, {});

    this.setState({ isLoading: true });
    await onSave(fieldsToEdit);
    this.setState({ isLoading: false }, this._clearOrderEdits);
  };

  _toggleShowDatetimePicker = () => {
    this.setState({
      showDateTimePicker: !this.state.showDateTimePicker
    });
  };

  _updateOrderEditsByKey = (key, value) => {
    const { orderEdits } = this.state;

    this.setState({
      orderEdits: {
        ...orderEdits,
        [key]: value
      }
    });
  };

  _updateOrderItemSpecialInstructions = ({ orderitemid, special_instructions }) => {
    const { orderEdits } = this.state;
    const items = [...orderEdits?.items || []];
    const indexToEdit = items.findIndex(item => item.orderitemid === orderitemid);

    if (indexToEdit >= 0) {
      items[indexToEdit] = { orderitemid, special_instructions };
    } else {
      items.push({ orderitemid, special_instructions });
    }

    this.setState({
      orderEdits: {
        ...orderEdits,
        items
      }
    });
  };

  _updateUserDesiredTime = (event, time) => {
    const { orderEdits } = this.state;
    let user_desired_time = moment(offsetOutput(time));

    if (user_desired_time.isSameOrBefore(moment(), 'minutes')) {
      user_desired_time = null; // Convert to ASAP order.
    }

    this.setState({
      orderEdits: {
        ...orderEdits,
        user_desired_time
      },
      showDateTimePicker: false
    });
  };
}

const MaxLengthHint = ({ maxLength, value }) => (
  <Text
    style={[styles.formField__hint, { ...(value?.length === maxLength && { color: Colors.lightTheme.error.regular }) }]}
  >
    {value?.length || 0} / {maxLength}
  </Text>
);

const SpecialInstructionsEditor = ({ edits, item, maxLength, onUpdate, disabled}) => {
  const { itemName, orderitemid, special_instructions } = item;
  const editedInstructions = edits?.special_instructions;
  const initialInputValue = editedInstructions === undefined ? special_instructions : editedInstructions;

  const [inputValue, setInputValue] = useState(initialInputValue);
  const [showEditor, setShowEditor] = useState(false);

  const timer = useRef(null); // Prevent memory leaks when user clicks cancel/save while input is focused.

  const handleBlur = () => {
    // Short delay is required to hide the input when the user presses the edit button on blur.
    timer.current = setTimeout(() => {
      setShowEditor(false)
    }, 150);

    if (inputValue !== initialInputValue) {
      onUpdate({
        orderitemid,
        special_instructions: inputValue
      });
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  let disabledStyle = disabled && {color: Colors.disabled}

  return (
    <Item style={[styles.specialInstructionsEditor, { borderBottomWidth: showEditor ? 0 : 1 }]} accessible={false}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexGrow: 1, width: '100%' }}>
        <Label style={[
          styles.specialInstructionsEditor__label,
          disabledStyle,
          { paddingBottom: showEditor || inputValue?.length > 0 ? 0 : 10 }
        ]}>
          {itemName}
        </Label>
        <TouchableWithoutFeedback disabled={disabled || showEditor} onPress={() => setShowEditor(true)}>
          <View style={{ alignItems: 'center', justifyContent: 'center', paddingVertical: 8 }}>
            <Icon type={'MaterialIcons'} name={'edit'} style={[styles.specialInstructionsEditor__icon, disabledStyle]} />
          </View>
        </TouchableWithoutFeedback>
      </View>
      {!showEditor && inputValue?.length > 0 && (
        <TouchableWithoutFeedback onPress={() => setShowEditor(true)}>
          <Text style={[styles.specialInstructionsEditor__text]}>{inputValue}</Text>
        </TouchableWithoutFeedback>
      )}
      {showEditor && (
        <Item style={[Forms.formField, { marginBottom: 0, marginTop: 10 }]} accessible={false}>
          <Input
            autoFocus={true}
            maxLength={maxLength}
            onBlur={handleBlur}
            onChangeText={value => setInputValue(value)}
            style={[styles.specialInstructionsEditor__input]}
            value={inputValue}
          />
          <MaxLengthHint maxLength={maxLength} value={inputValue} />
        </Item>
      )}
    </Item>
  );
};

const styles = EStyleSheet.create({
  formField__hint: {
    ...Forms.formField__hint,
    alignSelf: 'flex-end'
  },
  formField__label: {
    ...Forms.formField__label,
    color: 'black'
  },
  formField__picker: {
    ...Forms.formField__input,
    ...(Platform.OS === 'android' && { marginTop: 4 }),
    ...(Platform.OS === 'web' && { backgroundColor: 'transparent' }), // Omit on Android to preserve dropdown chevron.
    alignSelf: 'flex-start',
    borderColor: Colors.lightGray,
    borderBottomColor: Colors.gray1,
    width: '100%'
  },
  formField__textarea: {
    ...Forms.formField__input,
    borderColor: Colors.lightGray,
    borderBottomColor: Colors.gray1,
    paddingRight: 10
  },
  specialInstructionsEditor: {
    alignItems: 'flex-start',
    borderColor: Colors.gray1,
    flexDirection: 'column',
    marginLeft: 0,
    paddingTop: 10,
    width: '100%'
  },
  specialInstructionsEditor__icon: {
    fontSize: 20,
    marginBottom: 0,
    paddingLeft: 10
  },
  specialInstructionsEditor__input: {
    ...Forms.formField__input,
    borderColor: Colors.lightGray,
    borderBottomColor: Colors.gray1,
    paddingRight: 10,
    fontSize: 15,
    paddingLeft: 10
  },
  specialInstructionsEditor__label: {
    ...Forms.formField__label,
    color: 'black',
    flexShrink: 1,
    fontWeight: 'normal',
    marginBottom: 0,
    paddingRight: 10,
    paddingTop: 10
  },
  specialInstructionsEditor__text: {
    color: Colors.gray1,
    fontStyle: 'italic',
    padding: 10,
    paddingLeft: 10,
    width: '100%'
  }
});

export default EditOrderModal;
