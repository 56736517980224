import React, {Component} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {StyleSheet, View, ActivityIndicator} from "react-native";
import Alert from "../components/Alert";
import {Container, Content, List, ListItem, Left as NBLeft, Body, Right, Icon, Switch, Text} from "native-base";
import API from "../api";
//import {ActionSheet} from "native-base";
import ActionSheet from "../components/ActionSheet";
import EStyleSheet from "react-native-extended-stylesheet";
import PinPadModal from "../components/PinPad/PinPadModal";
import ServiceConditionModal from "../components/ServiceConditionModal/ServiceConditionModal";
import Loader from "../components/Loader";
import Colors from "../constants/Colors";

const Left = ({style, children}) => {
  return <NBLeft style={[{marginRight: 25, marginLeft: 5}, style]}>{children}</NBLeft>
}

/**
 * Each Station will have it's own set of controls:
   - Allow Ordering
   - Service Conditions
   - Fulfillment Methods (Expandable Section)
   - Location That Can Order
*/

class OrderControlScreen extends Component {

  static propTypes = {};

  static navigationOptions = {
    title: "Order Control"
  }

  state = {
    last_call: false,
    processing: {},
    locations_shown: {},
    fm_shown: {},
    stationLocations: {},
    showSCModal: false,
    modalStation: null,
    loading: true
  }

  componentDidMount() {
    let {navigation} = this.props;

    API.on('config_updated', this._refresh);
    API.on('stations', this._refresh);
    API.on('locations', this._refresh);

    this._blurListener = navigation.addListener('willBlur', this._lock);
    this._focusListener = navigation.addListener('didFocus', ()=>{
      if(this.state.loading && API.hasPolled) this.setState({loading: false});
    })
  }

  componentWillUnmount() {
    API.off('config_updated', this._refresh);
    API.off('stations', this._refresh);
    API.off('locations', this._refresh);
    this._blurListener.remove();
    this._focusListener.remove();
    if(this._inactionListener) this._inactionListener.remove();
  }

  _refresh = () => {
    this.setState({
      loading: false
    })
    this.forceUpdate();
  }

  _lock = (s) => {
    this.accessGranted = false;
    if(this._inactionListener) this._inactionListener.remove();
  }

  render() {
    let config = API.getConfig();

    return (
      <Container>
        <Loader shown={this.state.loading}/>
        <Content>
          <List>
            {config.kds_stations.map(station => {
              return this._renderStation(station);
            })}
          </List>
          { config.show_service_conditions && (
            <ServiceConditionModal
              visible={this.state.showSCModal}
              station={this.state.modalStation}
              onClose={()=>this.setState({showSCModal: false})}
              onSuccess={()=>{
                this.setState({showSCModal: false})
                this.forceUpdate();
              }}
            />
          )}
        </Content>
      </Container>
    );
  }

  _toggleFulfillmentMethod = (station, method, state) => {
    let processing = this.state.processing;
    if(!processing[station.id]) processing[station.id] = {};
    processing[station.id][method] = true;

    if(this.accessGranted) {
      this.setState({ processing: processing });
      this._doToggle(station, method, state);
    } else {
      this.setState({
        processing: processing
      });
      PinPadModal.show((user) => {
        if (user.isManager()) {
            this._inactionListener = API.onInaction(30, this._lock);
            this.accessGranted = user;
            this._doToggle(station, method, state);
          } else {
            this.accessGranted = false;
            this._doneProcessing(station.id+'.'+method);
            Alert.alert("Access Denied", "You do not have access to perform this action.")
          }
      }, () => {
        processing[station.id][method] = false;
        this.setState({
          processing: processing
        })
      })
    }
  }

  _doToggle = async (station, method, state) => {
    let result = await API.toggleFulfillmentMethod(station, method, state, this.accessGranted.user.id);
    this._doneProcessing(station.id+'.'+method);
    if (result.success) {
      this._refresh();
    }
  }

  _doneProcessing = (key) => {
     let processing = this.state.processing;
    _.set(processing, key, false);

    this.setState({
      processing: processing
    })
  }

  _renderStation = (station) => {
    const STATUS_LABELS = {
      on: "On",
      off: "Off",
      auto: "Determined by Schedule ("+(station.open_based_on_schedule ? 'On Now' : 'Off Now')+")"
    }

    let selectedIds = station.service_condition_ids;
    let allConditions = station.customer?.service_conditions || [];
    let selected = allConditions?.reduce((res, condition) => {
      return res.concat(condition.choices.filter(c => selectedIds.includes(c.id)).map(c => c.name));
    }, []);

    return (
      <List key={`station_${station.id}`}>
        <ListItem itemDivider>
          <Text>{station.station_name}</Text>
        </ListItem>

        {/*Allow Ordering*/}
        <ListItem icon onPress={() => this._showOrderAllowedSheet(station)}>
          <Left>
            <Icon style={styles.icon} type={"MaterialIcons"} name={"perm-device-information"}/>
          </Left>
          <Body>
            <Text>Allow Ordering</Text>
          </Body>
          <Right style={{flex:1}}>
            <Text note>{STATUS_LABELS[station.order_allowed]}</Text>
          </Right>
        </ListItem>

        {allConditions.length > 0 && API.config.show_service_conditions && (
          <ListItem icon onPress={() => {
            this.setState({
              showSCModal: true,
              modalStation: station
            })
          }}>
            <Left>
              <Icon style={styles.icon} type={"MaterialIcons"} name={"room-service"}/>
            </Left>
            <Body>
              <Text>Service Conditions</Text>
            </Body>
            <Right style={{flex: 1}}>
              <Text note>{selected.join(', ')}</Text>
              <Icon name="chevron-right" type="Entypo"/>
            </Right>
          </ListItem>
        )}

        <ListItem icon onPress={() => this._showLocations(station)}>
          <Left>
            <Icon style={styles.icon} type={"Entypo"} name={"location"}/>
          </Left>
          <Body>
            <Text>Allowed Locations</Text>
          </Body>
          <Right>
            <ExpandableArrow expanded={this.state.locations_shown[station.id]}/>
          </Right>
        </ListItem>

        {this._renderStationLocations(station)}
        {this._renderFulfillmentMethods(station)}
      </List>
    )
  }

  _showLocations = async station => {
    this.state.locations_shown[station.id] = !this.state.locations_shown[station.id];

    this.setState({
      locations_shown: this.state.locations_shown
    });
    if(this.state.locations_shown[station.id]) {
      let result = await API.getLocationOrderAllowed();
      if (result.error) {
        // TODO: Show an error
      } else {
        this.setState({
          stationLocations: result.stations
        })
      }
    }
  }

  _renderStationLocations = (station) => {
    if (this.state.locations_shown[station.id] && this.state.stationLocations[station.id]) {
      let {locations} = this.state.stationLocations[station.id];
      let locationModels = Object.keys(locations).map(locationId => API._locations[locationId]).filter(l=>l);

      if(!locationModels.length) return (
        <ListItem icon key={station.id+'_noLocations'}>
          <Left/>
          <Body>
            <Text note>No Locations Available</Text>
          </Body>
          <Right style={{flex:1}}/>
        </ListItem>
      )

      let zones = _.groupBy(_.orderBy(locationModels, 'shortId'), 'zone_for_reports');

      let rows = [];
      for (let zone in zones) {
        rows.push(
          <ListItem key={zone} icon onPress={() => this._showToggleZone(zone, station, zones[zone])}>
            <Left/>
            <Body>
              <Text style={{fontWeight: 'bold'}}>{zone}</Text>
            </Body>
            <Right style={{flex:1}}>
              <Text note>Toggle All</Text>
              <Icon name="chevron-right" type="Entypo"/>
            </Right>
          </ListItem>
        )
        rows = rows.concat(
          zones[zone].map(location => (
            <ListItem icon key={location.id}>
              <Left><View style={{width:50}}/></Left>
              <Body>
                <Text>{location.locationName}</Text>
              </Body>
              <Right>
                <Switch
                  value={location.order_allowed === 'on'}
                  onValueChange={(allowed) => this._toggleLocation(station, location, allowed)}
                />
              </Right>
            </ListItem>
          ))
        )
      }

      return rows;
    }
  }

  _renderFulfillmentMethods = (station) => {
    if (!station.may_change_fulfillment_method)
      return (
        <ListItem icon onPress={()=>{Alert.alert("Station does not currently have access to edit fulfillment methods")}}>
          <Left>
            <Icon name={"flow-line"} type={"Entypo"} style={styles.icon}/>
          </Left>
          <Body>
            <Text>Allowed Fulfillment Methods</Text>
          </Body>
          <Right style={{flex:1}}>
            <Text note>{station.selected_fulfillment_methods.map(fm => API.menuData.fulfillment_pretty_names[fm]).join(', ')}</Text>
          </Right>
        </ListItem>
      )

    return [
      <ListItem icon onPress={() => this._showFulfillmentMethods(station)} key={'fm_'+station.id}>
        <Left>
          <Icon style={styles.icon} name={"flow-line"} type={"Entypo"} />
        </Left>
        <Body>
          <Text>Fulfillment Methods</Text>
        </Body>
        <Right>
          <ExpandableArrow expanded={this.state.fm_shown[station.id]}/>
        </Right>
      </ListItem>,
      ...station.possible_fulfillment_methods.map(fm => {
        let isProcessing = !!_.get(this.state.processing, station.id+'.'+fm);
        if(!this.state.fm_shown[station.id]) return null;
        return (
          <ListItem icon key={`${station.id}_${fm}`}>
            <Left>
              <Icon style={styles.subIcon} name={"level-down"} type={"Entypo"}/>
            </Left>
            <Body>
              <Text>{API.menuData.fulfillment_pretty_names[fm]}</Text>
            </Body>
            <Right>
              { !isProcessing && (
                <Switch value={station.selected_fulfillment_methods.includes(fm)}
                      onValueChange={(val) => this._toggleFulfillmentMethod(station, fm, val) }
                />
              )}
              { isProcessing && (
                <View style={{margin: 15}}><ActivityIndicator color={Colors.primary}/></View>
              )}
            </Right>
          </ListItem>
        )
      })
    ]
  }

  _showFulfillmentMethods = (station) => {
    this.state.fm_shown[station.id] = !this.state.fm_shown[station.id];
    this.setState({
      fm_shown: this.state.fm_shown
    })
  }

  _showToggleZone = (zone, station, locations) => {
    const buttons = [
      { text: 'Set All On', value: true},
      { text: 'Set All Off', value: false}
    ]
    ActionSheet.show({
      options: buttons,
      title: zone
    }, async btnId => {
      let button = buttons[btnId];
      let response = await API.setLocationOrderAllowed(station, locations, button.value);
      this.setState({
        stationLocations: {...this.state.stationLocations}
      })
    })
  }

  _toggleLocation = async (station, location, allowed) => {
    let response = await API.setLocationOrderAllowed(station, [location], allowed);
    if(response.success){
      this.setState({
        stationLocations: {...this.state.stationLocations}
      })
    }
  }

  _showOrderAllowedSheet = station => {
    let offText = 'Force Off';
    if(station.extra_properties?.allow_reset_to_auto) offText += " (Resets to auto at 5AM)";
    let buttons = [
      { text: "Force On", value: "on" },
      { text: "Auto (Determined by Schedule)", value: "auto" },
      { text: offText, value: "off" },
    ];
    if (station.order_allowed === 'on' || station.order_allowed === 'auto' && station.open_based_on_schedule){
      buttons = buttons.concat(
      { text: "Pause Ordering for 5 Minutes", value: "5"   },
      { text: "Pause Ordering for 10 Minutes", value: "10" },
      { text: "Pause Ordering for 15 Minutes", value: "15" })
    }
    ActionSheet.show(
      {
        options: buttons,
        title: "Allow Ordering",
      },
      async (result) => {
        let btn = buttons[result];
        if (btn) {
          this.setState({ loading: true });
          let result = await API.setOrderAllowed(station, btn.value);
          await API.handheldPoll();
          // this.forceUpdate();
          this.setState({ loading: false });
        }
      }
    );
  };
}

const ExpandableArrow = (props) => {
  let arrow = props.expanded ? 'chevron-down' : 'chevron-right';
  return <Icon type={"Entypo"} name={arrow} style={styles.arrow}/>
}


export default OrderControlScreen;


const styles = EStyleSheet.create({
  arrow: {
    color: 'lightgray',
    fontSize: 22,
    margin: 10
  },
  icon: {
    marginLeft: 15,
    alignSelf: 'center',
    height: 30,
    width: 30
  },
  subIcon: {
    marginLeft: 50,
    alignSelf: 'center',
    height: 30,
    width: 30
  },
  sectionHeaderContainer: {
    backgroundColor: '#e8e8e8',
    paddingVertical: 8,
    paddingHorizontal: 15,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: '#ededed',
    fontWeight: 'bold'
  },
  sectionSubHeaderContainer: {
    backgroundColor: '#f7f7f7',
    paddingVertical: 3,
    margin: 0,
    paddingLeft: 25,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: '#ededed',
    fontWeight: 'bold'
  }
})
