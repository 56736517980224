import React, {Component} from 'react';
import moment from "moment";
import color from "color";
import _ from "lodash";

import {
  Animated,
  Dimensions,
  LayoutAnimation,
  Platform, Pressable,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  UIManager,
  View
} from 'react-native';
import PropTypes from 'prop-types';
import API from "../api";
import KDSOrderModal from "../components/KDS/KDSOrderModal";
import KDSOrderTicket from "../components/KDS/Ticket/KDSOrderTicket";
import KDSGroupedTicket from "../components/KDS/Ticket/KDSGroupedTicket";
import CutePic from "../components/CutePic";
import {RecyclerListView, DataProvider, GridLayoutProvider} from "recyclerlistview";
import KDSItemAnimator from "../components/KDS/KDSItemAnimator"
import ScrollButtons from "../components/ScrollButtons";
import {FormattedPlural} from "react-native-globalize";
import OrderHelper from "../helpers/OrderHelper";
import Colors from "../constants/Colors";
import {Loader} from "../components";
import EStyleSheet from "react-native-extended-stylesheet";

import {Icon} from "native-base";

if (Platform.OS === 'android' && UIManager.setLayoutAnimationEnabledExperimental) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

const {height: windowHeight, width: windowWidth} = Dimensions.get('window');

export default class KDSOrdersView extends Component {

  state = {
    rows: 1,
    cols: 1,
    orientation: windowWidth >= windowHeight ? 'landscape' : 'portrait',
    fadeBarOpacity: new Animated.Value(1),
    scrollY: 0,
    // scrollEnabled: true,
    selectedOrder: null,
    showModal: false,
    orders: [],
    page: 0,
    sendRobotButtons: [],
    onRobotOrders: [],
    processing: {},
    expandedCheckoutIds: {} // todo: cleanup as tickets are removed
  };

  _viewHeight = windowHeight - 89;

  static propTypes = {
    filterFn: PropTypes.func,
    sortDir: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    sortField: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    style: PropTypes.object,
    emptyMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    showCutePicsWhenEmpty: PropTypes.bool
  };

  static defaultProps = {
    filterFn: () => {
    }, // Show All
    sortDir: 'asc',
    sortField: 'time',
    style: {},
    emptyMsg: "Waiting for Orders",
    showCutePicsWhenEmpty: false
  };

  static MIN_TICKET_WIDTH = 300;
  static MIN_TICKET_HEIGHT = 235; // might be better to make dynamic based on screen resolution - this gives 2 rows on an iPad
                                  // Dimensions.get('window').height > 698 ? 350 : 293

  constructor(props) {
    super(props);

    this.state.dataProvider = this._getDataProvider()
    // placeholder to prevent annoying warning:
    this.state.layoutProvider = new GridLayoutProvider(1, i => 1, i => 1, 1);


    this.itemAnimator = new KDSItemAnimator();
  }


  componentDidMount() {
    this._mounted = true;
    this._ordersListener = API.on('orders', () => this.refresh(), 'KDSOrdersView');

    const {height, width} = Dimensions.get('window');
    this._updateGridLayoutProvider(width, height);

    if (API.hasPolled) {
      this.refresh(true);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    this._ordersListener.remove();
  }

  refresh = async (force) => {
    if (!this._mounted) return;
    let orders = this._getCurrentOrders();

    if (API.currentScreen !== 'KDSView') force = true;
    // Flatten the orders, since grouped orders are arrays:
    const flatNew = _.flatten(orders);
    const flatOld = _.flatten(this.state.orders);

    if (!force) {
      // Incoming Orders
      const incoming = _.difference(flatNew, flatOld);
      // Outgoing Orders
      const outgoing = _.difference(flatOld, flatNew);
      const allChanges = [].concat(incoming, outgoing)

      // We could filter incoming and outgoing to only care about other peoples changes
      if (!this.state.orders.length || !allChanges.length || allChanges.every(o => moment(o.last_modified).isSame(o._lastLocalChange))) {
        // either the are no current orders, or all changes are local changes, so go ahead and force update:
        force = true;
      }
      // If we're not forcing it, and we already have orders, and the length changes:
      if (!force && ((incoming.length + outgoing.length) > 0)) {
        // If last touch was less then 10 seconds ago:
        if (moment().diff(API.lastTouch, 'seconds') < 10) {
          this.setState({
            upcomingOrders: orders
          });
          return;
        }
      }
    }

    let onRobotOrders = _.flatten(orders).filter(o => o.items.find(i => i.status === 'loaded'));
    const sendRobotButtons = this._getRobotButtons(onRobotOrders);

    this.setState({
      sendRobotButtons,
      onRobotOrders,
      orders,
      dataProvider: this._getDataProvider(orders),
      upcomingOrders: false
    })
  }

  _getDataProvider = (orders) => {
    if (!orders) orders = this._getCurrentOrders();
    return new DataProvider(
      (r1, r2) => {
        if (Array.isArray(r1) || Array.isArray(r2)) return r1 !== r2;
        return r1.orderId !== r2.orderId || (r1.orderId === r2.orderId && r1.last_modified !== r2.last_modified);
      }
    ).cloneWithRows(orders);
  }

  _getCurrentOrders() {
    let {sortDir, sortField, filterFn} = this.props;
    const {expandedCheckoutIds} = this.state;
    const config = API.getConfig();

    let kds_station_orders = config.kds_stations.reduce((orders, station) => {
      if (station) return orders.concat(station.orders);
      return orders;
    }, []);

    const orders = _.orderBy(kds_station_orders.filter(filterFn), sortField, sortDir);
    if (!config.kds_group_related_tickets) return orders;

    let groupedOrders = _.groupBy(orders, 'checkout_id');

    // == Cleanup ==  Maybe make this run intermittently? Might impact performance:
    const checkoutIdsToDelete = _.difference(Object.keys(expandedCheckoutIds), Object.keys(groupedOrders));
    if (checkoutIdsToDelete.length) {
      checkoutIdsToDelete.forEach(id => delete expandedCheckoutIds[id]);
      this.setState({expandedCheckoutIds})
    }
    /**
     * Here we convert the orders dict (grouped by checkout_id) into an array of orders and order arrays, which can be
     * ingested by our RecyclerListView.
     */
    return _.reduce(
      groupedOrders,
      (res, group) => {
        if(!expandedCheckoutIds[group[0].checkout_id] && group[0].related_orders.length > 0) {
          // Group is Collapsed
          return res.concat([group])    // we're not expanded and we have related orders and we're not
        } else {
          // Group is expanded or is not a group
          if(expandedCheckoutIds[group[0].checkout_id]){
            // we are indeed an expanded group, we might need to add orders which are no longer on screen:
            if(group.length < (group[0].related_orders.length + 1)){
              // We're missing some orders, so bring them back:
              group = _.orderBy([group[0], ...group[0].related_orders], 'orderNumberToday')
            }
          }
          return res.concat(group)
        }
      },
      []  // Initial Value
    );
  }

  _fadeOutBoundary = 100;

  setAnimation = () => {
    LayoutAnimation.configureNext({
      duration: 300,
      delete: {
        type: LayoutAnimation.Types.easeOut,
        property: LayoutAnimation.Properties.scaleX,
        springDamping: 0.7
      }
    })
  };


  render() {
    let {style} = this.props;
    const {cols, rows, page, dataProvider, upcomingOrders} = this.state;
    const numTickets = dataProvider.getSize();
    const contentHeight = this._recyclerListView?._scrollComponent?.props?.contentHeight;
    const numPerScreen = cols * rows;
    const start = (page * cols) + 1;
    const end = _.min([(start + numPerScreen) - 1, numTickets]);

    return (
      <View style={[style, {flex: 1, marginRight: 0, flexDirection: 'row'}]}>

        <View style={{flex: 1}}>
          {numTickets > 0 && (
            <View style={{flex: 1, marginRight: 1}} onLayout={this._onViewLayout}>
              <TouchableWithoutFeedback onPressOut={() => setTimeout(() => {
                // on mouse up, we always wan to set _scrolling to false. Put it in a setTimeout because
                // otherwise this gets called too soon.
                this._scrolling = false;
              }, 10)}>
                <RecyclerListView
                  ref={sv => {
                    this._recyclerListView = sv;
                  }}
                  canChangeSize={true}  // canChangeSize={isScreenVisible}
                  rowRenderer={this._renderRow}
                  layoutProvider={this.state.layoutProvider}
                  dataProvider={this.state.dataProvider}
                  onScroll={this._onScroll}
                  disableRecycling={true}
                  scrollViewProps={{
                    showsVerticalScrollIndicator: false,
                  }}
                  //optimizeForInsertDeleteAnimations={true}
                  //itemAnimator={this.itemAnimator}  // can't figure out how do get this to work
                />
              </TouchableWithoutFeedback>
            </View>
          )}

          {
          !numTickets && (
            this._listEmpty()
          )
        }

          { /* Bottom Bar */}
          <View style={styles.footerBar}>
            <Text style={{color: Colors.darkGray, marginHorizontal: 5}} numberOfLines={1} ellipsizeMode={'middle'}>
              {API.config.kds_stations.map(s => s.station_name).join(', ')}
            </Text>
            <IndexDisplay
              start={start}
              end={end}
              total={numTickets}
            />
          </View>
          { /* Robot Buttons */}
          <View style={styles.floatingButtons}>
            {this.state.sendRobotButtons.map(rail => (
              <SendRobotButton key={rail.railId} rail={rail}
                               onPress={this._sendRobot}
                               processing={this.state.processing[rail.railId]}
              />
            ))}
            {!!upcomingOrders && <RefreshButton upcomingOrders={upcomingOrders} onPress={() => this.refresh(true)}/>}
          </View>
        </View>

        {numTickets > 0 && (
          <ScrollButtons
            onUpPress={this._scrollUp}
            onDownPress={this._scrollDown}
            upDisabled={!this.state.scrollY}
            downDisabled={this.state.scrollY >= contentHeight - this._viewHeight}
          />
        )}

        <KDSOrderModal
          visible={this.state.showModal}
          onClose={this._hideModal}
          order={this.state.selectedOrder}
        />
      </View>
    );
  }

  _scrollUp = () => {
    this._scroll(-1);
  }

  _scrollDown = () => {
    this._scroll(1);
  }

  _scroll = (dir) => {
    if (this._recyclerListView) {
      let scrollY = this._recyclerListView.getCurrentScrollOffset();
      let pageSize = this._viewHeight / this.state.rows;
      let page = Math.floor(scrollY / pageSize);
      this.setState({page, scrollY});
      let targetY = pageSize * (page + dir);
      if (targetY < 0) targetY = 0;
      this._recyclerListView.scrollToOffset(0, targetY, true);
    }
  }

  _renderRow = (type, item, index) => {
    if (Array.isArray(item)) {
      return (
        <KDSGroupedTicket
          orders={item}
          onStatusChange={() => {
          }}
          columns={1}
          height={this._viewHeight / this.state.rows}
          onPress={() => {
          }}
          onClose={() => {
          }}
          onToggle={this._toggleCollapsed}
        />
      )
    } else {
      const isPrevRelated = index > 0 && this.state.dataProvider.getDataForIndex(index-1)?.related_orders?.includes(item);
      const isNextRelated = this.state.dataProvider.getDataForIndex(index+1)?.related_orders?.includes(item);
      return (
        <KDSOrderTicket
          key={item.id}
          onStatusChange={this._ticketStatusChange}
          columns={1}
          height={this._viewHeight / this.state.rows}
          order={item}
          onPress={this._showModal}
          onClose={this._hideModal}
          onToggle={this._toggleCollapsed}
          isPrevRelated={isPrevRelated}
          isNextRelated={isNextRelated}
        />
      )
    }
  }

  _toggleCollapsed = (checkout_id) => {
    let {expandedCheckoutIds} = this.state;
    expandedCheckoutIds[checkout_id] = !expandedCheckoutIds[checkout_id];

    this.setState({
      expandedCheckoutIds,
      dataProvider: this._getDataProvider()
    })
  }

  _onScroll = (event) => {
    this._scrolling = true;
    if (this._mounted && this._recyclerListView) {
      const scrollY = this._recyclerListView.getCurrentScrollOffset();
      const pageSize = this._viewHeight / this.state.rows;
      let page = Math.floor(scrollY / pageSize);
      this.setState({scrollY, page});
    }
    /*  Animated.event(
        [
          { nativeEvent: {contentOffset: {y: this.state.scrollY}}}
        ]
      )(event);*/
  }

  _onViewLayout = ({nativeEvent}) => {
    let {layout: {width, height}} = nativeEvent;
    KDSOrdersView.MIN_TICKET_HEIGHT = height > 800 ? 300 : 235;
    this._updateGridLayoutProvider(width, height);
  }

  _updateGridLayoutProvider = (width, height) => {
    this._viewHeight = height;
    let orientation = width > height ? 'landscape' : 'portrait';
    const numRows = Math.floor(height / KDSOrdersView.MIN_TICKET_HEIGHT) || 1;
    const numCols = Math.floor(width / KDSOrdersView.MIN_TICKET_WIDTH) || 1;
    const rowHeight = this._viewHeight / numRows;
    this.setState({
      orientation: orientation,
      rows: numRows,
      cols: numCols,
      layoutProvider: new GridLayoutProvider(
        numCols,
        index => 1,
        index => 1,
        index => {
          return rowHeight;
          // New Logic:
          if (numRows === 1) return rowHeight;
          const item = this.state.dataProvider._data[index];
          const items = Array.isArray(item) ? item.reduce((acc, i) => acc += i.itemAndModCount, 0) : item.itemAndModCount;
          if (items > 10) return rowHeight * 2
          return rowHeight;
        }
      )
    })
  }

  _ticketStatusChange = (order) => {
    // remove ticket if doesn't pass filter function:
  }

  _showModal = (order) => {
    if (!this._scrolling) this.setState({showModal: true, selectedOrder: order});
    this._scrolling = false;
  }

  _hideModal = () => {
    this.setState({showModal: false});
  }

  _listEmpty = () => {
    let {emptyMsg, showCutePicsWhenEmpty} = this.props;

    return (
      <View style={{margin: 15, flex: 1, alignItems: 'center', justifyContent: 'center'}}>
        <Text style={{fontSize: 30, marginBottom: 15}}>{emptyMsg}</Text>
        {showCutePicsWhenEmpty && (
          <View style={{flex: 1, width: '100%', alignItems: 'center', justifyContent: 'center'}}>
            <CutePic/>
          </View>
        )}
      </View>
    )
  }

  _getRobotButtons = (onRobotOrders) => {
    const buttonsToShow = new Set();
    onRobotOrders.forEach(order => {
      const station = API._stations[order.bartending_station_id];
      const rail = station.rails.find(rail => rail.deliverylocation_set.includes(order.location_id));
      if (!buttonsToShow.has(rail)) rail.numItems = 0;
      rail.numItems += order.items.filter(i => i.status === 'loaded').length;
      buttonsToShow.add(rail);
    });
    return [...buttonsToShow];
  }

  _sendRobot = async (rail) => {
    const railOrderItems = this.state.onRobotOrders.filter(order => rail.deliverylocation_set.includes(order.location_id))
      .map(order => order.items)
      .flat()
      .filter(item => item.status === 'loaded');
    let {processing} = this.state;
    processing[rail.railId] = true;
    this.setState({processing})
    let response = await OrderHelper.changeItemsState(railOrderItems, 'botsent');
    processing[rail.railId] = false;
    this.setState({processing});
  }

}


const IndexDisplay = ({start, end, total}) => {
  if(!end) return null;
  return (
    <View style={styles.indexDisplay}>
      <Text style={styles.indexDisplayText}>Showing {start}-{end} of {total}</Text>
    </View>
  )
}

const RefreshButton = ({upcomingOrders, onPress}) => {
  return (
    <Pressable onPress={onPress} style={[styles.floatingButton, styles.refreshButton]}>
      <Icon name={'refresh'} type={'FontAwesome'} style={styles.fabButtonIcon}/>
      <Text style={styles.refreshButtonText}>REFRESH</Text>
    </Pressable>
  )
}

const SendRobotButton = ({rail, onPress, processing}) => {
  const itemsText = <FormattedPlural value={rail.numItems} other={'items'} zero={'item'} one={'item'}/>
  const pressFn = () => onPress(rail);
  const extraStyle = processing ? {backgroundColor: Colors.gray} : null;
  return (
    <Pressable disabled={processing} onPress={pressFn}>
      <View style={[styles.floatingButton, SendRobotButton.styles.button, extraStyle]}>
        {processing && <Loader/>}
        {!processing && <Icon name={'send'} type={'FontAwesome'} style={styles.fabButtonIcon}/>}
        <Text style={SendRobotButton.styles.text}>
          SEND {rail.numItems} {itemsText}{"\n"}
          {rail.railName}
        </Text>
      </View>
    </Pressable>
  )
}
SendRobotButton.styles = EStyleSheet.create({
  button: {
    backgroundColor: color(Colors.robotButton).alpha(0.8).string(),
  },
  text: {
    color: 'white',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 'bold'
  }
});


const styles = StyleSheet.create({
  indexDisplay: {
    paddingHorizontal: 15,
    backgroundColor: 'rgba(0,0,0,0.2)',
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  indexDisplayText: {
    color: 'white'
  },
  floatingButtons: {
    position: 'absolute',
    bottom: 30,
    right: 15,
    height: 90,
    flexDirection: 'row'
  },
  floatingButton: {
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 15,
    paddingHorizontal: 20,
    height: '100%',
    ...Platform.OS === 'web' ? {boxShadow: '2px 2px 4px rgba(0,0,0,0.2)'} : {elevation: 4}
  },
  refreshButton: {
    backgroundColor: 'rgba(255,0,0,0.8)',
  },
  refreshButtonText: {
    color: 'white',
    fontWeight: 'bold'
  },
  fabButtonIcon: {
    color: 'white',
    fontSize: 26,
    marginBottom: 8
  },
  footerBar: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 5
  }
})
