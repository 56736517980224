/*
  Custom BBOT Text component which applies our desired style to every Text element
 */

import React, { Component } from 'react';
import {Text} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

const BASE_FONT = 'Roboto';
const FONT_SIZE = 14;
let FONT_SCALE = 1;

export const BBText = props => {
  return <Text {...props} style={[styles.myAppText, props.style]}>{props.children}</Text>
}

export const TextS = props => {
  return <Text {...props} style={[styles.textSmall, props.style]}>{props.children}</Text>
}
export const TextM = props => {
  return <Text {...props} style={[styles.textMedium, props.style]}>{props.children}</Text>
}
export const TextL = props => {
  return <Text {...props} style={[styles.textLarge, props.style]}>{props.children}</Text>
}

export class ScalableText extends Component {
   render() {
    const style = this.props.style ? {...this.props.style} : {};
    if(style.fontSize){
      style.fontSize = style.fontSize * FONT_SCALE;
    } else {
      style.fontSize = FONT_SIZE * FONT_SCALE;
    }

    return <Text {...this.props} style={style} >{this.props.children}</Text>
  }
}



const styles = EStyleSheet.create({
  myAppText: {
    fontFamily: BASE_FONT,
    fontSize: 16,
  },
  textSmall: {
    fontFamily: BASE_FONT,
    fontSize: '1rem'
  },
  textMedium: {
    fontFamily: BASE_FONT,
    fontSize: '1.2rem'
  },
  textLarge: {
    fontFamily: BASE_FONT,
    fontSize: '1.5rem'
  }

});
