import Colors from "../constants/Colors";
import {Badge, Button, Icon, Text} from "native-base";
import {ActivityIndicator} from "react-native";
import React from "react";
import Alert from "./Alert";

const IconButton = ({
  color = Colors.light,
  testID,
  badge,
  badgeProps = {primary: true},
  onPress,
  icon,
  iconType = 'Ionicons',
  label,
  processing,
  disabled,
  disabledMsg,
  shown = true,
  style
}) => {
  if (!shown) return null;
  let textColor = (disabled || processing) ? Colors.disabled : color;

  const onBtnPress = () => {
    if(disabled) {
      if(disabledMsg) Alert.alert(disabledMsg);
    } else {
      onPress();
    }
  }

  const getIcon = () => {
    if(React.isValidElement(icon)) return React.cloneElement(icon, {color: textColor});
    return <Icon name={icon} type={iconType} style={{color: textColor}}/>
  }

  return (
    <Button
      testID={testID}
      vertical
      onPress={onBtnPress}
      style={[style, disabled ? {backgroundColor: 'auto'}:null]}
      badge={!!badge}
    >
      { !!badge && !processing && (
        <Badge {...badgeProps}>
          <Text>{ badge }</Text>
        </Badge>
      )}
      { processing ?
        <ActivityIndicator color={color}/> : getIcon()
      }
      {!!label && (<Text style={{textAlign: "center", lineHeight: 15, color: textColor}} numberOfLines={1}>{label}</Text>) }
    </Button>
  )
}

export default IconButton;
