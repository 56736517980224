import EventModel from './EventModel';
import moment from 'moment';
import _ from 'lodash';

import {IconMap} from './SavedCard';

export default class PartyTab extends EventModel {

  /** @type {?string} */
  id = null;
  /** @type {('type' | string)} TODO enumerate the other values type can take */
  type = 'tab';
  tab_name = '';
  seated_group_id = null;
  default_card_id = null;
  /** @type {?moment.Moment} The time of creation of the Party Tab */
  start_date = null;
  /** @type {?moment.Moment} The time the Party Tab was closed */
  end_date = null;
  /** @type {?moment.Moment} The time of the last change to the Party Tab */
  last_modified = null;
  used_cents = 0;
  limit_cents = 0;
  available_cents = 0;
  card_brand = '';
  card_last_4 = '';
  card_exp_month = null;
  card_exp_year = null;
  tab_owner_secret = null;

  locations = [];

  // internal properties:
  _field_map = {
    last_modified: d => moment(d),
    start_date: d => moment(d),
    end_date: d => moment(d)
  }

  _joinURL = null;
  _local = false;

  constructor(obj) {
    super(obj);
    this.update(obj);
  }

  get name() {
    return this.tab_name || ("x" + this.last4);
  }

  get brand() {
    return this.card_brand
  }

  get last4() {
    return this.card_last_4;
  }

  get expiry() {
    return this.card_exp_month + ' / ' + this.card_exp_year;
  }

  get icon() {
    let brand = this.card_brand?.toLowerCase();
    return IconMap.hasOwnProperty(brand) ? IconMap[brand] : IconMap['default'];
  }

  get orders() {
    return _.filter(this.api._orders, order => order.party_tab_ids.includes(this.id));
  }

  /**
   * Returns an object with information on how to charge this payment method.
   * @param {number} amount_cents The total cost to charge this payment method
   * @returns {Charge} The object with information on the current Charge on the Party Tab
   */
  getCharge(amount_cents) {
    return {
      id: this.id,
      tab_id: this.id,
      type: this.type,
      amount_cents: amount_cents
    }
  }

}
