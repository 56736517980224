import React from 'react';
import EStyleSheet from "react-native-extended-stylesheet";
import {Button, Text} from "native-base";
import {Image, View} from "react-native";
import _ from 'lodash';
import {Loader} from "../index";


const FormWrapper = ({onSave, onCancel, disabled, saveButtonText = "Save", children}) => {
  return (
    <View>
      <Loader shown={disabled}/>
      <View style={{backgroundColor: '#f7fafc', padding: 20, width: '100%'}}>
        <Text style={{marginBottom: 10}}>Enter your card</Text>
        <View style={{alignItems: 'center', justifyContent: 'center', marginVertical: 20}}>
          <Image source={require('../../assets/images/StripeCard.png')} style={{width: 168, height: 108}}/>
        </View>
        <View style={{marginBottom: 20}}>
          {children}
        </View>
        <View nativeID={"card-errors"}/>
        <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
          <Button primary onPress={onCancel} style={{marginLeft: 10}}>
            <Text style={{color: '#fff'}}>CANCEL</Text>
          </Button>
          <Button primary onPress={onSave} disabled={disabled} style={{marginLeft: 10}}>
            <Text style={{color: '#fff'}}>{_.upperCase(saveButtonText)}</Text>
          </Button>
        </View>
      </View>
    </View>
  )
}

export default FormWrapper;
