import React from 'react';
import {StyleSheet, View, Text, TouchableWithoutFeedback} from 'react-native';
import Colors from '../constants/Colors';
import PropTypes from 'prop-types';

export default class CmdButton extends React.Component {

  static defaultProps = {
    style: {}
  }

  render(){
    let {disabled, disabledMsg, highlight, hidden, text, onPress, testID} = this.props;
    if(hidden) return null;

    let btnText = text &&
      <Text style={[styles.textStyle, disabled && styles.disabledTxt]}>{text}</Text>;

    return (
        <TouchableWithoutFeedback
            testID={testID}
            disabled={disabled}
            accessible={true}
            accessibilityLabel={text}
            onPress={()=>{if(!disabled && onPress) onPress() }}
        >
          <View style={[styles.buttonContainer, this.props.style, disabled && styles.disabled, !disabled && highlight && styles.highlight]}>
            {this.props.children || btnText}
          </View>
        </TouchableWithoutFeedback>
    )
  }
}

CmdButton.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  highlight: PropTypes.bool,
  onPress: PropTypes.func,
  hidden: PropTypes.bool
}

const styles = StyleSheet.create({
  buttonContainer: {
    backgroundColor: '#f4f4f4',
    borderRadius: 2,
    minWidth: 60,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 7,
    paddingHorizontal: 4,
    height: 35,
  },
  highlight: {
    backgroundColor: Colors.primaryLight,
    borderWidth: 2,
    margin: 5,
    borderColor: Colors.primary
  },
  disabled: {
    backgroundColor: '#b3b3b3',
  },
  textStyle: {
    color: 'black',
    fontSize: 14,
    textAlign: 'center'
  },
  disabledTxt: {
    color: '#464646'
  }
});
