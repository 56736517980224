import {Orientation} from "../../api";
import {View} from "react-native";
import {Text} from "native-base";
import {FormattedCurrency} from "react-native-globalize";
import React from "react";
import EStyleSheet from "react-native-extended-stylesheet";

/**
 * A <Card> is the type of object for each of the subsections on the <TipScreen>. The only two properties are the title
 * to be displayed at the top of the Card, and the children to be rendered just underneath the title.
 *
 * @param props
 * @param {string} props.title The title displayed at the top of the Card
 * @param {React.component[]} props.children The children of this Card
 * @param {Orientation} [props.orientation=Orientation.PORTRAIT] The orientation of the screen, landscape or vertical
 * @returns {JSX.Element}
 * @constructor
 */
const Card = ({title, titleRight, children, orientation = Orientation.PORTRAIT, style = {}, ...rest}) => {
  const landscape = orientation === Orientation.LANDSCAPE;
  return (
    <View style={[styles.card, landscape && styles.landscapeCard, style]} {...rest}>
      {!!title && (
        <View style={styles.cardTitle}>
          <Text style={styles.cardTitleText}>{title}</Text>
          { titleRight }
        </View>
      )}
      {children}
    </View>
  );
}

export default Card;

const styles = EStyleSheet.create({
  card: {
    alignSelf: 'stretch',
    padding: 20,
    paddingHorizontal: '5%',
    borderBottomColor: 'lightgray',
    borderBottomWidth: 8
  },
  landscapeCard: {
    paddingHorizontal: '5%'
  },
  cardTitle: {
    borderBottomColor: '#E6E6E6',
    borderBottomWidth: 0.5,
    paddingBottom: 5,
    marginBottom: 5,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cardTitleText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
})
