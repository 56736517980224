import React, {Component} from 'react';
import {View} from "react-native";
import ConsumerTabList from "./ConsumerTabList";
import {Header, Icon, Input, Item, Text} from "native-base";
import NewTabButton from "./NewTabButton";
import API from "../../api";

class ConsumerTabsView extends Component {

  state = {
    search: '',
    updated: false
  }

  _onSearchTextChange = text => this.setState({search: text});

  render() {
    const {location} = this.props;
    const {search, updated} = this.state;
    const {config} = API;

    const canCreate = config.allow_pay_with_consumer_tab && (
      config.use_stripe_chip_reader || config.allow_manual_card_entry);

    return (
      <View style={{flex: 1}}>
        <View style={{flex: 1, flexBasis: 0}}>
          <Header searchBar style={{backgroundColor: null, alignItems: 'center'}}>
            <Item>
              <Icon name='search'/>
              <Input placeholder="Search" onChangeText={this._onSearchTextChange} value={search}/>
            </Item>
            {canCreate && (
              <View style={{marginLeft: 10}}>
                <Text>
                  <NewTabButton
                    location={location}
                    iconRight
                    style={{height: 40}}
                    buttonContent={
                      <>
                        <Text style={{fontWeight:'bold'}}>NEW TAB</Text>
                        <Icon name={'pluscircleo'} type={'AntDesign'}/>
                      </>
                    }
                    onSuccess={() => this.setState({updated: !updated})}
                  />
                </Text>
              </View>
            )}
          </Header>
          <ConsumerTabList
            search={search}
            location={location}
            updated={updated}
          />
        </View>
      </View>
    );
  }
}

export default ConsumerTabsView;
