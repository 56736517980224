import React, {Component} from 'react';
import {TouchableWithoutFeedback, BackHandler} from 'react-native';
import {Icon, View, Text} from 'native-base';
import {styles as gStyles} from '../styles/Global';
import Colors from '../constants/Colors';
import {TextM, TextS} from "../components/Text";
import API from "../api";
import {QRCode} from "react-native-custom-qr-codes-expo";

/**
 * CaptureSuccessScreen
 *
 * Check Mark Symbol
 * Please pass the device back to your server to finalize your order
 */

export default class CaptureSuccessScreen extends Component {

  static navigationOptions = {
    title: "Thank You",
    headerLeft: () => null
  }


  componentDidMount() {
    this.backHandler = BackHandler.addEventListener("hardwareBackPress", this._onTap );
  }

  componentWillUnmount() {
    this.backHandler.remove();
  }

  _showQR = (joinURL) => {
    return (
      <View style={{justifyContent: 'center', alignItems:'center'}}>
        <Text style={{textTransform:'uppercase', fontWeight: 'bold', marginBottom: 10, fontSize: 20}}>Scan to Join</Text>
        <QRCode content={joinURL}/>
      </View>
    )
  }

  render(){
    const joinURL = this.props.navigation.getParam('joinURL');

    const displayImg = joinURL ?
      this._showQR(joinURL) :
      <Icon type={'AntDesign'} name={'checkcircleo'} style={{fontSize: 120, color: Colors.success}}/>

    return (
      <TouchableWithoutFeedback style={{flex: 1}}
                                accessible={true}
                                accessibilityLabel={'Success'}
                                onPress={ this._onTap }>
        <View style={{flex: 1}}>
          <View style={[gStyles.centered,{flex: 1}]}>
            { displayImg }
            <TextM style={{marginTop: 50, textAlign: 'center'}}>Please pass the device back to your server to finalize your order</TextM>
          </View>
          <View style={[gStyles.centered, {margin: 10}]}>
            <TextS style={{color: Colors.gray}}>(Touch anywhere to continue)</TextS>
          </View>
        </View>
      </TouchableWithoutFeedback>
    )
  }

  _onTap = () => {

    let {navigation} = this.props;

    navigation.getParam('refreshFn')();
    navigation.goBack(navigation.getParam('go_back_key'));

    return true;
  }
}
