import React from "react";
import _ from 'lodash';
import Model from './Model';
import moment from "moment";
import sh from "shorthash";
import {StatusIcons} from "../constants/Constants";
import {FormattedCurrency} from "react-native-globalize";

// todo: refactor this file
export default class OrderItem extends Model {

  constructor(order, obj) {
    super();

    /** @type {?string} */
    this.itemId = obj.itemId || null;
    /** @type {string} The readable name of the item as displayed on the menu */
    this.itemName = obj.itemName || "";
    this.name_when_purchased = obj.name_when_purchased || "";
    /** @type {{id: string, name: string, pretax_cents: number, tax_cents: number}[]} List of modifiers */
    this.mods = obj.mods || [];
    this.order_item_hash = obj.order_item_hash;
    /** @type {string} The UUID of the Order Item */
    this.orderitemid = obj.orderitemid || null;
    /** @type {number} (In cents) The pretax (unsure if post-discount?) cost of the item */
    this.pretax_cents = obj.pretax_cents || 0;
    /** @type {number} (In cents) The pretax (probably pre-discount?) cost of the item */
    this.orig_pretax_cents = obj.orig_pretax_cents || 0;
    /** @type {number} (In cents) The cost of tax applied to this specific order item */
    this.tax_cents = obj.tax_cents || 0;
    /** @type {?number} (In cents) The amount of tip applied to this specific order item */
    this.tip_in_cents = obj.tip_in_cents || null;
    this.refunds_pretax_cents_added = '';
    /** @type {number[]} A list of integer seat numbers that this Order Item belongs to */
    this.seat_numbers = obj.seat_numbers || [];
    /** @type {'' | 'refunded' | 'waiting' | string} TODO enumerate all values status can take */
    this.status = obj.status || "";
    /** @type {string} */
    this.special_instructions = obj.special_instructions || "";
    /** @type {moment.Moment} The moment this order item was last modified. */
    this.time_modified = moment(obj.time_modified || order.time);
    this.qty = obj.qty || 1;
    this.quantity = obj.quantity || 1;
    this.claimed_by = obj.claimed_by;

    this.notified = false;    // whether or not the handheld has been notified of this items status change. Times out after 60 seconds
    this.made = false;        // internally tracked - not from server

    // defining the property this way makes sure it isn't enumerable
    Object.defineProperty(this, 'order', {
      value: order
    });

    this.update(obj);

  }

  update = (obj) => {
    super.update(obj);
    if(obj.status !== this.status) this.notified = false;
    this.status = obj.status;
    this.claimed_by = obj.claimed_by;
    this.time_modified = moment(obj.time_modified || this.order.time);
  }

  claim = () => {

  }

  get orderId() {
    return this.order.orderId;
  }

  get pretty_status(){
    return this.api.menuData?.status_pretty_names?.[this.status] || this.status;
  }

  get menuItemId() {
    return this.itemId
  }

  get menuItem(){
    return this.api.menuData?.menuItemsById[this.itemId];
  }

  // todo remove
  get posttax_cents() {
    return this.pretax_cents + this.tax_cents;
  }

  get total_cents(){
    return this.posttax_cents + this.tip_in_cents;
  }
  set total_cents(val){} // todo: remove this from serializer

  /**
   * @returns {Location} The restaurant location this Order Item will be sent to
   */
  get location(){
    return this.order.location;
  }

  getId() {
    return this.orderitemid;
  }

  getName() {
    return this.name_when_purchased;
  }

  getOrder() {
    return this.order;
  }

  getCreatedTime() {
    return this.order && this.order.time;
  }

  getTotal(formatted) {
    let val = this.status === "refunded" ? 0 : this.posttax_cents + this.tip_in_cents;

    if(formatted) return <FormattedCurrency value={val/100}/>
    return val;
  }

  getPretaxTotal() {
    return this.pretax_cents;
  }

  getTaxTotal() {
    return this.tax_cents;
  }

  getModifiers() {
    return this.mods;
  }

  getHash() {
    let string = this.orderitemid;
    let mods = [...this.mods]; // Don't want to sort the actual modifiers, just a copy for reference
    mods.sort((a, b) => a.name > b.name).map(i => {
      string += "_" + i.name.replace(/\W/g, '');
    });
    return sh.unique(string);
  }

  get status_icon() {
    return StatusIcons['large'][this.status];
  }

}
