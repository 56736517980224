import React, {Component} from 'react';
import {View, Text, TouchableWithoutFeedback, ScrollView} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import {Icon} from 'native-base';
import {SplitItem} from "./SplitOrder/SplitItem";
import {FormattedCurrency} from "react-native-globalize";
import Colors from "../constants/Colors";
import PropTypes from 'prop-types';


export default class CheckView extends Component {

  static propTypes = {
    /** @type {CheckModel} */
    check: PropTypes.object,
    index: PropTypes.number,
    onDrop: PropTypes.func,
    onDelete: PropTypes.func,
    onItemPress: PropTypes.func,
    onCheckout: PropTypes.func,
    /** @type {ItemPart[]} A list of ItemParts that have been selected on the SplitScreen */
    selectedItems: PropTypes.array
  }
  static defaultProps = {
    selectedItems: []
  }

  render() {

    let {page, check, index, onDrop, onDelete, onItemPress, selectedItems, onRemoveItem } = this.props;
    /** @type {number[]} The list of seats spanned by the items in this check */
    let seats = check.getUniqSeats();
    let showDropTarget = !!selectedItems.length && !check.items.includes(selectedItems[0]) && !check.charge;

    return (
      <View testID={`checkView-${page}-${index}`} style={{flex: 1}}>
        {
          showDropTarget &&
          <TouchableWithoutFeedback testID={`checkViewDropTarget-${index}`} onPress={() => {
            onDrop(check)
          }}>
            <View style={styles.dropTarget}>
              <Text style={styles.dropTargetText}>Move Here</Text>
            </View>
          </TouchableWithoutFeedback>
        }
        <View style={{flex: 1}}>
          <View style={styles.cellHeader}>
            <View style={{flexDirection: 'row', flex: 1}}>
              <Text style={styles.headerText}>Check {index + 1}</Text>
              {!!seats.length && (<Text style={styles.headerText}>, Guest{seats.length > 1 ? 's' : ''} {seats.join(', ')}</Text>)}
            </View>
            {
              !check.items.length && (
                <TouchableWithoutFeedback testID={`checkViewDelete-${index}`} onPress={() => {
                  onDelete(check)
                }}>
                  <View style={styles.closeBtn}>
                    <Icon type={"Ionicons"} style={{fontSize: 18}} name="md-close"/>
                  </View>
                </TouchableWithoutFeedback>
              )
            }
          </View>
          <View style={styles.cellBody}>
            <ScrollView style={{flex: 1}}>
              {
                check.items.map((itemPart, itemIndex) => {
                  return (
                    <SplitItem
                      testID={`checkView-${page}-${index}-${itemIndex}`}
                      selected={selectedItems.includes(itemPart)}
                      key={itemPart.id}
                      itemPart={itemPart}
                      onPress={() => {
                        if (check.charge) return;
                        onItemPress(itemPart);
                      }}
                      onRemoveItem={onRemoveItem}
                    />
                  )
                })
              }
            </ScrollView>
          </View>
          <View testID={`checkViewCheckoutButton-${page}-${index}`} style={styles.cellFooter}>
            {
              this._getCheckButton(check)
            }
          </View>
        </View>
      </View>
    )
  }

  /**
   * Returns the <CheckButton> component to place at the bottom of the CheckView displaying the given check. The button
   * is disabled if there is an error with the CheckModel.
   * @param {CheckModel} check - Information on the Check to determine the properties of the CheckButton
   * @returns {JSX.Element} The <CheckButton> instance for this CheckModel
   * @private
   */
  _getCheckButton(check) {
    let {onCheckout} = this.props;
    if (check.charge === null) {
      return <CheckButton
        onPress={() => {
          onCheckout(check)
        }}
        text={["Checkout - ", <FormattedCurrency key={1} value={check.pretax_total / 100}/>]}
        disabled={!check.items.length || !check.isValid()}
      />
    } else {
      let diff = null;
      if(check.charge.orig_cents){
        let change = (check.charge.amount_cents - check.charge.orig_cents)/100;
        diff = <Text key={2}> (<FormattedCurrency value={change}/>)</Text>;
      }
      return (
        <CheckButton
          style={{backgroundColor: Colors.ternary}}
          onPress={() => {
            onCheckout(check)
          }}
          text={[
            "Approved - ",
            <FormattedCurrency key={1} value={check.tip_total / 100}/>,
            diff
          ]}
        />
      )
    }
  }

}

/**
 * The <CheckButton> is the button at the bottom of each CheckView in the Split Screen.
 * @param props
 * @param {boolean} props.disabled - If true, the CheckButton at the bottom of the CheckView is disabled. False otherwise
 * @param {function} props.onPress - The callback function for when the CheckButton is clicked
 * @param {string} props.text - The text to display inside of the CheckButton
 * @param {Object | Object[]} props.style - The object defining the style properties of this button
 * @returns {JSX.Element}
 * @constructor
 */
const CheckButton = (props) => {
  let {disabled, onPress, text, style, testID} = props;
  return (
    <TouchableWithoutFeedback testID={testID} onPress={() => {
      if(!disabled && onPress) onPress();
    }}>
      <View style={[styles.checkoutButton, style, disabled ? styles.disabledButton : null]}>
        <Text style={styles.checkoutButtonText}>{text}</Text>
      </View>
    </TouchableWithoutFeedback>
  )
}


const styles = EStyleSheet.create({

  cellHeader: {
    backgroundColor: Colors.primary,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 5,
    borderBottomWidth: 1,
    borderBottomColor: Colors.primaryLight,
    minHeight: 31
  },
  headerText: {
    color: Colors.light
  },
  cellBody: {
    flex: 1
  },
  dropTarget: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 100,
    backgroundColor: 'rgba(0,0,0,0.1)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dropTargetText: {
    color: 'white',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textShadowColor: 'rgba(0,0,0,0.75)',
    textShadowOffset: {width: 1, height: 1},
    textShadowRadius: 5
  },
  checkoutButton: {
    padding: 10,
    backgroundColor: Colors.primary,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  checkoutButtonText: {
    fontWeight: 'bold',
    color: Colors.light
  },
  disabledButton: {
    backgroundColor: Colors.gray
  },
  closeBtn: {
    height: 20,
    width: 20,
    borderWidth: 1,
    backgroundColor: Colors.light,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'black'
  }

});
