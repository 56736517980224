import {Header} from "native-base";
import PropTypes from "prop-types";
import React, {Component} from "react";
import {Text, TouchableOpacity, TouchableWithoutFeedback, View, Modal} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import LocationFilter from "./LocationFilter";
import {Colors} from "../styles";

export default class LocationFilterModal extends Component {

  static propTypes = {
    headerTitle: PropTypes.string,
    locations: PropTypes.arrayOf(PropTypes.string).isRequired, // Location.AllLocationIDs()
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    style: PropTypes.any,
    visible: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onCancel: () => {},
    onSave: () => {},
    visible: true,
  }

  state = {
    locations: [],
  }

  constructor(props) {
    super(props);
    this.state.locations = props.locations;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.locations !== this.props.locations){
      this.setState({
        locations: this.props.locations
      })
    }
  }

  render() {
    let {headerTitle, visible} = this.props;

    return (
      <Modal visible={visible} transparent={true} onRequestClose={this.props.onCancel}>
        {/* Allow modal to be closed by pressing backdrop. */}
        <TouchableOpacity style={styles.modal} activeOpacity={1} onPress={this.props.onCancel}>
          <View style={styles.container}>
            <TouchableWithoutFeedback>
              <Header style={{backgroundColor: 'black', margin: 5}}>
                <Text style={styles.headerTitle}>
                  {headerTitle}
                </Text>
              </Header>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback style={styles.body}>
              <LocationFilter
                locations={this.state.locations}
                filterLocations={this._filterLocations}
              />
            </TouchableWithoutFeedback>
            <View style={styles.footer}>
              <TouchableWithoutFeedback onPress={this.props.onCancel} testID={'locationsFilterModelCancel'}>
                <View style={[styles.footerButton, {backgroundColor: Colors.gray}]}>
                  <Text>Cancel</Text>
                </View>
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback onPress={this._onSave} testID={'locationsFilterModalApplyFilters'}>
                <View style={[styles.footerButton, {backgroundColor: Colors.primary}]}>
                  <Text style={styles.selectLocation}>Apply Filters</Text>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </View>
        </TouchableOpacity>
      </Modal>
    );
  }

  _filterLocations = (locations) => {
    this.setState({
      locations
    })
  }

  _onSave = () => {
    let {onSave} = this.props;
    let {locations} = this.state;
    onSave(locations);
  }
}

const styles = EStyleSheet.create(
  {
    modal: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,0.5)'
    },
    container: {
      backgroundColor: 'white',
      borderWidth: 0.1,
      borderRadius: 5,
      flex: 1,
      width: '80%',
      height: '80%',
      maxHeight: '80%',
      overflow: 'hidden'
    },
    flatListContainer: {
      flex: 1,
      margin: 10,
      marginBottom: 70
    },
    row: {
      flex: 1,
    },
    header: {
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
      paddingTop: 10,
      borderBottomWidth: 0.5,
      backgroundColor: Colors.white
    },
    headerText: {
      fontWeight: "bold",
      fontSize: 15,
      color: Colors.light
    },
    loader: {
      margin: 'auto'
    },
    scrollView: {
      width: '100%',
      height: '100%',
    },
    contentContainer: {
      width: '100%',
    },
    flatList: {
      marginBottom: 50,
      padding: 5,
      flex: 1,
      width: '100%',
    },
    locationListItemCont: {
      padding: 5,
    },
    locationListItem: {
      height: 100,
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 0.5,
      borderRadius: 10,
      borderColor: Colors.lightGray
    },
    body: {
      flex: 1,
    },
    footer: {
      flexDirection: 'row',
      bottom: 0,
      width: '100%',
    },
    footerButton: {
      height: 50,
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1
    },
    notSelected: {
      backgroundColor: Colors.lightGray
    },
    selected: {
      backgroundColor: '#9ebcea'
    },
    notSelectedText: {
      color: Colors.darkGray
    },
    selectedText: {
      fontWeight: "500",
      color: "black"
    },
    selectLocation: {
      color: "white",
      fontWeight: '700'
    },
    headerTitle: {
      margin: 'auto',
      color: 'white',
      fontSize: 18,
      fontWeight: '500',
    }
  }
)
