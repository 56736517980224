import React from "react";
import {ScrollView, Text, View} from "react-native";
import EStyleSheet from 'react-native-extended-stylesheet';
import PropTypes from 'prop-types';
import MenuItem from './MenuItem';
import {BBText} from "./Text";
import uuid from 'uuid';

export default class ItemViewer extends React.Component {

  // Todo: this is lazy, should try to make it smarter:
  // See ItemBuilder for how to scroll to a specific item
  componentDidUpdate(prevProps, prevState, snapshot) {
    this._scrollView.scrollToEnd();
  }

  render() {
    return (
      <View testID={'itemViewer'} style={this.props.style}>
        <View style={styles.menuItemsHeader}>
          <Text style={[styles.headerText, {flex: 1}]}>NAME</Text>
          <Text style={[styles.headerText, {width:'10%', textAlign:'right'}]} >QTY</Text>
          <Text style={[styles.headerText, {width:'18%', textAlign:'right'}]}>EACH</Text>
          <Text style={[styles.headerText, {width:'18%', textAlign:'right'}]}>TOTAL</Text>
        </View>
        <View style={{flex:1}}>
          <ScrollView
            ref={sv => { this._scrollView = sv}}>
            <View testID={'itemViewerItems'} style={styles.locationItems}>
              {this._getItems()}
            </View>
          </ScrollView>
        </View>
      </View>
    )
  }

  _getItems() {
    let {items, onItemPress} = this.props;
    if (items.length) {
      return items.map(item => {
        return (
          <MenuItem
            key={"item_" + uuid.v4()}
            selected={this.isItemSelected(item)}
            item={item}
            onPress={() => {
             onItemPress(item)
            }}
            current={this.props.current}
          />
        )
      });
    } else {
      return <View testID={'itemViewerEmptyOrderMessage'} style={styles.noItems}><BBText>Add some items</BBText></View>;
    }
  }

  isItemSelected(item){
    return this.props.selectedItems.findIndex(i => i && i.getId() === item.getId()) >= 0;
  }
}

ItemViewer.propTypes = {
  items: PropTypes.array,
  numGuests: PropTypes.number,
  onItemPress: PropTypes.func
};
ItemViewer.defaultProps = {
  numGuests: 1,
  onItemPress: () => {}
};

let styles = EStyleSheet.create({
  menuItemsHeader: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    borderBottomColor: '#bebebe',
    borderBottomWidth: 2,
    padding: 5
  },
  headerText: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  locationItems: {},
  noItems: {
    padding: 10,
    backgroundColor: '#ece9f0'
  },
});
