import React from 'react';
import {View, ActivityIndicator, StyleSheet, Platform} from 'react-native';
import API from '../api';
import _ from 'lodash';
import OrderCreator from "./OrderCreator";
import HeaderIconButton from "../components/HeaderIconButton";
import { Colors } from "../styles";
import {Footer, FooterTab, Button, Text, Icon, Badge } from "native-base";

// Different browsing tabs for location overview page
import OrdersView from "../components/Order/OrdersView";
import ConsumerTabsView from "../components/ConsumerTab/ConsumerTabsView";
import SmartOrdersView from "../components/SmartOrders/SmartOrdersView";

/**
 *
 * ONLY COMBINE ITEMS IF IN SAME ORDER?
 * This Screen is really slow and needs optimization
 */

export default class LocationOverview extends React.Component {

  static navigationOptions = ({navigation}) => {
    let location = navigation.getParam('location');
    let handlePress = navigation.getParam('handleGoToOrderCreator');
    const {config} = API;

    return {
      title: location.locationName,
      headerRight: () => (config.show_create_order ? (
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <ActivityIndicator animating={!location.fulfillable_items} color={Colors.primary}/>
          <HeaderIconButton
            type={"FontAwesome5"}
            name={(location.order_allowed !== "off" ? "Block":"Allow")+" Ordering"}
            icon={location.order_allowed !== "off" ? "lock":"unlock"}
            onPress={()=>{
              API.setLocationOrderAllowed({}, [location], location.order_allowed !== 'on').then(res => {
                navigation.setParams({location});
              })
            }}
          />
          <HeaderIconButton
            testID={'locationHeaderIconButtonNewOrder'}
            type={"MaterialCommunityIcons"}
            name={"New Order"}
            onPress={handlePress}
            disabled={!location.fulfillable_items}
            icon={"food"}/>
        </View>
      ) : null)
    }
  }


  constructor(props) {
    super(props);

    let location = props.navigation.getParam('location');

    this.state = {
      location: location,
      orders: location.orders,
      smart_orders: location.smart_orders.filter( (so) => so.incart?.length > 0 ),
      old_orders: location.order_history,
      open_tabs: location.open_tabs,
      items: [],
      menuData: API.getMenu(),
      breadcrumbs: [],
      numGuests: 0,
      showNumGuestsChooser: false,
      currentTab: "current-orders"
    };
    props.navigation.setParams({
      handleGoToOrderCreator: this._goToOrderCreator
    });
  }

  componentDidMount() {
    this._mounted = true;

    this._updateLocationDetails();

    let debouncedRefresh = _.debounce(this.refresh, 50);
    API.on('orders', debouncedRefresh, 'LocationOverview');
    API.on('smart_orders', debouncedRefresh);
    API.on('tabs', debouncedRefresh);
    API.on('seated_groups', debouncedRefresh);
  }

  componentWillUnmount() {
    this._mounted = false;
    clearTimeout(this._timeout);
  }

  _updateLocationDetails = async () => {
    let {location} = this.state;
    try {
      await API.getLocationDetails(location);
      this.refresh();
    } catch(err){ }
    // setup next run:
    if(this._mounted) this._timeout = setTimeout(this._updateLocationDetails, 10000);
    if(module.hot){ module.hot.dispose(() => clearTimeout(this._timeout)); }
  }

  // Current Orders Specific
  refresh = () => {
    if (!this._mounted) return;
    let { location } = this.state
    this.setState({
      orders: location.orders,
      old_orders: location.order_history,
      smart_orders: location.smart_orders.filter( (so) => so.incart?.length > 0 ),
      open_tabs: location.open_tabs,
      loading: false
    });
  }

  changePageTab = (key) => {
    this.setState({
      currentTab: key,
    })
  }

  render() {
    const { currentTab, old_orders, smart_orders, orders, open_tabs, location } = this.state;

    return (
      <View style={{ flex: 1 }} testID={`locationOverview-${location.id}`}>
        <Footer style={{ backgroundColor: "#000" }}>
          <FooterTab>
            <Button
              vertical
              testID={'locationsOverviewCurrentOrdersButton'}
              badge={ orders?.length > 0 }
              active={ currentTab === "current-orders" }
              onPress={() => this.changePageTab("current-orders")}
              style={[ styles.pageTab, (currentTab === "current-orders" ? styles.activeTab : {})]}
            >
              {
                orders?.length > 0 && (
                  <Badge primary>
                    <Text>{ orders.length }</Text>
                  </Badge>
              )}
              <Icon type={"Entypo"} name={"list"} style={{ color: Colors.white }}/>
              <Text style={{ color: Colors.white, textAlign: "center" }}>Current Orders</Text>
            </Button>
            <Button
              vertical
              testID={'locationsOverviewOrderHistoryButton'}
              active={ currentTab === "order-history" }
              onPress={() => this.changePageTab("order-history")}
              style={[ styles.pageTab, (currentTab === "order-history" ? styles.activeTab : {})]}>
              <Icon type={"MaterialIcons"} name={"history"} style={{ color: Colors.white }} />
              <Text style={{ color: Colors.white, textAlign: "center" }}>Order History</Text>
            </Button>
            { location.allow_consumer_tabs && (
              <Button
                vertical
                testID={'locationsOverviewConsumerTabsButton'}
                badge={open_tabs.length > 0}
                active={currentTab === "tabs"}
                onPress={() => this.changePageTab("tabs")}
                style={[styles.pageTab, (currentTab === "tabs" ? styles.activeTab : {})]}
              >
                {
                  open_tabs?.length > 0 && (
                    <Badge primary>
                      <Text>{open_tabs.length}</Text>
                    </Badge>
                  )}
                <Icon type={"MaterialCommunityIcons"} name={"cash-multiple"} style={{color: Colors.white}}/>
                <Text style={{color: Colors.white, textAlign: "center"}}>Tabs</Text>
              </Button>
            )}
            <Button vertical testID={'locationsOverviewOrderPreviewsButton'} badge={ smart_orders?.length > 0 } active={ currentTab === "smart-orders" } onPress={() => this.changePageTab("smart-orders")} style={[ styles.pageTab, (currentTab === "smart-orders" ? styles.activeTab : {})]}>
              {
                smart_orders?.length > 0 && (
                  <Badge primary>
                    <Text>{smart_orders.length}</Text>
                  </Badge>
              )}
              <Icon type={"AntDesign"} name={"shoppingcart"} style={{ color: Colors.white }} />
              <Text style={{ color: Colors.white, textAlign: "center" }}>Order Previews</Text>
            </Button>
          </FooterTab>
        </Footer>
        <View style={{flex: 1}}>
          { currentTab === "current-orders" &&
            <OrdersView
              testID={'locationOverviewCurrentOrdersView'}
              location={location}
              navigation={this.props.navigation}
              orders={orders}
              refresh={this.refresh}
            />
          }

          { currentTab === "order-history" &&
            <OrdersView
              testID={'locationOverviewOrderHistoryView'}
              location={location}
              navigation={this.props.navigation}
              orders={old_orders}
              refresh={this.refresh}
            />
          }

          { currentTab === "tabs" &&
            <ConsumerTabsView
              testID={'locationOverviewTabsView'}
              location={location}
              refresh={this.refresh}
            />
          }

          { currentTab === "smart-orders" &&
            <SmartOrdersView
              testID={'locationOverviewOrderPreviewsView'}
              location={location}
              smart_orders={smart_orders}
            />
          }
        </View>
      </View>
    )
  }

  _goToOrderCreator = () => {
    let {navigation} = this.props;
    this.props.navigation.navigate("OrderCreator", {
      location: this.state.location,
      location_overview_key: navigation.state.key,
      refreshFn: this._showLoading
    });
  }

  _showLoading = () => {
    this.setState({
      loading: true
    })
  }
}

const styles = StyleSheet.create({
  pageTab: {
    backgroundColor: "#000",
    height: "100%",
    borderRadius: 0
  },
  activeTab: {
    backgroundColor: "#555",
  },
})
