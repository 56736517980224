import {View} from "react-native";
import {TextM} from "../../components";
import FuzzyField from "../../components/FuzzyField";
import {FormattedCurrency} from "react-native-globalize";
import {Text} from "native-base";
import Colors from "../../constants/Colors";
import MenuItem from "../../components/MenuItem";
import React from "react";
import EStyleSheet from "react-native-extended-stylesheet";

/**
 * The <ReceiptItem> is a single row with information on a receipt item listed in the <CartItemCard>. This includes the
 * fraction of this item, the name and modifiers, and the price of this individual item.
 *
 * @param {Object} props
 * @param {ItemPart} props.item - Information about the specific item to render within this ReceiptItem line
 * @param {boolean} props.blurPrice - If true, blurs the price of the Receipt Item
 * @returns {JSX.Element}
 * @constructor
 */
const CheckoutCartItem = ({item, blurPrice, ...rest}) => {
  const fulfillable = item.item.is_fulfillable;
  return (
    <View testID={'tipScreenReceiptItem'} style={styles.receiptItem} {...rest}>
      <View style={{
        flexDirection: 'row',
        width: '100%',
        marginBottom: 5,
        marginTop: 5
      }}>
        {/* The fraction to denote how many times this receipt item has been split */}
        <TextM testID={'tipScreenReceiptItemFraction'}
               style={[{width: '10%', fontSize: 14}, styles.bold]}>{item.getFraction()}</TextM>
        {/* The name of the item */}
        <TextM testID={'tipScreenReceiptItemTitle'}
               style={[{flex: 1, fontSize: 14}, !fulfillable && styles.unfulfillableItem, styles.bold]}>
          {item.label}
        </TextM>
        <View testID={'tipScreenReceiptItemCost'} style={{alignItems: 'flex-end'}}>
          <FuzzyField fuzzy={blurPrice}>
            <FormattedCurrency style={[{fontSize: 14}, styles.bold]} value={item.pretax_total / 100}/>
          </FuzzyField>
        </View>
      </View>
      {/* List any modifiers for the current item */}
      <View testID={'tipScreenReceiptItemModifiers'} style={styles.itemModifiers}>
        {!fulfillable && (
          <Text style={{color: Colors.error}}>This item is no longer available</Text>
        )}
        {MenuItem.getModifiers(item.item, null, item)}
        {MenuItem.getSpecialInstructions(item.item)}
      </View>
    </View>
  );
}

export default CheckoutCartItem;

const styles = EStyleSheet.create({
  receiptItem: {
    borderBottomColor: '#E6E6E6',
    borderBottomWidth: 1,
    paddingBottom: 5,
    marginBottom: 5
  },
  bold: {
    fontWeight: 'bold'
  },
  unfulfillableItem: {
    textDecorationLine: 'line-through'
  },
  itemModifiers: {
    marginLeft: '10%'
  }
})
