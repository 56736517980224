import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container, Content, List, ListItem, Text, Icon, Right, Left, Body} from "native-base";
import Input from "../native-base-theme/components/Input";

const SERVERS = [
  {
    name: 'Staging',
    ip: 'https://staging.bbot.menu/'
  },
  {
    name: 'Dev',
    ip: 'https://dev.bbot.menu/'
  },
  {
    name: 'Demo',
    ip: 'https://demo.bbot.menu/'
  },
  {
    name: 'Custom',
    ip: null
  }
]

export default class ServerSelection extends Component {

  static propTypes = {}

  render() {
    return (
      <Container>
        <Content>
          <List>
            { SERVERS.map(server => (
              <ListItem>
                <Text>{server.name}</Text>
                { !server.name && (
                  <Input/>
                )}
              </ListItem>
            ))}
          </List>
        </Content>
      </Container>
    );
  }
}
