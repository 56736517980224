import {createStackNavigator} from "react-navigation-stack";
import OrderControlScreen from "../screens/OrderControlScreen";
import TabBarIcon from "../components/TabBarIcon";
import {defaultNavigationOptions} from "./Defaults";
import React from "react";

export const OrderControlStack = createStackNavigator(
  {
    OrderControlScreen
  },
  {
    navigationOptions: ({navigation}) => {
      return {
        tabBarLabel: 'Order Control',
        tabBarTestID: 'ordercontrol',
        tabBarIcon: (props) => (
          <TabBarIcon
            {...props}
            name="switch"
            type="Entypo"
          />
        )

      }
    },
    defaultNavigationOptions: defaultNavigationOptions
  }
);
