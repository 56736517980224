import API from "../api";
import {orderBy} from 'lodash';
import React, {Component} from 'react';
import {View,  FlatList} from 'react-native';
import { ListItem, Body, Text, Right, Icon} from 'native-base';
import Loader from "../components/Loader";
import HeaderIconButton from "../components/HeaderIconButton";
import * as Analytics from "expo-firebase-analytics";
import {showMessage} from "react-native-flash-message";

export default class ConfigChooser extends Component {

  static navigationOptions = ({navigation}) => {
    let onRefresh = navigation.getParam('onRefresh');
    let onLogout = navigation.getParam('onLogout');
    return {
      title: 'Choose Device Profile',
      headerRight: () => (
        <View style={{flexDirection: 'row'}}>
          <HeaderIconButton name={'Refresh'} icon='refresh' onPress={onRefresh} />
          <HeaderIconButton name={"Logout"} icon={'exit'} onPress={onLogout}/>
        </View>
      )
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      configs: []
    }

    props.navigation.setParams({
      onRefresh: this.getConfigs,
      onLogout: this._logout
    })
  }

  componentDidMount() {
    this.getConfigs();
  }

  getConfigs = () => {
    this.setState({
      loading: true,
      configs: []
    });

    API.getAvailableConfigs().then(data => {
      this.setState({
        loading: false,
        configs: orderBy(data, 'config_name')
      })
    });
  }

  render() {
    return (
      <View style={{flex: 1}}>
        {this.state.loading && <Loader/>}
        <FlatList
          ListEmptyComponent={this._emptyList}
          data={this.state.configs}
          keyExtractor={config => config.id}
          renderItem={this._renderItem}
        />
      </View>
    )
  }

  _emptyList = () => {
    let msg = this.state.loading ? "Loading..." : "No Configs Found. Please contact support.";
    return (
      <ListItem>
        <Body>
          <Text>{msg}</Text>
        </Body>
      </ListItem>
    )
  }

  _renderItem = ({item}) => {
    return (
      <ListItem accessibilityLabel={item.config_name} onPress={()=>this._setConfig(item)}>
        <Body>
          <Text>{item.config_name}</Text>
          <Text note numberOfLines={1}>{item.description}</Text>
        </Body>
        <Right>
          <Icon name={"arrow-forward"}/>
        </Right>
      </ListItem>
    )
  }

  _setConfig(config) {
    let {navigation} = this.props;
    let forwardTo = navigation.getParam('forwardTo', null);

    this.setState({
      loading: true
    });

    let currConfig = API.config?.config_name;

    API.setConfig(config.id).then(response => {
      if(response.error){
        showMessage({
           floating: true, position: 'top', type: 'error',
           message: response.error
        })
      } else {
        Analytics.logEvent("DeviceProfileChanged", {
          screen: 'ConfigChooser',
          user: API.currUser?.user?.email,
          from: currConfig,
          to: config.config_name
        }).then(() => {
        });
        navigation.navigate("AuthLoading", {forwardTo: forwardTo});
      }
    })
  }

  _logout = () => {
    API.logout().then(() => {
        this.props.navigation.navigate("Login")
      }
    )
  }
}
