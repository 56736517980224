import React, {Component} from 'react';
import {View, TouchableWithoutFeedback, Text} from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import Colors from "../constants/Colors";


export default class Segment extends Component {

  /**
   * https://frontarm.com/james-k-nelson/passing-data-props-children/
   * Uses this technique to automatically add first and last flags to children
   */

  render() {
    let {style, children, ...props} = this.props;

    let elements = React.Children.toArray(children);

    elements[0] = React.cloneElement(elements[0], { first: true });
    elements[elements.length-1] = React.cloneElement(elements[elements.length-1], { last:true });

    return (
      <View style={[styles.segment, styles.centered, style]} {...props}>
        {elements}
      </View>
    )
  }
}

export const SegmentButton = ({display, testID, note, value, selected, onPress, style, textStyle, ...props}) => {
  let isFirst = props.hasOwnProperty('first');
  let isLast = props.hasOwnProperty('last');
  const noteEl = note ? (
   <Text note style={[selected ? styles.segmentButtonTextSelected:null]}>{note}</Text>
  ) : null;

  return (
    <TouchableWithoutFeedback testID={testID} onPress={() => { onPress(value) }}>
      <View
        style={[
          styles.segmentButton,
          selected && styles.selected,
          isFirst && styles.segmentButtonFirst,
          isLast && styles.segmentButtonLast,
          style
        ]}>
        <Text numberOfLines={1} style={[styles.segmentButtonText, selected ? styles.segmentButtonTextSelected : null, textStyle]}>{display}</Text>
        { noteEl }
      </View>
    </TouchableWithoutFeedback>
  )
}


const styles = EStyleSheet.create({
  segment: {
    flexDirection: 'row',
  },
  centered: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  segmentButton: {
    flex: 1,
    padding: 10,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colors.primary,
    alignItems: 'center',
    justifyContent: 'center'
  },
  segmentButtonFirst: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5
  },
  segmentButtonLast: {
    borderRightWidth: 1,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  segmentButtonText: {
    fontSize: 20,
    color: Colors.dark
  },
  segmentButtonTextSelected: {
    color: Colors.light
  },
  selected: {
    backgroundColor: Colors.primary
  },
});
