import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {View, Text} from 'react-native';
import {FormattedCurrency} from "react-native-globalize";
import {ItemPart} from "../models";

export default class CartItemModifiers extends Component {

  static propTypes = {}

  render() {
    let {item, itemPart, indent, current} = this.props;
    let groupIds = Object.keys(item.mods);
    if (!groupIds.length) return null;
    const partIndex = item._parts?.indexOf(itemPart);
    const partWeights = item._parts?.map(part => part.numerator / part.denominator);

    return (
      <View style={{marginLeft: indent ? 10 : 0}}>
        {
          groupIds.map(groupId => {
            let mods = item.mods[groupId];
            return (
              <View key={groupId}>
                {
                  mods.map(mod => {
                    if (mod.selected) {
                      const modParts = itemPart ? ItemPart.distributeByWeights(mod.pretax_cents * item.qty, partWeights) : [mod.pretax_cents];
                      const modPrice = itemPart ? modParts?.[partIndex] : mod.pretax_cents;

                      return (<View key={mod.id}>
                        <View style={{flexDirection: 'row'}}>
                          <Text
                            style={[mod && mod === this.props.current && {textDecorationLine: "underline"}, mod?.errors?.length && {color: "red"}]}>{mod.getName()}</Text>
                          {mod.pretax_cents > 0 &&
                          <Text style={{marginLeft: 10}}>[+<FormattedCurrency value={modPrice / 100}/>]</Text>}
                        </View>
                        <CartItemModifiers current={current} item={mod} indent={true}/>
                      </View>);
                    } else {
                      return null
                    }
                  })
                }
              </View>
            )
          })
        }
      </View>
    );
  }
}
