import React from 'react';
import {Icon} from "native-base";

export const defaultNavigationOptions = {
  headerStyle: {
    backgroundColor: '#000000'
  },
  headerTitleStyle: {
    color: '#fff'
  },
  headerTintColor: '#ccc',

  // Fixes issue with back arrow disappearing on iOS:
  headerBackImage: () => <Icon name={'arrow-back'} type={"MaterialIcons"} style={{color: '#ccc'}}/>
}
