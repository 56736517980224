import React from 'react';
import {Image, Text, TouchableWithoutFeedback, View} from "react-native";
import {Icon} from 'native-base';
import EStyleSheet from "react-native-extended-stylesheet";
import Colors from "../constants/Colors";
import {formatCardExpirationDate} from "../helpers/HelperFunctions";
import {SavedCard, PartyTab} from "../models";
import ServerSelection from "../screens/ServerSelection";

//import {FormattedCurrency} from "react-native-globalize";

export const CardView = (props) => {
  let {card, selected, onPress, testID} = props;
  if (!(card instanceof SavedCard)) card = new SavedCard(card);

  return (
    <View style={[styles.cardContainer, selected ? styles.cardSelected : null]}>
      <Image style={styles.cardIcon} source={card.icon}/>
      <Text style={styles.cardNameOnCard}>{card.name_on_card || "(Unknown)"}</Text>

      <View style={{alignItems: 'flex-end'}}>
        <View style={{flexDirection: 'row'}}>
          <Text style={{fontSize: 14, color: Colors.darkGray, marginRight: 5}}>x</Text>
          <Text style={styles.cardNumber}>{card.last4}</Text>
        </View>
        <Text style={styles.cardExpiry}>{formatCardExpirationDate(card.exp_month, card.exp_year)}</Text>
      </View>

      {!!onPress && (
        <SelectButton card={card} onPress={onPress} testID={testID}/>
      )}
    </View>
  )
};

export const TabView = (props) => {
  let {tab, selected, onPress, testID} = props;
  if (!(tab instanceof PartyTab)) tab = new PartyTab(tab);

  return (
    <View style={[styles.cardContainer, selected ? styles.cardSelected : null]}>
      <Image style={styles.cardIcon} source={tab.icon}/>
      <Text style={styles.cardNameOnCard}>{tab.name}</Text>

      <View style={{alignItems: 'flex-end'}}>
        <View style={{flexDirection: 'row'}}>
          <Text style={{fontSize: 14, color: Colors.darkGray, marginRight: 5}}>x</Text>
          <Text style={styles.cardNumber}>{tab.last4}</Text>
        </View>
        <Text style={styles.cardExpiry}>{formatCardExpirationDate(tab.card_exp_month, tab.card_exp_year)}</Text>
      </View>
      {!!onPress && (
        <SelectButton card={tab} onPress={onPress} testID={testID}/>
      )}
    </View>

  )
};

const SelectButton = ({card, onPress, testID}) => {
  return (
    <View style={{marginLeft: 10}}>
      <TouchableWithoutFeedback testID={testID} onPress={() => {
        if (onPress) onPress(card)
      }}>
        <Icon
          name={'arrow-with-circle-right'}
          type={'Entypo'}
          style={{color:Colors.success}}
        />
      </TouchableWithoutFeedback>
    </View>
  )
}

export const styles = EStyleSheet.create({
  cardContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 5,
    marginBottom: 5,
    padding: 10,
    minHeight: '1.5rem'
  },
  cardRadio: {
    fontSize: 20,
    marginRight: 10
  },
  cardNumber: {
    fontSize: 14
  },
  cardNameOnCard: {
    fontSize: 16,
    fontFamily: 'space-mono',
    flex: 1,
  },
  cardExpiry: {
    minWidth: 65,
    fontSize: 12,
    textAlign: 'right'
  },
  cardIcon: {
    width: 24,
    height: 24,
    aspectRatio: 1,
    marginRight: 10
  },
  cardSelected: {
    backgroundColor: Colors.primary
  }
});
