import _ from 'lodash';
import Model from './Model';
import Menu from './Menu';
import MenuItem from './MenuItem';
import ModifierGroup from "./ModifierGroup";

export default class MenuData extends Model {

  modifiersById = {};
  modifierGroupsById = {};
  menus = [];
  menuItemsById = {};
  customer = null;
  fulfillment_pretty_names = {};
  status_sequences = {};
  status_pretty_names = {};
  closed_statuses_for_station = [];
  closed_statuses_for_runner = [];
  headingsById = {};

  closed_statuses = ['refunded', 'delivered', 'notified', 'humandelivered', 'with_driver_closed', 'makingclosed', 'runnerdelivering'];
  bot_protected_statuses = ['pleasesend','botsent', 'botattable'];

  _field_map = {
    customer: customer => new MenuCustomer(customer),
    modifierGroupsById: groups => _.mapValues(groups, (mg, id) => new ModifierGroup(this, Object.assign(mg, {id}))),
    menuItemsById: menuItems => _.mapValues(menuItems, (item, itemId) => new MenuItem(this, Object.assign(item, {menuItemId: itemId}))),
    menus: menus => menus.map(menu => new Menu(this, menu))
  }

  constructor(data){
    super();
    this.update(data);
  }

  update(data){
    super.update(data);

    this.buildItemMap();
  }

  buildItemMap(){
    this.menus.forEach((menu, i) => {
      menu.headings.forEach((heading, j) => {
        if(!this.headingsById[heading.id]) this.headingsById[heading.id] = [];
        this.headingsById[heading.id].push(heading);
      })
    });
  }

  itemsAndModifiersByName(){
    let items = Object.values(this.menuItemsById);
    let modifiers = _.reduce(this.modifierGroupsById, (acc, obj) => {
      return acc.concat(Object.values(obj.modifiers));
    }, []);
    return _.sortBy(items.concat(modifiers), o=>o.name.toLowerCase());
  }

  get allow_order_ahead() {
    // todo: should cache this, then clear it when we get a server update on locations or menus;
    return _.some(this.menus, menu => menu.allow_order_ahead)
      || _.some(this.api._locations, loc => loc.allow_order_ahead === 'on');
  }
}


/**
 * Menu Customer vs
 */
class MenuCustomer extends Model {
  
  customer_id = null;
  app_properties = {};
  customer_name = '';
  diet_icons = [];
  emoji_icons = [];
  fee_configs = [];
  order_number_prefix = '';
  promotions = [];
  banner_url = '';
  logo_url = '';
  desktop_banner_url = '';
  timezone = null;
  has_printers = false;

  constructor(data){
    super();
    
    this.update(data);
  }

}
