import Model from './Model';
import mix from "./mixins/Mixin.js";
import hasModifiers from "./mixins/hasModifiers.js";

export default class Modifier extends mix(Model).with(hasModifiers) {

  enabled = false;
  name_for_bartender = '';
  name_for_customer = '';
  menuItemId = null;
  menu_heading_id = null;
  pretax_cents = 0;
  tax_cents = 0;
  tax_fraction = 0;
  is_default_choice = false;
  menu_item_class = null;
  in_stock = false;
  display_position = 0;
  sku = null;
  price_in_cents = 0;
  printers = [];

  price = 0;
  primary_type = null;
  report_category = null;
  skip_ahead_minutes = null;
  price_string = null;
  stations_that_can_fulfill=[];
  name_for_owner = '';
  diet_icons = [];
  tags = [];
  inventory_item_id = null;

  modifierGroups = [];
  _modifier_groups = null;

  constructor(heading, obj){
    super();

    Object.defineProperty(this, 'heading', {value: heading});
    this.update(obj);
  }

  update(data){
    super.update(data);
    if(!this.menu_heading_id) this.menu_heading_id = this.heading.id;
  }

  get name(){
    return this.name_for_bartender || this.name_for_customer;
  }

  get search_string(){
    return this.name;
  }

  get pre_selected(){
    return this.is_default_choice;
  }
  set pre_selected(val){}

  get menu_heading(){
    return this.heading;
  }

  get is_fulfillable(){
    return this.enabled && this.in_stock;// && this.menudata.fulfillable_menuitem_ids.includes(this.menuItemId);
  }
}
