import React, {Component} from 'react';
import {FlatList, Text, View, TouchableWithoutFeedback} from 'react-native';
import moment from "moment";
import EStyleSheet from "react-native-extended-stylesheet";
import _ from "lodash";
import StatusIcon from "../StatusIcon";
import { Typography, Colors } from "../../styles"
import API from "../../api"

/**
 * SmartOrders are orders that are in a guests Cart but have not been checked out.
 */
class SmartOrdersView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showBanner: true
    }
  }

  componentDidMount = async () => {
    let helper_prompts = await API.retrieveAsyncStorageData("helper_prompts")
    this.setState({
      helper_prompts: helper_prompts ? helper_prompts : {},
      showBanner: helper_prompts?.smartOrderBanner,
    })
  }

  dismissBanner = async () => {
    let { helperPrompts } = this.state;
    await API.storeAsyncStorageData("helper_prompts", { ...helperPrompts, smartOrderBanner: false });
    this.setState({
      helper_prompts: Object.assign({}, { ...helperPrompts, smartOrderBanner: false }),
      showBanner: false,
    })
  }

  render() {
    let { smart_orders } = this.props;
    let { showBanner } = this.state;
    return (
      <View style={{flex: 1}}>
        {
          showBanner &&
            <TouchableWithoutFeedback onPress={ this.dismissBanner }>
              <View  style={{ padding: 20 }}>
                <Text style={{ textAlign: "center" }}>Order previews are items in a guest's cart before they place an order.</Text>
                <Text style={{ textAlign: "center" }}>[ Click to Dismiss ]</Text>
              </View>
            </TouchableWithoutFeedback>
        }
        <FlatList
          style={{flexBasis: 0}}
          data={smart_orders}
          renderItem={this.renderSmartOrder}
          keyExtractor={item => (item.location_id + item.activity_time) }
          ListEmptyComponent={this.renderEmptyMessage}
        />
      </View>
    );
  }

  renderEmptyMessage = () => {
    return (
      <View style={{ padding: 20, flex: 1 }}>
        <Text style={[ Typography.header2, { textAlign: 'center', marginBottom: 20 }]}>Empty</Text>
        <Text style={{ textAlign: 'center' }}>Note: The location must have a seated group to be able to track cart items.</Text>
        <Text style={{ textAlign: 'center' }}>You can create a seated group in the Current Orders tab by pressing the guests button.</Text>
      </View>
    );
  }

  renderSmartOrder = ({ item, index }) => {
    if (!item.incart.length) return;
    const activityTime = moment(item.activity_time);
    const hideSuffix = moment().diff(activityTime, 'seconds') < 45;

    let orderTime = activityTime.fromNow(hideSuffix);

    return (
      <View style={styles.orderSection}>
        <View style={[styles.orderHeader, styles.soOrderHeader]}>
          <Text style={styles.orderNumber}>Device { index + 1 }</Text>
          <Text style={styles.orderTime}>Active {orderTime}</Text>
        </View>
        <View>
          { item.incart.length ?
            this.renderSOItems(index, item.incart) : (
              <Text style={{ padding: 5 }}>No items in cart.</Text>
            )}
        </View>
      </View>
    )
  }

  renderSOItems = (index, cart) => {
    return cart.map((item, i) => {
      let menuItem = item.id ? API.menuData.menuItemsById[item.id] : item;
      return (
        <View key={'so_' + index + "_" + i} style={styles.soOrderItem}>
          <View style={{width: 24, height: 24, marginRight: 5, alignItems: 'center'}}>
            <StatusIcon status={'smartorder'} style={{width: '100%', height:'100%'}}/>
          </View>
          <View style={{flexDirection: 'row', flex: 1}}>
            <Text style={{width: 35, textAlign: 'right'}}>{item.qty} x</Text>
            <Text style={{flex: 1, fontWeight: 'bold', paddingLeft: 5}}>{menuItem.name}</Text>
          </View>
        </View>
      )
    });
  }
}

let styles = EStyleSheet.create({
  menuItemsHeader: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    borderBottomColor: '#bebebe',
    borderBottomWidth: 2,
    padding: 5
  },
  headerText: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  noItems: {
    padding: 10,
    backgroundColor: '#ece9f0'
  },
  orderSection: {},
  orderHeader: {
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 5,
    paddingRight: 5,
    flexDirection: 'row',
    backgroundColor: '#ededed',
    borderBottomWidth: 1,
    borderBottomColor: '#c8c8c8',
  },
  soOrderHeader: {
    backgroundColor: '#cde0ed'
  },
  soOrderItem: {
    padding: 5,
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#c8c8c8'
  },
  orderNumber: {
    flex: 1,
  },
  orderTime: {},
  showMoreButton: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: Colors.gray,
  },
  seatedGroupHeader: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.ternary
  },
  seatedGroupHeaderText:{
    fontWeight: 'bold',
    color: Colors.light
  }

});

export default SmartOrdersView;

