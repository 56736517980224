import React, {Component} from 'react';
import API from '../api';
import _ from 'lodash';
import {View, Text, Platform, Picker} from 'react-native';
import Alert from '../components/Alert';
import {Container, Content, Form, Item, Label, Input, Button, Icon} from 'native-base';
import Loader from "../components/Loader";
import {showMessage} from "react-native-flash-message";

export default class DeviceRegistration extends Component {

  state = {
    name_status: '',
    device_name: API.handheldDevice.name,
    selected_profile: API.handheldDevice.config_id,
    loading: true,
    configs: []
  };

  static navigationOptions = {
    title: 'Device Registration'
  };

  componentDidMount() {
    API.getAvailableConfigs().then(data => {
      const configs = _.orderBy(data, 'config_name');
      this.setState({
        loading: false,
        selected_profile: API.handheldDevice.config_id || configs[0].id,
        configs
      })
    });
  }

  render() {
    let {name_status, loading, device_name, selected_profile, configs} = this.state;
    let nameOpts = {};

    if (name_status) nameOpts[name_status] = true;
    return (
      <Container>
        <Loader shown={loading}/>
        <Content>
          <Form>
            <Item inlineLabel>
              <Label style={{flex: 1}}>Customer</Label>
              <Input style={{flex: 4}}
                     value={API.main_customer ? API.main_customer.customer_name :''}
                     editable={false}
              />
            </Item>
            <Item inlineLabel {...nameOpts}>
              <Label style={{flex:1}}>Device Name</Label>
              <Input style={{flex:4}}
                value={device_name}
                testID="deviceNameInput"
                placeholder={"(Required)"}
                accessibilityLabel="deviceName"
                onChangeText={this._onChange}
                autoFocus={true}
              />
              {name_status === 'error' && <Icon name={'close-circle'}/>}
              {name_status === 'success' && <Icon name={'checkmark-circle'}/>}
            </Item>
            <Item inlineLabel>
              <Label style={{flex: 1}}>Device Profile</Label>
              <Picker
                style={Platform.select({
                  web: {
                    backgroundColor: 'white',
                    flex: 4,
                    height: 50,
                    borderWidth: 0
                  },
                  android: {
                    flex: 4
                  }
                })}
                testID="deviceProfilePicker"
                selectedValue={selected_profile}
                onValueChange={this._setDeviceProfile}
                mode={"dialog"}
              >
                {
                  configs.map(config => (
                    <Picker.Item testID={config.id} key={config.id} label={config.config_name} value={config.id}/>
                  ))
                }
              </Picker>
            </Item>
            {Platform.OS !== 'web' && (
              <View>
                <Item inlineLabel>
                  <Label style={{flex: 1}}>Make</Label>
                  <Input style={{flex: 4}}
                         value={API.handheldDevice.make}
                         editable={false}
                  />
                </Item>
                <Item inlineLabel>
                  <Label style={{flex: 1}}>Model</Label>
                  <Input style={{flex: 4}}
                         value={API.handheldDevice.model}
                         editable={false}
                  />
                </Item>
              </View>
            )}
            <View style={{margin: 20, marginTop: 50}}>
              <Button title={"Save"}
                      //disabled={name_status === 'error'}
                      testID="deviceSaveButton"
                      accessibilityLabel="saveDeviceRegistration"
                      onPress={this._onSave} block>
                <Text style={{color: 'white'}}>Save</Text>
              </Button>
            </View>
          </Form>
        </Content>
      </Container>
    )
  }

  _onChange = async (val) => {
    val = _.trimStart(val);

    this.setState({
      device_name: val,
      name_status: !val || val.length < 4 ? 'error' : ''
    });

    this._debouncedValidate(val);
  }

  // Only validate after no input for 500ms
  _debouncedValidate = _.debounce(async (val) => {
     if (val.length >= 4) {
      let response = await API.checkDeviceName(val);
      this.setState({
        name_status: response.valid ? 'success' : 'error'
      })
    }
  }, 500)

  _setDeviceProfile = (val) => {
    this.setState({
      selected_profile: val
    })
  }

  _validateName = (name) => {
    return name.length > 3;
  }

  _onSave = async () => {
    this.setState({
      loading: true,
      name_status: ''
    });

    if (!this._validateName(this.state.device_name)) {
      this.setState({
        loading: false,
        name_status: 'error'
      });
      Alert.alert("Device Name required", "Please name this device");
      return;
    }

    let result = await API.updateDevice({
      name: this.state.device_name,
      config_id: this.state.selected_profile
    });

    if (result.error) {
      this.setState({
        name_status: 'error',
        loading: false
      })
    } else {

      if (!this.props.navigation.getParam('stayAfterSave')) {
        const authStep = this.props.navigation.getParam('authStep');
        this.props.navigation.navigate('AuthLoading', { step: authStep+1});
      } else {
        showMessage({
          message: 'Save Successful!',
          type: 'success',
          position: 'top',
          floating: true,
          autoHide: true
        })
        this.setState({
          loading: false
        })
      }
    }
  }
}
