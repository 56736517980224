import React, {Component} from 'react';
import {View, KeyboardAvoidingView, Modal} from 'react-native';
import Alert from './Alert';
import EStyleSheet from 'react-native-extended-stylesheet';
import {
  Header,
  Body,
  Title,
  Content,
  Footer,
  Right,
  Form,
  Item,
  Input,
  Label,
  Text,
  Button,
  Segment
} from 'native-base';
import Colors from "../constants/Colors";
import PropTypes from 'prop-types';
import {Order} from '../models';
import {styles as globalStyles} from '../styles/Global';
import API from "../api";
import Loader from "./Loader";
import {formatPhoneNumber, validateEmail} from "../helpers/HelperFunctions";

const noop = () => {
}

export default class SendReceiptModal extends Component {

  static propTypes = {
    visible: PropTypes.bool,
    doSend: PropTypes.bool,
    order: PropTypes.instanceOf(Order),
    phone: PropTypes.string,
    email: PropTypes.string,
    onCancel: PropTypes.func,
    onSave: PropTypes.func
  };
  static defaultProps = {
    visible: false,
    doSend: true,
    onSave: noop,
    onCancel: noop,
    email: '',
    phone: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      phone: '',
      processing: false,
      method: 'email'
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let {visible, phone, email, order} = this.props;

    if(visible && !prevProps.visible){
      this.setState({
        phone: phone || order?.patron?.mobile || order?.mobile || order?.extra_checkout_info?.phone_number?.value || '',
        email: email || order?.patron?.email || order?.email || order?.extra_checkout_info?.email?.value || ''
      })
    }
  }

  render() {
    let {visible, doSend} = this.props;
    let {method, processing} = this.state;

    return (
      <Modal visible={visible} transparent={true} onRequestClose={this._onCancel}>
        <View style={globalStyles.modalBackground}>
          <KeyboardAvoidingView style={[styles.modal]}>
            <Loader shown={processing}/>
            <Header hasSegment style={{backgroundColor: Colors.primary}}>
              <Body>
                <Title>Send Receipt</Title>
              </Body>
              <Right style={{marginRight: 15}}>
                <Segment style={{backgroundColor: 'transparent'}}>
                  <Button first active={method==='email'} onPress={()=>this.setState({method:'email'})}>
                    <Text>E-Mail</Text>
                  </Button>
                  <Button last active={method==='phone'} onPress={()=>this.setState({method:'phone'})}>
                    <Text>SMS</Text>
                  </Button>
                </Segment>
              </Right>
            </Header>
            <Content style={{ marginBottom: 10}}>
              <Form >
                { method === 'phone' && (
                  <Item stackedLabel>
                    <Label>Phone Number</Label>
                    <Input
                      value={this.state.phone}
                      autoFocus={true}
                      keyboardType={'phone-pad'}
                      onChangeText={text=>this.setState({phone: text})}
                      onEndEditing={(e) => {
                        if(this.state.phone) {
                          let phone = formatPhoneNumber(this.state.phone);
                          if (phone === null) {
                            Alert.alert("Invalid Phone Number", "The given phone number appears to be invalid");
                          }
                        }
                      }}
                    />
                  </Item>
                )}
                { method === 'email' && (
                  <Item stackedLabel>
                    <Label>E-Mail Address</Label>
                    <Input
                      value={this.state.email}
                      onChangeText={text=>this.setState({email: text})}
                      onEndEditing={(e) => {
                        if(this.state.email) {
                          if (!validateEmail(this.state.email)) {
                            Alert.alert("Invalid E-mail", "The e-mail address appears to be invalid");
                          }
                        }
                      }}
                      keyboardType="email-address"
                    />
                  </Item>
                )}
              </Form>
            </Content>
            <Footer style={{backgroundColor: Colors.light}}>
              <Right>
                <View style={{flexDirection: 'row'}}>
                  <Button danger style={styles.button} onPress={this._onCancel}>
                    <Text>CANCEL</Text>
                  </Button>
                  <Button primary style={styles.button} onPress={this._onSave}>
                    <Text>{doSend ? 'SEND':'SAVE'}</Text>
                  </Button>
                </View>
              </Right>
            </Footer>
          </KeyboardAvoidingView>
        </View>
      </Modal>
    )
  }

  _onCancel = () => {
    let {onCancel} = this.props;
    onCancel();
  }

  _onSave = async () => {
    let {doSend, onSave, order} = this.props;
    let {email, phone, method} = this.state;

    if(method === 'email') phone = '';
    if(method === 'phone') email = '';

    if (doSend && order) {
      this.setState({processing: true});
      let result = await API.sendReceipt(order, email, phone);
      if (result.errorCode) {
        this.setState({processing: false});
        Alert.alert("Error", result.errorCode);
        return;
      } else {
        this.setState({
          processing: false,
          email: '',
          phone: ''
        });
      }
    }

    onSave(phone, email);
  }

}

const styles = EStyleSheet.create({
  modal: {
    borderWidth: 1,
    borderColor: Colors.dark,
    backgroundColor: Colors.light,
    minWidth: 350,
    maxWidth: 450,
    height: 220
  },
  button: {
    marginRight: 20,
  }
});
