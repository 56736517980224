import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {TouchableWithoutFeedback, View, Modal} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import {styles as globalStyles} from "../../styles/Global";
import API from '../../api';
import {
  Container,
  Header,
  Body,
  Title,
  Footer,
  ListItem,
  Content,
  Left,
  Right,
  Radio,
  Text,
  CheckBox,
  Button
} from 'native-base';

export default class ServiceConditionModal extends Component {

  static propTypes = {};

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.visible && !prevProps.visible) {
      this.setState({
        selectedIds: this.props.station.service_condition_ids
      })
    }
  }

  state = {
    selectedIds: []
  }

  render() {
    let {visible, station, onClose, onSuccess} = this.props;
    if (!station) return null;
    let {service_conditions} = station.customer;

    return (
      <Modal visible={visible} transparent={true} onRequestClose={onClose}>
        <TouchableWithoutFeedback onPress={onClose}>
          <View style={globalStyles.modalBackground}>
            <Container style={styles.modal}>
              <Header>
                <Body>
                  <Title>Service Conditions</Title>
                </Body>
              </Header>
              <Content>
                {
                  service_conditions.map(condition => this._getField(condition))
                }
              </Content>
              <Footer>
                <Right>
                  <Button primary onPress={this._save}>
                    <Text>Save</Text>
                  </Button>
                </Right>
              </Footer>
            </Container>
          </View>
        </TouchableWithoutFeedback>

      </Modal>
    );
  }

  _getField = (condition) => {
    let {widget, group_name, choices} = condition;
    let {station} = this.props;
    let {selectedIds} = this.state;
    let result = [
      <ListItem itemDivider key={condition.group_name} style={{borderTopWidth:1, borderBottomWidth:1, borderTopColor: 'lightgray'}}>
        <Left>
          <Text>{group_name}</Text>
        </Left>
      </ListItem>
    ];
    if (widget === 'radio') {
      return result.concat(choices.map((choice, i) => (
        <ListItem
          onPress={() => { this._setServiceConditions(condition, choice.id) }} key={choice.id}
          style={{borderBottomWidth: i === choices.length-1 ? 0 : 1}}
        >
          <Left><Text>{choice.name}</Text></Left>
          <Right>
            <Radio selected={selectedIds.includes(choice.id)} onPress={() => { this._setServiceConditions(condition, choice.id) }}/>
          </Right>
        </ListItem>
      )))
    } else if (widget === 'checkbox') {
      return result.concat(choices.map((choice, i) => (
        <ListItem
          key={choice.id}
          selected={selectedIds.includes(choice.id)}
          style={{borderBottomWidth: i === choices.length-1 ? 0 : 1}}
          onPress={() => { this._setServiceConditions(condition, choice.id) }}>
          <Left><Text>{choice.name}</Text></Left>
          <Right>
            <CheckBox
              checked={selectedIds.includes(choice.id)}
              style={{marginRight: 10}}
               onPress={() => { this._setServiceConditions(condition, choice.id)}}
            />
          </Right>
        </ListItem>
      )))
    }
  }

  _setServiceConditions = async (condition, id) => {
    let selectedIds = this.state.selectedIds;

    if (condition.widget === 'radio') {
      selectedIds = _.without(selectedIds, ...condition.choices.map(c => c.id));
      selectedIds.push(id);
    } else if (condition.widget === 'checkbox') {
      selectedIds = _.xor(selectedIds, [id]);
    }
    this.setState({
      selectedIds: selectedIds
    })
  }

  _save = async () => {
    let {onSuccess} = this.props;

    this.setState({
      processing: true
    });
    let {station} = this.props;
    let {selectedIds} = this.state;
    let response = await API.setServiceConditions(station, selectedIds);
    if (response.success) {
      onSuccess(selectedIds);
    }
    this.setState({
      processing: false
    })
  }

  static get styles() {
    return EStyleSheet.create({
      modal: {
        backgroundColor: 'white',
        width: '50%',
        maxHeight: '80%'
      }
    })
  }
}

const styles = ServiceConditionModal.styles;
