import React, {useState} from "react";
import {Body, Button, Icon, Left, ListItem, Right, Text, View} from "native-base";
import {formatDate} from "../../helpers/HelperFunctions";
import EStyleSheet from "react-native-extended-stylesheet";
import Colors from "../../constants/Colors";
import API from "../../api";
import Loader from "../Loader";

const TYPE_ICONS = {
  info: <Icon type={"FontAwesome5"} name={"info-circle"}/>,
  warning: <Icon type={"FontAwesome"} name={"warning"}/>,
  cutlery: <Icon type={'MaterialCommunityIcons'} name={'silverware-clean'}/>,
  napkins: <Icon type={"Feather"} name={"square"}/>,
  water: <Icon type={'MaterialCommunityIcons'} name={'cup-water'}/>,
  service: <Icon type={"MaterialCommunityIcons"} name={"room-service"}/>,
  custom: <Icon type={"AntDesign"} name={"message1"}/>
}

const NotificationView = ({notification, mode}) => {
  const unread = notification.status === 'active';
  const titleStyle = styles[unread ? 'unread' : 'read'];
  const [last_modified, setLastModified] = useState(notification.last_modified);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);


  if (notification.status === 'dismissed') return null;

  const onAccept = () => {
  }

  const onDismiss = async () => {
    setLoading(true);
    let result = await API.setNotificationState(notification, 'dismissed');
    if (!result.success) {
    }
    setLastModified(result.notification.last_modified)
    setLoading(false);
  }

  const markRead = async () => {
    if (!notification.time_read) {
      let result = await API.setNotificationState(notification, 'read');
      setLastModified(result.notification.last_modified);
    }
    setExpanded(!expanded);
  }

  return (
    <ListItem onPress={markRead} style={{marginLeft: 0}}>
      {loading && <Loader/>}
      <Left style={[styles.left]}>
        {TYPE_ICONS[notification.type]}
      </Left>
      <Body>
        <Text style={titleStyle}>{notification.title}</Text>
        <Text note numberOfLines={ expanded ? null : 2} ellipsizeMode={'tail'}>{notification.body}</Text>
      </Body>
      <Right style={{flexDirection: 'column'}}>
        <Text numberOfLines={1} note>{formatDate(notification.time_created)}</Text>
        <View style={{flexDirection: 'row'}}>
          {unread ? (
            <Button icon transparent onPress={markRead}>
              <Icon type={"Octicons"} name={"primitive-dot"} style={{color: Colors.unreadNotification}}/>
            </Button>
          ) : (
            <Button icon transparent onPress={onDismiss}>
              <Icon type={"FontAwesome"} name={"trash"}/>
            </Button>
          )}
        </View>
      </Right>
    </ListItem>
  )
}

export default NotificationView;

const styles = EStyleSheet.create({
  left: {
    marginLeft: 30,
    marginRight: 20,
    justifyContent: 'center',
    flex: 0
  },
  unread: {
    fontWeight: 'bold',
    color: Colors.unreadNotification
  },
  read: {
    color: 'black'
  }
})
