import React from 'react';
import Card from "./Card";
import {Text} from "native-base";
import Colors from "../../constants/Colors";
import {TouchableWithoutFeedback, View} from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";

{/* The Continue Button */
}
const CheckoutButtonCard = ({check, onApprove, disabled, label}) => {
  return (
    <Card style={{borderBottomWidth: 0}}>
      {!check.isValid() && (
        <Text style={{color: Colors.error, marginBottom: 5}}>Please return the device to your server.</Text>
      )}
      <ContinueButton
        onPress={onApprove}
        disabled={disabled}
        label={label}
      />
    </Card>
  )
}

export default CheckoutButtonCard;

/**
 * The <ContinueButton> is the button at the bottom of the screen. It is not enabled until various criteria in different
 * parts of the <TipScreen> have been satisfied, such as having the required checkout information fields populated.
 *
 * @param props
 * @param {function} props.onPress - What to do once the button has been clicked
 * @param {boolean} props.disabled - True if the user can click this button to finalize the purchase.
 * @param {string} props.label - The text inside of the button
 * @returns {JSX.Element}
 * @constructor
 */
const ContinueButton = (props) => {
  const {onPress, disabled, label, ...rest} = props;

  return (
    <TouchableWithoutFeedback
      testID={'approveButton'}
      accessible={true}
      disabled={disabled}
      onPress={onPress}
      {...rest}
    >
      <View
        style={[
          styles.centered,
          styles.submitButton,
          disabled && styles.submitButtonDisabled
        ]}
      >
        <Text style={styles.submitButtonText}>{label}</Text>
      </View>
    </TouchableWithoutFeedback>
  );
}

const styles = EStyleSheet.create({
  centered: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  submitButton: {
    width: '100%',
    height: 40,
    backgroundColor: Colors.primary // Overwritten if button is disabled, see submitButtonDisabled style
  },
  submitButtonDisabled: {
    backgroundColor: Colors.darkGray
  },
  submitButtonText: {
    color: Colors.light,
    fontWeight: 'bold',
    fontSize: 20
  }
})
