import React from 'react';
import {createStackNavigator} from 'react-navigation-stack';
import LocationsScreen from '../screens/LocationsScreen';
import LocationOverview from '../screens/LocationOverview';
import OrderCreator from '../screens/OrderCreator';
import SplitOrderScreen from '../screens/SplitOrderScreen';
import CardChooser from '../screens/CardChooser';
import CheckoutScreen from '../screens/CheckoutScreen';
import LocationDetails from '../screens/LocationDetails';
import StripeConfig from '../screens/StripeConfig';
import ThankYouScreen from '../screens/ThankYouScreen';
import CaptureSuccessScreen from "../screens/CaptureSuccessScreen";
import GuestManager from "../screens/GuestManager";
import {defaultNavigationOptions} from "./Defaults";
import TabBarIcon from "../components/TabBarIcon";


const LocationStack = createStackNavigator(
  {
    LocationsScreen,
    LocationOverview,
    OrderCreator,
    SplitOrderScreen,
    CheckoutScreen,
    CardChooser,
    LocationDetails,
    StripeConfig,
    GuestManager,
    ThankYouScreen,
    CaptureSuccessScreen
  },
  {
    navigationOptions: ({navigation}) => {
      return {
        tabBarLabel: 'Locations',
        tabBarTestID: 'locations',
        tabBarIcon: (props) => (
          <TabBarIcon
            {...props}
            name={'location'}
            type={'Entypo'}
          />
        ),
        tabBarVisible: navigation.state.index === 0
      }
    },
    defaultNavigationOptions
  }
);

export default LocationStack;



